import { CommonModule } from "@angular/common";
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    ViewChild,
} from "@angular/core";

import { LayoutFacade } from "@hermes/aphrodite/layout";
import { LoaderSpinnerComponent } from "@hermes/aphrodite/loader-spinner";
import { Context } from "@hermes/app-core";
import { stopEventPropagation } from "@hermes/utils-generic/helpers";

/**
 * This component implements the Hermes Call To Action (CTA) button.
 * Not to be confused with the Action Button (h-action-button), which is the incidental button on a page.
 * Here we have an CTA for "Add to cart" or "Pay" a product, for example.
 *
 * Usage:
 * ```
 *  <h-call-to-action [appearance]="'secondary'"
 *                    [size]="'medium'"
 *                    [name]="'cta-name'"
 *                    [loading]="false"
 *                    [disabled]="false"
 *                    [doesDomButtonNeedToBeFocused]="false"
 *                    [type]="'cta'"
 *                    [id]="'cta-id'"
 *                    [describedBy]="'cta description'"
 *                    [labelledBy]="'cta-label'"
 *                    [trayTargetId]="'cta-tray-target-id'"
 *                    (pressed)="pressed()"
 *                    [dataTestId]="'My cta'">
 *      <ng-container svgLeft>
 *          <h-svg-icon [name]="'my-svg-if-I-want-one-on-left'"></h-svg-icon>
 *      </ng-container>
 *      <ng-container i18n="@@my.key.translate">
 *          My CTA
 *      </ng-container>
 *      <ng-container svgRight>
 *          <h-svg-icon [name]="'my-svg-if-I-want-one-on-right'"></h-svg-icon>
 *      </ng-container>
 *  </h-call-to-action>
 * ```
 *
 */

export const SMALL = "small";
export const MEDIUM = "medium";
export const LARGE = "large";
export const AUTO = "auto";
export const MAX = "max";
export type CtaSize =
    | typeof SMALL
    | typeof MEDIUM
    | typeof LARGE
    | typeof AUTO
    | typeof MAX;

export const PRIMARY = "primary";
export const SECONDARY = "secondary";
export const TERTIARY = "tertiary";
export type CtaAppearance = typeof PRIMARY | typeof SECONDARY | typeof TERTIARY;

export const BUTTON = "button";
export const SUBMIT = "submit";
export type HtmlAttributeType = typeof BUTTON | typeof SUBMIT;

export const SVG_LEFT = "svgLeft";
export const SVG_RIGHT = "svgRight";
export const SVG_CENTER = "svgCenter";
export type CtaSvgSide = typeof SVG_LEFT | typeof SVG_RIGHT | typeof SVG_CENTER;

export const SVG_PLUS = "plus";
export const SVG_MINUS = "minus";
export type CtaSvgIcon = typeof SVG_PLUS | typeof SVG_MINUS;

export const SVG_WHITE = "stroke:white";
export const SVG_BLACK = "stroke:black";
export type CtaSvgColor = typeof SVG_WHITE | typeof SVG_BLACK;

@Component({
    selector: "h-call-to-action",
    templateUrl: "./call-to-action.component.html",
    styleUrls: ["./call-to-action.component.scss"],
    imports: [CommonModule, LoaderSpinnerComponent],
    standalone: true,
})
export class CallToActionComponent implements OnChanges {
    @Input()
    public type: HtmlAttributeType = BUTTON;

    @Input()
    public id: string | undefined;

    @Input()
    public appearance: CtaAppearance = PRIMARY;

    @Input()
    public name: string | undefined;

    @Input()
    public loading: boolean = false;

    @Input()
    public disabled: boolean = false;

    @Input()
    public describedBy?: string;

    @Input()
    public labelledBy?: string;

    @Input()
    public label?: string;

    /**
     * For automated tests
     */
    @Input()
    public dataTestId?: string;

    /**
     * When provided, the button will have aria attributes to open the tray
     *
     * @see aria-dialog pattern.
     */
    @Input()
    public trayTargetId: string | undefined;

    @Input()
    public size: CtaSize = LARGE;

    /**
     * When the button is next to a field we apply a specific height to match the field's height
     */
    @Input()
    public isNextToField: boolean = false;

    /**
     * Some callers of this component prefer to manage SVG sizes themselves
     */
    @Input()
    public enableSvgSizes: boolean = true;

    @Input()
    public doesDomButtonNeedToBeFocused: boolean = false;

    @Output()
    public pressed: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild("button")
    public button!: ElementRef<HTMLButtonElement>;

    constructor(private context: Context, public layoutFacade: LayoutFacade) {}

    public ngOnChanges(): void {
        if (
            this.context.isInBrowserMode() &&
            this.doesDomButtonNeedToBeFocused &&
            this.button
        ) {
            requestAnimationFrame(() => {
                this.button.nativeElement.focus();
            });
        }
    }

    /**
     * Prevent a click when the button is disabled with aria-disable
     * why is aria-disable better than disabled? Because the button can still be
     * focusable and read by the screen reader
     *
     *
     * @param event Event
     */
    public stopPropagation(event: Event): void {
        stopEventPropagation(event);
    }
}
