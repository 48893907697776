import { GTMEventProductClickData } from "../types/gtm.type";
import { AnalyticsEvent } from "./analytics.event";

/**
 * Abstract class for call-to-action event
 */
export abstract class CartAnalyticsEvent
    implements AnalyticsEvent<GTMEventProductClickData>
{
    /**
     * Event name expected for every uaevent event
     */
    public name: string = "addToCart";

    /**
     * Event's data from component to format correctly event
     */
    public eventData: any;

    constructor(data: { [key: string]: any }) {
        this.eventData = data;
    }

    /**
     * Overloaded by each subclass with their own formatter
     * Return event formatted to send to GTM
     */
    public formatForGTM(): GTMEventProductClickData {
        return {
            event: this.name,
        };
    }
}
