import { HttpClient, HttpContext } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import {
    Observable,
    Subscription,
    map,
    merge,
    partition,
    switchMap,
} from "rxjs";

import { FooterData, FooterDataDeprecated } from "@hermes/api-model-shell";
import { Settings } from "@hermes/app-core";
import {
    ENABLE_FOOTER_FROM_DISCOVERY,
    FeatureFlagFacade,
} from "@hermes/states/flipper";
import { SKIP_404_INTERCEPTOR } from "@hermes/utils/constants";

import { mapFooterDataDeprecatedToFooterData } from "./mappers/footer.mapper";

/**
 * Service to fetch footer data
 */
@Injectable()
export class FooterService implements OnDestroy {
    private subscription = new Subscription();

    constructor(
        private http: HttpClient,
        private settings: Settings,
        private featureFlagFacade: FeatureFlagFacade,
    ) {}

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    /**
     * Fetch footer items from Drupal
     */
    public fetchFooterData(): Observable<FooterData> {
        const context = new HttpContext().set(SKIP_404_INTERCEPTOR, true);
        const isFooterDataFromDiscovery$ = this.featureFlagFacade.isActivated(
            ENABLE_FOOTER_FROM_DISCOVERY,
        );

        const [request$, deprecatedRequest$] = partition(
            isFooterDataFromDiscovery$,
            Boolean,
        );

        const footer$: Observable<FooterData> = request$.pipe(
            switchMap(() =>
                this.http.get<FooterData>(
                    `${this.settings.apiUrl}/footer-items`,
                    {
                        context,
                    },
                ),
            ),
        );

        const footerDeprecated$: Observable<FooterData> =
            deprecatedRequest$.pipe(
                switchMap(() =>
                    this.http.get<FooterDataDeprecated>(
                        `${this.settings.apiUrl}/footer-items`,
                        {
                            context,
                        },
                    ),
                ),
                map(mapFooterDataDeprecatedToFooterData),
            );

        return merge(footer$, footerDeprecated$);
    }
}
