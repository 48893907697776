<button
    [class]="'icon-button ' + 'size-' + buttonSize"
    type="button"
    [style.line-height]="lineHeight"
    [tabIndex]="disableTabIndex ? -1 : 0"
    [ngClass]="{
        'keyboard-navigation': isKeyboardNavigationActive,
        'border-radius': borderRadius,
        'text-border-radius': borderRadius && isSvgWithText,
        'focus-padding': [smallSize, mediumSize].includes(buttonSize)
    }"
    [attr.id]="id"
    [name]="name"
    [attr.aria-label]="label"
    [attr.aria-describedby]="describedBy"
    (focusout)="focusOut.emit()"
    (click)="pressed.emit($event)"
    #iconButton>
    <div
        class="border-radius"
        [style.gap.px]="gapBetweenSvgAndText"
        [ngClass]="{
            'button-wrapper': !isSvgWithText,
            'box-shadow': boxShadow,
            background: !changeOpacityOnHover,
            border: border,
            opacity: changeOpacityOnHover,
            'background-secondary': !changeOpacityOnHover && backgroundColor === 'secondary',
            'background-secondary-extra-light': !changeOpacityOnHover && backgroundColor === 'secondary-extra-light',
            'background-secondary-dark': !changeOpacityOnHover && backgroundColor === 'secondary-dark',
            'background-secondary-light': !changeOpacityOnHover && backgroundColor === 'secondary-light',
            'svg-with-text': isSvgWithText,
            'svg-with-text-right': appearance === svg_with_text_right,
            'text-bold-uppercase': textBoldUppercase,
        }">
        @if (isLoading) {

        <h-loader-spinner [size]="buttonSize"></h-loader-spinner>

        } @else {

        <span class="svg-wrapper" [style.height]="iconSize" [style.width]="iconSize">
            <ng-content></ng-content>
        </span>
        <span
            [ngClass]="hasTransition && appearance === svg_with_text_left ? 'text-wrapper' : ''"
            [@slideInText]="hasTransition && appearance === svg_with_text_left"
            [@.disabled]="!(hasTransition && appearance === svg_with_text_left)">
            <ng-content select="[text]"></ng-content>
        </span>

        }
    </div>
</button>
