import {
    Product,
    ProductItemBikini,
    ProductSizeVariant,
} from "@hermes/api-model-product";
import { getStockTypeAndDisplayOnly } from "@hermes/fragments/product-utils";
import { PRODUCT_PAGE } from "@hermes/states/add-to-cart";
import {
    PRODUCT_IN_STOCK,
    GTMEcommerceItemData,
    GTMEventProductClickData,
    CartAnalyticsEvent,
    NO_SIZE,
} from "@hermes/utils/analytics";

import { isDoubleObject } from "@hermes/utils-generic/helpers";

import { getFormatedSku } from "../helpers/product-page-analytics.helpers";

/**
 * This class is used for add-to-cart event in cart page for a bikini
 */
export class AddToCartBikini extends CartAnalyticsEvent {
    public override eventData: {
        currencyCode?: string;
        product: Product;
        variants: ProductSizeVariant[];
    };

    constructor(data: {
        currencyCode?: string;
        product: Product;
        variants: ProductSizeVariant[];
    }) {
        super(data);
        this.eventData = data;
    }

    /**
     * Overload formatForGTM of inherited class
     * Return formatter for ecommerce key
     */
    public override formatForGTM(): GTMEventProductClickData {
        return {
            ...super.formatForGTM(),
            ecommerce: {
                currencyCode: this.eventData.currencyCode,
                add: {
                    products: [
                        this.getProductData(
                            this.eventData.product,
                            this.eventData.variants,
                        ),
                    ],
                },
            },
        };
    }

    private getProductData(
        product: Product,
        variants?: ProductSizeVariant[],
    ): GTMEcommerceItemData {
        const isIdo = isDoubleObject(product.sku);
        const bikiniItems = product.bikiniAttributes?.items ?? [];
        const getBikiniCodes = (attribute: keyof ProductItemBikini) =>
            isIdo
                ? `${bikiniItems[0][attribute] as string}|${
                      bikiniItems[1][attribute] as string
                  }`
                : bikiniItems[0][attribute];

        return {
            name: product.title,
            id: getFormatedSku(product),
            price: product.price ? product.price.toFixed(2) : undefined,
            brand: "hermes",
            category: `${bikiniItems[0].departmentCode}/${bikiniItems[0].familyCode}`,
            variant: `${bikiniItems[0].colorHermes}|${bikiniItems[1].colorHermes}`,
            quantity: 1,
            dimension5:
                variants?.[0] && variants?.[1]
                    ? `${variants?.[0].size}|${variants?.[1].size}`
                    : NO_SIZE,
            dimension6: getBikiniCodes("departmentCode"),
            dimension7: getBikiniCodes("familyCode"),
            dimension8: getBikiniCodes("productCode"),
            dimension9: getBikiniCodes("divisionCode"),
            dimension37: PRODUCT_IN_STOCK,
            dimension52: "no",
            dimension53: getStockTypeAndDisplayOnly(
                product.stock,
                product.displayMode,
            ),
            dimension54: "no",
            context: PRODUCT_PAGE,
        } as GTMEcommerceItemData;
    }
}
