import {
    HttpClient,
    HttpContext,
    HttpErrorResponse,
    HttpHeaders,
    HttpParams,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { JsonConvert } from "json2typescript";
import { catchError, map, Observable, of } from "rxjs";

import {
    AddAddressResponse,
    AddressRequest,
    AddressSourceTypeEnum,
    PrivateContact,
    ValidationCodeCheckRequestBody,
} from "@hermes/api-model-account";
import { Address, AddressTypeEnum } from "@hermes/api-model-address";
import { EReservationsInfo } from "@hermes/api-model-eresa";
import { GuestRequest, GuestResponse } from "@hermes/api-model-session";
import { Settings } from "@hermes/app-core";
import {
    GUEST_BCK_RESPONSE_INVALID_FORMAT,
    GUEST_BCK_RESPONSE_NEW_CUSTOMER,
    GUEST_BCK_RESPONSE_RETURNING_CUSTOMER,
    GUEST_BCK_RESPONSE_WRONG_VALIDATION_CODE,
    GuestBCKResponse,
    SKIP_404_INTERCEPTOR,
} from "@hermes/utils/constants";
import {
    EcomErrorCodes,
    HTTP_JSON_CONTENT_TYPE_HEADER,
    HTTP_NO_CACHE_CONTROL_HEADER,
} from "@hermes/utils-generic/constants";

const X_PRIVATE_CONTACT = "x-private-contact";

@Injectable()
export class CustomerService {
    constructor(private httpClient: HttpClient, private settings: Settings) {}

    public getCustomerEReservations(
        isFullRequest: boolean = false,
    ): Observable<EReservationsInfo> {
        return this.httpClient.get<EReservationsInfo>(
            `${this.settings.apiUrl}/ereservations`,
            { params: new HttpParams().set("isFullRequest", isFullRequest) },
        );
    }

    public cancelCustomerEReservation(
        eReservationId: number,
    ): Observable<void> {
        return this.httpClient.put<void>(
            `${this.settings.apiUrl}/ereservations/${eReservationId}/cancel`,
            {},
        );
    }

    public checkValidationCode(
        validationCodeRequest: ValidationCodeCheckRequestBody,
    ): Observable<null | HttpErrorResponse> {
        validationCodeRequest = new JsonConvert().serializeObject(
            validationCodeRequest,
        );

        return this.httpClient.post<null | HttpErrorResponse>(
            `${this.settings.apiUrl}/customer/validation-code/check-code`,
            validationCodeRequest,
            {
                headers: new HttpHeaders({
                    ...HTTP_JSON_CONTENT_TYPE_HEADER,
                }),
                context: new HttpContext().set(SKIP_404_INTERCEPTOR, true),
            },
        );
    }

    /**
     * Verify if the user already exists by calling the get route : /customer/guest/verification
     * returns Observable<GuestBCKResponse>
     */
    public getGuestVerification(
        guestRequest: PrivateContact,
        guest: boolean = false,
    ): Observable<GuestBCKResponse> {
        return this.httpClient
            .get<{ socialSecurityNumber?: string; isGuest?: boolean }>(
                `${this.settings.apiUrl}/customer/guest/verification`,
                {
                    headers: new HttpHeaders({
                        ...HTTP_NO_CACHE_CONTROL_HEADER,
                        [X_PRIVATE_CONTACT]: JSON.stringify(guestRequest),
                    }),
                    params: new HttpParams().set("guest", guest),
                    context: new HttpContext().set(SKIP_404_INTERCEPTOR, true),
                },
            )
            .pipe(
                map((guestVerificationResponse) => {
                    // when guest
                    const response = {
                        event: GUEST_BCK_RESPONSE_NEW_CUSTOMER,
                    } as GuestBCKResponse;

                    if (guestVerificationResponse?.isGuest) {
                        return response;
                    }

                    // when returning customer
                    response.event = GUEST_BCK_RESPONSE_RETURNING_CUSTOMER;

                    if (guestVerificationResponse?.socialSecurityNumber) {
                        response.socialSecurityNumber =
                            guestVerificationResponse.socialSecurityNumber;
                    }

                    return response;
                }),
                catchError((error) => {
                    if (error.status === 401) {
                        return of({
                            event: GUEST_BCK_RESPONSE_NEW_CUSTOMER,
                        } as GuestBCKResponse);
                    }
                    if (
                        error.status === 400 &&
                        error.error?.error ===
                            EcomErrorCodes.LOGIN_SMS_WRONG_CODE
                    ) {
                        return of({
                            event: GUEST_BCK_RESPONSE_WRONG_VALIDATION_CODE,
                        } as GuestBCKResponse);
                    }
                    if (error.status === 400) {
                        return of({
                            event: GUEST_BCK_RESPONSE_INVALID_FORMAT,
                        } as GuestBCKResponse);
                    }
                    throw new Error(`fetch guest error : ${error.error}`);
                }),
            );
    }

    public postGuest(guestRequest: GuestRequest): Observable<GuestResponse> {
        return this.httpClient.post<GuestResponse>(
            `${this.settings.apiUrl}/customer/guest`,
            guestRequest,
            {
                headers: new HttpHeaders({
                    ...HTTP_JSON_CONTENT_TYPE_HEADER,
                }),
                context: new HttpContext().set(SKIP_404_INTERCEPTOR, true),
            },
        );
    }

    /**
     * Get addresses of the current user
     * @param type if not specified we retrieve all known addresses (billing + shipping)
     */
    public getAddresses(
        type?: AddressTypeEnum | undefined,
    ): Observable<Address | Address[]> {
        let params = new HttpParams();

        if (type) {
            params = params.set("type", type);
        }

        return this.httpClient
            .get<Address | Address[]>(
                `${this.settings.apiUrl}/customer/addresses`,
                {
                    params,
                    headers: new HttpHeaders({
                        ...HTTP_JSON_CONTENT_TYPE_HEADER,
                    }),
                    context: new HttpContext().set(SKIP_404_INTERCEPTOR, true),
                },
            )
            .pipe(
                catchError((error) => {
                    throw error;
                }),
            );
    }

    /**
     * Add an address of the current user or guest
     */
    public addAddress(
        address: Address,
        source: AddressSourceTypeEnum,
        addressType: AddressTypeEnum,
        isKnownButNotConnected: boolean = false,
    ): Observable<AddAddressResponse> {
        const request = new AddressRequest();
        request.address = address;
        request.source = source;
        request.addressType = addressType;
        request.isKnownButNotConnected = isKnownButNotConnected;
        const serializedRequest = new JsonConvert().serializeObject(request);

        return this.httpClient
            .post<AddAddressResponse>(
                `${this.settings.apiUrl}/customer/address`,
                serializedRequest,
                {
                    headers: new HttpHeaders({
                        ...HTTP_JSON_CONTENT_TYPE_HEADER,
                    }),
                    context: new HttpContext().set(SKIP_404_INTERCEPTOR, true),
                },
            )
            .pipe(
                catchError((error) => {
                    throw error;
                }),
            );
    }

    /**
     * Edit an existing address of the current user or guest
     */
    public editAddress(
        address: Address,
        source: AddressSourceTypeEnum,
        addressType: AddressTypeEnum,
    ): Observable<void> {
        const request = new AddressRequest();
        request.address = address;
        request.source = source;
        request.addressType = addressType;

        const serializedRequest = new JsonConvert().serializeObject(request);

        return this.httpClient
            .put<void>(
                `${this.settings.apiUrl}/customer/address/edit`,
                serializedRequest,
                {
                    headers: new HttpHeaders({
                        ...HTTP_JSON_CONTENT_TYPE_HEADER,
                    }),
                    context: new HttpContext().set(SKIP_404_INTERCEPTOR, true),
                },
            )
            .pipe(
                catchError((error) => {
                    throw error;
                }),
            );
    }

    public deleteAddress(addressId: string): Observable<void> {
        return this.httpClient
            .delete<void>(
                `${this.settings.apiUrl}/customer/address/remove/${addressId}`,
                {
                    headers: new HttpHeaders({
                        ...HTTP_JSON_CONTENT_TYPE_HEADER,
                    }),
                    context: new HttpContext().set(SKIP_404_INTERCEPTOR, true),
                },
            )
            .pipe(
                catchError((error) => {
                    throw error;
                }),
            );
    }
}
