import { createFeatureSelector, createSelector } from "@ngrx/store";

import * as fromErrors from "../reducers/errors.reducer";

export const selectErrorsState = createFeatureSelector<fromErrors.State>(
    fromErrors.errorsFeatureKey,
);

export const selectHasCheckoutError = createSelector(
    selectErrorsState,
    (errorsState: fromErrors.State) => errorsState.hasCheckoutError,
);
