import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

import type { HermesSvg } from "../../icons/svg.model";

@Component({
    selector: "h-svg-icon[name]",
    templateUrl: "./svg-icon.component.html",
    styleUrls: ["./svg-icon.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class SvgIconComponent {
    @Input()
    public name: HermesSvg | undefined;
    @Input()
    public testId: string | undefined;
    @Input()
    public ariaLabel: string | undefined;
    @Input()
    public ariaHidden: boolean = true;
    @Input()
    public role: string = "presentation";

    /**
     * Provide explicit viewBox for svg element
     *
     * Optional, but may be required in some cases.
     * If provided, it SHOULD match the actual viewBox of the svg icon.
     */
    @Input()
    public viewBox: string | undefined;
}
