import { CrossSellingProduct } from "@hermes/api-model-product";
import { GTMEcommerceData, NO, NODATA, YES } from "@hermes/utils/analytics";

import { ProductClickAnalyticsEvent } from "./product-click.event";

/**
 * This class is used for product-click event in product-content
 */
export class ProductContentProductClickEvent extends ProductClickAnalyticsEvent {
    /**
     * Overload formatForGTM of inherited class
     * Return formatter for ecommerce key
     */
    public override formatForGTM(): GTMEcommerceData {
        return {
            ...super.formatForGTM(),
            ecommerce: {
                click: {
                    products: [this.getProductData(this.eventData.product)],
                    actionField: {
                        list: this.eventData.list,
                    },
                },
            },
        };
    }

    /**
     * Formatter for product
     *
     * @param product product clicked
     */
    private getProductData = (product: CrossSellingProduct) => ({
        name: product.title,
        brand: "hermes",
        id: product.sku,
        price: product.price,
        category: `${product.departmentCode}/${product.familyCode}`,
        variant: product.avgColor || NODATA,
        dimension5: NODATA,
        dimension6: product.departmentCode,
        dimension7: product.familyCode,
        dimension8: product.productCode,
        dimension9: product.divisionCode,
        dimension37: product.hasStock ? "in stock" : "out of stock",
        dimension52: product.personalize ? YES : NO,
    });
}
