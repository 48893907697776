import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, tap } from "rxjs/operators";

import { AnalyticsService } from "@hermes/utils/analytics";

import {
    addGiftSetItem,
    removeGiftSetItem,
} from "./../actions/giftset-product-page.actions";
import { AddRemoveGiftsetItemEvent } from "./../events/add-remove-giftset-item-event-click.event";

/**
 * Effects for send analytics event on product page
 * For some effect we need to know in wich template we are
 * withLatestForm is for this, before our effect we receive templateType from State
 * each effects send analytics event corresponding
 */
@Injectable()
export class GiftSetPageAnalyticsEffects {
    public addGiftsetItem$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(addGiftSetItem),
                map((action: ReturnType<typeof addGiftSetItem>) => action),
                tap((action) => {
                    this.analyticsService.sendData(
                        new AddRemoveGiftsetItemEvent({
                            eventaction: "Fragrance_Selector",
                            sku: action.sku,
                        }),
                    );
                }),
            ),
        { dispatch: false },
    );

    public removeGiftsetItem$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(removeGiftSetItem),
                map((action: ReturnType<typeof removeGiftSetItem>) => action),
                tap((action) => {
                    this.analyticsService.sendData(
                        new AddRemoveGiftsetItemEvent({
                            eventaction: "Fragrance_Removal",
                            sku: action.sku,
                        }),
                    );
                }),
            ),
        { dispatch: false },
    );

    constructor(
        private actions$: Actions,
        private analyticsService: AnalyticsService,
    ) {}
}
