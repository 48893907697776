import { Inject, Injectable } from "@angular/core";
import type { Ripe } from "ripe-sdk/src/js";

import { Logger } from "@hermes/logger";
import { LOGGER } from "@hermes/web-logger";

import { initializeDkuConfiguration } from "../../mappers/platforme-dku.mapper";
import {
    getRadioButtonModelElement,
    getRadioButtonModelFontColor,
    getRadioButtonModelFontSize,
    getRadioButtonModelFontStyle,
    mapColorsForSpecificLeather,
} from "../../mappers/product-personalization.mapper";
import {
    LeatherCombination,
    Material,
    Part,
    PlatformeConfigResponse,
    RadioButtonItemModel,
    RadioButtonModel,
    RadioButtonType,
    SmallLeatherGoodsConfigurator,
    Color,
    DkuConfiguration,
    MappedSmlgConfigurator,
    PartMaterial,
    SmallLeatherGoodsLeather,
} from "../../types/product-personalization.type";

import { MapPlatformeResponseService } from "./map-platforme-response.service";

@Injectable()
export class SmlgMapPlatformeResponseService {
    private defaultHexaColor = "#00459c";

    constructor(
        private mapPlatformeResponseService: MapPlatformeResponseService,
        @Inject(LOGGER) private logger: Logger,
    ) {}

    /**
     * Construct Smallleathergoods configurator
     */
    public initSmlgConfigurator(
        platformeInitialConfiguration: PlatformeConfigResponse,
        ripeInstance: Ripe,
    ): MappedSmlgConfigurator {
        let mappedSmlgConfigurator: MappedSmlgConfigurator = {
            markingConfiguration: {
                message: "",
                markingRules: [],
            },
            leather: [],
            engravingConfigurator: {
                fontConf: [],
                fontSizeConf: [],
                styleConf: [],
                positionConf: [],
            },
            luckySigns: {
                details: [],
            },
        };
        const dkuConfigurationSmlg = this.initConfigSmlg(ripeInstance);

        // Generic mapping
        mappedSmlgConfigurator.markingConfiguration.message =
            dkuConfigurationSmlg?.message ?? "";
        mappedSmlgConfigurator =
            this.mapPlatformeResponseService.mapPlatformeProperties(
                dkuConfigurationSmlg,
                platformeInitialConfiguration.initials.properties,
                mappedSmlgConfigurator,
            );
        mappedSmlgConfigurator = this.mapPlatformeResponseService.mapLuckySigns(
            platformeInitialConfiguration.meta.lucky_signs,
            mappedSmlgConfigurator,
        );

        return this.mapSmlgLeather(
            platformeInitialConfiguration,
            mappedSmlgConfigurator,
            dkuConfigurationSmlg,
        );
    }

    /**
     * Map leather combinations and colors/sku associated with them
     */
    public mapSmlgLeather(
        platformeInitialConfig: PlatformeConfigResponse,
        smlgConfigurator: MappedSmlgConfigurator,
        dkuConfiguration: DkuConfiguration,
    ): MappedSmlgConfigurator {
        let haveDefaultLeather = false;
        platformeInitialConfig.meta.leather_combinations =
            this.clearLeatherCombinations(platformeInitialConfig);
        platformeInitialConfig.meta.leather_combinations.forEach(
            (combination: LeatherCombination) => {
                let updatedCombination = {} as SmallLeatherGoodsLeather;

                updatedCombination.default = false;
                if (
                    dkuConfiguration.exterior?.material ===
                        combination.exterior_leather &&
                    dkuConfiguration.lining?.material ===
                        combination.interior_leather
                ) {
                    updatedCombination.default = true;
                    haveDefaultLeather = true;
                }
                updatedCombination.name = combination.id;
                updatedCombination.interiorLeather =
                    combination.interior_leather;
                updatedCombination.exteriorLeather =
                    combination.exterior_leather;
                updatedCombination.translation =
                    combination.combination_key_trad;

                platformeInitialConfig.parts.forEach((part) => {
                    updatedCombination = this.mapSmlgLeatherParts(
                        part,
                        updatedCombination,
                        combination,
                        platformeInitialConfig,
                        dkuConfiguration,
                    );
                });
                smlgConfigurator.leather?.push(updatedCombination);
            },
        );

        // Set default combination if the dku was empty
        if (
            haveDefaultLeather === false &&
            smlgConfigurator.leather?.length > 0
        ) {
            smlgConfigurator.leather[0].default = true;
        }
        return smlgConfigurator;
    }

    /**
     * Map leather combination parts
     */
    public mapSmlgLeatherParts(
        part: Part,
        updatedCombination: SmallLeatherGoodsLeather,
        combination: LeatherCombination,
        platformeConfigurationResponse: PlatformeConfigResponse,
        dkuConfiguration: DkuConfiguration,
    ): SmallLeatherGoodsLeather {
        // Exterior
        if (part.name === PartMaterial.exterior) {
            part.materials.forEach((material) => {
                if (
                    dkuConfiguration.exterior?.color &&
                    platformeConfigurationResponse.meta.force_bicolor &&
                    material.name === combination.exterior_leather
                ) {
                    updatedCombination.exteriorColors =
                        mapColorsForSpecificLeather(
                            material.colors,
                            dkuConfiguration.exterior.color,
                            material.name ===
                                dkuConfiguration.exterior.material,
                            platformeConfigurationResponse.meta.force_bicolor,
                        );

                    updatedCombination.sku =
                        platformeConfigurationResponse.meta.sku_generic[
                            material.name
                        ] ?? "";
                }
            });
        }

        // Interior
        if (part.name === PartMaterial.lining) {
            part.materials.forEach((material) => {
                if (
                    dkuConfiguration.lining?.color &&
                    platformeConfigurationResponse.meta?.force_bicolor &&
                    material.name === combination.interior_leather
                ) {
                    updatedCombination.interiorColors =
                        mapColorsForSpecificLeather(
                            material.colors,
                            dkuConfiguration.lining.color,
                            material.name === dkuConfiguration.lining.material,
                            platformeConfigurationResponse.meta.force_bicolor,
                        );
                }
            });
        }

        return updatedCombination;
    }

    /**
     * Init the DKU configuration for smallleathergoods specific attributes and return it
     */
    public initConfigSmlg(ripeInstance: Ripe): DkuConfiguration {
        const dkuConfiguration = {
            lining: ripeInstance.parts.lining,
            exterior: ripeInstance.parts.exterior,
        };

        return initializeDkuConfiguration(ripeInstance, dkuConfiguration);
    }
    /**
     * Allow to create a conf object per input
     */
    public convertMappedSmlgConfiguratorToSmallLeatherGoodsConfigurator(
        mappedPlateformeConfigurator: MappedSmlgConfigurator,
    ): SmallLeatherGoodsConfigurator {
        const smallLeatherGoodsConfigurator =
            {} as SmallLeatherGoodsConfigurator;

        // MarkingConfiguration
        smallLeatherGoodsConfigurator.markingConfiguration =
            mappedPlateformeConfigurator.markingConfiguration;

        const engraving = mappedPlateformeConfigurator.engravingConfigurator;

        // Font marking zone
        if (engraving?.positionConf) {
            smallLeatherGoodsConfigurator.fontMarkingZone =
                getRadioButtonModelElement(
                    false,
                    RadioButtonType.Text,
                    "fontMarkingZone",
                    engraving.positionConf,
                    true,
                );
        }

        // Font size
        if (engraving?.fontSizeConf && engraving.fontSizeConf.length > 0) {
            smallLeatherGoodsConfigurator.fontSize =
                getRadioButtonModelFontSize(engraving.fontSizeConf);
        }

        // Font style
        if (engraving?.styleConf && engraving.styleConf.length > 0) {
            smallLeatherGoodsConfigurator.fontStyle =
                getRadioButtonModelFontStyle(engraving.styleConf);
        }

        // Font color
        if (engraving?.fontConf && engraving.fontConf.length > 0) {
            smallLeatherGoodsConfigurator.fontColor =
                getRadioButtonModelFontColor(engraving.fontConf);
        }

        // Lucky signs
        smallLeatherGoodsConfigurator.luckySigns =
            mappedPlateformeConfigurator.luckySigns;

        // Leather combinations & colors
        smallLeatherGoodsConfigurator.leather = getRadioButtonModelElement(
            true,
            RadioButtonType.Text,
            "leather",
            this.mapLeatherItem(
                mappedPlateformeConfigurator.leather ?? [],
                smallLeatherGoodsConfigurator,
                "default",
            ),
            false,
        );
        return smallLeatherGoodsConfigurator;
    }

    /**
     * Allow to map leather
     */
    public mapLeatherItem(
        leather: SmallLeatherGoodsLeather[],
        smallLeatherGoodsConfigurator: SmallLeatherGoodsConfigurator,
        selectedLeather: string,
    ): RadioButtonItemModel[] {
        const result: RadioButtonItemModel[] = [];
        leather.forEach((item: SmallLeatherGoodsLeather) => {
            // If chosen leather on configurator, default configured leather otherwise.
            const isDefaultItem =
                selectedLeather === "default"
                    ? item.default ?? true
                    : item.name === selectedLeather;
            const radioButtonItem: RadioButtonItemModel =
                {} as RadioButtonItemModel;
            radioButtonItem.id = `config_${item.name}`;
            radioButtonItem.value = `${item.name}`;
            radioButtonItem.name = item.name ?? "";
            radioButtonItem.disabled = false;
            radioButtonItem.default = isDefaultItem;

            radioButtonItem.sku = item.sku ?? "";
            radioButtonItem.exteriorLeather = item.exteriorLeather;
            radioButtonItem.liningLeather = item.interiorLeather;

            if (isDefaultItem) {
                this.mapColor(
                    item.interiorColors ?? [],
                    PartMaterial.lining,
                    smallLeatherGoodsConfigurator,
                );
                this.mapColor(
                    item.exteriorColors ?? [],
                    PartMaterial.exterior,
                    smallLeatherGoodsConfigurator,
                );
            }
            result.push(radioButtonItem);
        });
        return result;
    }

    /**
     * Allow to map color
     */
    public mapColor(
        colors: Color[],
        part: PartMaterial,
        smallLeatherGoodsConfigurator: SmallLeatherGoodsConfigurator,
    ): void {
        const exteriorColor: RadioButtonModel = {} as RadioButtonModel;
        const interiorColor: RadioButtonModel = {} as RadioButtonModel;
        const result: RadioButtonItemModel[] = [];

        colors.forEach((item: Color) => {
            const radioButtonItem: RadioButtonItemModel =
                {} as RadioButtonItemModel;
            radioButtonItem.id = `config_${part}_${item.label}`;
            radioButtonItem.value = `${item.label}`;
            radioButtonItem.name = item.label;
            radioButtonItem.color = this.defaultHexaColor;
            radioButtonItem.disabled = false;
            radioButtonItem.default = item.default ?? true;
            if (item.unicolorAllowed !== undefined) {
                radioButtonItem.unicolorAllowed = item.unicolorAllowed;
            }
            result.push(radioButtonItem);
        });
        if (part === PartMaterial.exterior) {
            exteriorColor.name = `${PartMaterial.exterior}Color`;
            exteriorColor.type = RadioButtonType.Color;
            exteriorColor.expandable = true;
            exteriorColor.items = result;
            exteriorColor.hasDefault = true;
            smallLeatherGoodsConfigurator.exteriorColor = exteriorColor;
        } else {
            interiorColor.name = `${PartMaterial.lining}Color`;
            interiorColor.type = RadioButtonType.Color;
            interiorColor.expandable = true;
            interiorColor.items = result;
            interiorColor.hasDefault = true;
            smallLeatherGoodsConfigurator.interiorColor = interiorColor;
        }
    }

    // remove combinations with leather not available/ not present in parts property (from CAPA response)
    private clearLeatherCombinations(
        platformeInitialConfig: PlatformeConfigResponse,
    ): LeatherCombination[] {
        const partsLeather: {
            exterior: string[];
            lining: string[];
        } = {
            exterior: [],
            lining: [],
        };
        platformeInitialConfig.parts.forEach((part: Part) => {
            part.materials.forEach((materials: Material) => {
                if (part.name === PartMaterial.exterior) {
                    partsLeather.exterior.push(materials.name);
                } else if (part.name === PartMaterial.lining) {
                    partsLeather.lining.push(materials.name);
                }
            });
        });

        return (
            platformeInitialConfig.meta?.leather_combinations?.filter(
                (combination: LeatherCombination) => {
                    if (
                        partsLeather.exterior.includes(
                            combination.exterior_leather,
                        ) &&
                        partsLeather.lining.includes(
                            combination.interior_leather,
                        )
                    ) {
                        return true;
                    }

                    this.logger.warn(
                        `${combination.id} has one or more leathers not available. Combinations will be removed.`,
                    );
                    return false;
                },
            ) ?? []
        );
    }
}
