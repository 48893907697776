import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { Product } from "@hermes/api-model-product";
import { LOCALE, Settings } from "@hermes/app-core";
import { Locale } from "@hermes/locale";

@Injectable()
export class FetchProductService {
    public isVariantVolumeChanged$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);

    constructor(
        @Inject(LOCALE) private locale: Locale,
        private http: HttpClient,
        private settings: Settings,
    ) {}

    public fetchProductWithSku(productSku: string): Observable<Product> {
        const params = new HttpParams().set(
            "productsku",
            encodeURIComponent(productSku),
        );
        return this.http.get<Product>(`${this.settings.apiUrl}/product`, {
            params,
        });
    }
}
