import { LinkDefinition } from "@hermes/utils-generic/services/head";

import {
    LAZY,
    LoadingMode,
    LoadingModeParameters,
    MediaPicture,
    MediaQueryOption,
    PRELOAD,
    SERVER,
} from "../../model/media.types";
import { toPosterUrlOptions } from "../../pipes/poster-url/poster-url.pipe";

export function computeLoadingMode(params: LoadingModeParameters): LoadingMode {
    const { preloadPoster, isInServerMode, mediaUrl } = params;

    if (preloadPoster && Boolean(mediaUrl)) {
        return PRELOAD;
    } else if (!preloadPoster && Boolean(mediaUrl) && !isInServerMode) {
        return LAZY;
    } else if (!preloadPoster && Boolean(mediaUrl) && isInServerMode) {
        return SERVER;
    }

    return undefined;
}

export function mediaQueryOptionsToLinkDefinition(
    mediaQueryOption: MediaQueryOption,
    desktopImageUrl: string,
    mobileImageUrl?: string,
): LinkDefinition {
    return {
        rel: "preload",
        href: toPosterUrlOptions(desktopImageUrl, {
            mobileUrl: mobileImageUrl,
            size: mediaQueryOption.size,
            width: mediaQueryOption.width,
        }),
        as: "image",
        fetchpriority: "high",
        media: mediaQueryOption.mediaQuery,
    };
}

export function generateLinkDefinitions(
    preloadPoster: boolean,
    media?: MediaPicture,
    mediaOptions?: MediaQueryOption[],
): LinkDefinition[] {
    if (preloadPoster && media && media.desktopImage && mediaOptions) {
        const desktopImageUrl = media.desktopImage;
        const mobileImageUrl = media.mobileImage;
        const linkDefitions: LinkDefinition[] = mediaOptions.map((v) =>
            mediaQueryOptionsToLinkDefinition(
                v,
                desktopImageUrl,
                mobileImageUrl,
            ),
        );
        return linkDefitions;
    }
    return [];
}
