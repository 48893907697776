import {
    animate,
    AnimationTriggerMetadata,
    style,
    transition,
    trigger,
} from "@angular/animations";

export const iconButtonAnimations: AnimationTriggerMetadata = trigger(
    "slideInText",
    [
        transition(":enter", [
            style({
                width: "16px",
                overflow: "hidden",
            }),
            animate(
                "1s ease",
                style({
                    width: "*",
                    overflow: "hidden",
                }),
            ),
        ]),
    ],
);
