import { inject, Injector } from "@angular/core";

import { Svg } from "../icons/svg.model";

import { SvgRegistryService } from "./svg-registry.service";

/**
 * Register some svgs to be used in application templates with `<h-svg-icon>`
 *
 * This is typically used in constructor of standalone components.
 * If not used in an injection context, then `injector` option must be provided.
 *
 * @param svg One or more svg(s) to register
 * @param injector An optional injector to use, required if not in an injection context
 */
export function registerSvgs(
    svg: Svg[],
    { injector }: { injector?: Injector } = {},
): void {
    const registry = injector
        ? injector.get(SvgRegistryService)
        : inject(SvgRegistryService);

    registry.registerSvgs(svg);
}
