import { NONE } from "@hermes/aphrodite/dynamic-box";
import { BoxType, Product } from "@hermes/api-model-product";

import {
    CrossSell,
    SelectedVariantVariantsState,
    VariantExpandedState,
    ZoomModalState,
} from "../types/product-page.types";

/** State interface for product page */
export interface ProductPageState {
    productSKU: string;
    currentProduct: Product;
    type?: string;
    selectedVariants: SelectedVariantVariantsState[];
    variantExpanded: VariantExpandedState;
    zoomModal: ZoomModalState;
    crossSell?: CrossSell;
    isLoading: boolean;
    variantSelectedBoxType: BoxType;
}

/** initial product page state */
export const initialProductState: ProductPageState = {
    productSKU: "",
    currentProduct: {} as Product,
    type: undefined,
    selectedVariants: [],
    variantExpanded: {
        // with show more button
        color: false,
        buckle: false,
        leather: false,
        strap: false,
        // with toggle
        size: true,
        volume: false,
        finish: false,
        strapSize: false,
    },
    zoomModal: {
        zoomStatus: false,
        currentIndex: 0,
    },
    crossSell: undefined,
    isLoading: false,
    variantSelectedBoxType: NONE,
};
