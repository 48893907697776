import { Inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, mergeMap, switchMap, tap } from "rxjs/operators";

import { LOCALE, StorageService } from "@hermes/app-core";
import { Locale } from "@hermes/locale";
import { EcomErrorCodes } from "@hermes/utils-generic/constants";

import * as PaymentActions from "../actions/payment-methods.actions";
import { PaymentMethodsResponseDTO } from "../models/payment-methods-response-dto.model";
import { AvailablePaymentMethodsService } from "../services/available-payment-methods.service";
import { FetchPaymentMethodsService } from "../services/fetch-payment-methods.service";
import { SavedCardService } from "../services/saved-card.service";

@Injectable()
export class PaymentMethodsEffects {
    public fetchPaymentMethods$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PaymentActions.fetchPaymentMethods),
            switchMap(({ options }) => {
                if (options?.cache) {
                    const instance =
                        this.storageService.getSessionStorageInstance();
                    const cachedValue =
                        instance?.getItem<PaymentMethodsResponseDTO>(
                            this.getPaymentMethodSessionKey(),
                        );
                    if (cachedValue) {
                        // / If data is cached, send these
                        return of(cachedValue);
                    }
                }

                // Else, query the backend
                return this.fetchPaymentMethodsService.fetchPaymentMethods();
            }),
            map((response: PaymentMethodsResponseDTO) =>
                PaymentActions.fetchPaymentMethodsSuccess({
                    methods: response,
                }),
            ),
            catchError(() =>
                // when response is not OK 200, create error with technical error code
                of(
                    PaymentActions.fetchPaymentMethodsFailure({
                        error: {
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            internal_code: EcomErrorCodes.TECHNICAL_ERROR,
                        },
                    }),
                ),
            ),
        ),
    );

    public fetchPaymentMethodsSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(PaymentActions.fetchPaymentMethodsSuccess),
                tap((payload) => {
                    const instance =
                        this.storageService.getSessionStorageInstance();
                    instance?.setItem(
                        this.getPaymentMethodSessionKey(),
                        this.availablePaymentMethodsService.setSavedCardsInformations(
                            payload.methods,
                        ),
                    );
                }),
            ),
        { dispatch: false },
    );

    public fetchPaymentMethodsFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(PaymentActions.fetchPaymentMethodsFailure),
                tap(() => {
                    const instance =
                        this.storageService.getSessionStorageInstance();
                    instance?.setItem(
                        this.getPaymentMethodSessionKey(),
                        undefined,
                    );
                }),
            ),
        { dispatch: false },
    );

    public deleteSavedCard$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PaymentActions.deleteSavedCard),
            mergeMap((action) =>
                this.savedCardService
                    .deleteSavedCard(action.id, action.cardToken)
                    .pipe(
                        map((id: string) =>
                            PaymentActions.deleteSavedCardSuccess({ id }),
                        ),
                        catchError((error) =>
                            of(
                                PaymentActions.deleteSavedCardFailure({
                                    id: action.id,
                                    error: error.message,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );

    public removeCard$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PaymentActions.deleteSavedCardSuccess),
            map((action) => action.id),
            map((id) => PaymentActions.removeCard({ id })),
        ),
    );

    constructor(
        private actions$: Actions,
        private storageService: StorageService,
        private fetchPaymentMethodsService: FetchPaymentMethodsService,
        private availablePaymentMethodsService: AvailablePaymentMethodsService,
        private savedCardService: SavedCardService,
        @Inject(LOCALE) private locale: Locale,
    ) {}

    private getPaymentMethodSessionKey(): string {
        return `paymentMethods-${this.locale.countryCode}`;
    }
}
