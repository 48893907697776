import { CTA, CTAToken, Media, TOKEN_TYPE } from "@hermes/api-model-editorial";

export function getMediaName(media: Media): string {
    // Get name from media url
    const nameFromUrl = media.videoUrl
        ? media.videoUrl.split("name=").pop()
        : "";
    let videoName = "";

    if (nameFromUrl) {
        // Some case, media url look like V_169_FW15_Cherche_Midi-AVS&end.
        // Remove all parameter after &
        videoName = nameFromUrl.slice(0, nameFromUrl.indexOf("&"));
    }

    return videoName;
}

export function showFigCaption(
    media: Media,
    ignoreCaptions: boolean = false,
): boolean {
    // Written this way because we want to return the evaluate boolean value of the condition, not the value of one of its elements (that could be a string).
    // Also written this way because of an ESlint rule.
    return (
        !ignoreCaptions &&
        !!(
            media?.imageRetouched === true ||
            !!media?.caption ||
            !!media?.credits
        )
    );
}

export function resolveCTA(token: CTAToken | undefined): CTA | undefined {
    if (token?.tokenType !== TOKEN_TYPE.ctaMissing) {
        return token;
    }
    return undefined;
}

export function setMediaImageAltFromCTA(
    media: Media,
    cta: CTA | undefined,
): Media {
    if (!media?.imageAlt && cta?.text) {
        return { ...media, imageAlt: cta.text };
    }
    return media;
}
