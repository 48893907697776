import { Inject, Injectable } from "@angular/core";

import { map, Observable, of, withLatestFrom } from "rxjs";

import { BasketItem } from "@hermes/api-model-basket";
import { Product, ProductGiftSetItem } from "@hermes/api-model-product";
import { Context, WINDOW } from "@hermes/app-core";
import { GiftsetProductPageFacade } from "@hermes/states/giftset-product";

import {
    MERCURY_ADYEN_APPLE_PAY,
    MERCURY_ADYEN_ONE_CLICK,
    pspCardType,
} from "../constants";
import { isAlipayHelper, isCreditCardPaymentHelper } from "../helpers";

import { AdyenApplePayPaymentMethod } from "../models/adyen-apple-pay-payment-method.model";
import { AdyenOneClickPaymentMethod } from "../models/adyen-one-click-payment-method.model";
import { ApplePayPage } from "../models/apple-pay-page.model";
import { ExclusionCodes } from "../models/exclusion-codes.model";
import { PaymentMethodsResponseDTO } from "../models/payment-methods-response-dto.model";
import { PaymentMethods } from "../reducers/payment-methods.reducer";

@Injectable()
export class AvailablePaymentMethodsService {
    constructor(
        private context: Context,
        private giftsetProductPageFacade: GiftsetProductPageFacade,
        @Inject(WINDOW) private window: Window,
    ) {}

    public getAvailablePaymentMethods(
        paymentMethods: PaymentMethods,
        parameters: {
            isWeChat?: boolean;
            total?: number;
            hasRecurring?: boolean;
            applePayPage?: ApplePayPage;
            productCodes?: ExclusionCodes;
        } = {},
    ): PaymentMethods {
        return paymentMethods.filter((pm) => {
            if (parameters.productCodes) {
                const excludedDepartments = pm.exclusionCodes?.department.map(
                    (code) => code.toUpperCase(),
                );
                const excludedFamilies = pm.exclusionCodes?.family.map((code) =>
                    code.toUpperCase(),
                );
                const excludedProducts = pm.exclusionCodes?.product.map(
                    (code) => code.toUpperCase(),
                );

                const hasExcludedDepartment = excludedDepartments?.some(
                    (departmentCode) =>
                        parameters.productCodes?.department
                            .map((code) => code.toUpperCase())
                            .includes(departmentCode),
                );
                const hasExcludedFamily = excludedFamilies?.some((familyCode) =>
                    parameters.productCodes?.family
                        .map((code) => code.toUpperCase())
                        .includes(familyCode),
                );
                const hasExcludedProduct = excludedProducts?.some(
                    (productCode) =>
                        parameters.productCodes?.product
                            .map((code) => code.toUpperCase())
                            .includes(productCode),
                );

                if (
                    hasExcludedDepartment ||
                    hasExcludedFamily ||
                    hasExcludedProduct
                ) {
                    return false;
                }
            }

            if (pm.code === MERCURY_ADYEN_APPLE_PAY) {
                return this.filterApplePay(pm, parameters.applePayPage);
            }
            if (isAlipayHelper(pm.code) && parameters.isWeChat) {
                return false;
            }
            if (pm.code === MERCURY_ADYEN_ONE_CLICK) {
                return this.isMercuryAdyenOneClickValid(pm);
            }

            return !(
                parameters.hasRecurring && !isCreditCardPaymentHelper(pm.code)
            );
        });
    }

    public setSavedCardsInformations(
        paymentMethods: PaymentMethodsResponseDTO,
    ): PaymentMethodsResponseDTO {
        const oneClickMethods = paymentMethods.filter(
            (pm): pm is AdyenOneClickPaymentMethod =>
                pm.code === MERCURY_ADYEN_ONE_CLICK,
        );

        oneClickMethods.forEach((oneClick) => {
            oneClick.isDateValid = this.isMercuryAdyenOneClickValid(oneClick);
            oneClick.cardLabel = `${
                pspCardType[oneClick.type] || oneClick.type
            }****-${oneClick.endNumbers}`;
            oneClick.formatedDate = `${oneClick.expiryMonth.padStart(
                2,
                "0",
            )}/${oneClick.expiryYear.slice(2, 4)}`;
        });
        return paymentMethods;
    }

    public getExclusionsCodeFromItems(
        items: Array<BasketItem | Product>,
    ): Observable<ExclusionCodes> {
        return this.mapToBasketItems(items).pipe(
            map((formatedItems) => this.mapToExclusionCodes(formatedItems)),
        );
    }

    private mapToExclusionCodes(basketItems: BasketItem[]): ExclusionCodes {
        return {
            department: this.getProductCodes(basketItems, "departmentCode"),
            family: this.getProductCodes(basketItems, "familyCode"),
            product: this.getProductCodes(basketItems, "productCode"),
        };
    }

    private formatItems(
        items: Array<BasketItem | Product | ProductGiftSetItem>,
    ): BasketItem[] {
        const formatedItems: BasketItem[] = [];

        items.forEach((item) => {
            if (item.departmentCode && item.familyCode && item.productCode) {
                formatedItems.push(
                    this.mapCodeToBasketItem(
                        item.departmentCode,
                        item.familyCode,
                        item.productCode,
                    ),
                );
            }

            if ("setItems" in item && item.setItems?.length > 0) {
                formatedItems.push(
                    ...item.setItems.map((setItem) =>
                        this.mapCodeToBasketItem(
                            setItem.departmentCode,
                            setItem.familyCode,
                            setItem.productCode,
                        ),
                    ),
                );
            }

            if (item instanceof Product) {
                this.formatLookOrBikiniProducts(formatedItems, item);

                this.formatProductAttributes(formatedItems, item);
            }
        });
        return formatedItems;
    }

    private mapToBasketItems(
        listItems: Array<BasketItem | Product>,
    ): Observable<BasketItem[]> {
        return of(listItems).pipe(
            withLatestFrom(
                this.giftsetProductPageFacade.availableGiftSetItems$,
            ),
            map(([basketOrProducts, giftSetProducts]) =>
                this.formatItems([...basketOrProducts, ...giftSetProducts]),
            ),
        );
    }

    private getProductCodes(
        items: BasketItem[],
        name: "productCode" | "departmentCode" | "familyCode",
    ): string[] {
        return items.map((item) => item[name]).filter((c): c is string => !!c);
    }

    private formatProductAttributes(
        formatedItems: BasketItem[],
        item: Product,
    ): void {
        if (item.beltkitAttributes) {
            formatedItems.push(
                this.mapCodeToBasketItem(
                    item.beltkitAttributes.departmentCodeBuckle,
                    item.beltkitAttributes.familyCodeBuckle,
                    item.beltkitAttributes.productCodeBuckle,
                ),
                this.mapCodeToBasketItem(
                    item.beltkitAttributes.departmentCodeLeather,
                    item.beltkitAttributes.familyCodeLeather,
                    item.beltkitAttributes.productCodeLeather,
                ),
            );
        }

        if (item.appleWatchAttributes) {
            formatedItems.push(
                this.mapCodeToBasketItem(
                    item.appleWatchAttributes.departmentCodeGuizmo,
                    item.appleWatchAttributes.familyCodeGuizmo,
                    item.appleWatchAttributes.productCodeGuizmo,
                ),
                this.mapCodeToBasketItem(
                    item.appleWatchAttributes.departmentCodeStrap,
                    item.appleWatchAttributes.familyCodeStrap,
                    item.appleWatchAttributes.productCodeStrap,
                ),
            );
        }

        if (item.doubleFragranceAttributes) {
            formatedItems.push(
                this.mapCodeToBasketItem(
                    item.doubleFragranceAttributes.departmentCodePerfume,
                    item.doubleFragranceAttributes.familyCodePerfume,
                    item.doubleFragranceAttributes.productCodePerfume,
                ),
                this.mapCodeToBasketItem(
                    item.doubleFragranceAttributes.departmentCodeAccessory,
                    item.doubleFragranceAttributes.familyCodeAccessory,
                    item.doubleFragranceAttributes.productCodeAccessory,
                ),
            );
        }
    }

    private formatLookOrBikiniProducts(
        formatedItems: BasketItem[],
        item: Product,
    ): void {
        if (item.lookAttributes && item.lookAttributes.items?.length > 0) {
            formatedItems.push(
                ...item.lookAttributes.items.map((lookItem) =>
                    this.mapCodeToBasketItem(
                        lookItem.departmentCode,
                        lookItem.familyCode,
                        lookItem.productCode,
                    ),
                ),
            );
        }

        if (item.bikiniAttributes && item.bikiniAttributes.items?.length > 0) {
            formatedItems.push(
                ...item.bikiniAttributes.items.map((bikiniItem) =>
                    this.mapCodeToBasketItem(
                        bikiniItem.departmentCode,
                        bikiniItem.familyCode,
                        bikiniItem.productCode,
                    ),
                ),
            );
        }
    }

    private mapCodeToBasketItem(
        departmentCode: string,
        familyCode: string,
        productCode: string,
    ): BasketItem {
        const basketItem = new BasketItem();
        basketItem.departmentCode = departmentCode;
        basketItem.familyCode = familyCode;
        basketItem.productCode = productCode;
        return basketItem;
    }

    private filterApplePay(
        applePayMethod: AdyenApplePayPaymentMethod,
        applePayPage?: ApplePayPage,
    ): boolean {
        let isApplePayPageValid = true;
        if (applePayMethod.applePayPages?.length && applePayPage) {
            isApplePayPageValid =
                applePayMethod.applePayPages.includes(applePayPage);
        }

        return isApplePayPageValid;
    }

    private isMercuryAdyenOneClickValid(
        pm: AdyenOneClickPaymentMethod,
    ): boolean {
        const expiryYear = Number(pm.expiryYear);
        const expiryMonth = Number(pm.expiryMonth) - 1; // month's range is from 0 to 11 in Date()

        if (Number.isNaN(expiryYear) || Number.isNaN(expiryMonth)) {
            // Expiration unreadable
            return false;
        }

        const cardExpiryDate = new Date(expiryYear, expiryMonth);
        const now = new Date();

        return !(
            cardExpiryDate.getFullYear() < now.getFullYear() ||
            (cardExpiryDate.getFullYear() === now.getFullYear() &&
                cardExpiryDate.getMonth() < now.getMonth())
        );
    }
}
