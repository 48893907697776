import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable, OnDestroy } from "@angular/core";
import {
    BehaviorSubject,
    Observable,
    of,
    OperatorFunction,
    Subscription,
} from "rxjs";
import { catchError, filter, map, switchMap, take } from "rxjs/operators";

import { LOCALE, Settings, WINDOW } from "@hermes/app-core";
import { Locale } from "@hermes/locale";
import { LoadExternalUrlService } from "@hermes/utils/services/load-external-url";
import {
    HTTP_NO_CACHE_CONTROL_HEADER,
    RECAPTCHA_SITE_KEY,
} from "@hermes/utils-generic/constants";

// See https://www.npmjs.com/package/@types/grecaptcha
interface ReCaptcha {
    /**
     * Programatically invoke the reCAPTCHA check. Used if the invisible reCAPTCHA is on a div instead of a button.
     *
     * @param siteKey the key of your site
     * @param action the action
     *
     * @return a promise-like object containing the token
     */
    execute(siteKey: string, action: { action: string }): PromiseLike<string>;
    /**
     * will run the given function as soon as the reCAPTCHA library has loaded
     *
     * @param callback the function to coll
     */
    ready(callback: () => void): void;
}

/**
 * List of actions used for recaptcha validation.
 * see https://developers.google.com/recaptcha/docs/v3#actions
 */
export type ActionType =
    | "homepage"
    | "login"
    | "guest" // checkout guest
    | "client" // checkout returning customer
    | "social"
    | "e-commerce"
    | "customer_light";

@Injectable()
export class RecaptchaService implements OnDestroy {
    private recaptchaUrl: string = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`;

    private subscription = new Subscription();

    /**
     * Mini state. Either no checked (undefined),
     * check pending (BehaviorSubject(undefined)),
     * or checked (BehaviorSubject(boolean))
     */
    public isRecaptchaNeeded$?: BehaviorSubject<boolean | undefined>;

    constructor(
        private http: HttpClient,
        private settings: Settings,
        @Inject(WINDOW) private window: Window & { grecaptcha: ReCaptcha },
        private loadExternalUrlService: LoadExternalUrlService,
        @Inject(LOCALE) private locale: Locale,
    ) {}

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public getRecaptchaToken(actionType: ActionType): Promise<string> {
        return new Promise((resolve, reject) => {
            try {
                this.window.grecaptcha.ready(() => {
                    this.window.grecaptcha
                        .execute(RECAPTCHA_SITE_KEY, {
                            action: actionType,
                        })
                        .then(
                            (token) => {
                                resolve(token);
                            },
                            (error) => {
                                reject(error);
                            },
                        );
                });
            } catch (error) {
                reject(error);
            }
        });
    }

    public loadExternalRecaptchaLibrary(): Observable<boolean> {
        return this.loadExternalUrlService
            .loadExternalLibrary(this.recaptchaUrl)
            .pipe(
                switchMap(() => of(true)),
                catchError(() => of(false)),
            );
    }

    /**
     * Used to check if the recaptcha is needed and load the library if so.
     * Fully cached and paralellized (Can be called several times
     * (in parellel or not) and the lib will be loaded once)
     */
    public loadExternalRecaptchaLibraryIfNeeded(): Observable<
        boolean | undefined
    > {
        return this.checkIsRecaptchaNeeded().pipe(
            switchMap((isActivated) => {
                if (!isActivated) {
                    return of(undefined);
                }

                return this.loadExternalRecaptchaLibrary();
            }),
        );
    }

    /**
     * Helper to check only once if the library is needed
     */
    public checkIsRecaptchaNeeded(): Observable<boolean> {
        if (this.isRecaptchaNeeded$ === undefined) {
            this.isRecaptchaNeeded$ = new BehaviorSubject(
                undefined,
            ) as BehaviorSubject<boolean | undefined>;
            this.subscription.add(
                this.getIsCaptchaActivated().subscribe((needed) => {
                    this.isRecaptchaNeeded$?.next(needed);
                }),
            );
        }

        return this.isRecaptchaNeeded$.pipe(
            filter((value) => value !== undefined) as OperatorFunction<
                boolean | undefined,
                boolean
            >,
            take(1),
        );
    }

    public getIsCaptchaActivated(): Observable<boolean> {
        const httpOptions = {
            headers: new HttpHeaders({
                ...HTTP_NO_CACHE_CONTROL_HEADER,
            }),
        };

        return this.http
            .get<{ isCaptchaActivated: boolean }>(
                `${this.settings.apiUrl}/is-captcha-activated`,
                httpOptions,
            )
            .pipe(
                map((data) => data.isCaptchaActivated),
                catchError((e) => {
                    throw new Error(
                        `Call to /is-captcha-activated failed : ${e.error}`,
                    );
                }),
            );
    }

    /**
     * Remove reCaptcha badge (bottom right) and the imported recpatcha script from DOM
     */
    public removeRecaptcha(): void {
        this.loadExternalUrlService.removeExternalLibrary(this.recaptchaUrl);
        const recaptchaElement = document.querySelector(".grecaptcha-badge");
        if (recaptchaElement) {
            recaptchaElement.remove();
        }
    }
}
