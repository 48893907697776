import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";

import { AlternatesModule } from "@hermes/fragments/alternates";
import { ProductAddToCartServiceModule } from "@hermes/fragments/product-add-to-cart";

import { PersonalizationPageStateModule } from "@hermes/states/personalization-page";

import { ProductPageAnalyticsEffects } from "./effects/product-page-analytics.effects";
import { ProductPageEffects } from "./effects/product-page.effects";
import { ProductPageFacade } from "./facades/product-page.facade";
import { ProductImageSeoUrlPipeModule } from "./pipes/product-image-seo-url/product-image-seo-url.pipe";
import { productPageReducer } from "./reducers";
import { productFeatureKey } from "./selectors/product.selectors";
import { CrossSellingService } from "./services/cross-selling.service";
import { FetchProductService } from "./services/fetch-product.service";
import { ProductPageHeadService } from "./services/head/product-page-head.service";
import { ProductPageAssetService } from "./services/product-page-asset.service";
import { ProductPageVariantService } from "./services/product-page-variant.service";

@NgModule({
    imports: [
        CommonModule,
        // feature state for product page
        StoreModule.forFeature(productFeatureKey, productPageReducer),
        EffectsModule.forFeature([
            ProductPageEffects,
            ProductPageAnalyticsEffects,
        ]),
        ProductImageSeoUrlPipeModule,
        AlternatesModule,
        ProductAddToCartServiceModule,
        PersonalizationPageStateModule,
    ],
    providers: [
        FetchProductService,
        ProductPageHeadService,
        CrossSellingService,
        ProductPageFacade,
        ProductPageAssetService,
        ProductPageVariantService,
    ],
})
export class ProductPageStateModule {}
