import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";

import { ErrorsFacade } from "./facades/errors.facade";
import * as fromErrors from "./reducers/errors.reducer";

@NgModule({
    providers: [ErrorsFacade],
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature(fromErrors.errorsFeatureKey, fromErrors.reducer),
    ],
})
export class ErrorsStateModule {}
