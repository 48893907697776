@if (menuEntry$ | async; as menu) {
<nav
    (mouseleave)="onMouseLeave()"
    (keydown.arrowleft)="focusLeft()"
    (keydown.arrowright)="focusRight()"
    (keydown.home)="setFocusToFistItem()"
    (keydown.end)="setFocusToLastItem()">
    <ul
        role="menubar"
        class="menu-bar-container"
        [ngStyle]="{
        '--header-menu-height.px': headerMenuHeight,
    }">
        @for (subMenuEntry of menu.menuEntryList; track subMenuEntry; let index = $index) { @if (subMenuEntry.type !==
        menuEntryType.separatorMenuEntry) {
        <li class="item" role="none">
            <h-menu-parent-category
                [menuEntry]="subMenuEntry"
                [focused]="parentCategoryFocusedIndex() === index"
                [onCursorLeaveMenuBar$]="mousLeaveEventsSubject"></h-menu-parent-category>
        </li>
        } @else {
        <div class="menu-separator"></div>
        } }
    </ul>
</nav>
}
