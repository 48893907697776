import { ProductTemplateType } from "@hermes/api-model-product";
import {
    CallToActionAnalyticsEvent,
    GTMEventData,
} from "@hermes/utils/analytics";

import { getEventCategory } from "../helpers/product-page-analytics.helpers";

/**
 * This class is used for find-in-store event
 */
export class OpenTrayClickEvent extends CallToActionAnalyticsEvent {
    /**
     * Overload formatForGTM of inherited class
     * Return formatter for eventaction and eventlabel key
     */
    public override formatForGTM(): GTMEventData {
        const eventcategory = getEventCategory(this.eventData);

        switch (this.eventData.trayId) {
            case "tray-help": {
                return {
                    ...super.formatForGTM(),
                    eventcategory,
                    eventaction: "ContactUs",
                };
            }
            case "size-guide-tray": {
                return {
                    ...super.formatForGTM(),
                    eventcategory,
                    eventaction: "Size_Guide",
                    eventlabel:
                        (this.eventData.templateType ===
                            ProductTemplateType.Bikini ||
                            this.eventData.templateType ===
                                ProductTemplateType.Look) &&
                        this.eventData.metadata?.sku
                            ? `Product_${this.eventData.metadata.sku}`
                            : undefined,
                };
            }

            default: {
                return {
                    ...super.formatForGTM(),
                    eventaction: "trayId missing",
                };
            }
        }
    }
}
