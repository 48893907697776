import { CurrencyPipe as AngularCurrencyPipe } from "@angular/common";
import { Inject, LOCALE_ID, Pipe, PipeTransform } from "@angular/core";

import { LOCALE } from "@hermes/app-core";

import { isMexico, Locale } from "@hermes/locale";

import { CurrencyService } from "./currency.service";

@Pipe({
    name: "hCurrency",
})
export class CurrencyPipe implements PipeTransform {
    constructor(
        @Inject(LOCALE) private locale: Locale,
        @Inject(LOCALE_ID) private localeId: string,
        private currencyPipe: AngularCurrencyPipe,
        private currencyService: CurrencyService,
    ) {}

    /**
     * Display the right price format for the current context
     *
     * @param value
     * @param format short or full to display decimal
     * @param preferredCurrencyCode provide a currency code to display - default value is guessed from current locale
     */
    public transform(
        value: number,
        format?: string,
        preferredCurrencyCode?: string,
    ): string | undefined {
        const [_locale, currencyCodeFromLocale, symbol, defaultFormat] =
            (preferredCurrencyCode
                ? this.currencyService.getCurrencyMapByCode(
                      preferredCurrencyCode,
                  )
                : this.currencyService.getCurrencyMap()) || [];
        const currencyCode = preferredCurrencyCode ?? currencyCodeFromLocale;
        const currentFormat = format ?? defaultFormat;

        // Change format using digitsInfo parameter
        // CF angular doc -> https://angular.io/api/common/CurrencyPipe
        // To display price between 0 and 1, we force full format
        const digit =
            currentFormat === "full" || (value > 0 && value < 1)
                ? "1.2-2"
                : "1.0-0";

        // Temporary fix for MX currency, as long as SSR doesn't recognize es-MX locale
        if (isMexico(this.locale) && value >= 0) {
            return this.formatMxCurrency(value, symbol, digit);
        }
        const formattedNumber = this.currencyPipe.transform(
            value, // value to decorate in dom
            currencyCode, // set the local symbol
            symbol, // display the localized currency symbol
            digit, // format the string value
            // Always show currency in the language of user
            this.localeId, // current locale
        );

        if (formattedNumber) {
            // Replace unicode space characters
            return formattedNumber.replace(/\s+/g, " ");
        }

        return undefined;
    }

    /**
     * Format price for Mexico
     *
     * @param value the price to format
     * @param symbol custom MXN symbol
     *
     * @returns formated price
     */
    public formatMxCurrency(
        value: number,
        symbol: string | undefined,
        digit: string | undefined,
    ): string {
        let formatedPrice = value.toString();
        if (digit === "1.2-2") {
            formatedPrice = value.toFixed(2);
        }
        const numberWithCommas = formatedPrice
            // eslint-disable-next-line unicorn/no-unsafe-regex
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        formatedPrice = `${symbol}${numberWithCommas}`;
        return formatedPrice;
    }
}
