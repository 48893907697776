import {
    ChangeDetectorRef,
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
} from "@angular/core";
import newrelicNamespace from "newrelic";

import {
    Subscription,
    catchError,
    switchMap,
    map,
    timer,
    Observable,
    tap,
} from "rxjs";

import { ApplePayService } from "@hermes/states/apple-pay";
import { ModalData, ModalFacade, ModalType } from "@hermes/states/modal";
import { UserStateService } from "@hermes/states/user";
import { WINDOW } from "@hermes/app-core";

const PHONE_10_DIGITS = $localize`:@@hermes_account.phone_10-digits:Please enter a valid 10-digit phone number.`;

@Component({
    selector: "h-apple-pay",
    templateUrl: "./modal-apple-pay.component.html",
    styleUrls: ["./modal-apple-pay.component.scss"],
})
export class ModalApplePayComponent implements OnInit, OnDestroy {
    @Input({ required: true })
    public modalData!: Extract<ModalData, { type: ModalType.ApplePay }>;

    public state: "validation" | "error" = "validation";
    public errorMessage?: string;

    private subscription: Subscription = new Subscription();

    constructor(
        private applePayService: ApplePayService,
        private userService: UserStateService,
        private modalFacade: ModalFacade,
        private cdr: ChangeDetectorRef,
        @Inject(WINDOW)
        private window: Window & {
            newrelic: typeof newrelicNamespace;
        },
    ) {}

    public ngOnInit(): void {
        let startCount: number;
        this.subscription.add(
            this.applePayService
                .getIsLoggedIn()
                .pipe(
                    tap(() => (startCount = Date.now())),
                    switchMap((isUserConnected) =>
                        this.applePayService.startPayment(
                            this.modalData.token,
                            this.modalData.shippingContact,
                            isUserConnected,
                            this.modalData.processingChannel,
                        ),
                    ),
                    tap((paymentResponse) => {
                        const requestTime = (Date.now() - startCount) / 1000;
                        if (requestTime > 30) {
                            this.window.newrelic.noticeError(
                                new Error("ApplePay payment too long"),
                                {
                                    totalTime: `${requestTime}s`,
                                    orderId: paymentResponse?.orderId,
                                },
                            );
                        }
                    }),
                    switchMap((paymentResponse) =>
                        this.applePayService.handlePaymentResponse(
                            paymentResponse,
                        ),
                    ),
                    catchError((error) => {
                        this.errorMessage = [
                            "telephone-minLength",
                            "telephone-maxLength",
                        ].includes(error?.error?.error)
                            ? PHONE_10_DIGITS
                            : undefined;

                        return this.handleError();
                    }),
                )
                .subscribe(),
        );
    }

    public ngOnDestroy(): void {
        this.modalFacade.closeModal();
        this.subscription.unsubscribe();
    }

    public close(): void {
        if (this.state === "error") {
            this.modalFacade.closeModal(false);
            this.cdr.detectChanges();
        }
    }

    private handleError(): Observable<void> {
        this.state = "error";
        this.userService.setSessionNeedsRefresh(true);
        this.cdr.detectChanges();

        return timer(5000).pipe(
            map(() => {
                this.modalFacade.closeModal(false);
                this.cdr.detectChanges();
            }),
        );
    }
}
