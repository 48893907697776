/**
 * Cleanup url to match angular routes.
 *
 * @param path unsanitized url
 * @return path without first slash and no hash part
 */
export const pathCleaner = (path: string): string =>
    path
        // remove first slash
        .replace(/^\//, "")
        // remove char after hashbang
        .replace(/#(.*)$/, "")
        // remove back slash
        .replace(/\/$/, "");

/**
 * Obtain url substring that match a given regExp at a given index.
 *
 * @param url url string to search in
 * @param regExp expression to match a substring
 * @param index element index to output
 * @return matching element at specified index or empty string if no match
 */
export const getMatchAtIndex = (
    url: string | undefined,
    regExp: RegExp,
    index: number,
): string => {
    const result = url?.match(regExp);

    return result?.at(index) ?? "";
};
