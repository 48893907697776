import { GTMEventData } from "@hermes/utils/analytics";

export const EVENT_ACTION_VIDEO_FIRST_PLAY = "FirstPlay";
export const EVENT_ACTION_VIDEO_NEW_PLAY = "NewPlay";
export const EVENT_ACTION_VIDEO_PAUSE = "Pause";
export const EVENT_ACTION_VIDEO_STOP = "Stop";
export const EVENT_ACTION_VIDEO_FULLSCREEN = "Fullscreen";
export const EVENT_ACTION_VIDEO_MUTE = "Mute";
export const EVENT_ACTION_VIDEO_AUTOPLAY = "Autoplay";
export const UAEVENT = "uaevent";
export const NON_INTUA_EVENT = "nonintuaevent";

/**
 * This class is used to manage the sending of events of videoComponent
 */
export class VideoEvent {
    /**
     * Event name expected for every uaevent event
     */
    public name: string = UAEVENT;

    /**
     * Event's data from component to format correctly event
     */
    public eventData: any;

    constructor(data: { [key: string]: string }) {
        this.eventData = data;
    }

    /**
     * Overload formatForGTM of inherited class
     * Return formatter for eventaction and eventlabel key
     */
    public formatForGTM(): GTMEventData {
        return {
            event: this.eventData.name ?? this.name,
            eventcategory: "Module_Video",
            eventaction: this.eventData.eventaction,
            eventlabel: this.eventData.eventlabel,
        };
    }
}
