// allowed for actions, to remove with ngrx 8 action creators
import { createAction, props } from "@ngrx/store";

import { Product } from "@hermes/api-model-product";

import { BlockType } from "../constants/product-personalization.constant";
import {
    PlatformeConfigResponse,
    PlatformeInitialConfiguration,
    PersoConfiguratorState,
    ConfigPart,
    KeyboardTap,
    LoadingProgress,
    PersoParsedUrl,
    ProductCategory,
    EngravingConfiguration,
    SmallLeatherGoodsLeather,
} from "../types/product-personalization.type";

export const updateBlock = createAction(
    "[Perso Page] Update Personalization Selected Block",
    props<{ block: BlockType }>(),
);

export const updateCollapsedToggles = createAction(
    "[Perso Page] Update Leather Collapsed Toggles",
    props<{ collapsedToggle: string }>(),
);

export const updateConfiguration = createAction(
    "[Perso Page] Update Personalization Configuration",
    props<{ configuration: ConfigPart }>(),
);
export const updateSeveralConfiguration = createAction(
    "[Perso Page] Update Several Personalization Configuration",
    props<{ configurations: ConfigPart[] }>(),
);

export const updateKeyboardTap = createAction(
    "[Perso Page] Update Keyboard Tap On Luckysigns keyboard",
    props<{ keyboardTap: KeyboardTap | undefined }>(),
);

export const updateLoadingProgress = createAction(
    "[Perso Page] Update configurator loading progress",
    props<{ loadingProgress: LoadingProgress }>(),
);

export const updateParentProduct = createAction(
    "[Perso Page] Update parent product",
    props<{ parentProduct: Product }>(),
);

export const updateFirstProduct = createAction(
    "[Perso Page] Update first product",
    props<{ firstProduct: Product }>(),
);

export const updateSecondProduct = createAction(
    "[Perso Page] Update second product",
    props<{ secondProduct: Product }>(),
);

export const updateIsNoBuckleSelected = createAction(
    "[Perso Page] Update flag to know if user selected no_buckle",
    props<{ isNoBuckleSelected: boolean }>(),
);

export const updateCurrentUrl = createAction(
    "[Perso Page] Update currentUrl",
    props<{ currentUrl: string }>(),
);

export const updateDku = createAction(
    "[Perso Page] Update dku",
    props<{ dku: string }>(),
);

export const updateParsedUrlSkus = createAction(
    "[Perso Page] Update parsed url skus",
    props<{ firstSku: string; secondSku?: string }>(),
);

export const updateUrlParsedParameters = createAction(
    "[Perso Page] Update updateUrlParsedParameters",
    props<{ urlParsedParameters: PersoParsedUrl }>(),
);

export const updateIsDacProductMissing = createAction(
    "[Perso Page] Update isDacProductMissing",
    props<{ isDacProductMissing: boolean }>(),
);

export const updateProductCategory = createAction(
    "[Perso Page] Update productCategory",
    props<{ productCategory: ProductCategory }>(),
);

export const updateConfigurator = createAction(
    "[Perso Page] Update Configurator",
    props<{ configurator: PersoConfiguratorState }>(),
);

export const updateEngravingConfiguration = createAction(
    "[Perso Page] Update engravingConfiguration",
    props<{ engravingConfiguration: EngravingConfiguration }>(),
);

export const updateIsCallingPlatforme = createAction(
    "[Perso Page] Update isCallingPlatforme",
    props<{ isCallingPlatforme: boolean }>(),
);

export const updateSmallLeatherGoodsLeathers = createAction(
    "[Perso Page] Update smallLeatherGoodsLeathers",
    props<{ smallLeatherGoodsLeathers: SmallLeatherGoodsLeather[] }>(),
);

export const updatePlateformeExtraInformation = createAction(
    "[Perso Page] Update Translations and Colors codes",
    props<{
        colorsHexaCodes: Record<string, string>;
        translations: Record<string, string>;
    }>(),
);

export const resetPersonalizationState = createAction(
    "[Perso Page] Reset Personalization States",
);

export const updateDisplayedUrl = createAction(
    "[Perso Page] Update URL displayed",
);

export const hideLoader = createAction("[Perso Page] Hide Loader");

export const fetchProductSuccess = createAction(
    "[Perso Page] Fetch Product Success",
    props<{ data: Product; isVariant: boolean }>(),
);

export const fetchPlatformeConfiguration = createAction(
    "[Perso Page] Fetch PlatformE configuration",
    props<{ productSku: string }>(),
);

export const fetchPlatformeConfigurationSuccess = createAction(
    "[Perso Page] Fetch PlatformE configuration success",
    props<{ platformeConfiguration: PlatformeInitialConfiguration }>(),
);

export const fetchPersonalizeProducts = createAction(
    "[Perso Page] Fetch Personalize Products",
    props<{
        skus: string[];
        productCategory: ProductCategory;
    }>(),
);

export const fetchPersonalizeProductsSuccess = createAction(
    "[Perso Page] Fetch Personalize Products success",
    props<{ products: Product[] }>(),
);

export const fetchPersonalizeProductsFailure = createAction(
    "[Perso Page] Fetch Personalize Products failed",
    props<{ error: string; path: string }>(),
);

export const handleFirstProductMissing = createAction(
    "[Perso Page] First perso product is missing",
);

export const goBack = createAction("[Perso Page] goBack button click");

export const initializationOfRipeInstance = createAction(
    "[Perso Page] Initialization Ripe instance",
    props<{ initiateConfigurator: boolean }>(),
);

export const checkBeforeInitializationOfRipeInstance = createAction(
    "[Perso Page] Check condition to properly initialize Ripe instance",
);

export const initiateConfiguratorMapping = createAction(
    "[Perso Page] Initiate Configurator Mapping",
);
export const initiateBeltkitConfiguratorMapping = createAction(
    "[Perso Page] Initiate Beltkit Configurator Mapping",
    props<{ platformeConfigResponse: PlatformeConfigResponse }>(),
);
export const initiateSmlgConfiguratorMapping = createAction(
    "[Perso Page] Initiate Smlg Configurator Mapping",
    props<{ platformeConfigResponse: PlatformeConfigResponse }>(),
);

export const applyStateConfiguration = createAction(
    "[Perso Page] Apply State Configuration",
);
