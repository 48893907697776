import { Pipe, PipeTransform } from "@angular/core";

import { MenuEntryItem } from "@hermes/states/menu";

import { MenuEntryItemWithEdito } from "../models/menu.model";

@Pipe({
    name: "asMenuEntryItemWithEdito",
    standalone: true,
})
export class AsMenuEntryItemWithEditoPipe implements PipeTransform {
    public transform(value: MenuEntryItem): MenuEntryItemWithEdito {
        return value as MenuEntryItemWithEdito;
    }
}
