import { Component, Input } from "@angular/core";

export const SMALL = "small";
export const MEDIUM = "medium";
export const LARGE = "large";
export type LoaderSize = typeof SMALL | typeof MEDIUM | typeof LARGE;
@Component({
    selector: "h-loader-spinner",
    templateUrl: "./loader-spinner.component.html",
    styleUrls: ["./loader-spinner.component.scss"],
    standalone: true,
})
export class LoaderSpinnerComponent {
    @Input()
    public size: LoaderSize = MEDIUM;
}
