import { A11yModule } from "@angular/cdk/a11y";
import { DialogModule } from "@angular/cdk/dialog";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { CallToActionComponent } from "@hermes/aphrodite/call-to-action";
import { LoaderSpinnerComponent } from "@hermes/aphrodite/loader-spinner";
import { MessageBlockComponent } from "@hermes/aphrodite/message-block";
import { SvgIconComponent } from "@hermes/aphrodite/svg";
import { ApplePayModule } from "@hermes/fragments/apple-pay";
import { ModalStateModule } from "@hermes/states/modal";
import { PaymentMethodsStateModule } from "@hermes/states/payment-methods";

import { ModalApplePayComponent } from "./components/applePay/modal-apple-pay.component";
import { RedirectMessageComponent } from "./components/common/redirect-message/redirect-message.component";
import { ModalComponent } from "./components/modal/modal.component";
import { ModalAddToCartComponent } from "./components/modal-add-to-cart/modal-add-to-cart.component";
import { ModalContainerComponent } from "./components/modal-container/modal-container.component";
import { ModalTermsAndConditionsComponent } from "./components/modal-terms-and-conditions/modal-terms-and-conditions.component";
import { ModalThreeDS2Component } from "./components/threeDS2/modal-three-d-s2.component";
import { ModalPositioningDirective } from "./directive/modal-positioning/modal-positioning.directive";

@NgModule({
    declarations: [
        ModalAddToCartComponent,
        ModalComponent,
        ModalContainerComponent,
        ModalPositioningDirective,
        ModalTermsAndConditionsComponent,
        ModalThreeDS2Component,
        ModalApplePayComponent,
        RedirectMessageComponent,
    ],
    imports: [
        A11yModule,
        CallToActionComponent,
        CommonModule,
        SvgIconComponent,
        DialogModule,
        LoaderSpinnerComponent,
        MessageBlockComponent,
        PaymentMethodsStateModule,
        ModalStateModule,
        ApplePayModule,
    ],
    exports: [ModalContainerComponent, ModalTermsAndConditionsComponent],
})
export class ModalModule {}
