<span [attr.data-testid]="'country-selector'" class="heading" hSrOnlyNoSnippet>{{
    "hermes_country_selector" | translate
}}</span>

<button
    class="country-button heading-5 regular-uppercase"
    [attr.aria-haspopup]="'dialog'"
    [attr.aria-expanded]="'false'"
    [attr.aria-controls]="'tray-locale'"
    [hTrayOpener]="'country-selector-tray'"
    [trayPosition]="'right'"
    [trayOverlay]="true"
    [trayTitle]="'hermes-country-selector-tray.country'"
    (click)="sendShopInEvent($event)">
    <ng-container i18n="@@hermes_footer.shop-in">Ship to</ng-container>&nbsp;{{ ":"
    }}{{ "hermes_country_selector" | translate }}
    <svg width="7" height="10" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
        <path d="M1 9l4-4-4-4" stroke="#333" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round" />
    </svg>
</button>
