import { DOCUMENT, Location } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { of } from "rxjs";
import {
    filter,
    map,
    switchMap,
    take,
    tap,
    withLatestFrom,
} from "rxjs/operators";

import { CurrencyService } from "@hermes/aphrodite/price";
import { ModelObject } from "@hermes/api-model-core";
import {
    Product,
    ProductGiftSet,
    ProductItemLook,
    ProductSizeVariant,
    ProductTemplateType,
} from "@hermes/api-model-product";
import {
    StorageService,
    StorageManager,
    Context,
    LOCALE,
    Settings,
    getBuildVersion,
} from "@hermes/app-core";
import { Locale } from "@hermes/locale";
import { AddToCartEvent, PRODUCT_PAGE } from "@hermes/states/add-to-cart";
import { addToCartSuccess } from "@hermes/states/basket";
import { ProductPersonalizationFacade } from "@hermes/states/personalization-page";
import { openTray } from "@hermes/states/tray";
import { UserFacade } from "@hermes/states/user";
import {
    AnalyticsService,
    GTMClientGender,
    GTMClientType,
    getAnalyticsDeviceType,
} from "@hermes/utils/analytics";

import { UrlUtils } from "@hermes/utils-generic/services/user-interface";

import {
    goBack,
    selectVariant,
    sendPageviewAnalytics,
    showMoreVariant,
    toggleZoom,
} from "../actions/product-page.actions";
import { AddToCartBikini } from "../events/add-to-cart-bikini.event";
import { AddToCartLookItem } from "../events/add-to-cart-look-item.event";
import { AddToCartProductSetEvent } from "../events/add-to-cart-product-set.event";
import { BackToGridClickEvent } from "../events/back-to-grid-click.event";
import { OpenTrayClickEvent } from "../events/open-tray.event";
import { PageViewProductPage } from "../events/page-view-product-page.event";
import { ProductContentOpenZoomEvent } from "../events/product-content-open-zoom.event";
import { ProductInfoToggleClickEvent } from "../events/product-info-toggle-click.event";
import { ProductInfoVariantClickEvent } from "../events/product-info-variant-click.event";
import * as fromProduct from "../reducers/product-page.state";

import {
    selectAllSelectedVariants,
    selectCurrentProduct,
    selectTemplateType,
} from "../selectors/product.selectors";

/**
 * Effects for send analytics event on product page
 * For some effect we need to know in wich template we are
 * withLatestForm is for this, before our effect we receive templateType from State
 * each effects send analytics event corresponding
 */
@Injectable()
export class ProductPageAnalyticsEffects {
    public sendPageviewAnalytics$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(sendPageviewAnalytics),
                filter(() => this.context.isInBrowserMode()),
                withLatestFrom(
                    this.userFacade.userIsSynchronized$.pipe(
                        filter((isSynchro) => isSynchro),
                        switchMap(() => this.userFacade.userDetails$),
                        take(1),
                    ),
                ),
                map(([{ product }, user]) => {
                    this.analyticsService.sendData(
                        new PageViewProductPage({
                            pagename: this.document.title,
                            buildversion: getBuildVersion(this.settings.envKey),
                            devicetype: getAnalyticsDeviceType(),
                            languagecode: this.locale.langCode,
                            countrycode: this.locale.countryCode,
                            menuLvl:
                                this.sessionStorage
                                    ?.getItem<string>("nav")
                                    ?.split("/") ?? "",
                            userId: undefined,
                            clienttype:
                                (user.userType as GTMClientType) ||
                                "non-connected",
                            clientgender: user.prefix as GTMClientGender,
                            pageTranslate: this.location
                                .path()
                                .replace(/^\/+/, ""), // url after baseHref/locale, and without first slash.
                            product,
                            previousPage: this.urlUtils.getPreviousUrl(),
                        }),
                    );
                }),
            ),
        { dispatch: false },
    );

    public selectVariant$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(selectVariant),
                map((action: ReturnType<typeof selectVariant>) => action),
                withLatestFrom(this.store.pipe(select(selectCurrentProduct))),
                tap(([action, product]) => {
                    this.analyticsService.sendData(
                        new ProductInfoVariantClickEvent({
                            variantType: action.variantType,
                            variantItem: action.variantItem,
                            position: action.position ?? 0,
                            product,
                        }),
                    );
                }),
            ),
        { dispatch: false },
    );

    public openTray$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(openTray),
                map((action: ReturnType<typeof openTray>) => action),
                // only react to tray that are opened from product page
                filter((action) =>
                    ["tray-help", "size-guide-tray"].includes(action.name),
                ),
                withLatestFrom(
                    this.store.pipe(select(selectTemplateType)),
                    this.productPersoPageFacade.firstProduct$,
                ),
                tap(([action, templateType, firstProductPerso]) => {
                    this.analyticsService.sendData(
                        new OpenTrayClickEvent({
                            templateType:
                                templateType ?? firstProductPerso?.templateType,
                            trayId: action.name,
                            metadata: action.metadata,
                        }),
                    );
                }),
            ),
        { dispatch: false },
    );

    public showMoreVariant$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(showMoreVariant),
                filter((action) => action.data.isExpanded),
                map((action: ReturnType<typeof showMoreVariant>) => action),
                withLatestFrom(this.store.pipe(select(selectTemplateType))),
                tap(([action, templateType]) => {
                    this.analyticsService.sendData(
                        new ProductInfoToggleClickEvent({
                            templateType,
                            sku: action.data.sku,
                            eventType: action.data.id,
                        }),
                    );
                }),
            ),
        { dispatch: false },
    );

    public addToCartSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(addToCartSuccess),
                switchMap((action) =>
                    of(action).pipe(
                        withLatestFrom(
                            this.store.pipe(select(selectCurrentProduct)),
                            this.store.pipe(select(selectAllSelectedVariants)),
                        ),
                    ),
                ),

                tap(([action, currentProduct, variants]) => {
                    if (
                        action.sendAnalyticsEvent &&
                        Object.keys(currentProduct).length > 0 &&
                        action.context === PRODUCT_PAGE
                    ) {
                        currentProduct = ModelObject.fromJsonData(
                            currentProduct,
                            currentProduct.templateType ===
                                ProductTemplateType.Giftset
                                ? ProductGiftSet
                                : Product,
                        );
                        switch (currentProduct.templateType) {
                            case ProductTemplateType.Giftset: {
                                this.analyticsService.sendData(
                                    new AddToCartProductSetEvent({
                                        currencyCode:
                                            this.currencyService.getCurrencyMap()?.[1],
                                        product: currentProduct,
                                        quantity: 1,
                                        setItems: action.sku // Retrieve the composition of the set from the composite SKU
                                            .split("-")
                                            .slice(1) // First element is the set's SKU, we dont need it
                                            .map((sku) =>
                                                (
                                                    currentProduct as ProductGiftSet
                                                ).items.find(
                                                    (production) =>
                                                        production.sku === sku,
                                                ),
                                            ),
                                    }),
                                );
                                break;
                            }

                            case ProductTemplateType.Look: {
                                this.analyticsService.sendData(
                                    new AddToCartLookItem({
                                        currencyCode:
                                            this.currencyService.getCurrencyMap()?.[1],
                                        look: currentProduct,
                                        item: currentProduct.lookAttributes
                                            ? currentProduct.lookAttributes
                                                  ?.items[action.itemPosition]
                                            : ({} as ProductItemLook),
                                        variant:
                                            variants?.[action.itemPosition]
                                                .size ??
                                            ({} as ProductSizeVariant),
                                    }),
                                );
                                break;
                            }

                            case ProductTemplateType.Bikini: {
                                this.analyticsService.sendData(
                                    new AddToCartBikini({
                                        currencyCode:
                                            this.currencyService.getCurrencyMap()?.[1],
                                        product: currentProduct,
                                        variants:
                                            variants &&
                                            // eslint-disable-next-line unicorn/explicit-length-check
                                            variants[0].size &&
                                            // eslint-disable-next-line unicorn/explicit-length-check
                                            variants[1].size
                                                ? [
                                                      variants[0].size,
                                                      variants[1].size,
                                                  ]
                                                : [],
                                    }),
                                );
                                break;
                            }

                            default: {
                                this.analyticsService.sendData(
                                    new AddToCartEvent({
                                        currencyCode:
                                            this.currencyService.getCurrencyMap()?.[1],
                                        product: currentProduct,
                                        quantity: 1,
                                        isProductEngraving:
                                            action.isProductEngraving,
                                        context: action.context,
                                    }),
                                );
                            }
                        }
                    }
                }),
            ),
        { dispatch: false },
    );

    public goBack$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(goBack),
                map((action: ReturnType<typeof goBack>) => action),
                withLatestFrom(
                    this.store.pipe(select(selectTemplateType)),
                    this.store.pipe(select(selectCurrentProduct)),
                ),
                tap(([_action, templateType, currentProduct]) => {
                    this.analyticsService.sendData(
                        new BackToGridClickEvent({
                            templateType,
                            sku: currentProduct.sku,
                        }),
                    );
                }),
            ),
        { dispatch: false },
    );

    public toggleZoom$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(toggleZoom),
                map((action: ReturnType<typeof toggleZoom>) => action),
                withLatestFrom(
                    this.store.pipe(select(selectTemplateType)),
                    this.store.pipe(select(selectCurrentProduct)),
                ),
                tap(([action, templateType, currentProduct]) => {
                    if (action && action.zoomStatus === true) {
                        this.analyticsService.sendData(
                            new ProductContentOpenZoomEvent({
                                templateType:
                                    templateType ?? action.templateType,
                                isTopLevelProduct: action.isTopLevelProduct,
                                sku: currentProduct.sku,
                            }),
                        );
                    }
                }),
            ),
        { dispatch: false },
    );

    private sessionStorage: StorageManager | undefined;

    constructor(
        private actions$: Actions,
        private context: Context,
        private analyticsService: AnalyticsService,
        private currencyService: CurrencyService,
        private store: Store<fromProduct.ProductPageState>,
        private productPersoPageFacade: ProductPersonalizationFacade,
        private storageService: StorageService,
        private location: Location,
        @Inject(DOCUMENT) private document: Document,
        @Inject(LOCALE) private locale: Locale,
        private urlUtils: UrlUtils,
        private settings: Settings,
        private userFacade: UserFacade,
    ) {
        this.sessionStorage = this.storageService.getSessionStorageInstance();
    }
}
