import { ProductTemplateType } from "@hermes/api-model-product";
import {
    CallToActionAnalyticsEvent,
    GTMEventData,
} from "@hermes/utils/analytics";

import { getEventCategory } from "../helpers/product-page-analytics.helpers";

/**
 * This class is used to manage the sending of events when changing the image on the carousel
 */
export class CarouselClickEvent extends CallToActionAnalyticsEvent {
    /**
     * Overload formatForGTM of inherited class
     * Return formatter for eventaction and eventlabel key
     */

    public override formatForGTM(): GTMEventData {
        const eventcategory = getEventCategory(this.eventData);
        if (this.eventData.imageType === "product") {
            return {
                ...super.formatForGTM(),
                eventcategory,
                eventaction: this.getEventAction(),
                eventlabel: this.eventData.currentVariantSku
                    ? `${this.eventData.assetType}_${this.eventData.currentVariantSku}`
                    : `${this.eventData.assetType}_${
                          this.eventData.currentIndex + 1
                      }`,
            };
        }

        return {
            ...super.formatForGTM(),
            eventcategory: "Product_Grid",
            eventaction: "Alternative_View",
            eventlabel: this.eventData.eventlabel,
        };
    }

    /**
     * Get event action for look and bikini products
     */
    public getEventAction(): string {
        // When swiping on set
        if (
            this.eventData.templateType === ProductTemplateType.Look &&
            this.eventData.isTopLevelProduct
        ) {
            return "Alternative_View_Looks";
        }
        if (
            this.eventData.templateType === ProductTemplateType.Bikini &&
            this.eventData.isTopLevelProduct
        ) {
            return "Alternative_View_Bikini";
        }

        // When swiping on item
        if (
            (this.eventData.templateType === ProductTemplateType.Look &&
                !this.eventData.isTopLevelProduct) ||
            (this.eventData.templateType === ProductTemplateType.Bikini &&
                !this.eventData.isTopLevelProduct)
        ) {
            return "Alternative_View_Product";
        }
        return "Alternative_View";
    }
}
