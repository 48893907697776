import { DestroyRef, Inject, Injectable } from "@angular/core";
import type { Ripe } from "ripe-sdk/src/js";
import { from, of, Subscription } from "rxjs";
import { catchError, take, withLatestFrom } from "rxjs/operators";

import { Logger } from "@hermes/logger";
import { LOGGER } from "@hermes/web-logger";

import {
    BELTKIT,
    EMPTY_PLATFORME_MESSAGE,
    SMALL_LEATHER_GOODS,
} from "../../constants/product-personalization.constant";
import { ProductPersonalizationFacade } from "../../facades/product-personalization.facade";
import {
    ConfigParts,
    ConfigPartsBk,
    ConfigPartsCommon,
    ConfigPartsSmlg,
    EngravingConfiguration,
    PartMaterial,
    ProductCategory,
    PropertyEngravingType,
    RipePart,
} from "../../types/product-personalization.type";
import { PlatformeDkuService } from "../platformeDkuService/platforme-dku.service";

/**
 * Concerns everything that has to do with the message to display on the product
 */
@Injectable()
export class RipeInstanceService {
    public ripe: Ripe;

    private subscription: Subscription = new Subscription();

    constructor(
        private productPersonalizationFacade: ProductPersonalizationFacade,
        private platformeDku: PlatformeDkuService,
        @Inject(LOGGER) private logger: Logger,
        private destroyReference: DestroyRef,
    ) {
        this.destroyReference.onDestroy(() => this.subscription.unsubscribe());
    }

    /**
     * Get the configuration to update the state with
     */
    public getEngravingConfiguration(
        configParts: ConfigPartsCommon,
    ): EngravingConfiguration {
        return {
            font: configParts.fontStyle?.value ?? "",
            font_size: configParts.fontSize?.value ?? "",
            style: configParts.fontColor?.value ?? "",
            position: configParts.fontMarkingZone?.value ?? "",
            message: configParts.updatedMessage?.value ?? "",
        };
    }

    /**
     * Update leather type and color and then update message
     */
    public updatePartAndMessage(
        configParts: ConfigParts,
        productCategory: ProductCategory,
        ripe: Ripe,
    ): void {
        this.ripe = ripe;
        switch (productCategory) {
            case BELTKIT: {
                this.updatePartAndMessageForBk(configParts);
                break;
            }
            case SMALL_LEATHER_GOODS: {
                this.updatePartAndMessageForSmlg(configParts);
                break;
            }
        }
    }

    public updatePartAndMessageForSmlg(configParts: ConfigPartsSmlg): void {
        if (configParts.leather) {
            this.productPersonalizationFacade.updateIsCallingPlatforme(true);
            this.setPartsAndUpdateMessage(
                {
                    name: PartMaterial.exterior,
                    value: configParts.leather.exteriorLeather ?? "",
                    color: configParts.exteriorColor?.value ?? "",
                },
                {
                    name: PartMaterial.lining,
                    value: configParts.leather.liningLeather ?? "",
                    color: configParts.liningColor?.value ?? "",
                },
                SMALL_LEATHER_GOODS,
            );
        }
    }

    public updatePartAndMessageForBk(configParts: ConfigPartsBk): void {
        this.setPartsAndUpdateMessage(
            {
                name: PartMaterial.exterior,
                value: this.ripe.parts?.exterior?.material,
                color: configParts.exteriorColor?.value ?? "",
            },
            {
                name: PartMaterial.interior,
                value: this.ripe.parts?.interior?.material,
                color: configParts.interiorColor?.value ?? "",
            },
            BELTKIT,
            configParts.buckle
                ? configParts.buckle.value.split(":")
                : undefined,
            configParts.beltSize ? configParts.beltSize.value : undefined,
        );
    }

    /**
     * Set leather type and color in Ripe instance and then update message
     */
    public setPartsAndUpdateMessage(
        exterior: RipePart,
        lining: RipePart,
        productCategory: ProductCategory,
        buckle?: string[],
        beltSize?: string,
    ): void {
        const parts: string[][] = [
            [exterior.name, exterior.value, exterior.color],
            [lining.name, lining.value, lining.color],
        ];

        if (buckle) {
            parts.push(buckle);
        }

        this.subscription.add(
            from(this.ripe.setParts(parts))
                .pipe(
                    take(1),
                    withLatestFrom(
                        this.productPersonalizationFacade
                            .engravingConfiguration$,
                    ),
                    catchError((error) => {
                        this.logger.error(
                            "Unexpected error while calling platformE",
                            error,
                        );
                        // Stop loader on ATC button.
                        this.productPersonalizationFacade.updateIsCallingPlatforme(
                            false,
                        );
                        return of(undefined);
                    }),
                )
                .subscribe((result) => {
                    if (result) {
                        const [_voidReturn, engravingConfiguration] = result;
                        this.updateMessage(
                            productCategory,
                            engravingConfiguration,
                            beltSize,
                        );
                    }
                }),
        );
    }

    /**
     * Update the message to display on the image
     */
    public updateMessage(
        productCategory: ProductCategory,
        engravingConfiguration: EngravingConfiguration,
        productSize?: string,
    ): void {
        this.subscription.add(
            from(
                this.ripe.setInitials(
                    engravingConfiguration.message || EMPTY_PLATFORME_MESSAGE,
                    this.engravingConfigurationToEngraving(
                        engravingConfiguration,
                    ),
                ),
            )
                .pipe(take(1))
                .subscribe((ripe: Ripe) => {
                    this.platformeDku.updateDkuFromPlatforme(
                        ripe,
                        productCategory,
                        productSize,
                    );
                }),
        );
    }

    /**
     * Return engraving (ex: 'position:interior.font:filosofia.font_size:small.style:embossed.filosofia:small:embossed')
     */
    public engravingConfigurationToEngraving(
        engravingConfiguration: EngravingConfiguration,
    ): string {
        // the position attribute is used by myhpad,
        // it must be added to the dku with position if defined or with undefined
        const position = `${PropertyEngravingType.POSITION}:${
            engravingConfiguration.position || "undefined"
        }.`;
        const font = engravingConfiguration.font
            ? `${PropertyEngravingType.FONT}:${
                  engravingConfiguration.font || ""
              }.`
            : "";
        const fontSize = engravingConfiguration.font_size
            ? `${PropertyEngravingType.FONTSIZE}:${
                  engravingConfiguration.font_size || ""
              }.`
            : "";
        const fontStyle = engravingConfiguration.style
            ? `${PropertyEngravingType.STYLE}:${
                  engravingConfiguration.style || ""
              }`
            : "";
        const engraving: string = [
            engravingConfiguration.font,
            engravingConfiguration.font_size,
            engravingConfiguration.style,
        ]
            .filter(Boolean)
            .join(":");

        return `${position}${font}${fontSize}${fontStyle}.${engraving}`;
    }
}
