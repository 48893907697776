import { NgModule, Pipe, PipeTransform } from "@angular/core";

import { CustomerService } from "@hermes/api-model-editorial";

@Pipe({
    name: "openingHours",
})
export class OpeningHoursPipe implements PipeTransform {
    public transform(contact: CustomerService): string {
        const description = contact.description || "";
        const openingItemsArray = [];

        if (contact.callingHours) {
            for (const openingHoursItems of contact.callingHours) {
                const days = openingHoursItems.days || "";
                const openingHours = openingHoursItems.openingHours || "";
                const closingHours = openingHoursItems.closingHours || "";

                const timezone = openingHoursItems.timezone
                    ? ` ${openingHoursItems.timezone}`
                    : "";

                openingItemsArray.push(
                    `${days} ${openingHours} - ${closingHours}${timezone}`,
                );
            }
            const hours = openingItemsArray?.join(", ");
            return `${description} ${hours} :`;
        }

        return `${description} :`;
    }
}

@NgModule({
    declarations: [OpeningHoursPipe],
    exports: [OpeningHoursPipe],
    providers: [OpeningHoursPipe],
})
export class OpeningHoursPipeModule {}
