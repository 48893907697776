import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { first, map, switchMap, tap } from "rxjs/operators";

import { ApplePayFacade } from "@hermes/states/apple-pay";
import { BasketFacade } from "@hermes/states/basket";
import {
    getProp65warning,
    getProp65warningWithoutLinks,
} from "@hermes/utils/helpers";

import { ProductService } from "../../services/product.service";

const RECURRING_TEXT = $localize`:@@hermes-global-translations.checkout.recurring-message:By ticking this box, you acknowledge and agree that Hermes will save your bank details until the shipment of your order. This conservation period allows Hermès to debit your order only upon its shipment. Your bank details will be automatically deleted after the shipment is carried out.`;
const TITLE_SR_TEXT = $localize`:@@hermes.accessibility_apple-pay_payment.GTC-title:which will automatically launch the Apple Pay payment.`;
const TITLE_TEXT = $localize`:@@hermes_checkout_payment.GTC-title:Please accept our Terms & Conditions`;
const PRIVACY_TEXT = $localize`:@@hermes_global.i-agree-receiving-information-concerning-offers-services-products-events-privacy-text:Privacy Policy`;
const PRIVACY_LINK_KEY = "hermes_footer.legal.privacy.link.relative";
const CGU_TEXT = $localize`:@@hermes-footer-cgu-label:General Terms and Conditions of Use`;
const CGU_LINK_KEY = "hermes_global.url.terms";
const NEW_WINDOW = $localize`:@@hermes.footer.social.new-window:(new window)`;

const APPLEPAY_BODY = $localize`:@@hermes_applepay_payment.checkbox-GTC:I agree to the Hermès !gtcs. I also agree to the creation of a customer account (if I do not have one yet) and to the processing of my data in compliance with Hermès !privacyLink.`;

@Component({
    selector: "h-apple-pay-terms-and-conditions",
    templateUrl: "./apple-pay-terms-and-conditions.component.html",
    styleUrls: ["./apple-pay-terms-and-conditions.component.scss"],
})
export class ApplePayTermsAndConditionsComponent implements OnInit, OnDestroy {
    @ViewChild("containerTermsAndConditions", { read: ElementRef })
    public container: ElementRef | undefined;

    public checkoutPaymentBody?: SafeHtml;
    public checkoutPaymentBodyWithoutLinks?: SafeHtml;
    public prop65warningWithLinks?: SafeHtml;
    public prop65warningWithoutLinks?: string;
    public isProp65 = false;
    public recurringCheckbox: boolean = false;
    public termsConditionsCheckbox: boolean = false;
    public recurringInnerHtml: SafeHtml | undefined;
    public titleSrText: string | undefined;
    public titleText: string | undefined;
    public recurring: boolean = false;

    public acceptTermsConditions: UntypedFormGroup = new UntypedFormGroup({
        recurringCheckbox: new UntypedFormControl(false),
        termsConditionsCheckbox: new UntypedFormControl(false),
    });

    /**
     * To manage list of subscriptions
     */
    private subscription: Subscription = new Subscription();

    constructor(
        private domsanitizer: DomSanitizer,
        private translateService: TranslateService,
        private applePayFacade: ApplePayFacade,
        private basketFacade: BasketFacade,
        private productService: ProductService,
        private cdr: ChangeDetectorRef,
    ) {}

    @HostListener("document:click", ["$event"])
    public clickout(event: Event): void {
        if (!this.container?.nativeElement.contains(event.target)) {
            this.onExit();
        }
    }

    public ngOnInit(): void {
        this.recurringInnerHtml =
            this.domsanitizer.bypassSecurityTrustHtml(RECURRING_TEXT);
        this.titleText = TITLE_TEXT;
        this.titleSrText = TITLE_SR_TEXT;

        const bodyText = APPLEPAY_BODY;

        this.buildCheckoutPaymentBody(bodyText);
        this.buildCheckoutPaymentBodyWithoutLinks(bodyText);

        const property65plural = false;

        this.subscription.add(
            this.basketFacade.cart$
                .pipe(
                    map((serializedBasket) =>
                        this.basketFacade.deserializeBasket(serializedBasket),
                    ),
                    tap((basket) => (this.recurring = basket.hasRecurring)),
                    switchMap((basket) =>
                        this.productService.getProp65materials(
                            basket.items.map((item) => item.sku),
                        ),
                    ),
                    map((property65) => ({
                        plural: property65plural,
                        materials: property65,
                    })),
                )
                .subscribe((property65) =>
                    this.prepareTermsAndConditions({
                        plural: property65?.materials?.length > 1,
                        materials: property65?.materials,
                    }),
                ),
        );

        const recurringCheckboxControl =
            this.acceptTermsConditions.get("recurringCheckbox");
        if (recurringCheckboxControl) {
            this.subscription.add(
                recurringCheckboxControl.valueChanges.subscribe(
                    (value: boolean) => {
                        this.recurringCheckbox = value;
                        if (
                            (!this.recurring || this.recurringCheckbox) &&
                            this.termsConditionsCheckbox
                        ) {
                            this.onTermsValidated();
                        }
                    },
                ),
            );
        }

        const termsConditionsCheckboxControl = this.acceptTermsConditions.get(
            "termsConditionsCheckbox",
        );
        if (termsConditionsCheckboxControl) {
            this.subscription.add(
                termsConditionsCheckboxControl.valueChanges.subscribe(
                    (value: boolean) => {
                        this.termsConditionsCheckbox = value;
                        if (
                            (!this.recurring || this.recurringCheckbox) &&
                            this.termsConditionsCheckbox
                        ) {
                            this.onTermsValidated();
                        }
                    },
                ),
            );
        }
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onExit(): void {
        this.applePayFacade.abort();
    }

    public onTermsValidated(): void {
        this.applePayFacade.termsValidated();
    }

    private prepareTermsAndConditions(property65: {
        plural: boolean;
        materials: string[];
    }): void {
        if (property65?.materials && property65.materials.length > 0) {
            const metals = property65.materials.join(", ");

            const property65WarningString = getProp65warning(
                metals,
                `<a target="blank" tabindex="0" href='https://www.P65Warnings.ca.gov'><U>www.P65Warnings.ca.gov</U><span class="sr-only">${NEW_WINDOW}</span></a>`,
                !!property65.plural,
            );
            this.prop65warningWithLinks =
                this.domsanitizer.bypassSecurityTrustHtml(
                    property65WarningString,
                );
            this.prop65warningWithoutLinks = getProp65warningWithoutLinks(
                metals,
                !!property65.plural,
            );
            this.isProp65 = true;

            this.cdr.detectChanges();
        }
    }

    private buildCheckoutPaymentBody(bodyText: string): void {
        this.subscription.add(
            this.translateService
                .get([PRIVACY_LINK_KEY, CGU_LINK_KEY])
                .pipe(
                    first(),
                    map((translations) =>
                        bodyText
                            .replace(
                                "!privacyLink",
                                `<a target="blank" tabindex="0" href="${translations[PRIVACY_LINK_KEY]}" id="id-more-details-privacy-link" style="text-decoration: underline">${PRIVACY_TEXT}<span class="sr-only">${NEW_WINDOW}</span></a>`,
                            )
                            .replace(
                                "!gtcs",
                                `<a target="blank" tabindex="0" href="${translations[CGU_LINK_KEY]}" id="id-cgu-link" style="text-decoration: underline">${CGU_TEXT}<span class="sr-only">${NEW_WINDOW}</span></a>`,
                            )
                            .replace("\n", "<br>"),
                    ),
                )
                .subscribe((bodyTextWithLinks) => {
                    this.checkoutPaymentBody =
                        this.domsanitizer.bypassSecurityTrustHtml(
                            bodyTextWithLinks,
                        );
                }),
        );
    }

    private buildCheckoutPaymentBodyWithoutLinks(bodyText: string): void {
        const bodyTextWithoutLinks = bodyText
            .replace("!privacyLink", PRIVACY_TEXT)
            .replace("!gtcs", CGU_TEXT)
            .replace("\n", "<br>");

        this.checkoutPaymentBodyWithoutLinks =
            this.domsanitizer.bypassSecurityTrustHtml(bodyTextWithoutLinks);
    }
}
