import { Injectable, ElementRef } from "@angular/core";
import type { Ripe } from "ripe-sdk/src/js";

import { Asset } from "@hermes/api-model-editorial";
import { PersonalizationAsset } from "@hermes/api-model-perso";

import { Product } from "@hermes/api-model-product";
import { AssetMediaType } from "@hermes/utils-generic/constants";

import {
    BELTKIT,
    personalizeConstants,
    SMALL_LEATHER_GOODS,
} from "../../constants/product-personalization.constant";
import {
    EngravingConfiguration,
    FacesMapping,
    MarkingPosition,
    PartType,
    ProductCategory,
} from "../../types/product-personalization.type";

export interface RipeBuckle {
    color: string;
    name: string;
    reversible: boolean;
    sku: string;
    url: string;
}

/**
 * Concerns everything that has to do with the message to display on the product
 */
@Injectable({
    providedIn: "root",
})
export class PersoImageService {
    public ripe: Ripe;
    public facesMapping: FacesMapping = {
        exteriorFaces: [],
        interiorFaces: [],
    };
    public selectedFontMarkingZone: PartType = PartType.Interior;
    public productCategory: ProductCategory = "unsupported";
    public engraving: {
        configuration: EngravingConfiguration;
    };
    private pictureDefaultSize = 850;
    private PNG_FORMAT = "png";

    public currentBuckle: RipeBuckle | undefined;

    public updateCurrentBuckle(value: RipeBuckle | undefined) {
        this.currentBuckle = value;
    }

    public getBuckleBySku(
        buckles: Record<string, RipeBuckle> | undefined,
        secondProduct: Product,
    ): RipeBuckle | undefined {
        if (!buckles) {
            return undefined;
        }

        return Object.values(buckles).find(
            (ripeBuckle: RipeBuckle) => ripeBuckle.sku === secondProduct?.sku,
        );
    }

    /**
     * Initialization of the service
     */
    public initPersoImageService(
        ripeInstance: Ripe,
        productCategory: ProductCategory,
    ): void {
        this.ripe = ripeInstance;
        this.productCategory = productCategory;

        this.facesMapping.exteriorFaces =
            ripeInstance.loadedConfig.meta.exterior_faces;
        this.facesMapping.interiorFaces =
            ripeInstance.loadedConfig.meta.interior_faces;
    }

    public updateSelectedFontMarkingZone(
        selectedFontMarkingZone: PartType,
    ): void {
        this.selectedFontMarkingZone = selectedFontMarkingZone;
    }

    public getRequestAssets(face: MarkingPosition): PersonalizationAsset[] {
        const initials = this.getInitials(face, this.ripe.initials);
        return [
            {
                url: this.ripe._getImageURL({
                    brand: personalizeConstants.BRAND,
                    model: this.ripe.model,
                    frame: face,
                    crop: personalizeConstants.CROP,
                    format: personalizeConstants.FORMAT,
                    showInitials: true,
                    size: 850,
                    background: personalizeConstants.BACKGROUND,
                    initials,
                    engraving: this.ripe.engraving ?? "",
                    profile: [
                        this.ripe.engraving
                            ? this.ripe.engraving.replace(/\./g, ",")
                            : "",
                    ],
                }),
                face,
                customized: true,
            },
        ];
    }

    /**
     * Initialization of the ripe image
     */
    public initRipeImage(
        ripeInstance: Ripe,
        imageDiv: ElementRef,
        face: MarkingPosition,
        callback: (asset: Asset) => void,
    ): void {
        const imageRipe: Ripe = this.bindRipeImage(
            ripeInstance,
            imageDiv,
            face,
        );
        imageRipe.bind("loaded", (_frame) => {
            callback(
                new Asset({
                    title: imageRipe.frame,
                    url: imageRipe.element.src,
                    type: AssetMediaType.Image,
                    tag: imageRipe.frame,
                }),
            );
        });
    }

    public assetRipeImage(
        ripeInstance: Ripe,
        imageDiv: ElementRef,
        face: MarkingPosition,
    ): Asset {
        const imageRipe: Ripe = this.bindRipeImage(
            ripeInstance,
            imageDiv,
            face,
        );
        return new Asset({
            title: imageRipe.frame,
            url: imageRipe.element?.src,
            type: AssetMediaType.Image,
            tag: imageRipe.frame,
        });
    }

    public bindRipeImage(
        ripeInstance: Ripe,
        imageDiv: ElementRef,
        face: MarkingPosition,
    ): Ripe {
        const imageElement: ElementRef = imageDiv.nativeElement.querySelector(
            `#view-${face}`,
        );
        return ripeInstance.bindImage(imageElement, {
            frame: face,
            showInitials: true,
            size: this.pictureDefaultSize,
            format: ripeInstance.format || this.PNG_FORMAT,
            crop: false,
            initialsBuilder: (initials, engraving) => ({
                initials: this.getInitials(face, initials),
                profile: this.getEngraving(face, engraving),
            }),
        });
    }

    /**
     * Determine the message to display depending of the face and the product type
     *
     * @param face      The face of the image displayed
     * @param initials  The message to display on the image
     */
    public getInitials(face: MarkingPosition, initials: string): string {
        let displayInitials = true;

        switch (this.productCategory) {
            case BELTKIT: {
                displayInitials = [
                    MarkingPosition.ZOOM_BACK,
                    MarkingPosition.BACK,
                ].includes(face);
                break;
            }
            case SMALL_LEATHER_GOODS: {
                displayInitials =
                    this.selectedFontMarkingZone === this.getImageType(face);
                break;
            }
        }

        return displayInitials ? initials : "";
    }

    /**
     * Return a formatted array with engraving information
     */
    public getEngraving(
        face: MarkingPosition,
        engraving: string,
    ): string[] | void {
        if (!engraving) {
            return;
        }
        const splittedData: string[] = engraving.split(".");

        if (this.productCategory === BELTKIT) {
            splittedData.push(`view::${face}`);
        }

        return splittedData;
    }

    /**
     * Return the type of image (exterior or interior)
     */
    public getImageType(face: MarkingPosition): PartType {
        return this.facesMapping?.exteriorFaces?.includes(face)
            ? PartType.Exterior
            : PartType.Interior;
    }

    /**
     * Get the face by position
     */
    public getFaceByPosition(position: PartType): MarkingPosition | undefined {
        if (this.productCategory === SMALL_LEATHER_GOODS) {
            switch (position) {
                case PartType.Interior: {
                    return this.facesMapping
                        .interiorFaces[0] as MarkingPosition;
                }
                case PartType.Exterior: {
                    return this.facesMapping
                        .exteriorFaces[0] as MarkingPosition;
                }
                default: {
                    return undefined;
                }
            }
        }
        if (this.productCategory === BELTKIT) {
            return MarkingPosition.FRONT;
        }
        return undefined;
    }

    public bindAssetAsReversible = (
        ripeInstance: Ripe,
        assets: Asset[],
        imageDiv: ElementRef,
    ): Asset[] => {
        const faces = ripeInstance.loadedConfig.faces;
        const faceReversible = faces.find(
            (face: MarkingPosition) => face === MarkingPosition.REVERSIBLE,
        );

        const newAssets = assets.filter(
            (asset) => asset.tag !== MarkingPosition.REVERSIBLE,
        );

        const ripeAsset = this.assetRipeImage(
            ripeInstance,
            imageDiv,
            MarkingPosition.REVERSIBLE,
        );
        if (ripeAsset?.url) {
            newAssets.splice(faces.indexOf(faceReversible), 0, ripeAsset);
            return newAssets;
        }
        return newAssets;
    };
}
