import {
    Product,
    ProductItemBikini,
    ProductItemLook,
    ProductSizeVariant,
    ProductTemplateType,
} from "@hermes/api-model-product";
import { GTMEventData } from "@hermes/utils/analytics";

import { getEventCategory } from "../helpers/product-page-analytics.helpers";

import { VariationClickAnalyticsEvent } from "./variation-click.event";

/**
 * This class is used for variant-click event in product-info
 */
export class ProductInfoVariantClickEvent extends VariationClickAnalyticsEvent {
    /**
     * Overload formatForGTM of inherited class
     * Return formatter for eventaction and eventlabel key
     */
    public override formatForGTM(): GTMEventData {
        if (
            this.eventData.product.templateType === ProductTemplateType.Look ||
            this.eventData.product.templateType === ProductTemplateType.Bikini
        ) {
            return this.getComplexProductSizeSelector();
        }
        const eventcategory = getEventCategory({
            templateType: this.eventData.product.templateType,
        });
        switch (this.eventData.variantType) {
            case "color": {
                return {
                    ...super.formatForGTM(),
                    eventaction: "Color_Selector",
                    eventlabel: this.eventData.variantItem.color,
                };
            }
            case "volume": {
                return {
                    ...super.formatForGTM(),
                    eventaction: "Volume_Selector",
                    eventlabel: this.eventData.variantItem.volume,
                };
            }
            case "size": {
                return {
                    ...super.formatForGTM(),
                    eventcategory,
                    eventaction: "Size_Selector",
                    eventlabel: this.eventData.variantItem.size,
                };
            }
            case "buckle": {
                return {
                    ...super.formatForGTM(),
                    eventcategory,
                    eventaction: "Buckle_Selector",
                    eventlabel: `${this.eventData.variantItem.displayNameList} | ${this.eventData.variantItem.color}`,
                };
            }
            case "leather": {
                return {
                    ...super.formatForGTM(),
                    eventaction: "Color_Selector",
                    eventlabel: this.eventData.variantItem.color,
                    eventcategory,
                };
            }
            case "strap": {
                return {
                    ...super.formatForGTM(),
                    eventaction: "Bracelet_Selector",
                    eventlabel: this.eventData.variantItem.color,
                    eventcategory,
                };
            }
            case "finish": {
                return {
                    ...super.formatForGTM(),
                    eventaction: "Finish_Selector",
                    eventlabel: this.eventData.variantItem.finish,
                    eventcategory,
                };
            }
            default: {
                return {
                    ...super.formatForGTM(),
                };
            }
        }
    }

    /**
     * In case of look or bikini because we dont have default selected variant
     * we need send product view at size selection
     */
    private getComplexProductSizeSelector(): GTMEventData {
        const {
            product,
            variantItem,
            position,
        }: {
            product: Product;
            variantItem: ProductSizeVariant;
            position: number;
        } = this.eventData;

        let item: Partial<ProductItemBikini & ProductItemLook> | undefined;
        let itemLength: number = 0;
        let eventLabel: string = variantItem.size;

        if (product.templateType === ProductTemplateType.Look) {
            item = product.lookAttributes?.items[position];
            itemLength = product.lookAttributes?.items.length ?? 0;
            eventLabel = `${variantItem.size}_${variantItem.sku}`;
        }

        if (product.templateType === ProductTemplateType.Bikini) {
            item = product.bikiniAttributes?.items[position];
            itemLength = product.bikiniAttributes?.items.length ?? 0;
            eventLabel = `${variantItem.size}_${variantItem.sku}`;
        }
        return {
            ...super.formatForGTM(),
            eventcategory: getEventCategory(product),
            eventaction: "Size_Selector",
            eventlabel: eventLabel,
            ecommerce: {
                detail: {
                    products: [
                        {
                            name: item?.title,
                            id: variantItem.sku,
                            price: item?.price
                                ? item.price.toFixed(2)
                                : undefined,
                            brand: "hermes",
                            category: `${item?.departmentCode}/${item?.familyCode}`,
                            variant: product.sku,
                            dimension5: `${itemLength}`,
                            dimension6: item?.departmentCode,
                            dimension7: item?.familyCode,
                            dimension8: item?.productCode,
                            dimension9: item?.divisionCode,
                        },
                    ],
                },
            },
        };
    }
}
