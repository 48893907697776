import type CardElement from "@adyen/adyen-web/dist/types/components/Card";
import { DOCUMENT } from "@angular/common";
import { ElementRef, Inject, Injectable } from "@angular/core";
import { Observable, of } from "rxjs";

import { PaymentDataRequest } from "@hermes/api-model-payment";
import { MERCURY_CHECKOUTCOM_CC } from "@hermes/states/payment-methods";

export interface CheckoutcomForm {
    isCardValid: () => boolean;
    cardholder: { name: string };
    fieldEmpty: (field: string) => void;
    submitCard: () => Promise<{
        /* eslint-disable @typescript-eslint/naming-convention */
        card_holder_name?: string;
        bin?: string;
        card_category?: string;
        card_type?: string;
        expires_on?: string;
        expiry_month?: number;
        expiry_year?: number;
        issuer?: string;
        issuer_country?: string;
        last4?: string;
        name?: string;
        product_id?: string;
        product_type?: string;
        scheme?: string;
        token?: string;
        type?: string;
        /* eslint-enable @typescript-eslint/naming-convention */
    }>;
}

export interface CreditCardData {
    endDigits?: string;
    bin?: string;
    selectedBrand?: string;
}

@Injectable()
export class PaymentFormService {
    public adyenForm!: CardElement;
    public checkoutcomForm!: CheckoutcomForm;
    public checkoutcomNamecardHolder!: ElementRef;
    public authorizedCardType!: boolean;
    public adyenCardData: CreditCardData = {};
    public isValidBrand?: boolean;

    constructor(@Inject(DOCUMENT) private document: Document) {}

    /**
     * Test payment fields validity
     */
    public isFormValid(paymentMethod: string, oneClick?: boolean): boolean {
        if (paymentMethod === MERCURY_CHECKOUTCOM_CC) {
            if (
                this.checkoutcomNamecardHolder.nativeElement.value === "" ||
                !this.authorizedCardType
            ) {
                return false;
            }
            return this.checkoutcomForm.isCardValid();
        }
        if (oneClick && this.adyenForm.isValid) {
            return true;
        }

        return (
            this.adyenForm.isValid &&
            (typeof this.isValidBrand !== "boolean" || this.isValidBrand)
        );
    }

    /**
     * Get all payment datas
     */
    public getPaymentDatas(
        paymentMethod: string,
    ): Observable<PaymentDataRequest> {
        if (paymentMethod === MERCURY_CHECKOUTCOM_CC) {
            return new Observable((observer) => {
                if (this.checkoutcomNamecardHolder.nativeElement) {
                    this.checkoutcomForm.cardholder.name =
                        this.checkoutcomNamecardHolder.nativeElement.value;
                }
                this.checkoutcomForm.submitCard().then((data) => {
                    observer.next({
                        bin: data.bin,
                        token: data.token,
                        holderName:
                            this.checkoutcomNamecardHolder.nativeElement.value,
                        brand: data.scheme,
                        endDigits: data.last4,
                    });
                    observer.complete();
                });
            });
        }
        const data = this.adyenForm?.formatData();
        return of({
            ...data.paymentMethod,
            installments: data.installments?.value,
            endDigits: this.adyenCardData?.endDigits,
            bin: this.adyenCardData?.bin,
            brand: this.adyenCardData?.selectedBrand,
        });
    }

    /**
     * In case of checkout.com, submit card infos
     */
    public submitCard(paymentMethod: string): void {
        if (paymentMethod === MERCURY_CHECKOUTCOM_CC) {
            this.checkoutcomForm.submitCard();
        }
    }

    /**
     * Showing validation
     */
    public showValidation(paymentMethod: string): void {
        if (paymentMethod === MERCURY_CHECKOUTCOM_CC) {
            // Vide en attente d'US de finitions
        } else {
            this.adyenForm.showValidation();
        }
    }

    /**
     * Test if checkoutcom fields are empty
     */
    public checkIfEmptyField(
        paymentMethod: string,
        parentSelector?: string,
    ): void {
        if (paymentMethod === MERCURY_CHECKOUTCOM_CC) {
            const fields = this.document.querySelectorAll(
                [
                    `${parentSelector} div.card-number-frame`,
                    `${parentSelector} div.expiry-date-frame`,
                    `${parentSelector} div.cvv-frame`,
                ].join(","),
            );
            fields.forEach((div) => {
                if (!div.classList.contains("frame--valid")) {
                    const field = div.classList[0].slice(
                        0,
                        Math.max(0, div.classList[0].lastIndexOf("-")),
                    );
                    div.classList.add("frame--invalid");
                    this.checkoutcomForm.fieldEmpty(field);
                }
            });

            const nameCardHolderInvalidClass = "name-card-holder-invalid";
            if (this.checkoutcomNamecardHolder.nativeElement.value === "") {
                this.checkoutcomNamecardHolder.nativeElement.classList.add(
                    nameCardHolderInvalidClass,
                );
            } else {
                this.checkoutcomNamecardHolder.nativeElement.classList.remove(
                    nameCardHolderInvalidClass,
                );
            }
        }
    }
}
