import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";

import { Address } from "@hermes/api-model-address";
import { ErrorItem } from "@hermes/api-model-basket";
import { ErrorsFacade, setHasCheckoutError } from "@hermes/states/errors-state";
import { UserStateService } from "@hermes/states/user";

import {
    accordionSummary,
    editAddressErrorFocusable,
    editFocusState,
    editIsShippingAddressBlocked,
    editShippingAddressResponseError,
    goNextStep,
    hideLoader,
    setHideErrorPayment,
    setRequiredErrorOnAddressStreetFields,
    showLoader,
    submitCart,
    updateProgressionState,
} from "../actions/conversion-funnel.action";
import {
    FocusState,
    ProgressionState,
} from "../reducers/conversion-funnel-state.types";
import { State } from "../reducers/conversion-funnel.reducer";
import {
    selectAccordionSummary,
    selectFocusOnEditShippingAddress,
    selectFocusOnNewCustomerEmail,
    selectFocusState,
    selectGoNextStep,
    selectHideErrorPayment,
    selectIsAccountActive,
    selectIsAddressErrorFocusable,
    selectIsCartSubmited,
    selectIsDeliveryActive,
    selectIsLoading,
    selectIsPaymentActive,
    selectIsPaymentDone,
    selectIsShippingAddressBlocked,
    selectProgressionState,
    selectRequiredErrorOnAddressStreetFields,
    selectShippingAddressResponseError,
} from "../selectors/conversion-funnel.selectors";

@Injectable()
export class ConversionFunnelFacade {
    /**
     * Selectors
     */
    public focusOnEditShippingAddress$: Observable<string | undefined> =
        this.store.select(selectFocusOnEditShippingAddress);

    public focusOnNewCustomerEmail$: Observable<boolean | undefined> =
        this.store.select(selectFocusOnNewCustomerEmail);

    public focusState$: Observable<FocusState> =
        this.store.select(selectFocusState);

    public hasError$: Observable<boolean> = this.errorsFacade.hasError$;

    public hideErrorPayment$: Observable<boolean> = this.store.select(
        selectHideErrorPayment,
    );

    public requiredErrorStreetFields$: Observable<Address> = this.store.select(
        selectRequiredErrorOnAddressStreetFields,
    );

    public isAddressErrorFocusable$: Observable<boolean> = this.store.select(
        selectIsAddressErrorFocusable,
    );

    public isCartSubmited$: Observable<boolean> =
        this.store.select(selectIsCartSubmited);

    public isAccountActive$: Observable<boolean | undefined> =
        this.store.select(selectIsAccountActive);

    public isDeliveryActive$: Observable<boolean | undefined> =
        this.store.select(selectIsDeliveryActive);

    public isLoading$: Observable<boolean> = this.store.select(selectIsLoading);

    public isPaymentActive$: Observable<boolean | undefined> =
        this.store.select(selectIsPaymentActive);

    public isPaymentDone$: Observable<boolean | undefined> =
        this.store.select(selectIsPaymentDone);

    public isShippingAddressBlocked$: Observable<boolean> = this.store.select(
        selectIsShippingAddressBlocked,
    );

    public progressionState$: Observable<ProgressionState> = this.store.select(
        selectProgressionState,
    );
    public shippingAddressResponseError$: Observable<ErrorItem | undefined> =
        this.store.select(selectShippingAddressResponseError);

    public accordionSummary$: Observable<boolean> = this.store.select(
        selectAccordionSummary,
    );

    public goNextStep$: Observable<string | undefined> =
        this.store.select(selectGoNextStep);

    constructor(
        private store: Store<State>,
        private userService: UserStateService,
        private errorsFacade: ErrorsFacade,
    ) {}

    /**
     * Actions
     */
    public checkLocalStorage(): void {
        const checkout: ProgressionState =
            this.userService.getCheckoutStep() as ProgressionState;
        if (checkout) {
            this.editProgressionState(checkout);
        }
    }

    public editProgressionState(newKeys: ProgressionState): void {
        this.store.dispatch(updateProgressionState({ newKeys }));
    }

    public showLoader(): void {
        this.store.dispatch(showLoader());
    }

    public hideLoader(): void {
        this.store.dispatch(hideLoader());
    }

    public submitCart(): void {
        this.store.dispatch(submitCart());
    }

    public goNextStep(nextStep: "delivery" | undefined): void {
        this.store.dispatch(goNextStep({ nextStep }));
    }

    public setHideErrorPayment(value: boolean): void {
        this.store.dispatch(setHideErrorPayment({ value }));
    }

    public setRequiredErrorOnAddressStreetFields(
        defaultAddress: Address,
    ): void {
        this.store.dispatch(
            setRequiredErrorOnAddressStreetFields({ defaultAddress }),
        );
    }

    public accordionSummary(value: boolean): void {
        this.store.dispatch(accordionSummary({ value }));
    }

    public editFocusState(keys: FocusState): void {
        this.store.dispatch(editFocusState({ keys }));
    }

    public editIsAddressErrorFocusable(isAddressErrorFocusable: boolean): void {
        this.store.dispatch(
            editAddressErrorFocusable({
                isAddressErrorFocusable,
            }),
        );
    }

    public editIsShippingAddressBlocked(
        isShippingAddressBlocked: boolean,
    ): void {
        this.store.dispatch(
            editIsShippingAddressBlocked({
                isShippingAddressBlocked,
            }),
        );
    }

    public editShippingAddressResponseError(
        shippingAddressResponseError: ErrorItem,
    ): void {
        this.store.dispatch(
            editShippingAddressResponseError({
                shippingAddressResponseError,
            }),
        );
    }

    public setHasError(value: boolean): void {
        this.store.dispatch(setHasCheckoutError({ value }));
    }
}
