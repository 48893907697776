import { Inject, Injectable, Signal } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, filter, map, switchMap, timeout } from "rxjs/operators";

import { BasketItem } from "@hermes/api-model-basket";
import { Product } from "@hermes/api-model-product";
import { WINDOW } from "@hermes/app-core";
import { ExternalLibraryFacade } from "@hermes/states/external-library";
import { RecaptchaService } from "@hermes/utils-generic/services/client-api";

import * as PaymentActions from "../actions/payment-methods.actions";
import { isApplePayMethodHelper } from "../helpers";
import { ApplePayPage } from "../models/apple-pay-page.model";
import { ExclusionCodes } from "../models/exclusion-codes.model";
import { FetchPaymentMethodsOptions } from "../models/fetch-payment-methods-options.model";
import { PaymentMethods, State } from "../reducers/payment-methods.reducer";
import paymentQuery, {
    selectSavedPaymentCardsState,
} from "../selectors/payment-methods.selectors";
import { AvailablePaymentMethodsService } from "../services/available-payment-methods.service";

export type WindowWithApplePay = Window & {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ApplePaySession: { canMakePayments: () => boolean };
};

@Injectable()
export class PaymentMethodsFacade {
    /**
     * Selectors
     */
    public adyenCreditCardPaymentMethod$ = this.store.select(
        paymentQuery.selectAdyenCreditCardPaymentMethod,
    );

    public getSavedPaymentCards$ = this.store.select(
        paymentQuery.selectSavedPaymentCards,
    );
    public pendingSignal: Signal<boolean> = this.store.selectSignal(
        selectSavedPaymentCardsState,
    );

    constructor(
        private store: Store<State>,
        private availablePaymentMethodsService: AvailablePaymentMethodsService,
        private recaptchaService: RecaptchaService,
        private externalLibraryFacade: ExternalLibraryFacade,
        @Inject(WINDOW) private window: WindowWithApplePay,
    ) {}

    public isApplePayToDisplay(
        applePayPage?: ApplePayPage,
        items?: Array<Product | BasketItem>,
    ): Observable<boolean> {
        return this.availablePaymentMethodsService
            .getExclusionsCodeFromItems(items ?? [])
            .pipe(
                switchMap((exclusionCodes) =>
                    this.getAvailablePaymentMethods({
                        applePayPage,
                        productCodes: exclusionCodes,
                    }),
                ),
                switchMap((paymentMethods) =>
                    paymentMethods.some((pm) => isApplePayMethodHelper(pm.code))
                        ? this.recaptchaService
                              .loadExternalRecaptchaLibraryIfNeeded()
                              .pipe(map((result) => result ?? true))
                        : of(false),
                ),
            );
    }

    /**
     * Actions
     */
    public fetchPaymentMethods(options?: FetchPaymentMethodsOptions): void {
        this.store.dispatch(PaymentActions.fetchPaymentMethods({ options }));
    }

    public deleteSavedCard(id: string, cardToken: string): void {
        this.store.dispatch(PaymentActions.deleteSavedCard({ id, cardToken }));
    }

    public removeCard(id: string): void {
        this.store.dispatch(PaymentActions.removeCard({ id }));
    }

    public getAvailablePaymentMethods(
        parameters: {
            isWeChat?: boolean;
            total?: number;
            hasRecurring?: boolean;
            applePayPage?: ApplePayPage;
            productCodes?: ExclusionCodes;
        } = {},
    ): Observable<PaymentMethods> {
        return this.store.select(paymentQuery.selectPaymentMethodsData).pipe(
            map((paymentMethods) =>
                this.availablePaymentMethodsService.getAvailablePaymentMethods(
                    paymentMethods,
                    parameters,
                ),
            ),
            switchMap((fetchPaymentMethods) => {
                // if applePay is not present as a paymentMethod, return the paymentMethods directly
                if (
                    !fetchPaymentMethods.some(
                        (pm) => pm.code === "mercury_adyen_apple_pay",
                    )
                ) {
                    return of(fetchPaymentMethods);
                }

                try {
                    // if the script is already there (Safari), return the paymentMethods directly
                    if (this.window.ApplePaySession?.canMakePayments()) {
                        return of(fetchPaymentMethods);
                    }
                } catch {
                    // if we catch an error with ApplePaySession, return the paymentMethods without ApplePay (localhost in http)
                    return of(
                        fetchPaymentMethods.filter(
                            (method) =>
                                method.code !== "mercury_adyen_apple_pay",
                        ),
                    );
                }

                // else we load the script for other browsers and wait until it's loaded
                this.externalLibraryFacade.load({
                    url: "https://applepay.cdn-apple.com/jsapi/1.latest/apple-pay-sdk.js",
                    windowAttribute: "ApplePaySession",
                    key: "APPLE_PAY",
                });

                return this.externalLibraryFacade.isLoaded$("APPLE_PAY").pipe(
                    filter((isloaded) => isloaded),
                    timeout({
                        // if the load hasn't finish within 5 seconds, we return false
                        each: 5000,
                        with: () => of(false),
                    }),
                    map(() => this.window.ApplePaySession?.canMakePayments()),
                    // if we catch an error with ApplePaySession, we return false
                    catchError(() => of(false)),
                    map((isValidForApplePay) =>
                        // if we can't load/find applePay in browser, remove it from payment methods
                        isValidForApplePay
                            ? fetchPaymentMethods
                            : fetchPaymentMethods.filter(
                                  (method) =>
                                      method.code !== "mercury_adyen_apple_pay",
                              ),
                    ),
                );
            }),
        );
    }
}
