import { Component, EventEmitter, Input, Output } from "@angular/core";

import { CTAToken, Edito, Media } from "@hermes/api-model-editorial";

import {
    MenuEntryItemWithEdito,
    MenuItemType,
    MenuAnalyticsService,
} from "@hermes/fragments/menu-shared";

@Component({
    selector: "h-menu-bar-edito",
    templateUrl: "./menu-bar-edito.component.html",
    styleUrls: ["./menu-bar-edito.component.scss"],
})
export class MenuBarEditoComponent {
    @Input()
    public menuEntry!: MenuEntryItemWithEdito;

    @Input()
    public edito!: Edito;

    @Output()
    public menuEditoClicked: EventEmitter<void> = new EventEmitter<void>();

    public type = MenuItemType.Edito;

    constructor(private menuAnalyticsService: MenuAnalyticsService) {}

    public get media(): Media {
        return {
            mobileImage: this.edito.image,
            desktopImage: this.edito.image,
            videoLoopMuteAutoplay: false,
            alwaysShowControls: false,
            imageRetouched: false,
            caption: "",
            credits: "",
            videoUrl: "",
        };
    }

    public get token(): CTAToken {
        return {
            path: this.edito.path,
            text: this.edito.title,
            tokenType: "ctaStory",
        };
    }

    public onMediaRedirection(event?: MouseEvent | Event): void {
        event?.preventDefault();
        event?.stopPropagation();
        this.menuEditoClicked.emit();

        this.menuAnalyticsService.sendAnalyticsOnMenuEditoClick(this.menuEntry);
    }
}
