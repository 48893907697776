<div class="suggestion-container" [@animateContainer]="getSuggestionsState()">
    <div class="search-suggestions" [@animateSuggestions]="getSuggestionsState()">
        @if (suggestions && suggestions.length > 0) {
        <p id="search-suggestions-label" class="label label-medium-bold-uppercase" [attr.role]="'status'">
            <!-- Screenreader : Enforce reading this element every time suggestions are refreshed by removing and bringing back the element -->
            <ng-container *hRedraw="suggestions">
                <ng-container i18n="@@hermes-global-translations.search-suggestions"> Suggested searches </ng-container>
                <span hSrOnlyNoSnippet
                    >, {{ suggestions.length }}
                    @if (suggestions.length < 2) {

                    <ng-container i18n="@@hermes.accessibility.item">item</ng-container>

                    } @else {

                    <ng-container i18n="@@hermes.accessibility.items">items</ng-container>

                    }
                </span>
            </ng-container>
        </p>
        } @if (suggestions?.length === 0) {
        <p
            id="search-suggestions-label"
            class="label"
            [attr.role]="'status'"
            i18n="@@hermes-global-translations.no-suggestions">
            No suggested searches
        </p>
        }
        <ul
            [attr.role]="'listbox'"
            id="header-search-suggestions"
            class="search-suggestion-details"
            [attr.aria-labelledby]="suggestions ? 'search-suggestions-label' : null">
            @if (suggestions) { @for (suggestion of suggestions; track suggestion; let i = $index) {
            <li
                [id]="i | suggestionItem"
                class="search-suggestion-item"
                [class.focused]="i === selected"
                [attr.role]="'option'"
                [attr.aria-selected]="i === selected"
                (mousedown)="redirect(suggestion, i + 1)">
                <span
                    class="search-suggestion-label"
                    [innerHTML]="suggestion.label | strong : searchTerm : 'presentation'"></span>
                @if (suggestion.quantity && suggestion.quantity >= 0) {

                <span class="search-suggestion-label" [attr.aria-hidden]="'true'"> ({{ suggestion.quantity }})</span>
                <span hSrOnlyNoSnippet
                    >, {{ suggestion.quantity }}
                    @if (suggestion.quantity && suggestion.quantity < 2) {

                    <ng-container i18n="@@hermes.accessibility.product">item</ng-container>

                    } @else {

                    <ng-container i18n="@@hermes.accessibility.products">items</ng-container>

                    }
                </span>

                }
            </li>
            } }
        </ul>
    </div>
</div>
