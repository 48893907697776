import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Subject, Subscription, timer } from "rxjs";
import { debounceTime, filter, switchMap, takeUntil } from "rxjs/operators";

import { LOCALE, WINDOW } from "@hermes/app-core";
import { AnalyticsService } from "@hermes/utils/analytics";
import { Locale } from "@hermes/locale";
import { MenuFacade, FlatCategories } from "@hermes/states/menu";

import { stopEventPropagation } from "@hermes/utils-generic/helpers";

import { SearchEvent } from "../../events/search.event";
import { isPrintableSearch } from "../../helpers/header-search.helper";
import { Suggestion } from "../../model/suggestions";
import { HeaderSearchService } from "../../services/header-search.service";

@Component({
    selector: "h-header-search-bar",
    templateUrl: "./header-search-bar.component.html",
    styleUrls: ["./header-search-bar.component.scss"],
})
export class HeaderSearchBarComponent implements OnInit, OnDestroy {
    @Output()
    public searchExpanded: EventEmitter<boolean> = new EventEmitter<boolean>();
    @ViewChild("searchInput", { static: true })
    public searchInput!: ElementRef<HTMLInputElement>;

    public formAction!: string;
    public isFocused: boolean = false;
    public suggestions: undefined | Suggestion[] = undefined;
    public selected: number | undefined;
    public searchTerm: string = "";
    public searchForm = new UntypedFormGroup({
        search: new UntypedFormControl({ value: "", disabled: true }),
    });

    private categories: FlatCategories = {};
    private scrollListener!: EventListener;
    private subscription = new Subscription();
    private destroy$ = new Subject<void>();

    constructor(
        private headerSearchService: HeaderSearchService,
        @Inject(LOCALE) private locale: Locale,
        private cd: ChangeDetectorRef,
        @Inject(WINDOW) private window: Window,
        private analyticsService: AnalyticsService,
        private menuFacade: MenuFacade,
    ) {}

    public ngOnInit(): void {
        this.scrollListener = () => this.searchInput.nativeElement.blur();
        this.formAction = `${this.locale.urlPrefix}/search/`;
        this.fetchCategories();
        this.initAutocomplete();
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.destroy$.next();
        this.destroy$.complete();
    }

    public onSubmit(): void {
        const formValue = this.searchForm.value;
        if (formValue.search && isPrintableSearch(formValue.search)) {
            this.window.location.assign(
                `${this.locale.urlPrefix}/search/?s=${formValue.search}`,
            );
        }
    }

    public handleSearch(): void {
        if (this.isFocused) {
            this.onSubmit();
        } else {
            this.searchInput.nativeElement.focus();
        }
    }

    public handleFocus(): void {
        this.isFocused = true;
        this.searchExpanded.emit(true);
        // Adding a delay to not catch the scroll from the focus itself
        setTimeout(() => {
            this.window.addEventListener("scroll", this.scrollListener);
        }, 200);
        const searchTerm = this.searchForm.get("search")?.value;
        this.subscription.add(
            this.headerSearchService
                .getSuggestions(searchTerm, this.categories)
                .pipe(filter(() => this.isFocused)) // Only accept results when bar is focused
                .subscribe((suggestions) => {
                    this.saveSuggestions(suggestions);
                }),
        );
    }

    public handleBlur(): void {
        const BLUR_DELAY = 100; // ms
        // Delay handler to prevent instant removal of suggestions and subcomponent.
        this.subscription.add(
            timer(BLUR_DELAY).subscribe(() => {
                this.isFocused = false;
                this.searchExpanded.emit(false);
                this.window.removeEventListener("scroll", this.scrollListener);
                this.suggestions = undefined;
                this.selected = undefined;
            }),
        );
    }

    public handleEsc(): void {
        this.resetSearchField();
        this.suggestions = undefined;
        this.selected = undefined;
    }

    public handleDown(event: Event): void {
        stopEventPropagation(event);
        if (!this.hasSuggestionSelected(this.selected)) {
            return this.selectFirstSuggestion();
        }

        if (this.isLastSuggestionSelected()) {
            return this.selectFirstSuggestion();
        }

        this.selected++;
    }

    public handleUp(event: Event): void {
        stopEventPropagation(event);
        if (!this.hasSuggestionSelected(this.selected)) {
            return this.selectLastSuggestion();
        }

        if (this.isFirstSuggestionSelected()) {
            return this.selectLastSuggestion();
        }

        this.selected--;
    }

    public handleEnter(event: Event): void {
        if (this.selected !== undefined && this.selected >= 0) {
            // Prevent form submit
            stopEventPropagation(event);
            const suggestionLabel = this.suggestions?.[this.selected].label;
            this.searchForm.get("search")?.setValue(suggestionLabel, {
                emitEvent: false,
            });
            this.sendSearchEvent(
                suggestionLabel ?? "undefined",
                this.selected + 1,
            );
            const url = this.suggestions?.[this.selected]?.url;
            if (typeof url === "string") {
                this.window.location.assign(url);
            }
            this.suggestions = undefined;
            this.selected = undefined;
        }
        // Else form submit
    }

    public resetSearchField(): void {
        this.searchForm.reset({ search: "" }, { emitEvent: false });
    }

    private sendSearchEvent(suggestionLabel: string, position: number): void {
        this.analyticsService.sendData(
            new SearchEvent({
                eventaction: "Search suggest list",
                eventlabel: `${this.searchTerm} : ${suggestionLabel}`,
                eventvalue: position,
            }),
        );
    }

    private fetchCategories(): void {
        this.menuFacade.fetchMenu();
        this.subscription.add(
            this.menuFacade.categories$
                .pipe(filter((categories) => !!categories))
                .subscribe((categories) => {
                    this.searchForm.get("search")?.enable({ emitEvent: false });
                    this.categories = categories as FlatCategories;
                }),
        );
    }

    private initAutocomplete() {
        this.searchForm
            .get("search")
            ?.valueChanges.pipe(
                debounceTime(500),
                switchMap((term) =>
                    this.headerSearchService.getSuggestions(
                        term,
                        this.categories,
                    ),
                ),
                filter(() => this.isFocused), // Only accept results when bar is focused
                takeUntil(this.destroy$), // unsubscribe when component is destroyed
            )
            .subscribe((suggestions) => this.saveSuggestions(suggestions));
    }

    private saveSuggestions(suggestions: Suggestion[]) {
        this.searchTerm = this.searchForm.get("search")?.value;
        this.suggestions = suggestions;
        delete this.selected;
        // Handle OnPush pages
        this.cd.markForCheck();
    }

    private hasSuggestionSelected(
        selected: number | undefined,
    ): selected is number {
        return selected !== undefined && selected >= 0;
    }

    private isFirstSuggestionSelected(): boolean {
        return this.selected === 0;
    }

    private isLastSuggestionSelected(): boolean {
        return (
            this.suggestions !== undefined &&
            this.selected === this.suggestions.length - 1
        );
    }

    private selectFirstSuggestion() {
        this.selected = 0;
    }

    private selectLastSuggestion() {
        if (this.suggestions) {
            this.selected = this.suggestions.length - 1;
        }
    }
}
