import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { ApplicationRef, Inject, Injectable } from "@angular/core";
import { JsonConvert } from "json2typescript";
import isEqual from "lodash-es/isEqual";
import { interval, merge, Observable, of } from "rxjs";
import {
    catchError,
    distinctUntilChanged,
    first,
    map,
    switchMap,
    take,
} from "rxjs/operators";

import { CustomerInformation, Guest, User } from "@hermes/api-model-account";
import { Address } from "@hermes/api-model-address";
import { Basket, WebToShopDetails } from "@hermes/api-model-basket";
import { Fapiao } from "@hermes/api-model-conversion-funnel";
import { PaymentResponse as PaymentResponseMercury } from "@hermes/api-model-payment";
import {
    LoginResponse,
    UnionIdResponse,
    WechatBindingResponse,
} from "@hermes/api-model-session";
import {
    Context,
    LOCALE,
    Settings,
    StorageManager,
    StorageService,
} from "@hermes/app-core";
import { Locale } from "@hermes/locale";
import { HTTP_NO_CACHE_CONTROL_HEADER } from "@hermes/utils-generic/constants";

import { UserFacade } from "../facades/user.facade";

@Injectable()
export class UserStateService {
    private sessionStorage: StorageManager;
    private localStorage: StorageManager;
    private runFetchsSessionStorageDelay: number = 1000;

    constructor(
        private context: Context,
        private storageService: StorageService,
        private http: HttpClient,
        private settings: Settings,
        private userFacade: UserFacade,
        private appReference: ApplicationRef,
        @Inject(LOCALE) private locale: Locale,
    ) {
        this.sessionStorage =
            this.storageService.getSessionStorageInstance() as StorageManager;
        this.localStorage =
            this.storageService.getLocalStorageInstance() as StorageManager;
    }

    public updateBasket(basket: Basket): void {
        const userStorage: { basket: Basket } = {
            ...this.getSessionStorageData("user"),
            basket,
        };
        this.setSessionStorageData("user", userStorage);
        basket = new JsonConvert().deserializeObject(basket, Basket);
        this.setSessionStorageData("total_items", basket.totalItems);
    }

    /**
     * Retrieve the basket from sessionStorage.
     * Return an empty basket if not found
     */
    public getBasket(): Basket {
        let user: { basket: Basket } | undefined =
            this.getSessionStorageData("user");
        if (user) {
            user = new JsonConvert().deserializeObject(user, User);
            return user.basket ?? new Basket();
        }
        return new Basket();
    }

    public getCustomerInformation(
        isLightRequest: boolean = false,
    ): Observable<CustomerInformation> {
        const httpOptions = {
            params: new HttpParams().set("isLightRequest", isLightRequest),
        };
        return this.http
            .get<CustomerInformation>(
                `${this.settings.apiUrl}/customer/information`,
                httpOptions,
            )
            .pipe(
                catchError((error) => {
                    throw error;
                }),
            );
    }

    public getUser(): User | undefined {
        const serializedUser = this.getSessionStorageData("user");
        if (serializedUser) {
            return new JsonConvert().deserializeObject(serializedUser, User);
        }
        return undefined;
    }

    /**
     * Clear User in Session Storage
     */
    public clearUser(): void {
        this.removeSessionStorageData("user");
    }

    public hasChangeLanguage(): boolean {
        return this.getSessionStorageData("changeLanguage") !== undefined;
    }

    /**
     * Return true if 'cookieEndOfTime' found in session storage
     */
    public hasCookieEndOfTime(): boolean {
        return this.getSessionStorageData("cookieEndOfTime") !== undefined;
    }

    public sessionNeedsRefresh(): boolean {
        return this.getSessionStorageData("sessionNeedsRefresh") === true;
    }

    public setSessionNeedsRefresh(sessionNeedsRefresh: boolean): void {
        this.setSessionStorageData("sessionNeedsRefresh", sessionNeedsRefresh);
    }

    /**
     * Call the get-session-info method that returns an object with the basket and user infos from the ecomProxy session
     * if found, sets the data in sessionStorage.
     * if not found, call the getBasket() function
     *
     * TODO: Refactor this function to separate the API fetch from the session update algorithm.
     */
    public getCustomerSession(options?: {
        withShippingMethods: boolean;
    }): Observable<Basket> {
        const params = new HttpParams().set(
            "withShippingMethods",
            options?.withShippingMethods ? 1 : 0,
        );

        return this.http
            .get<{
                user: User;
                // eslint-disable-next-line @typescript-eslint/naming-convention
                basket: { total_items: number };
                lifetime: string;
            }>(`${this.settings.apiUrl}/customer-session`, {
                headers: new HttpHeaders({
                    ...HTTP_NO_CACHE_CONTROL_HEADER,
                }),
                params,
            })
            .pipe(
                map((data) => {
                    let basket: Basket;

                    const basketAnalytics = this.getBasketAnalytics();
                    if (data.user) {
                        this.sessionStorage.setItem("user", data.user);
                        this.sessionStorage.setItem(
                            "checkUserSessionToken",
                            true,
                        );
                        this.sessionStorage.setItem("isConnected", true);
                    }

                    if (data.basket) {
                        this.sessionStorage.setItem("changeLanguage", true);
                        this.sessionStorage.setItem(
                            "sessionNeedsRefresh",
                            false,
                        );
                    }

                    if (
                        data.basket &&
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        data.basket.total_items > 0
                    ) {
                        const user = this.getSessionStorageData("user") || {
                            basket: undefined,
                        };
                        (user as Record<string, unknown>)["basket"] =
                            data.basket;
                        this.sessionStorage.setItem("user", user);
                        basket = new JsonConvert().deserializeObject(
                            data.basket,
                            Basket,
                        );
                    } else {
                        basket = new Basket();
                    }

                    if (basketAnalytics) {
                        const user = this.getSessionStorageData("user") || {
                            basketAnalytics: undefined,
                        };
                        (user as Record<string, unknown>)["basketAnalytics"] =
                            basketAnalytics;
                        this.sessionStorage.setItem("user", user);
                    }

                    if (!this.hasCookieEndOfTime()) {
                        this.setSessionStorageData(
                            "cookieEndOfTime",
                            Date.now() +
                                Number.parseInt(data.lifetime, 10) * 1000,
                        );
                    }

                    return basket;
                }),
            );
    }

    /**
     * Call the /customer-session route that returns an object with the basket and user infos from the ecomProxy session
     */
    public fetchCustomerSession(
        withShippingMethods = 0,
    ): Observable<LoginResponse> {
        const params = new HttpParams().set(
            "withShippingMethods",
            withShippingMethods,
        );

        const httpOptions = {
            headers: HTTP_NO_CACHE_CONTROL_HEADER,
            params,
        };

        return this.http
            .get<LoginResponse>(
                `${this.settings.apiUrl}/customer-session`,
                httpOptions,
            )
            .pipe(
                map((data) => {
                    if (data.user) {
                        data.user = new JsonConvert().deserializeObject(
                            data.user,
                            User,
                        );
                    }
                    return data;
                }),
                catchError((error) => {
                    throw new Error(
                        `fetch customer session error : ${error.error}`,
                    );
                }),
            );
    }

    /**
     * @description Log in the given user
     */
    public loginUser(user: User): void {
        this.userFacade.removeGuest();
        this.userFacade.setUserInStorage(user);
        this.sessionStorage.setItem("checkUserSessionToken", true);
        this.sessionStorage.setItem("isConnected", true);
    }

    /**
     * Called when the login is successful for a returning customer
     */
    public deprecatedLoginSuccess(
        response: LoginResponse | UnionIdResponse | WechatBindingResponse,
    ): void {
        this.userFacade.removeGuest();
        // sets the shipping addresses in the localstorage
        if (response?.shippingAddresses) {
            this.setShippingAdresses(response.shippingAddresses);
        }
        // sets the available countries in the localstorage
        if (response.availableCountries) {
            this.setAvailableCountries(response.availableCountries);
        }
        if (
            response.user &&
            Number.parseInt(response.user.customerId, 10) > 0
        ) {
            const user = new User();

            // When we login from wechat the basket is not returned by the service
            if (response.user.basket) {
                this.logUserIn(user);
                this.updateBasket(response.basket!);
            } else {
                // We have to map to the new object otherwise we can't deserialize it
                for (const [key, value] of Object.entries(response.user)) {
                    (user as unknown as Record<string, unknown>)[key] = value;
                }
                user.basket = this.getBasket();
                this.updateBasket(user.basket);
                this.logUserIn(user);
            }
        }
    }

    public getGuest(): Guest {
        return this.getSessionStorageData("guest") as Guest;
    }

    public removeCheckout(): void {
        this.removeLocalStorageData("checkout");
    }

    public setCheckoutStep(step: unknown): void {
        const checkout = this.getLocalStorageData<{ step: unknown }>(
            "checkout",
        );

        if (checkout) {
            checkout.step = step;
            this.setLocalStorageData("checkout", checkout);
        } else {
            this.setLocalStorageData("checkout", {
                step,
            });
        }
    }

    public getCheckoutStep(): unknown {
        const checkout = this.getLocalStorageData<{ step: unknown }>(
            "checkout",
        );

        if (checkout && checkout.step) {
            return checkout.step;
        }
        return undefined;
    }

    public setShippingAdresses(addresses: Address[]): void {
        const checkout = this.getLocalStorageData<{
            shippingAddresses: Address[];
        }>("checkout");

        if (checkout) {
            checkout.shippingAddresses = addresses;
            this.setLocalStorageData("checkout", checkout);
        } else {
            this.setLocalStorageData("checkout", {
                shippingAddresses: addresses,
            });
        }
    }

    public getShippingAdresses(): Address[] {
        const checkout = this.getLocalStorageData<{
            shippingAddresses: Address[];
        }>("checkout");
        let shippingAddresses: Address[] = [];

        if (checkout && checkout.shippingAddresses) {
            shippingAddresses = checkout.shippingAddresses;
        }
        return shippingAddresses;
    }

    public setAvailableCountries(countries: string[]): void {
        const checkout = this.getLocalStorageData<{
            availableCountries: string[];
        }>("checkout");

        if (checkout) {
            checkout.availableCountries = countries;
            this.setLocalStorageData("checkout", checkout);
        } else {
            this.setLocalStorageData("checkout", {
                availableCountries: countries,
            });
        }
    }

    public getAvailableCountries(): string[] {
        const checkout = this.getLocalStorageData<{
            availableCountries: string[];
        }>("checkout");
        let availableCountries: string[] = [];

        if (checkout && checkout.availableCountries) {
            availableCountries = checkout.availableCountries;
        }
        return availableCountries;
    }

    public setShops(shops: WebToShopDetails[]): void {
        const checkout = this.getLocalStorageData<{
            shops: WebToShopDetails[];
        }>("checkout");

        if (checkout) {
            checkout.shops = shops;
            this.setLocalStorageData("checkout", checkout);
        } else {
            this.setLocalStorageData("checkout", {
                shops,
            });
        }
    }

    public getShops(): WebToShopDetails[] {
        const checkout = this.getLocalStorageData<{
            shops: WebToShopDetails[];
        }>("checkout");
        let shops: WebToShopDetails[] = [];

        if (checkout && checkout.shops) {
            shops = checkout.shops;
        }
        return shops;
    }

    public setSelectedShop(shop: WebToShopDetails): void {
        const checkout = this.getLocalStorageData<{
            selectedShop: WebToShopDetails;
        }>("checkout");

        if (checkout) {
            checkout.selectedShop = shop;
            this.setLocalStorageData("checkout", checkout);
        } else {
            this.setLocalStorageData("checkout", {
                shop,
            });
        }
    }

    public removeSelectedShop(): void {
        const checkout = this.getLocalStorageData<{
            selectedShop?: WebToShopDetails;
        }>("checkout");
        if (checkout) {
            delete checkout["selectedShop"];
            this.setLocalStorageData("checkout", checkout);
        }
    }

    public getSelectedShop(): WebToShopDetails | undefined {
        const checkout = this.getLocalStorageData<{
            selectedShop: WebToShopDetails;
        }>("checkout");

        if (checkout && checkout.selectedShop) {
            return checkout.selectedShop;
        }
        return undefined;
    }

    public setLastShop(shopId: string): void {
        const checkout = this.getLocalStorageData<{ lastWebToShop: string }>(
            "checkout",
        );

        if (checkout) {
            checkout.lastWebToShop = shopId;
            this.setLocalStorageData("checkout", checkout);
        } else {
            this.setLocalStorageData("checkout", {
                shopId,
            });
        }
    }

    public getLastShop(): string | undefined {
        const checkout = this.getLocalStorageData<{ lastWebToShop: string }>(
            "checkout",
        );

        if (checkout && checkout.lastWebToShop) {
            return checkout.lastWebToShop;
        }
        return undefined;
    }

    public setFapiao(fapiao: Fapiao): void {
        const checkout = this.getLocalStorageData<{ fapiao: Fapiao }>(
            "checkout",
        );

        if (checkout) {
            checkout.fapiao = fapiao;
            this.setLocalStorageData("checkout", checkout);
        } else {
            this.setLocalStorageData("checkout", {
                fapiao,
            });
        }
    }

    public getFapiao(): Fapiao | undefined {
        const checkout = this.getLocalStorageData<{ fapiao: Fapiao }>(
            "checkout",
        );

        if (checkout && checkout.fapiao) {
            return checkout.fapiao;
        }
        return undefined;
    }

    public setCookieExpiration(): void {
        const checkout = this.getLocalStorageData<{
            cookieExpirationTime: number;
        }>("checkout");

        const expiration = this.getCookieExpirationFromSessionStorage();

        if (checkout) {
            checkout.cookieExpirationTime = expiration;
            this.setLocalStorageData("checkout", checkout);
        } else {
            this.setLocalStorageData("checkout", {
                cookieExpirationTime: expiration,
            });
        }
    }

    public getCookieExpirationFromLocalStorage(): number | undefined {
        const checkout = this.getLocalStorageData<{
            cookieExpirationTime: number;
        }>("checkout");

        if (checkout && checkout.cookieExpirationTime) {
            return checkout.cookieExpirationTime;
        }
        return undefined;
    }

    public getCookieExpirationFromSessionStorage(): number {
        return this.getSessionStorageData("cookieEndOfTime") || 0;
    }

    /**
     * Get a basket copy for analytics
     */
    public setAnalyticsBasket(
        response: PaymentResponseMercury,
        applePayWidget?: boolean,
    ): void {
        interface PartialBasket {
            /* eslint-disable @typescript-eslint/naming-convention */
            tax_amount?: number;
            shipping_amount?: number;
            increment_id: string;
            apple_pay_widget: boolean;
            /* eslint-enable @typescript-eslint/naming-convention */
        }

        const userStorage = this.getSessionStorageData("user") as {
            basket: PartialBasket;
            basketAnalytics: PartialBasket;
        };
        userStorage.basket.tax_amount = this.getBasket().taxAmount;
        userStorage.basket.shipping_amount = this.getBasket().shippingAmount;
        userStorage.basket.increment_id = response.orderId;
        userStorage.basket.apple_pay_widget = applePayWidget || false;

        userStorage.basketAnalytics = userStorage.basket;
        this.setSessionStorageData("user", userStorage);
    }

    // TODO: Use the appropriate types instead of these Records
    public getBasketAnalytics(): Record<string, unknown> | undefined {
        const user = this.getSessionStorageData<{
            basketAnalytics: Record<string, unknown>;
        }>("user") || { basketAnalytics: undefined };
        return user.basketAnalytics;
    }

    public getCustomerFromSessionStorage(): Observable<User> {
        return merge(
            // Force the emission of the user from the Storage (to avoid waiting for the `isStable` and the `interval`)
            of(this.getUserForState()).pipe(take(1)),
            // wait for app to be stable before starting interval.
            // see https://angular.io/api/core/ApplicationRef#is-stable-examples
            this.appReference.isStable.pipe(
                first((stable) => stable),
                switchMap(() => interval(this.runFetchsSessionStorageDelay)),
                map((): User => this.getUserForState()),
            ),
        ).pipe(
            distinctUntilChanged((previous, current) =>
                isEqual(previous, current),
            ),
        );
    }

    public getProductThreshold(): boolean | undefined {
        return this.getSessionStorageData("has_product_threshold");
    }

    /**
     * In the NgRX User State, User must be decorrelated to basket and shippingAddresses.
     *
     * When the basket / shippingAddresses is updated we ignore its value
     */
    private getUserForState(): User {
        const user = this.getUser() ?? {};
        return {
            ...user,
            basket: undefined as unknown as Basket,
            shippingAddresses: undefined as unknown as unknown[],
        } as User;
    }

    private logUserIn(user: User): void {
        this.userFacade.setUserInStorage(user);
        this.sessionStorage.setItem("checkUserSessionToken", true);
        this.sessionStorage.setItem("isConnected", true);
    }

    private getSessionStorageData<S>(key: string): S | undefined {
        return this.context.isInBrowserMode()
            ? this.sessionStorage.getItem<S>(key)
            : undefined;
    }

    private setSessionStorageData<S>(key: string, data: S): void {
        if (this.context.isInBrowserMode()) {
            this.sessionStorage.setItem<S>(key, data);
        }
    }

    private getLocalStorageData<S>(key: string): S | undefined {
        return this.context.isInBrowserMode()
            ? this.localStorage.getItem<S>(key)
            : undefined;
    }

    private setLocalStorageData<S>(key: string, data: S): void {
        if (this.context.isInBrowserMode()) {
            this.localStorage.setItem<S>(key, data);
        }
    }

    private removeLocalStorageData(key: string): void {
        if (this.context.isInBrowserMode() && this.getLocalStorageData(key)) {
            this.localStorage.deleteItem(key);
        }
    }

    private removeSessionStorageData(key: string): void {
        if (this.context.isInBrowserMode() && this.getSessionStorageData(key)) {
            this.sessionStorage.deleteItem(key);
        }
    }
}
