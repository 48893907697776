import { Component, Inject, Input } from "@angular/core";

import { FooterLegalLink } from "@hermes/api-model-shell";
import { LOCALE } from "@hermes/app-core";
import { Locale } from "@hermes/locale";

@Component({
    selector: "h-footer-legal-links",
    templateUrl: "./footer-legal-links.component.html",
    styleUrls: ["./footer-legal-links.component.scss"],
})
export class FooterLegalLinksComponent {
    @Input({ required: true })
    public legalLinks: FooterLegalLink[] = [];

    public chinaLicenseLogo = `${this.locale.urlPrefix}/assets/images/china-license.png`;
    public chinaNetworkSecurityLogo = `${this.locale.urlPrefix}/assets/images/china-network-security.png`;

    constructor(@Inject(LOCALE) private locale: Locale) {}
}
