import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";

import { ErrorsFacade } from "@hermes/states/errors-state";

import { ConversionFunnelEffects } from "./effects/conversion-funnel.effects";
import { ConversionFunnelFacade } from "./facades/conversion-funnel.facade";
import * as fromConversionFunnel from "./reducers/conversion-funnel.reducer";
@NgModule({
    imports: [
        CommonModule,
        EffectsModule.forFeature([ConversionFunnelEffects]),
        StoreModule.forFeature(
            fromConversionFunnel.conversionFunnelFeatureKey,
            fromConversionFunnel.reducer,
        ),
    ],
    providers: [ConversionFunnelFacade, ErrorsFacade],
})
export class ConversionFunnelStateModule {}
