<label
    [ngClass]="{
        'block-collapsed': isCollapsible,
        'block-disabled': disabled,
    }">
    <div class="block-input" [class.generic-input-error]="controlHaveErrors()">
        <input
            #checkboxInput
            [id]="id + '-checkbox'"
            [checked]="activate"
            [attr.aria-checked]="activate"
            [attr.aria-labelledby]="labelledById"
            [attr.data-testid]="dataTestId"
            [attr.aria-describedby]="describedById"
            [disabled]="disabled"
            type="checkbox"
            class="checkbox-input"
            (blur)="touched()"
            (change)="toggle()" />

        <span [attr.id]="id" class="checkmark"></span>
    </div>
    @if (insideLabel) {
    <div class="block-label">
        <ng-content></ng-content>
    </div>
    }
</label>

@if (!insideLabel && isCollapsible && checkboxInput.checked) {
<div>
    <ng-content select="[collapsable-body]"></ng-content>
</div>
}
