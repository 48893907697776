<div class="institutional-wrapper">
    <ul [attr.aria-labelledby]="'collection-button'" class="list-menu-institutional">
        <li>
            <a href="{{ findAStoreUrl }}" class="item-institutional" (click)="navigateOnShopPage($event)">
                <h-svg-icon [name]="'pin'"></h-svg-icon>

                <span #transFindastore class="item-link-label">
                    <ng-container i18n="@@hermes_commerce.find-a-store"> Find a store </ng-container>
                </span>
            </a>
        </li>

        <li>
            @if ((isLoggedIn$ | async) === true && (isAccountPageAngularEnabled$ | async) === false) {
            <button
                [hTrayOpener]="'tray-account'"
                [trayOverlay]="true"
                [isDrupalTray]="true"
                class="item-institutional account"
                [attr.aria-expanded]="'false'"
                [attr.aria-haspopup]="'dialog'"
                [attr.aria-controls]="'tray-account'"
                (click)="sendAccountEvent($event)">
                <h-svg-icon class="icon-logged-in" [name]="'person-with-dot'"></h-svg-icon>
                <span hSrOnlyNoSnippet i18n="@@hermes.customer_account.connected">Connected</span>
                <span #transAccount class="item-link-label" aria-hidden="true">
                    <ng-container i18n="@@block--hermes-commerce--nav.user-account"> Account </ng-container>
                </span>
            </button>
            } @if ((isAccountPageAngularEnabled$ | async) === true && (isLoggedIn$ | async) === true) {
            <a
                href="{{ baseHref + accountUrl }}"
                class="item-institutional account"
                (click)="goToPage($event, accountUrl)">
                <h-svg-icon [name]="'person-with-dot'"></h-svg-icon>
                <span hSrOnlyNoSnippet i18n="@@block--hermes-commerce--nav.user-account">Account</span>
                <span hSrOnlyNoSnippet>, </span>
                <span hSrOnlyNoSnippet i18n="@@hermes.customer_account.connected">Connected</span>
                <span class="item-link-label" aria-hidden="true">
                    <ng-container i18n="@@block--hermes-commerce--nav.user-account">Account</ng-container>
                </span>
            </a>
            } @if ((isLoggedIn$ | async) === false) {
            <a href="{{ baseHref + loginUrl }}" class="item-institutional account" (click)="goToPage($event, loginUrl)">
                <h-svg-icon [name]="'person'"></h-svg-icon>
                <span hSrOnlyNoSnippet i18n="@@block--hermes-commerce--nav.user-account">Account</span>
                <span hSrOnlyNoSnippet>, </span>
                <span hSrOnlyNoSnippet i18n="@@hermes.customer_account.disconnected">Offline</span>
                <span class="item-link-label" aria-hidden="true">
                    <ng-container i18n="@@block--hermes-commerce--nav.user-account">Account</ng-container>
                </span>
            </a>
            }
        </li>

        <li>
            <a class="email-us" href="{{ baseHref + contactUsUrl }}" (click)="goToPage($event, contactUsUrl)">
                <h-svg-icon [name]="'contact-us'"></h-svg-icon>

                <span class="item-link-label">
                    <ng-container>
                        {{ "hermes.page-header.link.title.help" | translate }}
                    </ng-container>
                </span>
            </a>
        </li>
    </ul>
</div>
