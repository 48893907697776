<div id="header-notifications" class="banner notification-banner" data-nosnippet>
    <!-- If there is only one notification, display it -->
    @if (headerNotificationsMessages.length === 1) {
    <section class="banner-message" i18n-aria-label="@@hermes.notifications" aria-label="Notifications">
        <h-notification-message [notificationMessage]="headerNotificationsMessages[0]"> </h-notification-message>
    </section>
    }

    <!-- If there are at least 2 notifications, display the notification carousel -->
    @if (headerNotificationsMessages.length > 1) {
    <section
        class="banner-message"
        i18n-aria-label="@@hermes.notifications"
        aria-label="Notifications"
        [attr.aria-roledescription]="'carousel'">
        <h-notification-slider [notifications]="headerNotificationsMessages"></h-notification-slider>
    </section>
    }

    <button #closeButton (click)="close()" (keypress.enter)="close()" class="close-icon">
        <span hSrOnlyNoSnippet i18n="@@hermes_global.close-info-banner-accessibility">Close the notification</span>
        <h-svg-icon [name]="'cross'"></h-svg-icon>
    </button>
</div>
