<div
    class="dynamic-box-container"
    [ngClass]="{
    checked: checked && !imageSrc,
        'checked-image': imageSrc && checkedImageVariant,
        'hover-image': imageSrc,
    'input-container-fixed-width': hasFixedWidth,
    'unavailable': unavailable,
    }">
    <div class="box-text-content" [class.inactive-cursor-radio]="boxType === 'radio' || boxType === 'checkbox'">
        <ng-container *ngTemplateOutlet="contentToDisplay"></ng-container>
        @if (boxType === 'link') { @if (isHref) {

        <a
            class="variant-link"
            [href]="linkUrl"
            [attr.title]="titleValue"
            [attr.aria-current]="checkedImageVariant"
            (click)="handleClick?.emit($event)">
        </a>

        } @else {

        <a
            class="variant-link"
            [routerLink]="buildRouteArray"
            [attr.title]="titleValue"
            [attr.aria-current]="checkedImageVariant"
            (click)="handleClick?.emit($event)">
        </a>

        } } @else {

        <!-- hidden content -->
        @if (boxType !== 'none') {
        <div [formGroup]="formGroup" class="input-content-hidden">
            <ng-container *ngTemplateOutlet="contentHidden"></ng-container>
            <div [title]="titleValue" data-nosnippet>
                @switch (boxType) { @case ('radio') {
                <input
                    type="radio"
                    [formControlName]="formName"
                    [value]="value"
                    [attr.name]="formName"
                    [attr.id]="inputId"
                    [checked]="checked"
                    [attr.required]="required"
                    [attr.aria-invalid]="inputAriaInvalid"
                    [attr.aria-describedby]="inputAriaDescribedby"
                    (change)="handleChange?.emit()"
                    (click)="handleClick?.emit($event)"
                    (focus)="handleFocus?.emit()"
                    (blur)="handleBlur?.emit()" />
                } @case ('checkbox') {
                <input
                    type="checkbox"
                    [formControlName]="formName"
                    [value]="value"
                    [attr.name]="formName"
                    [attr.id]="inputId"
                    [checked]="checked"
                    [attr.required]="required"
                    [attr.aria-invalid]="inputAriaInvalid"
                    [attr.aria-describedby]="inputAriaDescribedby"
                    (change)="handleChange?.emit()"
                    (click)="handleClick?.emit($event)"
                    (focus)="handleFocus?.emit()"
                    (blur)="handleBlur?.emit()" />
                } }
            </div>
        </div>
        } }
    </div>

    <ng-template #contentHidden>
        <ng-content select="[content-hidden]"></ng-content>
    </ng-template>
</div>
@if (displayLabelWithImage) {

<div id="label-with-image" class="visual-text label" [attr.aria-hidden]="true">
    {{ labelValue }}
</div>

}

<ng-template #contentToDisplay>
    <div
        class="box-display"
        [attr.title]="boxType === 'none' ? titleValue : ''"
        [attr.aria-current]="boxType === 'none' ? checked || checkedImageVariant : null"
        [attr.tabindex]="boxType === 'none' ? 0 : null">
        @if (!imageSrc) {

        <div
            class="box-text-display"
            [ngClass]="{ unavailable: unavailable, 'background-color-white': backgroundColorWhite }">
            <span class="visual-text" [attr.aria-hidden]="true">
                {{ labelValue }}
            </span>
        </div>

        } @else {

        <div
            class="box-image-display"
            [attr.tabindex]="boxType === 'none' ? 0 : -1"
            [class.box-image-display-checkbox]="boxType === 'checkbox' || forceStyleCheckbox"
            [attr.aria-hidden]="boxType !== 'none'"
            [class.black-overlay]="unavailable">
            <!-- In browser mode use lazy loading -->
            <div class="box-image-unavailable-icon svg-icon" [attr.aria-hidden]="'true'"></div>
            @if (unavailable) {
            <h-svg-icon name="diagonal" class="box-image-unavailable-icon svg-icon"></h-svg-icon>
            } @if (!isServerMode) {
            <picture class="picture">
                <source
                    [media]="pageProductImageMediaqueries.tablet"
                    [srcset]="imageSrc! | hImageUrl : imageConfig ?? imageConfigOnMobile : true" />
                <source
                    [media]="pageProductImageMediaqueries.desktop"
                    [srcset]="imageSrc! | hImageUrl : imageConfig ?? imageConfigOnDesktop : true" />
                <img
                    [attr.width]="imageConfig?.width ?? 'auto'"
                    [attr.height]="imageConfig?.height ?? 'auto'"
                    class="box-image-responsive"
                    [src]="imageSrc! | hImageUrl : imageConfig ?? imageConfigOnDesktop : true"
                    (load)="handleImageLoaded()"
                    loading="lazy"
                    alt="" />
            </picture>
            }
            <!-- In server mode use default image for SEO -->
            @if (isServerMode) {
            <div class="box-image-responsive">
                <img [src]="imageSrc! | hImageUrl : imageConfigOnMobile : true" alt="" />
            </div>
            }
        </div>

        }
    </div>
</ng-template>
