import { Component, Inject } from "@angular/core";

import { LOCALE } from "@hermes/app-core";
import { AnalyticsService } from "@hermes/utils/analytics";
import { Locale } from "@hermes/locale";

import { FooterClickEvent } from "../../events/click.event";

@Component({
    selector: "h-footer-country-selector-button",
    templateUrl: "./footer-country-selector-button.component.html",
    styleUrls: ["./footer-country-selector-button.component.scss"],
})
export class FooterCountrySelectorButtonComponent {
    constructor(
        @Inject(LOCALE) private locale: Locale,
        private analyticsService: AnalyticsService,
    ) {}

    public sendShopInEvent($event: Event): void {
        $event.stopPropagation();

        // Remove first slash
        const action = this.locale.urlPrefix.slice(1);

        this.analyticsService.sendData(
            new FooterClickEvent({
                action,
            }),
        );
    }
}
