import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

import { CallToActionComponent } from "@hermes/aphrodite/call-to-action";
import { LogoComponent } from "@hermes/aphrodite/logo";
import { SeparatorComponent } from "@hermes/aphrodite/separator";
import {
    svgCross,
    svgFacebook,
    svgInstagram,
    svgKakaostory,
    svgLine,
    svgTwitter,
    svgX,
    svgWechatAlternate,
    svgWeibo,
    svgYoutube,
    svgHermesLogo,
    SvgRegistryService,
    SvgIconComponent,
} from "@hermes/aphrodite/svg";
import {
    OpeningHoursPipeModule,
    CustomerServiceContactComponent,
} from "@hermes/fragments/customer-service-contact";
import { TrayStateModule } from "@hermes/states/tray";
import { SrOnlyNoSnippetDirective } from "@hermes/utils-generic/directives/sr-only-no-snippet";
import { PhoneNumberPipeModule } from "@hermes/utils-generic/pipes/phone-number";
import { SanitizePipeModule } from "@hermes/utils-generic/pipes/sanitize";

import { FooterComponent } from "./components/footer/footer.component";
import { FooterColumnsComponent } from "./components/footer-columns/footer-columns.component";
import { FooterCopyrightComponent } from "./components/footer-copyright/footer-copyright.component";
import { FooterCountrySelectorButtonComponent } from "./components/footer-country-selector-button/footer-country-selector-button.component";
import { FooterHereToHelpComponent } from "./components/footer-here-to-help/footer-here-to-help.component";
import { FooterLegalLinksComponent } from "./components/footer-legal-links/footer-legal-links.component";
import { FooterLightComponent } from "./components/footer-light/footer-light.component";
import { FooterLinksGroupComponent } from "./components/footer-links-group/footer-links-group.component";
import { PrefixWithPipe } from "./components/footer-links-group/prefix-with.pipe";
import { FooterNewsletterComponent } from "./components/footer-newsletter/footer-newsletter.component";
import { FooterSocialNetworksComponent } from "./components/footer-social-networks/footer-social-networks.component";
@NgModule({
    declarations: [
        FooterComponent,
        FooterColumnsComponent,
        FooterCopyrightComponent,
        FooterCountrySelectorButtonComponent,
        FooterHereToHelpComponent,
        FooterLegalLinksComponent,
        FooterLightComponent,
        FooterLinksGroupComponent,
        FooterNewsletterComponent,
        FooterSocialNetworksComponent,
        PrefixWithPipe,
    ],
    imports: [
        CallToActionComponent,
        CommonModule,
        LogoComponent,
        SeparatorComponent,
        OpeningHoursPipeModule,
        PhoneNumberPipeModule,
        TrayStateModule,
        TranslateModule,
        // For FooterLinksGroupComponent
        SanitizePipeModule,
        SrOnlyNoSnippetDirective,
        SvgIconComponent,
        CustomerServiceContactComponent,
    ],
    exports: [FooterComponent, FooterLightComponent],
})
export class FooterModule {
    constructor(svgRegistryService: SvgRegistryService) {
        svgRegistryService.registerSvgs([
            svgFacebook,
            svgCross,
            svgInstagram,
            svgKakaostory,
            svgTwitter,
            svgX,
            svgWechatAlternate,
            svgWeibo,
            svgYoutube,
            svgLine,
            svgHermesLogo,
        ]);
    }
}
