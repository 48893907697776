<div class="error-page">
    <div class="container">
        <h1 class="heading-2 display-light" i18n="@@hermes-global-translations.oops">
            Oops, this doesn't seem quite right! This date does not exist.
        </h1>
        <p class="paragraph-medium" i18n="@@hermes-global-translations.something-went-wrong.">
            Sorry, something's gone wrong.
        </p>
        <p class="paragraph-medium" i18n="@@hermes-global-translations.try-again"> Try again </p>
    </div>
</div>
