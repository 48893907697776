<div class="item-container" [class.auto-padding]="autoAddPadding">
    <div
        class="message-block"
        [class.centered-message]="messageIsCentered"
        [class.background-error]="showBackground"
        [attr.role]="ariaRole">
        <p>
            <!-- Icon - be default (not provided or unknow) the message is of type `info` -->
            @if (hasIcon) { @switch (type) { @case ('error') {
            <span class="icon">
                <h-svg-icon [name]="'exclamation'"></h-svg-icon>
                <span hSrOnlyNoSnippet i18n="@@hermes.common.icon-error"> Error </span>
            </span>
            } @default {
            <span class="icon">
                <h-svg-icon class="icon-info" [name]="'info'"></h-svg-icon>
                <span hSrOnlyNoSnippet i18n="@@hermes-global-translations.information"> Information </span>
            </span>
            } } }

            <!-- Message -->
            <span class="message-{{ type }}">
                <ng-content></ng-content>
            </span>
        </p>
    </div>
</div>
