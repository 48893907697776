import { Action, createReducer, on } from "@ngrx/store";

import * as ApplePayActions from "../actions/apple-pay.actions";
import { ApplepayStatus } from "../enum/status";

export const applePayFeatureKey = "apple-pay";

export interface State {
    status: ApplepayStatus;
}

export const initialState: State = {
    status: ApplepayStatus.Ready,
};

const applePayReducer = createReducer(
    initialState,
    on(ApplePayActions.clickedOnApplePay, (state) => ({
        ...state,
        status: ApplepayStatus.CheckingForm,
    })),
    on(ApplePayActions.formChecked, (state) => ({
        ...state,
        status: ApplepayStatus.AddingProductToCart,
    })),
    on(ApplePayActions.productAddedToCart, (state) => ({
        ...state,
        status: ApplepayStatus.TermValidating,
    })),
    on(ApplePayActions.termsValidated, (state) => ({
        ...state,
        status: ApplepayStatus.Buying,
    })),
    on(ApplePayActions.abort, ApplePayActions.done, (state) => ({
        ...state,
        status: ApplepayStatus.Ready,
    })),
);

export const reducer = (state: State | undefined, action: Action): State =>
    applePayReducer(state, action);
