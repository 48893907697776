import { Product, ProductTemplateType } from "@hermes/api-model-product";
import { hasSizeVariant } from "@hermes/fragments/product-utils";
import { EcomErrorCodes } from "@hermes/utils-generic/constants";

import { VariantExpandedState } from "../types/product-page.types";

import { initialProductState } from "./product-page.state";

export const toggleReducer = (
    togglesToClose: VariantExpandedState | undefined,
    togglesToOpen: string,
) => {
    let switchToggle = initialProductState.variantExpanded;
    if (togglesToClose) {
        switchToggle = togglesToClose;
        Object.keys(togglesToClose)
            .filter((key) => key !== togglesToOpen)
            .forEach((key) => {
                switchToggle[key] = false;
            });
        switchToggle[togglesToOpen] = true;
    }

    return switchToggle;
};

/**
 * Manage ecom error code - Default message is OOS
 */
export const getErrorCodeFromTemplate = (
    templateType: string,
    error: string,
    product: Product,
): string => {
    if (
        // for simple product with size variant : display SIZE OUT OF STOCK
        templateType === ProductTemplateType.Simple &&
        hasSizeVariant(product)
    ) {
        return EcomErrorCodes.SIZE_OUT_OF_STOCK;
    }
    // product set out of stock message
    if (templateType === ProductTemplateType.Giftset) {
        return EcomErrorCodes.ITEM_OUT_OF_STOCK;
    }
    // personalization mixed item message
    if (error === EcomErrorCodes.ERROR_CODE_PERSONALIZATION_MIXEDITEMS) {
        return EcomErrorCodes.ERROR_CODE_PERSONALIZATION_MIXEDITEMS;
    }
    // max child quantity
    if (error === EcomErrorCodes.MAX_CHILD_QUANTITY) {
        return EcomErrorCodes.MAX_CHILD_QUANTITY;
    }
    // Response http error from ecom
    if (error === EcomErrorCodes.TECHNICAL_ERROR) {
        return EcomErrorCodes.TECHNICAL_ERROR;
    }
    // personalization quote message
    if (error === EcomErrorCodes.ERROR_CODE_PERSONALIZATION_QUOTE) {
        return EcomErrorCodes.ERROR_CODE_PERSONALIZATION_QUOTE;
    }
    // personalization capacity message
    if (error === EcomErrorCodes.ERROR_CODE_PERSONALIZATION_CAPACITY) {
        return EcomErrorCodes.ERROR_CODE_PERSONALIZATION_CAPACITY;
    }
    return EcomErrorCodes.OUT_OF_STOCK;
};
