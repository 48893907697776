import { createFeatureSelector, createSelector } from "@ngrx/store";

import { IHeaderMainContainerProperties } from "../model/header-properties.model";
import * as fromLayout from "../reducers/layout.reducer";

export const selectLayoutState = createFeatureSelector<fromLayout.State>(
    fromLayout.layoutFeatureKey,
);

export const selectHeader = createSelector(
    selectLayoutState,
    (layoutState: fromLayout.State) => layoutState.header,
);

export const selectHeaderHeightWithLinks = createSelector(
    selectHeader,
    (header: IHeaderMainContainerProperties) => header.headerHeightWithLinks,
);
export const selectHeaderHeight = createSelector(
    selectHeader,
    (header: IHeaderMainContainerProperties) => header.headerMainHeight,
);

export const selectHeaderAndBannerHeight = createSelector(
    selectHeader,
    (header: IHeaderMainContainerProperties) => header.headerAndBannerHeight,
);

export const selectMenubarHeight = createSelector(
    selectHeader,
    (header: IHeaderMainContainerProperties) => header.menubarHeight,
);

export const selectHeaderTransparency = createSelector(
    selectHeader,
    (header: IHeaderMainContainerProperties) => header.isTransparent ?? false,
);

export const selectLoaderState = createSelector(
    selectLayoutState,
    (loaderState: fromLayout.State) => loaderState.loading,
);

export const selectNotificationMessage = createSelector(
    selectLayoutState,
    (layoutState: fromLayout.State) => layoutState.notification?.messageOption,
);

export const selectDisplayOverlay = createSelector(
    selectLayoutState,
    (layoutState: fromLayout.State) =>
        !!layoutState.notification?.displayOverlay,
);

export const selectKeyboardNavigation = createSelector(
    selectLayoutState,
    (layoutState: fromLayout.State) => layoutState.keyboardNavigation,
);
