import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";

import { setHasCheckoutError } from "../actions/errors.actions";
import { State } from "../reducers/errors.reducer";
import { selectHasCheckoutError } from "../selectors/errors.selectors";

@Injectable()
export class ErrorsFacade {
    public hasError$: Observable<boolean> = this.store.select(
        selectHasCheckoutError,
    );

    constructor(private store: Store<State>) {}

    public setHasCheckoutError(value: boolean): void {
        this.store.dispatch(setHasCheckoutError({ value }));
    }
}
