<footer
    id="page-footer-light"
    class="footer"
    [attr.role]="'contentinfo'"
    i18n-aria-label="@@hermes.accessibility.useful_links"
    aria-label="Useful links">
    <div class="main-container">
        <div class="footer-light">
            @if (!isKorea) {
            <div class="footer-links footer-links-{{ countryCode }}" [class.footer-links-eu]="isEurope">
                <ul>
                    <li class="cgv-link paragraph-small">
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            [href]="'hermes_footer.cgv-link' | translate"
                            (click)="handleFooterLinkClick(linkTypes.CGV)">
                            <ng-container i18n="@@hermes-footer-cgv-label"
                                >General Terms and Conditions of Sale
                            </ng-container>
                            <span hSrOnlyNoSnippet i18n="@@hermes.footer.social.new-window"> (new window) </span>
                        </a>
                    </li>
                    <li class="cgu-link paragraph-small">
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            [href]="'hermes_footer.cgu-link' | translate"
                            (click)="handleFooterLinkClick(linkTypes.CGU)">
                            <ng-container i18n="@@hermes_account.terms-and-conditions-use">
                                General Terms and Conditions of Use</ng-container
                            >
                            <span hSrOnlyNoSnippet i18n="@@hermes.footer.social.new-window"> (new window) </span>
                        </a>
                    </li>
                    @if (!isMexico) {
                    <li class="general-terms paragraph-small">
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            [href]="'hermes_global.url.terms' | translate"
                            (click)="handleFooterLinkClick(linkTypes.TERMS)">
                            {{ "hermes-footer-general-terms-label" | translate }}
                            <span hSrOnlyNoSnippet i18n="@@hermes.footer.social.new-window"> (new window) </span>
                        </a>
                    </li>
                    }
                    <li>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            class="privacy-policy-link paragraph-small"
                            [href]="'hermes_footer.legal.privacy.link.relative' | translate"
                            (click)="handleFooterLinkClick(linkTypes.PRIVACY)">
                            <ng-container i18n="@@hermes-footer-privacy-cookies-label">
                                Privacy &amp; cookies
                            </ng-container>
                            <span hSrOnlyNoSnippet i18n="@@hermes.footer.social.new-window"> (new window) </span>
                        </a>
                    </li>
                    <li class="legal-issues-link paragraph-small">
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            [href]="'hermes_footer.legal-issues-link' | translate"
                            (click)="handleFooterLinkClick(linkTypes.LEGAL)">
                            <ng-container i18n="@@hermes-footer-legal-issues-label"> Legal issues </ng-container>
                            <span hSrOnlyNoSnippet i18n="@@hermes.footer.social.new-window"> (new window) </span>
                        </a>
                    </li>
                    <li class="accessibility-link paragraph-small">
                        <a
                            (click)="handleFooterLinkClick(linkTypes.A11Y)"
                            href="{{ urlAccessibility$ | async }}"
                            target="_blank"
                            rel="noopener noreferrer">
                            <ng-container i18n="@@hermes-footer-accessibility-label"> Accessibility </ng-container>
                            <span hSrOnlyNoSnippet i18n="@@hermes.footer.social.new-window"> (new window) </span>
                        </a>
                    </li>
                </ul>
            </div>
            }
            <h-separator class="separator-line-wrapper"></h-separator>
            <div class="right-footer-links">
                <ul>
                    @if (isChina) {

                    <li class="right-footer-china-links">
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="{{ urlElectronicBusinessLicense }}"
                            rel="noopener">
                            <img src="{{ urlImageElectronicBusinessLicense }}" [attr.role]="'presentation'" alt="" />
                            <span i18n="@@hermes_electronic_business_license"> 电子营业执照 </span>
                        </a>
                    </li>
                    <li class="right-footer-china-links">
                        <a target="_blank" rel="noopener noreferrer" href="{{ urlShanghaiICP }}" rel="noopener">
                            <span i18n="@@hermes_shanghai_icp">沪ICP备17032469号-2</span>
                        </a>
                    </li>
                    <li class="right-footer-china-links">
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="{{ urlPublicNetworkSecurity }}"
                            rel="noopener">
                            <img
                                src="{{ urlImagePublicNetworkSecurity }}"
                                [alt]="'Shanghai public network security equipment 31010602002693'" />
                            <span i18n="@@hermes_shanghai_public_network_security">沪公网安备 31010602002693号</span>
                        </a>
                    </li>

                    }
                    <li class="copyright-infos">
                        <h-footer-copyright [hasLightCopyright]="true"></h-footer-copyright>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</footer>
