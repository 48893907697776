import { HeaderInnerState, PositionType } from "../model/header.model";

/**
 * Calculate in which state should be the header
 *
 * @param scrollLimit the y limit from which the header should go from fixed to relative and vice versa
 * @param currentScrollY current y scroll
 * @param lastScrollY the last y scroll (for knowing if we scroll up or down)
 * @returns the new state of the header, fixed or relative, menu-bar only or not
 */
export const getHeaderStateFromScroll = (
    scrollLimit: number,
    currentScrollY: number,
    isScrollingDown: boolean,
): HeaderInnerState => {
    const headerPosition =
        currentScrollY > scrollLimit
            ? PositionType.Fixed
            : PositionType.Relative;

    return {
        headerPosition,
        isOnlyMenuBarDisplayed:
            headerPosition === PositionType.Fixed && isScrollingDown,
    };
};

/**
 * Calculate the current scroll limit from which we should go from a header fixed to relative and vice versa
 */
export const calculateScrollLimit = (
    bannerHeight: number,
    headerHeight: number,
    isScrollingDown: boolean,
    isMenuHeaderDisplayed: boolean,
): number => {
    // User should always scroll the banners before having header fixed
    let scrollLimit = bannerHeight;
    // if we are scrolling down and the menu is displayed, the scroll limit should
    // be between the header-bar and the menu-bar because we only want to keep the menu-bar displayed
    if (isMenuHeaderDisplayed && isScrollingDown) {
        scrollLimit += headerHeight;
    }

    return scrollLimit;
};
