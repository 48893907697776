import {
    CallToActionAnalyticsEvent,
    GTMEventData,
} from "@hermes/utils/analytics";

import { getEventCategory } from "../helpers/product-page-analytics.helpers";

/**
 * This class is used for click on back button component
 */
export class BackToGridClickEvent extends CallToActionAnalyticsEvent {
    /**
     * Overload formatForGTM of inherited class
     * Return formatter for eventaction key
     */
    public override formatForGTM(): GTMEventData {
        const eventcategory = getEventCategory(this.eventData);
        return {
            ...super.formatForGTM(),
            eventcategory,
            eventaction: "Back_To_Grid",
        };
    }
}
