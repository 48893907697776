<div [ngClass]="hasLightCopyright ? 'copyright-block-light' : 'copyright-block'" [attr.data-testid]="'copyright-block'">
    <span i18n hSrOnlyNoSnippet>Copyright</span>
    <p class="paragraph-small" [ngClass]="!isKorea ? 'all-right-reserved' : ''">
        <ng-container i18n="@@hermes_footer.all-right-reserved">
            &copy; Hermès {{ year }}. All rights reserved.
        </ng-container>
    </p>

    @if (isKorea) {
    <ul id="korea-copyright">
        <li>
            <!-- (company name | address) -->
            <ng-container i18n="@@hermes_global.footer.company_name"> 에르메스코리아(유) </ng-container>
            <ng-container i18n="@@hermes.accessibility.pipe-symbol">|</ng-container>
            <ng-container i18n="@@hermes_global.footer.company_address">
                주소 및 연락처 : 서울시 강남구 도산대로45길 7 (02)-542-6622
            </ng-container>
        </li>
        <li>
            <!-- (name of the representative) -->
            <ng-container i18n="@@hermes_global.footer.name_representative">
                대표자 : 악셀 듀마, 플로리앙 크랭 ㅣ사업자등록번호 : 120-81-71453
            </ng-container>
        </li>
        <li>
            <!-- (email | hosting server) -->
            <ng-container i18n="@@hermes_global.footer.email"> 메일문의 </ng-container>
            <a href="mailto:contact.kr@hermes.com" i18n-href="@@hermes_global.footer.email_contact_mailto">
                <ng-container i18n="@@hermes_global.footer.email_contact"> contact.kr&#64;hermes.com </ng-container>
            </a>
            |
            <ng-container i18n="@@hermes_global.footer.hosting_serveur">
                호스팅서비스 : 에르메스 인터내셔널
            </ng-container>
        </li>
        <li>
            <!-- (business registration number) -->
            <ng-container i18n="@@hermes_global.footer.business_registration_number.title">
                통신판매업 신고번호
            </ng-container>
            <span class="footer-korean-registration-number">
                <ng-container i18n="@@hermes_global.footer.business_registration_number.value">
                    제2020-서울강남-02965호
                </ng-container>
            </span>
            <a
                href="http://www.ftc.go.kr/www/bizCommView.do?key=232&apv_perm_no=2020322023630202965&pageUnit=10&searchCnd=bup_nm&searchKrwd=%EC%97%90%EB%A5%B4%EB%A9%94%EC%8A%A4%EC%BD%94%EB%A6%AC%EC%95%84&pageIndex=1"
                i18n-href="@@hermes_global.footer.business_registration_number.href">
                <ng-container i18n="@@hermes_global.footer.business_registration_number.link_name">
                    사업자 정보 확인
                </ng-container>
            </a>
        </li>
    </ul>
    }
</div>
