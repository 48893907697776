import { Pipe, PipeTransform } from "@angular/core";

import { Size } from "../../model/media.types";

interface PosterUrlOptions {
    mobileUrl?: string;
    size: Size;
    width: number;
}

@Pipe({
    name: "hPosterUrl",
    standalone: true,
})
export class PosterUrlPipe implements PipeTransform {
    public transform(
        url: string,
        { mobileUrl, size, width }: PosterUrlOptions,
    ): string {
        return toPosterUrlOptions(url, { mobileUrl, size, width });
    }
}

export function toPosterUrlOptions(
    url: string,
    { mobileUrl, size, width }: PosterUrlOptions,
): string {
    const posterUrl = getUrlForSize({ mobileUrl, size, url });
    const urlWithSize = posterUrl.includes("?")
        ? `${posterUrl}&fit=wrap%2C0&wid=${width}`
        : `${posterUrl}?fit=wrap%2C0&wid=${width}`;
    // Adding parameters to sharpen image on mobile
    return isMobileSize({ size })
        ? `${urlWithSize}&resMode=sharp2&amp;op_usm=1%2C1%2C6%2C0`
        : urlWithSize;
}

export const getUrlForSize = ({
    mobileUrl,
    size,
    url,
}: {
    mobileUrl?: string;
    size: Size;
    url: string;
}) => (isMobileSize({ size }) ? mobileUrl || url : url);

const isMobileSize = ({ size }: { size: Size }) =>
    ["xsmall", "small"].includes(size);
