import { CommonModule } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";

import {
    registerSvgs,
    svgHermesLogo,
    svgHermesLogoSmall,
    SvgIconComponent,
} from "@hermes/aphrodite/svg";

import { LOCALE, Settings } from "@hermes/app-core";
import { Locale } from "@hermes/locale";
import { SrOnlyNoSnippetDirective } from "@hermes/utils-generic/directives/sr-only-no-snippet";

export const HERMES_LOGO_PATH = "hermes-logo";
@Component({
    selector: "h-logo",
    templateUrl: "./logo.component.html",
    styleUrls: ["./logo.component.scss"],
    imports: [CommonModule, SrOnlyNoSnippetDirective, SvgIconComponent],
    standalone: true,
})
export class LogoComponent implements OnInit {
    public homeUrl: string | undefined;

    public hermesLogoPath = `${this.settings.assetsUrlEditoImage}${HERMES_LOGO_PATH}`;

    constructor(
        @Inject(LOCALE) private locale: Locale,
        private settings: Settings,
    ) {
        registerSvgs([svgHermesLogo, svgHermesLogoSmall]);
    }

    public ngOnInit(): void {
        this.homeUrl = `${this.locale.urlPrefix}/`;
    }
}
