import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { MediaPictureComponent } from "@hermes/aphrodite/media-picture";
import { VideoComponent } from "@hermes/aphrodite/video";

import { BindSPANavigationForCTADirectiveModule } from "@hermes/fragments/bind-spa-navigation-for-cta";

import { MediaCaptionsComponent } from "./components/media-captions/media-captions.component";
import { MediaCtaComponent } from "./components/media-cta/media-cta.component";

@NgModule({
    imports: [
        CommonModule,
        VideoComponent,
        BindSPANavigationForCTADirectiveModule,
        MediaPictureComponent,
    ],
    declarations: [MediaCaptionsComponent, MediaCtaComponent],
    exports: [MediaCaptionsComponent, MediaCtaComponent],
})
export class MediaCtaModule {}
