import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import {
    svgBackCurvedArrow,
    SvgIconComponent,
    SvgRegistryService,
} from "@hermes/aphrodite/svg";

import { BackToHomeButtonComponent } from "./components/back-to-home-button/back-to-home-button.component";

@NgModule({
    declarations: [BackToHomeButtonComponent],
    imports: [CommonModule, SvgIconComponent],
    exports: [BackToHomeButtonComponent],
})
export class BackToHomeButtonModule {
    constructor(svgRegistryService: SvgRegistryService) {
        svgRegistryService.registerSvgs([svgBackCurvedArrow]);
    }
}
