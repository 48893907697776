import { Product } from "@hermes/api-model-product";

export const engravingOptionMask = /\d+L_\d+C/;
export const PERSO_PRODUCT_TYPE_FRAGRANCES = "fragrances";
export const ENGRAVING_OPTION_1L_2C = "1L_2C";

export const getNbCharsByLinePerfumeEngraving = (
    engravingOption: string,
): number[] => {
    const nbCharsByLine: number[] = [];
    if (validEngravingOption(engravingOption)) {
        const engravingOptionSplit = engravingOption.split("_");
        const nbLines = Number.parseInt(engravingOptionSplit[0], 10);
        const charsPart = engravingOptionSplit.splice(1);
        for (let index = 0; index < nbLines; index++) {
            nbCharsByLine.push(Number.parseInt(charsPart[index], 10));
        }
    }
    return nbCharsByLine;
};

export const validEngravingOption = (engravingOption: string): boolean =>
    engravingOptionMask.test(engravingOption);

export const isProductEngravable = (product: Product): boolean =>
    product.persoProductType === PERSO_PRODUCT_TYPE_FRAGRANCES &&
    validEngravingOption(product.simpleAttributes?.engravingOptions as string);
