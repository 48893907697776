import { Action, createReducer, on } from "@ngrx/store";

import { Address } from "@hermes/api-model-address";
import { ErrorItem } from "@hermes/api-model-basket";

import {
    accordionSummary,
    editAddressErrorFocusable,
    editFocusState,
    editIsShippingAddressBlocked,
    editShippingAddressResponseError,
    goNextStep,
    hideLoader,
    setHideErrorPayment,
    setRequiredErrorOnAddressStreetFields,
    showLoader,
    submitCart,
    updateProgressionState,
} from "../actions/conversion-funnel.action";

import { FocusState, ProgressionState } from "./conversion-funnel-state.types";

export const conversionFunnelFeatureKey = "conversionFunnel";

export interface State {
    isLoading: boolean;
    accordionSummary: boolean;
    cart: {
        isCartSubmitted: boolean;
    };
    checkout: {
        focusState: FocusState;
        isAddressErrorFocusable: boolean;
        hideErrorPayment: boolean;
        defaultAddress: Address;
        progressionState: ProgressionState;
        isShippingAddressBlocked: boolean;
        shippingAddressResponseError: ErrorItem | undefined;
        goNextStep: "delivery" | undefined;
    };
}

export const initialState: State = {
    isLoading: false,
    accordionSummary: true,
    cart: {
        isCartSubmitted: false,
    },
    checkout: {
        focusState: {
            focusOnNewCustomerEmail: false,
            focusOnEditBillingAddressButton: false,
            focusOnEditShippingAddress: undefined,
            isDeliveryTitleFocusable: false,
            isBillingTitleFocusable: false,
            isPaymentTitleFocusable: false,
        },
        isAddressErrorFocusable: false,
        hideErrorPayment: true,
        defaultAddress: {
            street1: "",
            street2: "",
            street3: "",
        } as Address,
        isShippingAddressBlocked: false,
        shippingAddressResponseError: undefined,
        goNextStep: undefined,
        progressionState: {
            isAccountActive: true,
            isDeliveryActive: false,
            isPaymentActive: false,
            isAccountEditable: false,
            isDeliveryEditable: false,
            isAccountFilled: false,
            isDeliveryFilled: false,
        },
    },
};

const conversionFunnelReducer = createReducer(
    initialState,
    on(updateProgressionState, (state, action) => ({
        ...state,
        checkout: {
            ...state.checkout,
            progressionState: {
                ...state.checkout.progressionState,
                ...action.newKeys,
            },
        },
    })),

    on(showLoader, (state) => ({
        ...state,
        isLoading: true,
    })),

    on(hideLoader, (state) => ({
        ...state,
        isLoading: false,
    })),

    on(submitCart, (state) => ({
        ...state,
        cart: {
            isCartSubmitted: true,
        },
    })),

    on(accordionSummary, (state, action) => ({
        ...state,
        accordionSummary: action.value,
    })),

    on(setHideErrorPayment, (state, action) => ({
        ...state,
        checkout: {
            ...state.checkout,
            hideErrorPayment: action.value,
        },
    })),

    on(setRequiredErrorOnAddressStreetFields, (state, action) => ({
        ...state,
        checkout: {
            ...state.checkout,
            defaultAddress: {
                ...action.defaultAddress,
                street1: "",
                street2: "",
                street3: "",
            } as Address,
        },
    })),

    on(editFocusState, (state, action) => ({
        ...state,
        checkout: {
            ...state.checkout,
            focusState: {
                ...state.checkout.focusState,
                ...action.keys,
            },
        },
    })),

    on(editAddressErrorFocusable, (state, action) => ({
        ...state,
        checkout: {
            ...state.checkout,
            isAddressErrorFocusable: action.isAddressErrorFocusable,
        },
    })),

    on(editIsShippingAddressBlocked, (state, action) => ({
        ...state,
        checkout: {
            ...state.checkout,
            isShippingAddressBlocked: action.isShippingAddressBlocked,
        },
    })),
    on(editShippingAddressResponseError, (state, action) => ({
        ...state,
        checkout: {
            ...state.checkout,
            shippingAddressResponseError: action.shippingAddressResponseError,
        },
    })),
    on(goNextStep, (state, action) => ({
        ...state,
        checkout: {
            ...state.checkout,
            goNextStep: action.nextStep,
        },
    })),
);

export function reducer(state: State | undefined, action: Action) {
    return conversionFunnelReducer(state, action);
}
