import { APP_BASE_HREF } from "@angular/common";
import { Component, EventEmitter, Inject, Input, Output } from "@angular/core";

import { LayoutFacade } from "@hermes/aphrodite/layout";
import { FooterItem } from "@hermes/api-model-shell";
import { FooterColumnState } from "@hermes/states/shell";
import { AnalyticsService } from "@hermes/utils/analytics";
import { stopEventPropagation } from "@hermes/utils-generic/helpers";
import { RouterService } from "@hermes/utils-generic/services/router";

import { FooterClickEvent } from "../../events/click.event";

@Component({
    selector: "h-footer-links-group",
    templateUrl: "./footer-links-group.component.html",
    styleUrls: ["./footer-links-group.component.scss"],
})
export class FooterLinksGroupComponent {
    @Input({ required: true })
    public column!: FooterColumnState;

    @Output()
    public triggerToggle = new EventEmitter<FooterColumnState>();

    constructor(
        @Inject(APP_BASE_HREF) public baseHref: string,
        private analyticsService: AnalyticsService,
        private routerService: RouterService,
        public layoutFacade: LayoutFacade,
    ) {}

    /**
     * Emit an event with column data and let the parent drive the open/close of columns
     */
    public handleClickOnColumnLabel(): void {
        this.triggerToggle.emit(this.column);
    }

    /**
     * Send analytics in addition to existing behavior (redirect or open a tray)
     *
     * @param $event Click event
     * @param item Clicked item
     */
    public handleClickOnColumnItem($event: Event, item: FooterItem): void {
        this.analyticsService.sendData(
            new FooterClickEvent({
                action: this.column.label,
                label: item.label,
            }),
        );

        if (item.type === "internalLink") {
            stopEventPropagation($event);
            this.routerService.spaRedirect(item.data);
            return;
        }
        if (item.type === "externalLink") {
            stopEventPropagation($event);
            this.routerService.nativeRedirect(item.data);
            return;
        }

        $event.stopPropagation();
    }

    public goToContactUsPage(event: MouseEvent): void {
        event.preventDefault();
        this.routerService.spaRedirect("/contact-us/");
    }
}
