import { RouteLike } from "@hermes/states/tray";

/**
 * reference all our lazy-loaded tray modules here.
 */
export const trays: RouteLike[] = [
    {
        path: "menu-tray",
        loadChildren: () =>
            import("@hermes/trays/menu-tray").then(
                (m) => m.MenuTrayStateModule,
            ),
    },
    {
        path: "tray-account",
        loadChildren: () =>
            import("./drupal-tray-account/drupal-tray-account.module").then(
                (m) => m.DrupalTrayAccountModule,
            ),
    },
    {
        path: "tray-email",
        loadChildren: () =>
            import("./drupal-tray-email/drupal-tray-email.module").then(
                (m) => m.DrupalTrayEmailModule,
            ),
    },
    {
        path: "tray-subscribe",
        loadChildren: () =>
            import("./drupal-tray-subscribe/drupal-tray-subscribe.module").then(
                (m) => m.DrupalTraySubscribeModule,
            ),
    },
    {
        path: "tray-help",
        loadChildren: () =>
            import("./drupal-tray-contact/drupal-tray-contact.module").then(
                (m) => m.DrupalTrayContactModule,
            ),
    },
    {
        path: "tray-forgotpassword",
        loadChildren: () =>
            import(
                "./drupal-tray-forgot-password/drupal-tray-forgot-password.module"
            ).then((m) => m.DrupalTrayForgotPasswordModule),
    },
    {
        path: "tray-sister-brands",
        loadChildren: () =>
            import(
                "./drupal-tray-sister-brands/drupal-tray-sister-brands.module"
            ).then((m) => m.DrupalTraySisterBrandsModule),
    },
    {
        path: "size-guide-tray",
        loadChildren: () =>
            import("@hermes/trays/size-guide").then(
                (m) => m.SizeGuideTrayStateModule,
            ),
    },
    {
        path: "tray-order-list",
        loadChildren: () =>
            import(
                "./drupal-tray-order-list/drupal-tray-order-list.module"
            ).then((m) => m.DrupalTrayOrderListModule),
    },
    {
        path: "tray-order",
        loadChildren: () =>
            import("./drupal-tray-order/drupal-tray-order.module").then(
                (m) => m.DrupalTrayOrderModule,
            ),
    },
    {
        path: "tray-request-exchange-return",
        loadChildren: () =>
            import(
                "./drupal-tray-exchange-return-request/drupal-tray-exchange-return-request.module"
            ).then((m) => m.DrupalTrayExchangeReturnRequestModule),
    },
    {
        path: "tray-exchange-return-method",
        loadChildren: () =>
            import(
                "./drupal-tray-exchange-return-method/drupal-tray-exchange-return-method.module"
            ).then((m) => m.DrupalTrayExchangeReturnMethodModule),
    },
    {
        path: "tray-exchange-return-instructions",
        loadChildren: () =>
            import(
                "./drupal-tray-exchange-return-instructions/drupal-tray-exchange-return-instructions.module"
            ).then((m) => m.DrupalTrayExchangeReturnInstructionsModule),
    },
    {
        path: "tray-exchange-return-details",
        loadChildren: () =>
            import(
                "./drupal-tray-exchange-return-details/drupal-tray-exchange-return-details.module"
            ).then((m) => m.DrupalTrayExchangeReturnDetailsModule),
    },
    {
        path: "tray-edit-address",
        loadChildren: () =>
            import(
                "./drupal-tray-edit-address/drupal-tray-edit-address.module"
            ).then((m) => m.DrupalTrayEditAddressModule),
    },
    {
        path: "tray-request-fapiao",
        loadChildren: () =>
            import(
                "./drupal-tray-request-fapiao/drupal-tray-request-fapiao.module"
            ).then((m) => m.DrupalTrayRequestFapiaoModule),
    },
    {
        path: "country-selector-tray",
        loadChildren: () =>
            import("./country-selector-tray/country-selector-tray.module").then(
                (m) => m.CountrySelectorTrayStateModule,
            ),
    },
    {
        path: "ereservation-tray",
        loadChildren: () =>
            import("@hermes/trays/ereservation").then(
                (m) => m.EreservationTrayStateModule,
            ),
    },
    {
        path: "shop-locations-countries-tray",
        loadChildren: () =>
            import("@hermes/trays/shop-locations-countries").then(
                (m) => m.ShopLocationsCountriesTrayModule,
            ),
    },
    {
        path: "shop-locations-cities-tray",
        loadChildren: () =>
            import("@hermes/trays/shop-locations-cities").then(
                (m) => m.ShopLocationsCitiesTrayModule,
            ),
    },
    {
        path: "back-in-stock-tray",
        loadChildren: () =>
            import("@hermes/trays/back-in-stock").then(
                (m) => m.BackInStockTrayModule,
            ),
    },
    {
        path: "product-locator-tray",
        loadChildren: () =>
            import("@hermes/trays/product-locator").then(
                (m) => m.ProductLocatorTrayStateModule,
            ),
    },
    {
        path: "grid-filters-tray",
        loadChildren: () =>
            import("@hermes/trays/grid-filters").then(
                (m) => m.GridFiltersTrayModule,
            ),
    },
    {
        path: "register-your-interest-tray",
        loadChildren: () =>
            import("@hermes/trays/register-your-interest").then(
                (m) => m.RegisterYourInterestTrayModule,
            ),
    },
];
