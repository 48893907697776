<span
    role="heading"
    i18n-role
    aria-level="2"
    hSrOnlyNoSnippet
    i18n="@@footer-legal-links.component.html"
    [attr.data-testid]="'legal-links'"
    >Legal links</span
>

<ul>
    @for (legalLink of legalLinks; track legalLink; let i = $index) {
    <li>
        <a [href]="legalLink.data" target="_blank" rel="noopener noreferrer">
            @if (legalLink.iconName === "china-license") {
            <img [src]="chinaLicenseLogo" width="9" height="11" [alt]="legalLink.label" loading="lazy" />
            } @if (legalLink.iconName === "china-network-security") {
            <img [src]="chinaNetworkSecurityLogo" width="9" height="11" [alt]="legalLink.label" loading="lazy" />
            } @if (i !== 0) {
            <ng-container>{{ legalLink.label }}</ng-container>
            }
        </a>
    </li>
    }
</ul>
