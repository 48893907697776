import {
    GridMenuEntry,
    LanditoMenuEntry,
    EcommerceStoryMenuEntry,
} from "@hermes/api-model-shell";

export type MenuEntryItemWithEdito =
    | GridMenuEntry
    | LanditoMenuEntry
    | EcommerceStoryMenuEntry;

export enum MenuItemType {
    ExpandableEntry = "expandable_entry",
    Link = "link",
    Edito = "edito",
}
