import { BreakpointObserver } from "@angular/cdk/layout";
import { Injectable, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { combineLatest, filter, map, Observable, Subscription } from "rxjs";

import {
    ENABLE_HORIZONTAL_MENU_HEADER,
    FeatureFlagFacade,
} from "@hermes/states/flipper";

import {
    hideLoader,
    hideNotification,
    keyboardNavigation,
    mouseNavigation,
    setHeaderTransparency,
    showLoader,
    showNotification,
    updateHeaderProperties as updateHeaderProperties,
} from "../actions/layout.actions";
import { IHeaderMainContainerProperties } from "../model/header-properties.model";
import { MessageOption } from "../model/notification.model";
import { State } from "../reducers/layout.reducer";
import {
    selectDisplayOverlay,
    selectHeader,
    selectHeaderAndBannerHeight,
    selectHeaderHeight,
    selectHeaderHeightWithLinks,
    selectHeaderTransparency,
    selectKeyboardNavigation,
    selectLoaderState,
    selectMenubarHeight,
    selectNotificationMessage,
} from "../selectors/layout.selectors";

@Injectable()
export class LayoutFacade implements OnDestroy {
    public headerMainHeight$ = this.store.select(selectHeaderHeight);
    public headerHeightWithLinks$ = this.store.select(
        selectHeaderHeightWithLinks,
    );
    public headerAndBannerHeight$ = this.store.select(
        selectHeaderAndBannerHeight,
    );
    public menubarHeight$ = this.store.select(selectMenubarHeight);
    public headerProperties$ = this.store.select(selectHeader);
    public headerTransparent$ = this.store.select(selectHeaderTransparency);
    public notificationMessage$ = this.store.select(selectNotificationMessage);
    public displayOverlay$ = this.store.select(selectDisplayOverlay);
    public keyboardNavigation$ = this.store.select(selectKeyboardNavigation);
    public selectLoaderState$ = this.store.select(selectLoaderState);
    private originalFullHeaderHeight: number = 0;

    /**
     * Calculates the header height after the user scrolls down.
     * The height depends on whether the horizontal menu is present in the header.
     *
     * For viewports smaller than 1024px:
     * - The entire main header height is used regardless of other conditions.
     *
     * For viewports 1024px or larger:
     * - If the feature flag (ENABLE_HORIZONTAL_MENU_HEADER) is active:
     *   - Upon scrolling down, the main header retracts and progressively disappears.
     *   - Only the menubar remains fixed at the top of the page.
     *   - Therefore, we need only the height of the menubar for accurate scroll calculations.
     * - If the feature flag is inactive:
     *   - The horizontal menu is not present in the header.
     *   - The entire header remains visible upon scrolling.
     *   - We need the full main header height for scroll calculations.
     */
    public headerHeightAfterScrollingDown$: Observable<number> = combineLatest([
        this.featureFlagFacade.isActivated(ENABLE_HORIZONTAL_MENU_HEADER),
        this.headerMainHeight$,
        this.menubarHeight$,
    ]).pipe(
        map(
            ([
                isHorizontalMenuHeaderEnabled,
                headerMainHeight,
                menubarHeight,
            ]) => {
                if (this.isMenuHorizontalBreakpointMatched) {
                    return headerMainHeight;
                }
                if (isHorizontalMenuHeaderEnabled) {
                    return menubarHeight;
                }
                return headerMainHeight;
            },
        ),
        filter((height) => height !== undefined),
    );

    private subscription = new Subscription();

    constructor(
        private store: Store<State>,
        private featureFlagFacade: FeatureFlagFacade,
        private breakpointObserver: BreakpointObserver,
    ) {}

    private get isMenuHorizontalBreakpointMatched(): boolean {
        return !this.breakpointObserver.isMatched("(min-width: 1024px)");
    }

    public getOriginalFullHeaderHeight(): number {
        return this.originalFullHeaderHeight;
    }

    public setOriginalFullHeaderHeight(height: number): void {
        if (!this.originalFullHeaderHeight) {
            this.originalFullHeaderHeight = height;
        }
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public updateHeaderProperties(
        header: IHeaderMainContainerProperties,
    ): void {
        this.store.dispatch(updateHeaderProperties({ header }));
    }

    public setHeaderTransparency(isTransparent: boolean): void {
        this.store.dispatch(setHeaderTransparency({ isTransparent }));
    }

    public showLoader(): void {
        this.store.dispatch(showLoader());
    }

    public hideLoader(): void {
        this.store.dispatch(hideLoader());
    }

    public showNotification(
        displayOverlay: boolean,
        messageOption: MessageOption,
    ): void {
        this.store.dispatch(
            showNotification({
                displayOverlay,
                messageOption,
            }),
        );
    }

    public hideNotification(): void {
        this.store.dispatch(hideNotification());
    }

    public enableKeyboardNavigation(): void {
        this.store.dispatch(keyboardNavigation());
    }

    public enableMouseNavigation(): void {
        this.store.dispatch(mouseNavigation());
    }
}
