import { CrossSellingProduct } from "@hermes/api-model-product";

import { NO, NODATA, THUMBNAIL_IMAGE, YES } from "@hermes/utils/analytics";

import { SCENE7_HERMES_PRODUCT_REGEX } from "@hermes/utils-generic/constants";
import { getMatchAtIndex } from "@hermes/utils-generic/helpers";

import {
    GTMImpressionEvent,
    ImpressionAnalyticsEvent,
} from "./impression.event";

/**
 * This class is used for change-image event in product-content
 */
export class ProductContentImpressionEvent extends ImpressionAnalyticsEvent {
    /**
     * Overload formatForGTM of inherited class
     * Return formatter for ecommerce key
     */
    public override formatForGTM(): GTMImpressionEvent {
        return {
            ...super.formatForGTM(),
            ecommerce: {
                impressions: this.getProductsData(this.eventData.products),
            },
        };
    }

    /**
     * Formatter for product
     *
     * @param products cross-sell produtcs
     */
    private getProductsData = (products: CrossSellingProduct[]) =>
        products.map((product, index) => ({
            name: product.title,
            brand: "hermes",
            id: product.sku,
            price: product.price,
            category: `${product.departmentCode}/${product.familyCode}`,
            variant: product.avgColor || NODATA,
            dimension5: NODATA,
            dimension6: product.departmentCode,
            dimension7: product.familyCode,
            dimension8: product.productCode,
            dimension9: product.divisionCode,
            dimension37: product.hasStock ? "in stock" : "out of stock",
            dimension52: product.personalize ? YES : NO,
            position: index + 1,
            list: this.eventData.list,
            dimension62: product?.image.includes("composite")
                ? ""
                : getMatchAtIndex(
                      product?.image,
                      SCENE7_HERMES_PRODUCT_REGEX,
                      1,
                  ),
            dimension63: THUMBNAIL_IMAGE,
        }));
}
