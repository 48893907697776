import { createFeatureSelector, createSelector } from "@ngrx/store";

import * as fromApplePay from "../reducers/apple-pay.reducer";

const selectApplePayState = createFeatureSelector<fromApplePay.State>(
    fromApplePay.applePayFeatureKey,
);

export const selectStatus = createSelector(
    selectApplePayState,
    (applePayState) => applePayState.status,
);
