@if (tray) {
<div class="tray-title" (click)="closeTray.emit($event)">
    <div
        class="accessibility-tray-title"
        cdkFocusInitial
        tabindex="0"
        (keydown.enter)="closeTray.emit($event)"
        (keydown.space)="closeTray.emit($event)">
        <div class="tray-title-i18n">
            @if (tray.titleKey) {

            <span
                role="heading"
                [attr.aria-level]="2"
                [id]="'tray-title-' + tray.uuid"
                [ngClass]="
                    tray.titleKey === 'hermes-categories-tree.shop' ? 'heading-3' : 'heading-4 regular-uppercase'
                ">
                @switch (tray.titleKey) {
                <!-- Add your tray title here -->
                <!-- Product Locator tray title -->
                @case ('hermes-global-translations.filter-by') {
                <ng-container i18n="@@hermes-global-translations.filter-by"> Filter by </ng-container>
                }
                <!-- Size guide tray title -->
                @case ('hermes_commerce.size-guide') {
                <ng-container i18n="@@hermes_commerce.size-guide"> Size Guide </ng-container>
                }
                <!-- Menu tray title -->
                @case ('hermes-categories-tree.shop') {
                <ng-container i18n="@@hermes-categories-tree.shop"> Menu </ng-container>
                }
                <!-- Shop locations countries (or cities) tray title -->
                @case ('hermes-country-selector-tray.country') {
                <ng-container i18n="@@hermes-country-selector-tray.country"> Location </ng-container>
                }
                <!-- Product Locator tray title -->
                @case ('hermes.commerce.product_locator.title') {
                <ng-container i18n="@@hermes.commerce.product_locator.title"> Find in store </ng-container>
                }
                <!-- E-Reservation tray title -->
                @case ('hermes.e-reservation.tray-title') {
                <ng-container i18n="@@hermes.e-reservation.tray-title"> E-reservation </ng-container>
                }
                <!-- Back in stock tray title -->
                @case ('hermes.back-in-stock.tray.subscription.title') {
                <ng-container i18n="@@hermes.back-in-stock.tray.subscription.title"> Notify me </ng-container>
                }
                <!-- Register your interest tray title -->
                @case ('hermes.back-in-stock_exceptional-pieces.tray.subscription.title') {
                <ng-container i18n="@@hermes.back-in-stock_exceptional-pieces.tray.subscription.title">
                    Register
                </ng-container>
                } @case ('hermes-global-translations.filter') {
                <ng-container i18n="@@hermes-global-translations.filter"> Filter </ng-container>
                }
                <!-- Default case that should not happen -->
                @default {
                <ng-container i18n="@@hermes-global.translations.translation-key-not-found">
                    Translation key not found : {{ tray.titleKey }}
                </ng-container>
                } }
            </span>

            } @else {
            {{ tray.translatedTitle }}
            }
        </div>
        <button
            class="tray-close-button"
            tabindex="-1"
            i18n-aria-label="@@hermes.accessibility.close_tray"
            aria-label="Close"
            [attr.aria-describedby]="'tray-title-' + tray.uuid">
            <h-svg-icon [name]="'cross-rounded'" class="tray-close-icon mobile-only"></h-svg-icon>
            <h-svg-icon [name]="'cross-rounded'" class="tray-close-icon desktop-only"></h-svg-icon>
        </button>
    </div>
</div>
}
