import { AnalyticsEvent } from "@hermes/utils/analytics";

export interface GTMImpressionEvent {
    event: string;
    category: string;
    action: string;
    [key: string]: any;
}

/**
 * Abstract class for impression event
 */
export abstract class ImpressionAnalyticsEvent
    implements AnalyticsEvent<GTMImpressionEvent>
{
    /**
     * Event name expected for every impression event
     */
    public name: string = "impressions";

    /**
     * Event's data from component to format correctly impression event
     */
    public eventData: any;

    constructor(data: { [key: string]: any }) {
        this.eventData = data;
    }

    /**
     * Return event formatted to send to GTM
     */
    public formatForGTM(): GTMImpressionEvent {
        return {
            event: this.name,
            category: "ecommerce",
            action: "impressions",
        };
    }
}
