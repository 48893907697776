import { Inject, Injectable } from "@angular/core";

import { Logger } from "@hermes/logger";
import { LOGGER } from "@hermes/web-logger";

import { getLeatherMarkingRules } from "../../mappers/product-personalization.mapper";
import {
    LuckySigns,
    RadioButtonItemModel,
    Property,
    SpecialCharacters,
    ConfiguratorType,
    DkuConfiguration,
    DkuConfigurationBeltkit,
    InputType,
} from "../../types/product-personalization.type";

/**
 * Allow to create a configurator that can be used by AdaptMappedResponseForSmlg Service
 * inputs: - platforme config response
 * output: - Mapped Plateforme Configurator
 */
@Injectable()
export class MapPlatformeResponseService {
    constructor(@Inject(LOGGER) private logger: Logger) {}

    /**
     * map properties
     */
    public mapPlatformeProperties(
        dkuConfiguration: DkuConfiguration | DkuConfigurationBeltkit,
        properties: Property[],
        configurator: ConfiguratorType,
    ): ConfiguratorType {
        properties.forEach((property: Property) => {
            const config: RadioButtonItemModel = {} as RadioButtonItemModel;

            config.id = property.name;
            config.value = property.name;
            config.name = property.name;
            config.disabled = false;
            const type = property.type;
            switch (type) {
                case InputType.Style: {
                    config.default = dkuConfiguration.style === property.name;
                    configurator.engravingConfigurator?.fontConf.push(config);
                    break;
                }
                case InputType.Font: {
                    config.default = dkuConfiguration.font === property.name;
                    config.fontFamily = property.name.toLowerCase();
                    configurator.engravingConfigurator?.styleConf.push(config);
                    break;
                }
                case InputType.Position: {
                    config.default =
                        dkuConfiguration.position === property.name;
                    configurator.engravingConfigurator?.positionConf.push(
                        config,
                    );
                    break;
                }
                case InputType.FontSize: {
                    config.default =
                        dkuConfiguration.fontSize === property.name;
                    configurator.engravingConfigurator?.fontSizeConf.push(
                        config,
                    );
                    break;
                }
                default: {
                    this.logger.warn(
                        `not have to map this property : ${property.type}`,
                    );
                }
            }
        });
        return configurator;
    }

    /**
     * Allow to map fetched luckysigns and create regular expression
     */
    public mapLuckySigns(
        fetchedLuckySigns: SpecialCharacters[],
        configurator: ConfiguratorType,
    ): ConfiguratorType {
        if (!fetchedLuckySigns || !configurator || !configurator.luckySigns) {
            return configurator;
        }

        // Get lucky signs
        configurator.luckySigns.details = fetchedLuckySigns as LuckySigns[];

        // Set leather marking rules
        configurator.markingConfiguration.markingRules =
            getLeatherMarkingRules(fetchedLuckySigns);
        return configurator;
    }
}
