import { Injectable } from "@angular/core";

import { AnalyticsService } from "@hermes/utils/analytics";

import {
    FullHeroMediaType,
    FullHeroSourceType,
    FullMediaAnalyticsEvent,
} from "../events/full-media.event";

@Injectable()
export class EditoAnalyticsService {
    constructor(private analyticsService: AnalyticsService) {}

    public sendCTAClickEvent(ctaName: string): void {
        this.analyticsService.sendRawData({
            event: "uaevent",
            eventcategory: "EditoPage",
            eventaction: "edito push link",
            eventlabel: ctaName,
        });
    }

    public sendFullMediaCTAClickEvent(
        mediaType: FullHeroMediaType,
        source: FullHeroSourceType,
        nextPath: string,
    ): void {
        this.analyticsService.sendData(
            new FullMediaAnalyticsEvent({
                source,
                mediaType,
                nextPath,
            }),
        );
    }
}
