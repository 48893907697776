import { Product } from "@hermes/api-model-product";
import {
    getStockTypeAndDisplayOnly,
    isOutOfStock,
} from "@hermes/fragments/product-utils";
import {
    AnalyticsEvent,
    GTMEcommerceItemData,
    GTMEventProductClickData,
    HERMES_BRAND,
    NO,
    NODATA,
    NO_SIZE,
    PRODUCT_IN_STOCK,
    PRODUCT_OUT_OF_STOCK,
    YES,
} from "@hermes/utils/analytics";

/**
 * This class is used for add-to-cart event in cart page
 */
export class AddToCartPersoEvent
    implements AnalyticsEvent<GTMEventProductClickData>
{
    /**
     * Event name expected for every uaevent event
     */
    public name: string = "addToCart";

    /**
     * Event's data from component to format correctly event
     */
    public eventData: {
        product: Product;
        currencyCode: string;
        quantity: number;
        context: string;
    };

    constructor(data: {
        product: Product;
        currencyCode: string;
        quantity: number;
        context: string;
    }) {
        this.eventData = data;
    }

    /**
     * Overload formatForGTM of inherited class
     * Return formatter for ecommerce key
     */
    public formatForGTM(): GTMEventProductClickData {
        return {
            event: this.name,
            ecommerce: {
                currencyCode: this.eventData.currencyCode,
                add: {
                    products: [this.getProductData(this.eventData.product)],
                },
            },
        };
    }

    /**
     * Formatter for product
     *
     * @param product product clicked
     */
    protected getProductData = (product: Product): GTMEcommerceItemData => ({
        name: product.title,
        price: product.price ? product.price.toFixed(2) : "",
        brand: HERMES_BRAND,
        quantity: this.eventData.quantity,
        dimension10: NODATA,
        dimension37:
            product.stock && isOutOfStock(product.stock) === false
                ? PRODUCT_IN_STOCK
                : PRODUCT_OUT_OF_STOCK,
        dimension52: product.personalize ? YES : NO,
        dimension53: getStockTypeAndDisplayOnly(
            product.stock,
            product.displayMode,
        ),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        availability_product: getStockTypeAndDisplayOnly(
            product.stock,
            product.displayMode,
        ),
        context: this.eventData.context,
        ...this.getFamilyAttributes(product),
    });

    private getFamilyAttributes(product: Product): GTMEcommerceItemData {
        return {
            id: product.sku,
            category: `${product.departmentCode}/${product.familyCode}`,
            variant: product.secondId || NODATA,
            // eslint-disable-next-line unicorn/explicit-length-check
            dimension5: product.size || NO_SIZE,
            dimension6: product.departmentCode,
            dimension7: product.familyCode,
            dimension8: product.productCode,
            dimension9: product.divisionCode,
            dimension54: "yes",
        } as GTMEcommerceItemData;
    }
}
