import { Inject, Injectable, LOCALE_ID } from "@angular/core";

import { localesCurrency } from "@hermes/app-core";
import { LocaleId } from "@hermes/locale";

@Injectable()
export class CurrencyService {
    constructor(@Inject(LOCALE_ID) private localeId: LocaleId) {}

    /**
     * Get local and currency code from context
     *
     *
     * @returns angular format locale, ISO_4217 currency code, symbol and format
     * (example : ['fr', 'EUR', '€', 'full'])
     */
    public getCurrencyMap(): [LocaleId, string, string, string] | undefined {
        return localesCurrency.find(([locale]) => locale === this.localeId);
    }

    /**
     * Get local and currency code from provided currency code
     *
     *
     * @returns angular format locale, ISO_4217 currency code, symbol and format
     * (example : ['fr', 'EUR', '€', 'full'])
     */
    public getCurrencyMapByCode(
        currencyCode: string,
    ): [LocaleId, string, string, string] | undefined {
        const matches = localesCurrency.filter(
            ([_locale, code]) => code === currencyCode,
        );
        const matchWithExactLocale = matches.find(
            ([locale]) => locale === this.localeId,
        );

        return matchWithExactLocale ?? matches[0];
    }
}
