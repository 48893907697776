import { CrossSellingProduct } from "@hermes/api-model-product";
import { AnalyticsEvent, GTMEcommerceData } from "@hermes/utils/analytics";

export interface ProductClickAnalyticsData {
    list?: "keep_exploring" | "perfect_partner";
    product: CrossSellingProduct;
}
/**
 * Abstract class for product-click event
 * If you need send one in your feature, create a subclass wich extends this one
 * Your subclass must at least have a getFormatter function which return a formatting function for ecommerce key event
 */
export abstract class ProductClickAnalyticsEvent
    implements AnalyticsEvent<GTMEcommerceData>
{
    /**
     * Event name expected for every productClick event
     */
    public name: string = "productClick";

    /**
     * Event's data from component to format correctly product-click event
     */
    public eventData: ProductClickAnalyticsData;

    constructor(data: ProductClickAnalyticsData) {
        this.eventData = data;
    }

    /**
     * Return event formatted to send to GTM
     */
    public formatForGTM(): GTMEcommerceData {
        return {
            event: this.name,
        };
    }
}
