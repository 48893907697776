import { Component, Inject, Input, OnInit } from "@angular/core";

import { FooterSocialNetwork } from "@hermes/api-model-shell";
import { LOCALE } from "@hermes/app-core";
import { Locale } from "@hermes/locale";
import { AnalyticsService } from "@hermes/utils/analytics";

import { FooterClickEvent } from "../../events/click.event";

@Component({
    selector: "h-footer-social-networks",
    templateUrl: "./footer-social-networks.component.html",
    styleUrls: ["./footer-social-networks.component.scss"],
})
export class FooterSocialNetworksComponent implements OnInit {
    @Input({ required: true })
    public socialNetworks: FooterSocialNetwork[] = [];

    public weChatImageUrl!: string;
    public showWeChatTootip: boolean = false;

    constructor(
        @Inject(LOCALE) private locale: Locale,
        private analyticsService: AnalyticsService,
    ) {}

    public ngOnInit(): void {
        this.weChatImageUrl = `${this.locale.urlPrefix}/assets/images/qr-code-wechat.png`;
    }

    public handleClickOnSocialNetwork(
        $event: Event,
        socialNetworkLabel: string,
    ): void {
        $event.stopPropagation();

        this.analyticsService.sendData(
            new FooterClickEvent({
                action: "SocialNetworksIcons",
                label: socialNetworkLabel,
            }),
        );
    }

    public handleClickOnWechat(
        $event: Event,
        socialNetworkLabel: string,
    ): void {
        this.showWeChatTootip = true;

        this.handleClickOnSocialNetwork($event, socialNetworkLabel);
    }

    public hideWeChatTooltip(): void {
        this.showWeChatTootip = false;
    }

    public trackBySocialNetwork(
        index: number,
        socialNetwork: FooterSocialNetwork,
    ): string {
        return `${index}_${socialNetwork?.iconName}`;
    }
}
