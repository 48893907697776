import {
    AfterViewInit,
    Directive,
    ElementRef,
    Inject,
    OnDestroy,
} from "@angular/core";
import { fromEvent, Subscription } from "rxjs";

import { Context, LOCALE } from "@hermes/app-core";
import { Locale } from "@hermes/locale";
import { stopEventPropagation } from "@hermes/utils-generic/helpers";
import { RouterService } from "@hermes/utils-generic/services/router";

import {
    FULL_HERO_CTA,
    FULL_HERO_IMAGE,
    FULL_HERO_VIDEO,
} from "../events/full-media.event";

import { EditoAnalyticsService } from "../services/edito-analytics.service";

export const CATEGORY_POSTER_CTA_CLASSNAME = "category-poster-cta";
export const FULL_MEDIA_CTA_CLASSNAME = "full-media-cta";
export const FULL_MEDIA_VIDEO = "data-full-video";
export const FULL_MEDIA_IMAGE = "data-full-image";

export enum SPAURLFragments {
    CONTENT = "content",
    PRODUCT = "product",
    CATEGORY = "category",
}

@Directive({ selector: "[hBindSPANavigationForCTA]" })
export class BindSPANavigationForCTADirective
    implements AfterViewInit, OnDestroy
{
    private subscription: Subscription = new Subscription();

    constructor(
        private element: ElementRef<HTMLElement>,
        private context: Context,
        private routerService: RouterService,
        private editoAnalyticsService: EditoAnalyticsService,
        @Inject(LOCALE) private locale: Locale,
    ) {}

    public ngAfterViewInit(): void {
        // eslint-disable-next-line unicorn/prefer-spread
        const links = Array.from(
            this.element.nativeElement.querySelectorAll<HTMLAnchorElement>(
                "a[data-cta-spa]",
            ),
        );
        // Add click event on SPA CTAs
        links
            .filter((anchor) => this.isSPA(anchor.href))
            .forEach((anchor) =>
                this.attachClickEventListenersToSPALinks(anchor),
            );
        // Add click event on non-SPA CTAs for analytics purposes
        links
            .filter((anchor) => !this.isSPA(anchor.href))
            .forEach((anchor) =>
                this.attachAnalyticsEventsToNonSPALinks(anchor),
            );
    }

    public sendCTAClickDataForEvent(
        ctaText: string | null,
        className: string,
        attributeNames: string[],
        href: string,
    ): void {
        // don't send click event for cta in category poster and highlight
        if (!ctaText || className === CATEGORY_POSTER_CTA_CLASSNAME) {
            return;
        }

        if (className === FULL_MEDIA_CTA_CLASSNAME) {
            return this.editoAnalyticsService.sendFullMediaCTAClickEvent(
                attributeNames.includes(FULL_MEDIA_VIDEO)
                    ? FULL_HERO_VIDEO
                    : FULL_HERO_IMAGE,
                FULL_HERO_CTA,
                href,
            );
        }

        this.editoAnalyticsService.sendCTAClickEvent(ctaText);
    }

    public attachAnalyticsEventsToNonSPALinks(anchor: HTMLAnchorElement): void {
        this.subscription.add(
            fromEvent(anchor, "click").subscribe(() => {
                this.sendCTAClickDataForEvent(
                    anchor.textContent,
                    anchor.className,
                    anchor.getAttributeNames(),
                    anchor.href,
                );
            }),
        );
    }

    public attachClickEventListenersToSPALinks(
        anchor: HTMLAnchorElement,
    ): void {
        this.subscription.add(
            fromEvent(anchor, "click").subscribe((event: Event) => {
                if ((event as MouseEvent).button === 0) {
                    stopEventPropagation(event);
                    this.sendCTAClickDataForEvent(
                        anchor.textContent,
                        anchor.className,
                        anchor.getAttributeNames(),
                        `${this.context.getCurrentOrigin()}${
                            this.locale.urlPrefix
                        }${this.transformSPALink(anchor)}`,
                    );
                    this.routerService.spaRedirect(
                        this.transformSPALink(anchor),
                    );
                }
            }),
        );
    }

    public transformSPALink(anchor: HTMLAnchorElement): string {
        const spaLink = anchor.href.replace(
            new RegExp(`.*${this.locale.urlPrefix}(/.*)`),
            "$1",
        );
        return spaLink.charAt(spaLink.length - 1) === "/"
            ? spaLink
            : `${spaLink}/`;
    }

    /**
     * Checks the first URL fragment after the local to check if it's
     * an Angular page
     */
    public isSPA(pathWithLocale: string): boolean {
        const urlPrefix = this.locale.urlPrefix;
        let cutString = pathWithLocale.slice(
            Math.max(0, pathWithLocale.indexOf(urlPrefix)),
        );
        cutString = cutString.slice(urlPrefix.length).slice(1);
        return (
            cutString.slice(0, cutString.indexOf("/")).toUpperCase() in
            SPAURLFragments
        );
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
