import {
    AfterViewInit,
    Directive,
    ElementRef,
    EventEmitter,
    Host,
    Inject,
    Input,
    OnDestroy,
    Output,
} from "@angular/core";
import { Subscription } from "rxjs";

import { Context, WINDOW } from "@hermes/app-core";

import {
    ObserverFactory,
    WindowWithResizeObserver,
} from "@hermes/utils-generic/helpers";

/**
 *
 * Directive which allows to detect if an element of the dom is visible in the viewport
 *
 * Example :
 * <h-example-component hDetectViewport
 * [detectOnce]="false"
 * (isInViewport)="sendAnalytics()">
 * </h-example-component>
 *
 */
@Directive({
    selector: "[hDetectViewport]",
})
export class DetectViewportDirective implements AfterViewInit, OnDestroy {
    /**
     * If true isInViewport event will only emit once when the element is in viewport and not each times element is in viewport
     * Default value is true
     */
    @Input()
    public detectOnce: boolean = true;
    /**
     * Indicate at what percentage of the target's visibility the isInViewport callback should be executed
     * Default value is 0.5
     */
    @Input()
    public threshold: number = 0.5;

    @Output()
    public isInViewport: EventEmitter<boolean> = new EventEmitter<boolean>();

    public subscription: Subscription = new Subscription();

    public observerFactory: ObserverFactory;

    constructor(
        @Inject(WINDOW) public window: WindowWithResizeObserver,
        @Host() private elementReference: ElementRef,
        private context: Context,
    ) {
        this.observerFactory = new ObserverFactory(window);
    }

    public ngAfterViewInit(): void {
        const options = {
            root: undefined,
            rootMargin: undefined,
            threshold: this.threshold,
        };
        if (this.context.isInBrowserMode()) {
            this.subscription.add(
                this.observerFactory
                    .observeIntersection(
                        this.elementReference.nativeElement,
                        options,
                    )
                    .subscribe((entry: IntersectionObserverEntry) => {
                        if (this.detectOnce) {
                            if (entry.isIntersecting) {
                                this.isInViewport.emit(entry.isIntersecting);
                                this.subscription.unsubscribe();
                            }
                        } else {
                            this.isInViewport.emit(entry.isIntersecting);
                        }
                    }),
            );
        }
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
