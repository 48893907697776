<header [attr.role]="'banner'">
    <ngx-loading-bar [color]="'#444444'" [height]="'5px'" [includeSpinner]="false" [fixed]="true"></ngx-loading-bar>

    <h1 class="sr-only">
        <span i18n="@@block--hermes-commerce--nav.cart">Cart</span>
    </h1>
    <div class="page-header-wrap">
        <div class="header header-light">
            <div class="main-container">
                <div class="header-content">
                    @if (displayBackToHome) {
                    <h-back-to-home-button
                        i18n-aria-label="@@hermes.accessibility.main-navigation"
                        aria-label="Main navigation"
                        role="navigation"></h-back-to-home-button>
                    } @if (!displayBackToHome) {
                    <h-back-button-checkout></h-back-button-checkout>
                    }
                    <h-logo (click)="onLogoClick()"></h-logo>
                </div>
            </div>
        </div>
        <!-- used to space content in body when header is position:fixed -->
        <div id="header-spacer" class="header-spacer spacer"></div>
    </div>
</header>
