import { Inject, Injectable } from "@angular/core";

import { LOCALE, WINDOW } from "@hermes/app-core";
import { Locale } from "@hermes/locale";
import { ANGULAR_SPA_ACTIVATION_ON_MENU_STORY_LINK } from "@hermes/states/flipper";
import { MenuEntryItem } from "@hermes/states/menu";
import { RouterService } from "@hermes/utils-generic/services/router";

import { isGridMenuEntry, isStoryMenuEntry } from "../helpers/menu.helper";

@Injectable()
export class MenuUtilsService {
    constructor(
        @Inject(LOCALE) private locale: Locale,
        @Inject(WINDOW) private window: Window,
        private routerService: RouterService,
    ) {}

    public getMenuEntryPathWithoutLocale(menuEntryPath: string): string {
        return menuEntryPath.startsWith(this.locale.urlPrefix)
            ? menuEntryPath.slice(this.locale.urlPrefix.length)
            : menuEntryPath;
    }

    public navigateToMenuLink(entry: MenuEntryItem): void {
        if (isGridMenuEntry(entry)) {
            this.routerService.spaRedirect(entry.path);
        } else if (isStoryMenuEntry(entry)) {
            this.routerService.navigate(
                entry.path,
                ANGULAR_SPA_ACTIVATION_ON_MENU_STORY_LINK,
            );
        } else {
            this.window.location.assign(entry.path);
        }
    }
}
