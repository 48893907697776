import {
    createSelector,
    createFeatureSelector,
    MemoizedSelector,
    DefaultProjectorFn,
} from "@ngrx/store";

import { Product, ProductTemplateType } from "@hermes/api-model-product";

import {
    appleWatchReference,
    beltkitReference,
    bikiniReference,
    doublefragranceReference,
    lookReference,
    simpleReference,
} from "../helpers/product-reference.helper";
import { ProductPageState } from "../reducers/product-page.state";
import { SelectedVariantVariantsState } from "../types/product-page.types";

export const productFeatureKey = "product";

export const selectProduct =
    createFeatureSelector<ProductPageState>(productFeatureKey);

// ---- primary-keys selectors :

export const selectCurrentProduct = createSelector(
    selectProduct,
    (productState: ProductPageState) => productState.currentProduct,
);
export const selectTemplateType = createSelector(
    selectProduct,
    (productState: ProductPageState) => productState.type,
);

export const selectVariantSelectedBoxType = createSelector(
    selectProduct,
    (productState: ProductPageState) => productState.variantSelectedBoxType,
);

type SelectSelectedVariant = (
    position: number,
) => MemoizedSelector<
    object,
    SelectedVariantVariantsState,
    DefaultProjectorFn<SelectedVariantVariantsState>
>;
export const selectSelectedVariant: SelectSelectedVariant = (position) =>
    createSelector(
        selectProduct,
        (productState) => productState.selectedVariants[position],
    );

export const selectAllSelectedVariants = createSelector(
    selectProduct,
    (productState: ProductPageState) => productState.selectedVariants,
);

export const selectVariantExpanded = createSelector(
    selectProduct,
    (productState: ProductPageState) => productState.variantExpanded,
);

export const selectZoomStatus = createSelector(
    selectProduct,
    (productState: ProductPageState) => productState.zoomModal,
);

export const selectIsLoadingProduct = createSelector(
    selectProduct,
    (productState: ProductPageState) => productState.isLoading,
);

export const selectCrossSell = createSelector(
    selectProduct,
    (productState: ProductPageState) => productState.crossSell,
);

// ---- subs-keys selectors :

export const selectCurrentProductSku = createSelector(
    selectCurrentProduct,
    (currentProduct: Product) => currentProduct.sku,
);

export const selectAPWskus = createSelector(
    selectCurrentProduct,
    (currentProduct: Product) => ({
        guizmoSku: currentProduct.appleWatchAttributes?.skuGuizmo,
        strapSku: currentProduct.appleWatchAttributes?.skuStrap,
    }),
);

export const selectBikiniHasVariants = createSelector(
    selectCurrentProduct,
    (currentProduct: Product) =>
        currentProduct.bikiniAttributes?.items?.every((item) => item.variants),
);

export const selectVariantChangeType = createSelector(
    selectProduct,
    (productState: ProductPageState) => {
        const variantExpanded = productState.variantExpanded;
        if (variantExpanded?.size) {
            return "sizes";
        }
        if (variantExpanded?.finish) {
            return "finishes";
        }
        if (variantExpanded?.strap) {
            return "straps";
        }
        if (variantExpanded?.strapSize) {
            return "strapSizes";
        }
        return undefined;
    },
);

export const selectVariants = createSelector(
    selectCurrentProduct,
    (currentProduct: Product) => currentProduct.variants,
);

/**
 * Product reference displayed to user
 */
export const selectCurrentProductReference = createSelector(
    selectCurrentProduct,
    (product) =>
        ({
            [ProductTemplateType.AppleWatch]: () =>
                appleWatchReference(product),
            [ProductTemplateType.Beltkit]: () => beltkitReference(product),
            [ProductTemplateType.Bikini]: () => bikiniReference(product),
            [ProductTemplateType.DoubleFragrance]: () =>
                doublefragranceReference(product),
            [ProductTemplateType.Look]: () => lookReference(product),
            [ProductTemplateType.Simple]: () => simpleReference(product),
            [ProductTemplateType.Giftset]: () => undefined, // Spécifique géré dans giftset-product-page.selectors.ts
            [ProductTemplateType.PersoSMLG]: () => undefined,
            [ProductTemplateType.PersoBeltkit]: () => undefined,
        }[product.templateType as ProductTemplateType]()),
);
