<section
    class="banner cookie-banner"
    i18n-aria-label="@@hermes.accessibility.page.privacy-policy"
    aria-label="information-about-cookies">
    <div class="banner-message">
        @if (privacyPolicyMessage) {
        <p class="banner-message-text">
            {{ privacyPolicyMessage }}
            <h-cookie-link [link]="cookieBanner.link" [linkLabel]="cookieLink"></h-cookie-link>
        </p>
        }
        <p class="banner-message-text">
            {{ cookieMessage }}
            <h-cookie-link [link]="cookieBanner.link" [linkLabel]="cookieLink"></h-cookie-link>
        </p>
    </div>

    <button (click)="close()" (keypress.enter)="close()" class="close-icon">
        <span hSrOnlyNoSnippet i18n="@@hermes.common.close-cookie-banner">Close the dialog box about cookies</span>
        <h-svg-icon [name]="'cross'"></h-svg-icon>
    </button>
</section>
