import { InfraSettings } from "@hermes/env-infra";
import { Locale } from "@hermes/locale";

/**
 * Return the relative url if previous page is internal (ex /faq/)
 * If the previous page is an external website it should return all url (http://google.com)
 * If there is no referrer it should return empty string
 */
export const initPreviousUrlFromReferrer = ({
    document,
    infraSettings,
    locale,
}: {
    document: Document;
    infraSettings: InfraSettings;
    locale: Locale;
}): string => {
    let initializePreviousUrl = document.referrer;
    if (
        initializePreviousUrl !== null &&
        initializePreviousUrl !== undefined &&
        initializePreviousUrl.includes(infraSettings.frontend)
    ) {
        initializePreviousUrl = initializePreviousUrl
            .replace(infraSettings.frontend, "")
            .replace(`${locale.urlPrefix}/`, "")
            .replace(/:\d{4}/, "");
        if (
            !initializePreviousUrl.startsWith("/") &&
            (initializePreviousUrl.length > 0 || document.referrer?.length > 0)
        ) {
            initializePreviousUrl = `/${initializePreviousUrl}`;
        }
    }
    return initializePreviousUrl;
};
