import { APP_BASE_HREF, Location } from "@angular/common";
import {
    ChangeDetectorRef,
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";

import type * as express from "express";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";

import { LayoutFacade } from "@hermes/aphrodite/layout";
import { Context, RESPONSE } from "@hermes/app-core";
import { AnalyticsService } from "@hermes/utils/analytics";
import { HeadService } from "@hermes/utils-generic/services/head";

import {
    CLICK_BACK_HOME,
    CLICK_TO_SEARCH,
    NotFoundPageClickEvent,
} from "../../events/not-found-page-click.event";
import { NotFoundPageAnalyticsService } from "../../services/not-found-page-analytics/not-found-page-analytics.service";

@Component({
    selector: "h-not-found-page",
    templateUrl: "./not-found-page.component.html",
    styleUrls: ["./not-found-page.component.scss"],
    providers: [NotFoundPageAnalyticsService],
})
export class NotFoundPageComponent implements OnInit, OnDestroy {
    public subscription: Subscription = new Subscription();

    constructor(
        public detector: ChangeDetectorRef,
        private context: Context,
        private activatedRoute: ActivatedRoute,
        private location: Location,
        private analyticsService: AnalyticsService,
        @Inject(RESPONSE) private response: express.Response,
        private router: Router,
        @Inject(APP_BASE_HREF) public baseHref: string,
        private headService: HeadService,
        private notFoundPageAnalyticsService: NotFoundPageAnalyticsService,
        private layoutService: LayoutFacade,
    ) {
        this.subscription.add(
            this.router.events
                .pipe(filter((event) => event instanceof NavigationEnd))
                .subscribe((event) => {
                    const typedEvent: NavigationEnd =
                        event as unknown as NavigationEnd;
                    // case of angular redirects
                    if (typedEvent.url !== typedEvent.urlAfterRedirects) {
                        this.location.replaceState(typedEvent.url);
                    }
                }),
        );
    }

    public ngOnInit(): void {
        this.layoutService.hideLoader();
        this.layoutService.hideNotification();

        // Restore original url if present
        const originalUrl =
            this.activatedRoute.snapshot.queryParams["originalUrl"];

        if (originalUrl && this.context.isInBrowserMode()) {
            this.location.go(originalUrl);
        }
        // on server rendering mode, send a 404 not found HTTP status
        if (this.context.isInServerMode()) {
            const statusCode =
                this.activatedRoute.snapshot.queryParams["statusCode"] || 404;
            this.response.status(statusCode);
        }

        this.headService.setTitle(
            $localize`:@@hermes-global-translations.404-error:404 Error`,
        );

        this.notFoundPageAnalyticsService.sendNotFoundPageViewEvent();
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    // Send analytics on go to home click
    public sendGoToHomeAnalytics(): void {
        this.analyticsService.sendData(
            new NotFoundPageClickEvent({
                eventType: CLICK_BACK_HOME,
            }),
        );
    }

    // Redirect to search input
    public goToSearchInput(event: Event): void {
        event.stopPropagation();

        // Angular ID
        const element = document.getElementById("search-suggestion-field");

        if (element) {
            element.focus();
        } else {
            // Drupal ID to delete when on Angular First
            const element2 = document.getElementById(
                "block-hermes-commerce-nav-search",
            );

            if (element2) {
                element2.focus();
            }
        }

        this.analyticsService.sendData(
            new NotFoundPageClickEvent({
                eventType: CLICK_TO_SEARCH,
            }),
        );
    }
}
