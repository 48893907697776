import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";

import {
    abort,
    clickedOnApplePay,
    done,
    formChecked,
    productAddedToCart,
    termsValidated,
} from "../actions/apple-pay.actions";
import { State } from "../reducers/apple-pay.reducer";
import { selectStatus } from "../selectors/apple-pay.selectors";

@Injectable()
export class ApplePayFacade {
    public status$ = this.store.select(selectStatus);

    constructor(private store: Store<State>) {}

    public clickOnApplepayButton(): void {
        this.store.dispatch(clickedOnApplePay());
    }

    public formChecked(): void {
        this.store.dispatch(formChecked());
    }

    public productAddedToCart(): void {
        this.store.dispatch(productAddedToCart());
    }

    public termsValidated(): void {
        this.store.dispatch(termsValidated());
    }

    public abort(): void {
        this.store.dispatch(abort());
    }

    public done(): void {
        this.store.dispatch(done());
    }
}
