import { EVENT_UAEVENT } from "@hermes/utils/analytics";

export const SHOP_NAV_TRAY_EVENT = "ShopNavTray";

export interface MenuAnalytics {
    event: typeof EVENT_UAEVENT;
    eventcategory: typeof SHOP_NAV_TRAY_EVENT;
    eventaction: string;
    eventlabel?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    event_lv3?: string;
}
