import { NONE } from "@hermes/aphrodite/dynamic-box";
import {
    BoxType,
    Product,
    ProductBuckleVariant,
    ProductLeatherVariant,
    ProductSizeVariant,
} from "@hermes/api-model-product";

import {
    hasBuckleVariant,
    hasLeatherVariant,
    hasSizeVariant,
} from "@hermes/fragments/product-utils";

import { ProductPageState } from "../../reducers/product-page.state";

/**
 * On fetch of beltkit product :
 * - calculate wich variant is selected by default for buckle and leather
 * - calculate wich size is selected if there is one
 * - calculate if product is OOS disable button and wich warning to display
 */
export const fetchBeltkitProductSuccess = (
    state: ProductPageState,
    product: Product,
): ProductPageState => {
    // calculate selected variants
    const selectedBuckle: ProductBuckleVariant | undefined = hasBuckleVariant(
        product,
    )
        ? product.variants?.buckles.find(
              (variant) =>
                  variant.skuBuckle === product.beltkitAttributes?.skuBuckle,
          )
        : undefined;
    const selectedLeather: ProductLeatherVariant | undefined =
        hasLeatherVariant(product)
            ? product.variants?.leathers.find(
                  (variant) =>
                      variant.refColoris ===
                      product.beltkitAttributes?.refColorisLeather,
              )
            : undefined;
    // if user has selected size, keep value.
    const selectedSize: ProductSizeVariant | undefined =
        hasSizeVariant(product) && state.selectedVariants[0]?.size
            ? product.variants?.sizes.find(
                  (variant) => variant.size === product.size,
              )
            : undefined;
    const variantSelectedBoxType: BoxType =
        (state.selectedVariants?.[0]?.buckle?.boxType ||
            state.selectedVariants?.[0]?.size?.boxType ||
            state.selectedVariants?.[0]?.leather?.boxType) ??
        NONE;

    return {
        ...state,
        productSKU: product.sku,
        currentProduct: product,
        type: product.templateType,
        selectedVariants: [
            {
                buckle: selectedBuckle,
                leather: selectedLeather,
                size: selectedSize,
            },
        ],
        isLoading: false,
        variantSelectedBoxType,
    };
};
