import {
    FooterColumnDeprecated,
    FooterData,
    FooterDataDeprecated,
    FooterItemDeprecated,
    FooterLegalLink,
    FooterLegalLinkDeprecated,
    FooterSocialNetwork,
    FooterSocialNetworkDeprecated,
    LegalLinkName,
    SocialNetworkName,
    TypeItem,
} from "@hermes/api-model-shell";

import {
    FooterColumnWithDeprecatedAccessibilityBoolean,
    FooterItemWithAccessibilityBoolean,
} from "../../state/shell.state";

export function mapFooterDataDeprecatedToFooterData(
    footerDataDeprecated: FooterDataDeprecated,
): FooterData {
    return {
        columns: mapFooterColumnsDeprecatedToFooterColumns(
            footerDataDeprecated.columns,
        ),
        socialNetworks: mapFooterSocialNetworksDeprecatedToFooterSocialNetworks(
            footerDataDeprecated.socialNetworks,
        ),
        legalLinks: mapFooterLegalLinksDeprecatedToFooterLegalLinks(
            footerDataDeprecated.legalLinks,
        ),
    };
}

/**
 * We need to create an extended version of FooterColumn to accept accessibility boolean
 * that is not provided by discovery, this need to change before discovery feature flipping
 * is activated
 */
export function mapFooterColumnsDeprecatedToFooterColumns(
    footerColumnsDeprecated: FooterColumnDeprecated[],
): FooterColumnWithDeprecatedAccessibilityBoolean[] {
    return footerColumnsDeprecated.map((columnDeprecated) => ({
        label: columnDeprecated.columnLabel,
        items: mapFooterItemsDeprecatedToFooterItems(
            columnDeprecated.columnItems,
        ),
    }));
}

export function mapFooterSocialNetworksDeprecatedToFooterSocialNetworks(
    footerSocialNetworksDeprecated: FooterSocialNetworkDeprecated[],
): FooterSocialNetwork[] {
    return footerSocialNetworksDeprecated.map(
        (footerSocialNetworkDeprecated) => ({
            label: footerSocialNetworkDeprecated.name,
            data: footerSocialNetworkDeprecated.href,
            iconName: generateIconNameForSocialNetworks(
                footerSocialNetworkDeprecated,
            ),
        }),
    );
}

function generateIconNameForSocialNetworks(
    footerSocialNetworkDeprecated: FooterSocialNetworkDeprecated,
): SocialNetworkName {
    if (
        footerSocialNetworkDeprecated.isWechat ||
        footerSocialNetworkDeprecated.machineName === "wechat-grey"
    ) {
        return "wechat";
    }

    if (footerSocialNetworkDeprecated.machineName === "weibo-grey") {
        return "weibo";
    }
    return footerSocialNetworkDeprecated.machineName as SocialNetworkName;
}

/**
 * Footer legal links had very specific contribution order that impacted front display
 * We re-created those rules here to have the same design has before :
 * Legal links have a strict behavior :
 * - Third contribution is displayed first with only a image
 * - First contribution is displayed then with only a label
 * - Second contribution is displayed after with an image and a label
 * - Every other contribution is displayed after with only a label
 *
 * If some legal links are not contributed, none will be displayed since we cannot define the right order.
 */
export function mapFooterLegalLinksDeprecatedToFooterLegalLinks(
    footerLegalLinksDeprecated: FooterLegalLinkDeprecated[],
): FooterLegalLink[] {
    let legalLinksReOrdered: FooterLegalLinkDeprecated[] = [];
    if (footerLegalLinksDeprecated && footerLegalLinksDeprecated.length > 2) {
        legalLinksReOrdered = [
            footerLegalLinksDeprecated[2],
            footerLegalLinksDeprecated[0],
            footerLegalLinksDeprecated[1],
            ...footerLegalLinksDeprecated.slice(3),
        ];
    }

    return legalLinksReOrdered.map((footerLegalLinkDeprecated, index) => ({
        label: index === 0 ? undefined : footerLegalLinkDeprecated.linkText,
        data: footerLegalLinkDeprecated.linkHref,
        iconName: generateIconNameForLegalLinks(index),
    }));
}

function generateIconNameForLegalLinks(
    index: number,
): LegalLinkName | undefined {
    if (index === 0) {
        return "china-license";
    }

    if (index === 2) {
        return "china-network-security";
    }
    return undefined;
}

export function mapFooterItemsDeprecatedToFooterItems(
    columnItemsDeprecated: FooterItemDeprecated[],
): FooterItemWithAccessibilityBoolean[] {
    return columnItemsDeprecated.map((columnItemDeprecated) => ({
        label: columnItemDeprecated.itemLabel,
        data: columnItemDeprecated.itemData,
        type: resolveColummItemType(columnItemDeprecated),
        isAccessibility: columnItemDeprecated.itemIsAccessibility,
    }));
}

/**
 * New BO use explict declaration of internal and external link that is not prensent in Drupal 7
 * We use the presence of http:// in the link to declare that a link is external
 */
function resolveColummItemType(
    columnItemDeprecated: FooterItemDeprecated,
): TypeItem {
    if (columnItemDeprecated.itemType === "button") {
        return "button";
    }
    return columnItemDeprecated.itemData.includes("http")
        ? "externalLink"
        : "internalLink";
}
