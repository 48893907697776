import {
    EcommerceStoryMenuEntry,
    GridMenuEntry,
    LanditoMenuEntry,
    MenuEntry,
    MenuEntryType,
} from "@hermes/api-model-shell";

import { MenuEntryItem, MenuLink } from "../model/menu.model";

/**
 * Remove invisible entries in menu tree
 *
 * @see isVisible()
 *
 * @param entry root entry of menu in a tree structure
 * @returns root entry of filtered tree
 */
export const removeInvisibleEntries = (entry: MenuEntry): MenuEntryItem => {
    if (hasChildren(entry)) {
        return {
            ...entry,
            menuEntryList: entry.menuEntryList
                .filter(isVisible)
                .map(removeInvisibleEntries),
        };
    }

    return { ...entry } as unknown as MenuEntryItem;
};

/**
 * Extract & flatten the menu links
 * This list of menu links is needed for SEO purpose.
 * Those links are hidden in HTML in all pages to improve ranking.
 *
 * @param menuEntryList menuEntryList from root menuEntry
 * @returns string[] of all links in the menu
 */
export const extractLinksFromEntries = (
    menuEntryList: MenuEntry[],
    menuLinks: MenuLink[] = [],
): MenuLink[] => {
    menuEntryList.forEach((menuEntry) => {
        if (menuEntry.type !== MenuEntryType.separatorMenuEntry) {
            extractLinksFromEntries(menuEntry.menuEntryList, menuLinks);

            if (menuEntry.path) {
                menuLinks.push({
                    path: menuEntry.path,
                    label: cleanMenuEntryName(menuEntry.name),
                });
            }
        }
    });
    return menuLinks;
};

/**
 * Determine if a menu entry should be displayed to users.
 * Some entry depends on having visible products, others are always displayed
 *
 * @param entry menu entry to check
 */
export const isVisible = (entry: MenuEntry): boolean | undefined =>
    ({
        [MenuEntryType.gridMenuEntry]: (entry as GridMenuEntry)
            .hasVisibleProducts,
        [MenuEntryType.landitoMenuEntry]: (entry as LanditoMenuEntry)
            .hasVisibleProducts,
        [MenuEntryType.ecommerceStoryMenuEntry]: (
            entry as EcommerceStoryMenuEntry
        ).hasVisibleProducts,
        [MenuEntryType.storyMenuEntry]: true,
        [MenuEntryType.separatorMenuEntry]: true,
    }[entry.type]);

/**
 * Determine if menu entry has children.
 *
 * @param entry menu entry to check
 */
export const hasChildren = (entry: MenuEntry): entry is MenuEntryItem =>
    entry.type !== MenuEntryType.separatorMenuEntry &&
    entry.menuEntryList.length > 0;

/**
 * Level 1 menu entry can have &nbsp; or <wbr> in name to be well displayed
 * on horizontal menu, on the majority of occasion we want the name to be clean
 */
export const cleanMenuEntryName = (input: string) =>
    input.replace(/&nbsp;/g, " ").replace(/<wbr>/g, "");
