import { Inject, Injectable } from "@angular/core";
import ResizeObserver from "resize-observer-polyfill";
import { Observable } from "rxjs";

import { WINDOW } from "@hermes/app-core";

interface Window {
    ["ResizeObserver"]: typeof ResizeObserver;
}

/**
 * Functions that creates Observables (rxjs) from DOM observers (resize-observer apis).
 * Those were extracted here to be able to unit test sticky behavior.
 */
@Injectable()
export class ObserverFactory {
    constructor(@Inject(WINDOW) public window: Window) {}
    /**
     * Creates an height observable from a ResizeObserver.
     *
     * @param element the dom element to observe
     */
    public observeResize(element: HTMLElement): Observable<number> {
        return new Observable((observer) => {
            const heightObserver: ResizeObserver =
                new this.window.ResizeObserver(
                    (entries: ResizeObserverEntry[]) => {
                        for (const entry of entries) {
                            observer.next(entry.contentRect.height);
                        }
                    },
                );
            heightObserver.observe(element);
            return () => {
                heightObserver.disconnect();
            };
        });
    }

    /**
     * Creates an intersection Observable from an IntersectionObserver.
     *
     * @param element the dom element to observe
     * @param options?: contains intersection observer options, @see IntersectionObserverInit
     * @param rootElement? the wrapper, if not provided, this would be the browser viewport
     */
    public observeIntersection(
        element: HTMLElement,
        options?: IntersectionObserverInit,
        rootElement?: HTMLElement,
    ): Observable<IntersectionObserverEntry> {
        return new Observable((observer) => {
            const intersectionObserver: IntersectionObserver =
                new IntersectionObserver(
                    (entries) =>
                        entries.forEach((entry) => observer.next(entry)),
                    {
                        root: rootElement,
                        ...options,
                    } as IntersectionObserverInit,
                );
            intersectionObserver.observe(element);
            return () => {
                intersectionObserver.disconnect();
            };
        });
    }
}
