import {
    CallToActionAnalyticsEvent,
    GTMEventData,
} from "@hermes/utils/analytics";

export const APPLE_PAY_BUTTON = "applePayButton";
export const APPLE_PAY_TERMS_CONDITIONS = "applePayTermsConditions";

/**
 * This class is used for uaevents for payments completed with Apple Pay
 */
export class ApplePayEvent extends CallToActionAnalyticsEvent {
    /**
     * Overload formatForGTM of inherited class
     * Return formatter for eventaction and eventcategory key
     */
    public override formatForGTM(): GTMEventData {
        const formattedEventData = {
            ...super.formatForGTM(),
            eventcategory: "Apple Pay",
            eventlabel: this.eventData.sku,
        };
        if (this.eventData.eventType === APPLE_PAY_BUTTON) {
            if (this.eventData.eventAction) {
                formattedEventData.eventaction = this.eventData.eventAction;
            } else {
                formattedEventData.eventaction = this.eventData.active
                    ? "Active"
                    : "Inactive";
            }
        }
        if (this.eventData.eventType === APPLE_PAY_TERMS_CONDITIONS) {
            formattedEventData.eventaction = "Optin";
        }

        return formattedEventData;
    }
}
