import { createAction } from "@ngrx/store";

export const clickedOnApplePay = createAction(
    "[ApplePay] click on appelpay button",
);

export const formChecked = createAction("[ApplePay] check if form is valid");

export const addProductToCart = createAction(
    "[ApplePay] add a product to the cart",
);

export const productAddedToCart = createAction(
    "[ApplePay] product added to cart",
);

export const termsValidated = createAction(
    "[ApplePay] validate terms and conditions",
);

export const abort = createAction("[ApplePay] abort process");

export const done = createAction("[ApplePay] Workflow completed");
