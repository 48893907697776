import { HttpClient, HttpContext } from "@angular/common/http";

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { CustomerService } from "@hermes/api-model-editorial";
import { Settings } from "@hermes/app-core";
import { SKIP_404_INTERCEPTOR } from "@hermes/utils/constants";

@Injectable()
export class CustomerServiceContactService {
    constructor(private http: HttpClient, private settings: Settings) {}

    /**
     * Fetch customer service contact informations from the back-end
     */
    public fetchCustomerServiceContacts(): Observable<CustomerService[]> {
        const context = new HttpContext().set(SKIP_404_INTERCEPTOR, true);
        return this.http.get<CustomerService[]>(
            `${this.settings.apiUrl}/customer-service`,
            {
                context,
            },
        );
    }
}
