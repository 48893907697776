import { Injectable } from "@angular/core";

import { Product, ProductTemplateType } from "@hermes/api-model-product";
import { AddToCartFacade } from "@hermes/states/add-to-cart";
import { AddToCartSuccessProperties } from "@hermes/states/basket";
import { ModalFacade, ModalType } from "@hermes/states/modal";

@Injectable()
export class ProductAddToCartService {
    constructor(
        private addToCartFacade: AddToCartFacade,
        private modalFacade: ModalFacade,
    ) {}

    public changeButtonEnabledState(
        itemPosition: number,
        enabled: boolean,
    ): void {
        this.addToCartFacade.changeButtonEnabledState(itemPosition, enabled);
    }

    public setAddToCartToDefault(position: number): void {
        this.addToCartFacade.setAddToCartCallToActionsToDefault(position);
    }

    public initAddToCartCallToAction(data: Product, isVariant: boolean) {
        this.addToCartFacade.initAddToCartCallToAction(data, isVariant);
    }

    public openSuccessAddToCartModal(
        payload: AddToCartSuccessProperties,
    ): void {
        this.modalFacade.openModal({
            type: ModalType.AddToCart,
            standardDelivery: payload.basket.has_complimentary_delivery,
            isUAEvent: Boolean(payload.sendAnalyticsEvent),
            templateType: payload.templateType as ProductTemplateType,
            sku: payload.sku,
            disableScrollOnClosingModal: Boolean(
                payload.disableScrollOnClosingModal,
            ),
            isFailedAddToCart: false,
            context: payload.context,
        });
    }

    public openFailedAddToCartModal(errorMessage: string): void {
        this.modalFacade.openModal({
            type: ModalType.AddToCart,
            standardDelivery: false,
            isUAEvent: false,
            disableScrollOnClosingModal: true,
            isFailedAddToCart: true,
            errorMessage,
        });
    }
}
