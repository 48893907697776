import { APP_BASE_HREF, CommonModule } from "@angular/common";
import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    Input,
} from "@angular/core";

import { Observable } from "rxjs";

import { CustomerService } from "@hermes/api-model-editorial";
import { COUNTRY } from "@hermes/app-core";
import { isChina, Country } from "@hermes/locale";
import {
    CustomerServiceContactFacade,
    CustomerServiceContactStateModule,
} from "@hermes/states/customer-service-contact";
import { ShellStateModule } from "@hermes/states/shell";
import { PhoneNumberPipeModule } from "@hermes/utils-generic/pipes/phone-number";
import { RouterService } from "@hermes/utils-generic/services/router";

import { OpeningHoursPipeModule } from "./pipe/opening-hours/opening-hours.pipe";
import { CustomerServiceContactAnalyticsService } from "./service/customer-service-contact-analytics.service";

export const FONT_SIZE_MEDIUM = "medium";
export const FONT_SIZE_LARGE = "large";
export type FontSize = typeof FONT_SIZE_MEDIUM | typeof FONT_SIZE_LARGE;

@Component({
    selector: "h-customer-service-contact",
    standalone: true,
    imports: [
        CommonModule,
        OpeningHoursPipeModule,
        PhoneNumberPipeModule,
        CustomerServiceContactStateModule,
        ShellStateModule,
    ],
    providers: [CustomerServiceContactAnalyticsService],
    templateUrl: "./customer-service-contact.component.html",
    styleUrls: ["./customer-service-contact.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerServiceContactComponent {
    @Input()
    public showEmailUs: boolean = false;

    @Input()
    public fontSize: FontSize = FONT_SIZE_LARGE;

    public isChina = isChina(this.country);

    public customerServiceConctact$: Observable<CustomerService[] | undefined>;

    constructor(
        @Inject(APP_BASE_HREF) public baseHref: string,
        @Inject(COUNTRY) private country: Country,
        private customerServiceContactAnalyticsService: CustomerServiceContactAnalyticsService,
        private routerService: RouterService,
        private customerServiceContactFacade: CustomerServiceContactFacade,
    ) {
        this.customerServiceConctact$ =
            this.customerServiceContactFacade.getCustomerServiceContacts();
    }

    public sendEventClickToCall(): void {
        this.customerServiceContactAnalyticsService.sendClickContactUs(
            "call_us",
        );
    }
    public goToContactUsPage(event: MouseEvent): void {
        this.customerServiceContactAnalyticsService.sendClickContactUs(
            "email_us",
        );
        event.preventDefault();
        this.routerService.spaRedirect("/contact-us/");
    }
}
