import { Inject, Injectable, OnDestroy } from "@angular/core";

import {
    catchError,
    combineLatest,
    firstValueFrom,
    map,
    Observable,
    of,
    Subject,
    Subscription,
    switchMap,
} from "rxjs";

import { COUNTRY, LOCALE } from "@hermes/app-core";
import { Country, isUnitedKingdom, Locale } from "@hermes/locale";
import { Logger } from "@hermes/logger";
import { ConversionFunnelFacade } from "@hermes/states/conversion-funnel";
import { AdyenApplePayPaymentMethod } from "@hermes/states/payment-methods";
import { LOGGER } from "@hermes/web-logger";

import { ApplePayService } from "./apple-pay.service";

const MINIMUM_3_CHARACTERES = $localize`:@@hermes_account.minimum-3-characters:Please enter at least 3 characters`;

@Injectable()
export class ApplePaySessionService implements OnDestroy {
    public token$ = new Subject<ApplePayJS.ApplePayPayment>();

    private isUserConnected = false;
    private subscription = new Subscription();

    constructor(
        @Inject(LOGGER) private logger: Logger,
        @Inject(LOCALE) private locale: Locale,
        @Inject(COUNTRY) private country: Country,
        public conversionFunnelFacade: ConversionFunnelFacade,
        private applePayService: ApplePayService,
    ) {}

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public doApplePayWorkflow(): Observable<void> {
        this.logger.trace('"doApplePayWorkflow" started');

        return this.getApplePaySession().pipe(
            map((applePaySession) => applePaySession.begin()),
            catchError((error) => {
                this.logger.error(
                    "Apple Pay workflow failed",
                    error.message ?? error,
                );
                return of(undefined);
            }),
        );
    }

    /**
     * Methode Apple Pay de la liste des methodes de payment
     */
    public doApplePayLitePayment(): Observable<string> {
        return this.getLightApplePaySession().pipe(
            switchMap((applePaySession) =>
                this.prepareLightPayment(applePaySession),
            ),
        );
    }

    private prepareLightPayment(
        applePaySession: ApplePaySession,
    ): Observable<string> {
        const token$ = new Subject<string>();

        applePaySession.onpaymentauthorized = (
            event: ApplePayJS.ApplePayPaymentAuthorizedEvent,
        ) => token$.next(btoa(JSON.stringify(event.payment.token.paymentData)));

        applePaySession.begin();

        return token$;
    }

    private getLightApplePaySession(): Observable<ApplePaySession> {
        return this.initApplePayPaymentRequest().pipe(
            map((applePayPaymentRequest) =>
                this.createLightApplePaySession(applePayPaymentRequest),
            ),
        );
    }

    private getApplePaySession(): Observable<ApplePaySession> {
        return this.initApplePayPaymentRequest().pipe(
            map((applePayPaymentRequest) =>
                this.createFullApplePaySession(applePayPaymentRequest),
            ),
        );
    }

    private createLightApplePaySession(
        applePayPaymentRequest: ApplePayJS.ApplePayPaymentRequest & {
            version: number;
        },
    ): ApplePaySession {
        const applePaySession = new ApplePaySession(
            applePayPaymentRequest.version,
            {
                ...applePayPaymentRequest,
                shippingMethods: undefined,
                total: { ...applePayPaymentRequest.total, type: "final" },
            },
        );

        applePaySession.onvalidatemerchant = (
            event: ApplePayJS.ApplePayValidateMerchantEvent,
        ) => this.openApplePayMerchantSession(event, applePaySession);

        // eslint-disable-next-line unicorn/prefer-add-event-listener
        applePaySession.oncancel = () => {
            this.conversionFunnelFacade.hideLoader();
        };

        return applePaySession;
    }

    private createFullApplePaySession(
        applePayPaymentRequest: ApplePayJS.ApplePayPaymentRequest & {
            version: number;
        },
    ): ApplePaySession {
        this.addRequiredFieldsForExpressCheckout(applePayPaymentRequest);

        const applePaySession = new ApplePaySession(
            applePayPaymentRequest.version,
            applePayPaymentRequest,
        );

        applePaySession.onvalidatemerchant = (
            event: ApplePayJS.ApplePayValidateMerchantEvent,
        ) => this.openApplePayMerchantSession(event, applePaySession);

        applePaySession.onshippingcontactselected = (
            event: ApplePayJS.ApplePayShippingContactSelectedEvent,
        ) =>
            firstValueFrom(
                this.handleOnShippingContactSelected(applePaySession, event),
            );

        applePaySession.onshippingmethodselected = (
            event: ApplePayJS.ApplePayShippingMethodSelectedEvent,
        ) =>
            firstValueFrom(
                this.handleOnShippingMethodSelected(applePaySession, event),
            );

        applePaySession.onpaymentauthorized = (
            event: ApplePayJS.ApplePayPaymentAuthorizedEvent,
        ) => {
            this.token$.next(event.payment);
            applePaySession.completePayment(ApplePaySession.STATUS_SUCCESS);
        };

        return applePaySession;
    }

    private addRequiredFieldsForExpressCheckout(
        applePayPaymentRequest: ApplePayJS.ApplePayPaymentRequest,
    ): void {
        applePayPaymentRequest.requiredShippingContactFields = [
            "phone",
            "postalAddress",
        ];
        if (!this.isUserConnected) {
            applePayPaymentRequest.requiredShippingContactFields.push("email");
        }
    }

    private initApplePayPaymentRequest(): Observable<
        ApplePayJS.ApplePayPaymentRequest & { version: number }
    > {
        return combineLatest([
            this.applePayService.getIsLoggedIn(),
            this.applePayService.getApplePayOptions(),
            this.applePayService.updateFromBasket(),
        ]).pipe(
            map(
                ([
                    isLoggedIn,
                    applePayPaymentMethodOptions,
                    { items, shippingMethods, total },
                ]) => {
                    this.isUserConnected = isLoggedIn;
                    return this.createRequest(
                        items,
                        shippingMethods,
                        total,
                        applePayPaymentMethodOptions,
                    );
                },
            ),
        );
    }

    private createRequest(
        items: ApplePayJS.ApplePayLineItem[],
        shippingMethods: ApplePayJS.ApplePayShippingMethod[],
        total: ApplePayJS.ApplePayLineItem,
        applePayPaymentMethodOptions: AdyenApplePayPaymentMethod,
    ): ApplePayJS.ApplePayPaymentRequest & { version: number } {
        return {
            countryCode: isUnitedKingdom(this.locale)
                ? "GB"
                : this.locale.countryCode.toUpperCase(),
            currencyCode: this.country.currency,
            merchantCapabilities:
                applePayPaymentMethodOptions.merchantCapabilities,
            supportedNetworks: applePayPaymentMethodOptions.brands,
            total,
            shippingMethods,
            lineItems: items,
            shippingType: "shipping",
            version: Number(applePayPaymentMethodOptions.version),
        };
    }

    private openApplePayMerchantSession(
        event: ApplePayJS.ApplePayValidateMerchantEvent,
        applePaySession: ApplePaySession,
    ): Promise<void> {
        return firstValueFrom(
            this.applePayService.onMerchantValidation(event.validationURL).pipe(
                map((merchantSession) =>
                    applePaySession.completeMerchantValidation(merchantSession),
                ),
                catchError(() => {
                    applePaySession.abort();
                    throw new Error("Apple Pay Merchant validation failed");
                }),
            ),
        );
    }

    private handleOnShippingContactSelected(
        applePaySession: ApplePaySession,
        event: ApplePayJS.ApplePayShippingContactSelectedEvent,
    ): Observable<void> {
        return this.applePayService
            .onShippingAddressChange(event.shippingContact)
            .pipe(
                map((updatedApplePayElements) =>
                    applePaySession.completeShippingContactSelection({
                        errors: updatedApplePayElements.applePayError
                            ? [updatedApplePayElements.applePayError]
                            : undefined,
                        newTotal: {
                            ...updatedApplePayElements.total,
                            type: "final",
                        },
                        newLineItems: updatedApplePayElements.items,
                        newShippingMethods:
                            updatedApplePayElements.shippingMethods,
                    }),
                ),
                catchError((error) =>
                    this.handleFedexError(error?.error?.error).pipe(
                        map((updatedApplePayElements) =>
                            applePaySession.completeShippingContactSelection({
                                errors: updatedApplePayElements.applePayError
                                    ? [updatedApplePayElements.applePayError]
                                    : undefined,
                                newTotal: {
                                    ...updatedApplePayElements.total,
                                    type: "final",
                                },
                                newLineItems: updatedApplePayElements.items,
                                newShippingMethods:
                                    updatedApplePayElements.shippingMethods,
                            }),
                        ),
                    ),
                ),
            );
    }

    private handleOnShippingMethodSelected(
        applePaySession: ApplePaySession,
        event: ApplePayJS.ApplePayShippingMethodSelectedEvent,
    ): Observable<void> {
        return this.applePayService
            .onShippingOptionChange(event.shippingMethod)
            .pipe(
                map((updatedApplePayElements) =>
                    applePaySession.completeShippingMethodSelection({
                        newTotal: {
                            ...updatedApplePayElements.total,
                            type: "final",
                        },
                        newLineItems: updatedApplePayElements.items,
                    }),
                ),
                catchError((error) =>
                    this.handleFedexError(error?.error).pipe(
                        map((updatedApplePayElements) =>
                            applePaySession.completeShippingMethodSelection({
                                newTotal: {
                                    ...updatedApplePayElements.total,
                                    type: "final",
                                },
                                newLineItems: updatedApplePayElements.items,
                            }),
                        ),
                    ),
                ),
            );
    }

    private handleFedexError(errorMessage?: string): Observable<{
        items: ApplePayJS.ApplePayLineItem[];
        shippingMethods: ApplePayJS.ApplePayShippingMethod[];
        total: ApplePayJS.ApplePayLineItem;
        applePayError?: ApplePayError;
    }> {
        const applePayError = this.getFedexApplePayError(errorMessage);
        return this.applePayService.updateFromBasket(applePayError);
    }

    private getFedexApplePayError(errorMessage?: string): ApplePayError {
        if (errorMessage !== "city-minLength") {
            throw new Error("Apple Pay adress failed");
        }

        return new ApplePayError(
            "shippingContactInvalid",
            "locality",
            MINIMUM_3_CHARACTERES,
        );
    }
}
