import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

import { Media } from "@hermes/api-model-editorial";

@Component({
    selector: "h-media-captions",
    templateUrl: "./media-captions.component.html",
    styleUrls: ["./media-captions.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaCaptionsComponent {
    @Input()
    public media!: Media;
}
