import {
    BACK_IN_STOCK_DISPLAY,
    DisplayMode,
    OOS_DISPLAY,
    ProductStock,
    REGISTER_INTEREST_DISPLAY,
    ProductTemplateType,
    Product,
} from "@hermes/api-model-product";

import {
    AvailabilityHitType,
    PRODUCT_AVAILABILITY_STATUS_STORE_DISPLAY_ONLY,
    PRODUCT_AVAILABILITY_STATUS_DISPLAY_ONLY,
    PRODUCT_AVAILABILITY_STATUS_STORE_BACK_IN_STOCK,
    PRODUCT_AVAILABILITY_STATUS_BACK_IN_STOCK,
    PRODUCT_AVAILABILITY_STATUS_STORE_REGISTER_INTEREST,
    PRODUCT_AVAILABILITY_STATUS_REGISTER_INTEREST,
    PRODUCT_AVAILABILITY_STATUS_STORE,
    PRODUCT_AVAILABILITY_STATUS_ECOM_STORE_DISPLAY_ONLY,
    PRODUCT_AVAILABILITY_STATUS_ECOM_STORE,
    PRODUCT_AVAILABILITY_STATUS_ECOM_DISPLAY_ONLY,
    PRODUCT_AVAILABILITY_STATUS_ECOM,
    NODATA,
} from "@hermes/utils/analytics";

import { BK_ANALYTICS_PRODUCT_TYPE } from "../../constants/product.constant";

export const getProductAvailabilityStatus = (
    displayMode?: DisplayMode,
    isRetail?: boolean,
): AvailabilityHitType | undefined => {
    switch (displayMode) {
        case OOS_DISPLAY: {
            return isRetail
                ? PRODUCT_AVAILABILITY_STATUS_STORE_DISPLAY_ONLY
                : PRODUCT_AVAILABILITY_STATUS_DISPLAY_ONLY;
        }
        case BACK_IN_STOCK_DISPLAY: {
            return isRetail
                ? PRODUCT_AVAILABILITY_STATUS_STORE_BACK_IN_STOCK
                : PRODUCT_AVAILABILITY_STATUS_BACK_IN_STOCK;
        }
        case REGISTER_INTEREST_DISPLAY: {
            return isRetail
                ? PRODUCT_AVAILABILITY_STATUS_STORE_REGISTER_INTEREST
                : PRODUCT_AVAILABILITY_STATUS_REGISTER_INTEREST;
        }
        default: {
            return isRetail ? PRODUCT_AVAILABILITY_STATUS_STORE : undefined;
        }
    }
};

export function getStockTypeAndDisplayOnly(
    productStock: ProductStock | undefined,
    displayMode?: DisplayMode,
): AvailabilityHitType | undefined {
    if (productStock?.ecom && productStock.retail) {
        return displayMode === OOS_DISPLAY
            ? PRODUCT_AVAILABILITY_STATUS_ECOM_STORE_DISPLAY_ONLY
            : PRODUCT_AVAILABILITY_STATUS_ECOM_STORE;
    } else if (productStock?.ecom) {
        return displayMode === OOS_DISPLAY
            ? PRODUCT_AVAILABILITY_STATUS_ECOM_DISPLAY_ONLY
            : PRODUCT_AVAILABILITY_STATUS_ECOM;
    }

    return getProductAvailabilityStatus(displayMode, productStock?.retail);
}

export function getAvailabilityHit(
    product: Product,
): AvailabilityHitType | undefined {
    if (
        product.templateType === ProductTemplateType.Look ||
        product.templateType === ProductTemplateType.Bikini
    ) {
        return PRODUCT_AVAILABILITY_STATUS_ECOM;
    }
    if (
        product.persoProductType &&
        product.persoProductType === BK_ANALYTICS_PRODUCT_TYPE
    ) {
        return NODATA;
    }

    return getStockTypeAndDisplayOnly(product.stock, product.displayMode);
}
