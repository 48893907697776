import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";

import { ProductAddToCartServiceModule } from "@hermes/fragments/product-add-to-cart";
import { ProductPageStateModule } from "@hermes/states/product-page";

import { GiftSetPageAnalyticsEffects } from "./effects/giftset-page-analytics.effects";
import { GiftsetProductPageEffects } from "./effects/giftset-product-page.effects";
import { GiftsetProductPageFacade } from "./facades/giftset-product-page.facade";
import { giftsetProductPageReducer } from "./reducers/giftset-product-page.reducers";
import { giftsetFeatureKey } from "./reducers/giftset-product-page.state";
@NgModule({
    imports: [
        CommonModule,
        ProductPageStateModule,
        // feature state for giftset product page
        StoreModule.forFeature(giftsetFeatureKey, giftsetProductPageReducer),
        EffectsModule.forFeature([
            GiftsetProductPageEffects,
            GiftSetPageAnalyticsEffects,
        ]),
        ProductAddToCartServiceModule,
    ],
    providers: [GiftsetProductPageFacade],
})
export class GiftsetProductStateModule {}
