@if (isNoScriptElement) {

<noscript>
    <ng-container *ngTemplateOutlet="seo"></ng-container>
</noscript>

} @else { @if (seoLinks?.length) {
<div [class.seo-links]="!isNoScriptElement">
    @for (seoLink of seoLinks; track trackByLink($index, seoLink)) { @if (seoLink.isDisplayed ?? true) {
    <a href="{{ seoLink.path }}" aria-disabled="true">{{ seoLink.label }}</a>
    } }
</div>
} }

<ng-template #seo>
    @if (seoLinks?.length) {
    <div [class.seo-links]="!isNoScriptElement">
        @for (seoLink of seoLinks; track trackByLink($index, seoLink)) { @if (seoLink.isDisplayed ?? true) {
        <a href="{{ seoLink.path }}" aria-disabled="true">{{ seoLink.label }}</a>
        } }
    </div>
    }
</ng-template>
