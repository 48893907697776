import { Component } from "@angular/core";

import { map, Observable } from "rxjs";

import { AnalyticsService } from "@hermes/utils/analytics";
import {
    FeatureFlagFacade,
    FOOTER_NEWSLETTER_REMOVAL,
} from "@hermes/states/flipper";

import { BreakpointService } from "@hermes/utils-generic/services/user-interface";

import { FooterClickEvent } from "../../events/click.event";

@Component({
    selector: "h-footer-newsletter",
    templateUrl: "./footer-newsletter.component.html",
    styleUrls: ["./footer-newsletter.component.scss"],
})
export class FooterNewsletterComponent {
    public isNewsletterRemove$: Observable<boolean> =
        this.featureFlagFacade.isActivated(FOOTER_NEWSLETTER_REMOVAL);

    public isMobile$ = this.breakpointService
        .mediumBreakpointObserver()
        .pipe(map((value) => !value.matches));

    constructor(
        private analyticsService: AnalyticsService,
        private featureFlagFacade: FeatureFlagFacade,
        private breakpointService: BreakpointService,
    ) {}

    public handleClickOnSubscribeButton($event: Event): void {
        $event.stopPropagation();

        this.analyticsService.sendData(
            new FooterClickEvent({
                category: "newsletter",
                action: "subscription",
                label: "subscribe_button",
            }),
        );
    }
}
