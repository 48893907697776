<span
    class="heading-5 regular-uppercase"
    role="heading"
    i18n-role
    aria-level="2"
    [attr.data-testid]="'footer-here-to-help'"
    i18n="@@hermes.customer-service.title"
    >Customer Service</span
>
<h-customer-service-contact [showEmailUs]="true"> </h-customer-service-contact>
