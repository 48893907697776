import { Injectable } from "@angular/core";
import { Ripe } from "ripe-sdk/src/js";

import {
    BELTKIT,
    SMALL_LEATHER_GOODS,
} from "../../constants/product-personalization.constant";
import { ProductPersonalizationFacade } from "../../facades/product-personalization.facade";
import {
    BeltkitsConfigurator,
    LuckySigns,
    PersoConfigurator,
    RadioButtonItemModel,
    SmallLeatherGoodsConfigurator,
    InputType,
    PlateformeExtraInformationsMap,
    ProductCategory,
} from "../../types/product-personalization.type";

import { PlatformeUrlService } from "./../platforme-url/platforme-url.service";

/**
 * Allow to add translations and color hexcode to an adapted configurator
 * inputs: ripeinstance and a mapped adapted configurator
 * output: the adapt configurator with translations
 */
@Injectable()
export class FetchPlatformeExtraInformationsService {
    private defaultHexaColor = "#4282CC";

    constructor(
        private platformeUrlService: PlatformeUrlService,
        private productPersonalizationFacade: ProductPersonalizationFacade,
    ) {}

    /**
     * Allow to call platforme config endpoints to fetch extra configs as translations and colors Hexcodes
     */
    public getPFExtraData(ripe: Ripe): Promise<{
        colorsHexaCodes: Record<string, string>;
        translations: Record<string, string>;
    }> {
        return Promise.all([
            ripe.getLocaleModelP({
                locale: "tech",
            }),
            ripe.getLocaleModelP({
                locale: this.platformeUrlService.getPlatformeLocale(),
            }),
        ]).then(([colorsHexaCodes, translations]) => {
            this.productPersonalizationFacade.updatePlateformeExtraInformation(
                colorsHexaCodes,
                translations,
            );
            return { colorsHexaCodes, translations };
        });
    }

    public getConfiguratorUpdatedWithPFExtraData(
        colorsHexaCodes: Record<string, string>,
        translations: Record<string, string>,
        configurator: PersoConfigurator,
        productCategory: ProductCategory,
    ): PersoConfigurator {
        const pfMappedColors2 = this.mapPlatformeColors(colorsHexaCodes);
        return this.mapGenericExtraInformations(
            configurator,
            pfMappedColors2,
            translations,
            productCategory,
        );
    }

    /**
     * Allow to add extra information to a configurator
     */
    public mapGenericExtraInformations(
        configurator: PersoConfigurator,
        pfMappedColors: PlateformeExtraInformationsMap[],
        translations: Record<string, string>,
        productCategory: ProductCategory,
    ): PersoConfigurator {
        // specific leather configurator
        if (productCategory === SMALL_LEATHER_GOODS) {
            configurator = this.mapSmlgExtraInformations(
                translations,
                configurator as SmallLeatherGoodsConfigurator,
            );
            configurator = this.mapLeatherExtraInformations(
                translations,
                configurator,
                pfMappedColors,
            );
        }
        if (productCategory === BELTKIT) {
            configurator = this.mapLeatherExtraInformations(
                translations,
                configurator as BeltkitsConfigurator,
                pfMappedColors,
            );
        }

        // fontsize
        if (configurator.fontSize) {
            configurator.fontSize.items.forEach(
                (element: RadioButtonItemModel) =>
                    (element.name = this.getPropertiesTranslation(
                        translations,
                        element.name,
                        InputType.FontSize,
                    )),
            );
        }

        // colors
        if (configurator.fontColor) {
            configurator.fontColor.items.forEach(
                (element: RadioButtonItemModel) => {
                    element.name = this.getPropertiesTranslation(
                        translations,
                        element.name,
                        InputType.Style,
                    );
                },
            );
        }

        return configurator;
    }
    /**
     * Allow to add extra information to a configurator
     */
    public mapLeatherExtraInformations(
        translations: Record<string, string>,
        configurator: BeltkitsConfigurator | SmallLeatherGoodsConfigurator,
        pfMappedColors: PlateformeExtraInformationsMap[],
    ): PersoConfigurator {
        // exterior color
        if (configurator.exteriorColor) {
            configurator.exteriorColor.items.forEach(
                (element: RadioButtonItemModel) =>
                    this.mapColorAndName(element, pfMappedColors, translations),
            );
        }
        // interior color
        if (configurator.interiorColor) {
            configurator.interiorColor.items.forEach(
                (element: RadioButtonItemModel) =>
                    this.mapColorAndName(element, pfMappedColors, translations),
            );
        }

        // lucky signs
        if (configurator.luckySigns) {
            configurator.luckySigns.details.forEach((element: LuckySigns) => {
                element.translation =
                    translations[`builds.hermes.${element.translationKey}`];
            });
        }

        return configurator;
    }

    public mapColorAndName(
        element: RadioButtonItemModel,
        pfMappedColors: PlateformeExtraInformationsMap[],
        translations: Record<string, string>,
    ): RadioButtonItemModel {
        element.color = this.getColorHexaCodeByKey(
            element.value,
            pfMappedColors,
        );
        element.name = this.getColorTranslation(translations, element.name);
        return element;
    }
    /**
     * Allow to add extra information to a SmallLeatherGoodsConfigurator
     */
    public mapSmlgExtraInformations(
        translations: Record<string, string>,
        configurator: SmallLeatherGoodsConfigurator,
    ): PersoConfigurator {
        // leather
        if (configurator.leather) {
            configurator.leather.items.forEach(
                (element: RadioButtonItemModel) =>
                    (element.name = this.getMaterialTranslation(
                        translations,
                        element.name,
                    )),
            );
        }

        // position
        if (configurator.fontMarkingZone) {
            configurator.fontMarkingZone.items.forEach(
                (element: RadioButtonItemModel) =>
                    (element.name = this.getPropertiesTranslation(
                        translations,
                        element.name,
                        InputType.Position,
                    )),
            );
        }
        return configurator;
    }

    /**
     * Allow to map platforme hex codes response
     */
    public mapPlatformeColors(
        plateformeHexaColor: Record<string, string>,
    ): PlateformeExtraInformationsMap[] {
        const pfMappedColors: PlateformeExtraInformationsMap[] = [];
        const entries = Object.entries(plateformeHexaColor);
        for (const [colorKey, colorHexValue] of entries) {
            const lastWordFromColorKey = colorKey.slice(
                colorKey.lastIndexOf(".") + 1,
            );
            pfMappedColors.push({
                key: lastWordFromColorKey,
                value: colorHexValue,
            });
        }
        return pfMappedColors;
    }

    /**
     * Allow to find a hex code in the mapped platforme response for a given key
     */
    public getColorHexaCodeByKey(
        colorKey: string,
        pfMappedColors: PlateformeExtraInformationsMap[],
    ): string {
        const hexaCode = pfMappedColors.find((color) => color.key === colorKey);
        if (hexaCode) {
            return `#${hexaCode.value}`;
        }
        return this.defaultHexaColor;
    }

    /**
     * Allow to find a translated text in the mapped platforme
     * response for a given property key
     */
    public getPropertiesTranslation(
        translations: Record<string, string>,
        propertyName: string,
        propertyType: string,
    ): string {
        return (
            translations[`properties.${propertyType}.${propertyName}`] ||
            propertyName
        );
    }

    /**
     * Allow to find a translated text in the mapped platforme
     * response for a given colors key
     */
    public getColorTranslation(
        translations: Record<string, string>,
        property: string,
    ): string {
        return translations[`colors.${property}`] || property;
    }

    /**
     * Allow to find a translated text in the mapped platforme
     * response for a given material combination key
     */
    public getMaterialTranslation(
        translations: Record<string, string>,
        property: string,
    ): string {
        return translations[`materials.combinaison.${property}`] || property;
    }
}
