import { AnalyticsEvent, GTMEventData } from "@hermes/utils/analytics";

export const FULL_HERO_CATEGORY = "Module_CategoryHero";
export const FULL_HERO_IMAGE = "image";
export const FULL_HERO_VIDEO = "video";
export const FULL_HERO_CTA = "cta";
export const FULL_HERO_BLOC = "bloc";
export type FullHeroMediaType = typeof FULL_HERO_IMAGE | typeof FULL_HERO_VIDEO;
export type FullHeroSourceType = typeof FULL_HERO_CTA | typeof FULL_HERO_BLOC;
/**
 * Class for full-hero media CTA click event
 */
export class FullMediaAnalyticsEvent implements AnalyticsEvent<GTMEventData> {
    /**
     * Event name expected for every uaevent event
     */
    public readonly name: string = "uaevent";

    /**
     * Event's data from component to correctly format the GTM event sent
     * when clicking on the full-hero media CTA or image
     */
    public eventData: {
        source: FullHeroSourceType;
        mediaType: FullHeroMediaType;
        nextPath: string;
    };

    constructor(data: {
        source: FullHeroSourceType;
        mediaType: FullHeroMediaType;
        nextPath: string;
    }) {
        this.eventData = data;
    }

    /**
     * Overloaded by each subclass with their own formatter
     * Return event formatted to send to GTM
     */
    public formatForGTM(): GTMEventData {
        return {
            event: this.name,
            eventcategory: FULL_HERO_CATEGORY,
            eventaction: `${this.eventData.mediaType} | ${this.eventData.source}`,
            eventlabel: this.eventData.nextPath,
        };
    }
}
