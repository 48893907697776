<div class="terms-and-conditions-container" #containerTermsAndConditions>
    <ng-container [formGroup]="acceptTermsConditions">
        <div class="header">
            <button cdkFocusInitial type="button" (click)="onExit()" class="notification-close">
                <p class="sr-only" i18n="@@hermes.accessibility.close_tray">close</p>
                <p class="close"></p>
            </button>
            <h2 id="terms-and-conditions-title" class="title">
                {{ titleText }}
                <p class="sr-only">{{ titleSrText }}</p>
                @if (isProp65) {
                <p class="sr-only">
                    {{ prop65warningWithoutLinks }}
                </p>
                }
            </h2>
        </div>
        <div class="body">
            @if (recurring) {
            <div class="checkbox-container recurring-checkbox">
                <h-checkbox [insideLabel]="true" formControlName="recurringCheckbox" class="terms-conditions-checkbox">
                    <span hSrOnlyNoSnippet [innerHtml]="recurringInnerHtml"></span>
                    <div class="secondary-text" [innerHtml]="recurringInnerHtml"></div>
                </h-checkbox>
            </div>
            }
            <div class="checkbox-container">
                <h-checkbox
                    [insideLabel]="true"
                    formControlName="termsConditionsCheckbox"
                    class="terms-conditions-checkbox">
                    <span hSrOnlyNoSnippet [innerHtml]="checkoutPaymentBodyWithoutLinks"></span>
                    <div class="secondary-text" [innerHtml]="checkoutPaymentBody"></div>
                </h-checkbox>
            </div>
            @if (isProp65) {
            <div class="prop65" [innerHtml]="prop65warningWithLinks"> </div>
            }
        </div>
    </ng-container>
</div>
