import { Inject, Injectable } from "@angular/core";

import { Router } from "@angular/router";

import newrelicNamespace from "newrelic";

import { WINDOW } from "@hermes/app-core";

import { AnalyticsService, EVENT_UAEVENT } from "@hermes/utils/analytics";
import { NOTIFICATION_TIME } from "@hermes/utils-generic/constants";
import { FocusService } from "@hermes/utils-generic/services/focus";

import { LayoutFacade } from "../facades/layout.facade";
import { MessageOption, MessageType } from "../model/notification.model";

/**
 * Notification service display the loader and messages
 */
@Injectable()
export class NotificationsService {
    private notificationTimeout!: ReturnType<typeof setTimeout>;

    constructor(
        private focusService: FocusService,
        private analyticsService: AnalyticsService,
        private layoutFacade: LayoutFacade,
        private router: Router,
        @Inject(WINDOW)
        private window: Window & {
            newrelic: typeof newrelicNamespace;
        },
    ) {}

    /**
     * Launch one event to display the overlay and a second to display the notification.
     * If the message type is temporary, it will automatically close after 4 seconds
     *
     * @param messageOption Options for the message
     * @returns Return a promise
     */
    public showNotification(messageOption: MessageOption): Promise<boolean> {
        this.layoutFacade.showNotification(true, messageOption);

        if (messageOption.isTemporary) {
            return new Promise((resolve) => {
                clearTimeout(messageOption.delay ?? this.notificationTimeout);

                this.notificationTimeout = setTimeout(
                    () => this.stopNotification(resolve),
                    NOTIFICATION_TIME,
                );
            });
        }

        return Promise.resolve(true);
    }

    /**
     * Display a message on error with a refresh page action
     */
    public technicalError(analyticEventMessage?: string): void {
        this.displayErrorNotification("refresh-page", analyticEventMessage);
    }

    /**
     * Display a message on error with a back to home action
     */
    public technicalErrorBackToHome(analyticEventMessage?: string): void {
        this.displayErrorNotification("back-to-home", analyticEventMessage);
    }

    /**
     * Helper method to display error notifications
     */
    private displayErrorNotification(
        action: "refresh-page" | "back-to-home",
        analyticEventMessage?: string,
    ): void {
        this.showNotification({
            message: {
                title: $localize`:@@hermes-global-translations.oops:Oops!`,
                description: $localize`:@@hermes-global-translations.something-went-wrong.:Something went wrong`,
                action,
            },
            type: MessageType.SuperCritical,
            isTemporary: false,
        });
        this.window.newrelic.noticeError(new Error(analyticEventMessage));
        this.sendErrorAnalyticsEvent(analyticEventMessage);
    }

    private sendErrorAnalyticsEvent(message?: string) {
        this.analyticsService.sendRawData({
            event: EVENT_UAEVENT,
            eventcategory: "error message",
            eventaction: this.router.url,
            eventlabel: message || "Event without message",
        });
    }

    /**
     * Close the notification
     *
     * @param resolve If it's launched automatically after 4 seconds, the resolve clear the Promise
     */
    public stopNotification(
        resolve?: (value: boolean | PromiseLike<boolean>) => void,
    ): void {
        this.hideLoader();

        this.layoutFacade.hideNotification();

        this.focusService.focusElementOnLoaderClose();

        if (resolve) {
            resolve(true);
        }
    }

    public showLoader(): void {
        this.layoutFacade.showLoader();
    }

    public hideLoader(): void {
        this.layoutFacade.hideLoader();
    }
}
