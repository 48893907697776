import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";

import {
    registerSvgs,
    svgExclamation,
    SvgIconComponent,
    svgInfo,
} from "@hermes/aphrodite/svg";

import { SrOnlyNoSnippetDirective } from "@hermes/utils-generic/directives/sr-only-no-snippet";

import { MessageType } from "../models/message.model";
/**
 * This component provides generic styles for a dynamic message.
 *
 * Usage:
 * ```
 * <h-message-block [showBackground]=false
 *                  [type]="'error'">
 *     <ng-container i18n="@@yourTradKey">
 *         Translated text in en-US
 *     </ng-container>
 * </h-message-block>
 * ```
 *
 * Usage with switch case:
 * ```
 * <ng-container [ngSwitch]="case">
 *     <h-message-block *ngSwitchCase="case1"
 *                      [type]="'info'">
 *         <ng-container i18n="@@yourTradKey">
 *             Translated text in en-US
 *         </ng-container>
 *     </h-message-block>
 *     <h-message-block *ngSwitchCase="case2"
 *                      [type]="'error'">
 *         <ng-container i18n="@@yourTradKey">
 *             Translated text in en-US
 *         </ng-container>
 *     </h-message-block>
 *     <h-message-block *ngSwitchCase="case3"
 *                      [type]="'info'">
 *         <ng-container i18n="@@yourTradKey">
 *             Translated text in en-US
 *         </ng-container>
 *     </h-message-block>
 * </ng-container>
 * ```
 */
@Component({
    selector: "h-message-block",
    templateUrl: "./message-block.component.html",
    styleUrls: ["./message-block.component.scss"],
    imports: [CommonModule, SrOnlyNoSnippetDirective, SvgIconComponent],
    standalone: true,
})
export class MessageBlockComponent {
    /** Type error will change text color and svg icon */
    @Input()
    public type: MessageType = "info";

    /** Display or not svg icon */
    @Input()
    public hasIcon: boolean = true;

    /** Display or not background-color */
    @Input()
    public showBackground: boolean = true;

    /** Automatically add padding & margin to the error/info message */
    @Input()
    public autoAddPadding: boolean = true;

    /** Center text message in block */
    @Input()
    public messageIsCentered: boolean = false;

    /**
     * Direct reference on all the WAI-ARIA roles discussed on MDN
     * See https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles documentation
     */
    @Input()
    public ariaRole: string | undefined = "alert";

    constructor() {
        registerSvgs([svgExclamation, svgInfo]);
    }
}
