import { Component, Inject, Input } from "@angular/core";
import { map, Observable } from "rxjs";

import { filter, tap } from "rxjs/operators";

import { COUNTRY, LOCALE } from "@hermes/app-core";
import {
    Country,
    isCanada,
    isChina,
    isEurope,
    isKorea,
    isMexico,
    Locale,
} from "@hermes/locale";
import { FooterColumnState, ShellFacade } from "@hermes/states/shell";
import { AnalyticsService } from "@hermes/utils/analytics";

import {
    CLICK_ACCESSIBILITY,
    CLICK_CGU,
    CLICK_CGV,
    CLICK_LEGAL,
    CLICK_PRIVACY,
    CLICK_TERMS,
    FooterLightClickEvent,
} from "../footer-light/events/footer-click.event";

export enum FooterLink {
    CGV,
    CGU,
    TERMS,
    PRIVACY,
    LEGAL,
    A11Y,
}

@Component({
    selector: "h-footer-light",
    templateUrl: "./footer-light.component.html",
    styleUrls: ["./footer-light.component.scss"],
})
export class FooterLightComponent {
    @Input()
    public hasLightCopyright = false;

    public countryCode = this.country.countryCode;
    public isEurope = isEurope(this.country);
    public isChina = isChina(this.country);
    public isKorea = isKorea(this.country);
    public year = new Date().getFullYear();
    public linkTypes = FooterLink;

    public urlElectronicBusinessLicense =
        "http://wap.scjgj.sh.gov.cn/businessCheck/verifKey.do?showType=extShow&serial=9031000020170912143911000002054562-SAIC_SHOW_310000-20190411150609212537&signData=MEUCIQCD26DfP1PSSxcPnq2wT11yNHB2Nz7EYZilcdqufpzXGwIgbZjwSVOj1xm9GN4lGd5fjqY7Inh0rI4IhUo4bVB5AEk=";
    public urlImageElectronicBusinessLicense = `${this.locale.urlPrefix}/assets/images/china-license.png`;
    public urlShanghaiICP = "http://sh.beian.miit.gov.cn/";
    public urlPublicNetworkSecurity =
        "https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010602002693";
    public urlImagePublicNetworkSecurity = `${this.locale.urlPrefix}/assets/images/china-network-security.png`;

    public isMexico: boolean = false;
    public urlAccessibility$: Observable<string>;

    constructor(
        @Inject(LOCALE) private locale: Locale,
        @Inject(COUNTRY) private country: Country,
        private analyticsService: AnalyticsService,
        private shellFacade: ShellFacade,
    ) {
        this.isMexico = isMexico(this.locale);
        this.urlAccessibility$ = this.shellFacade.footerItems$.pipe(
            tap((data) => {
                if (!data) {
                    this.shellFacade.fetchFooterItems();
                }
            }),
            filter((footerItems) => footerItems !== undefined),
            map((footerItems) =>
                isCanada(this.locale)
                    ? `http://accessibility.hermes.com${this.locale.urlPrefix}/`
                    : this.footerItemAccessibility(footerItems.columns),
            ),
        );
    }

    public handleFooterLinkClick(link: FooterLink): void {
        const eventType = {
            [FooterLink.CGV]: CLICK_CGV,
            [FooterLink.CGU]: CLICK_CGU,
            [FooterLink.TERMS]: CLICK_TERMS,
            [FooterLink.PRIVACY]: CLICK_PRIVACY,
            [FooterLink.LEGAL]: CLICK_LEGAL,
            [FooterLink.A11Y]: CLICK_ACCESSIBILITY,
        }[link];
        this.analyticsService.sendData(
            new FooterLightClickEvent({ eventType }),
        );
    }

    public footerItemAccessibility = (
        footerColumnState: FooterColumnState[],
    ): string => {
        const defaultAccessibilityHref: string = `http://accessibility.hermes.com${this.locale.urlPrefix}/`;

        for (const footerColumn of footerColumnState) {
            const accessibilityItem = footerColumn.items.find(
                ({ isAccessibility }) => isAccessibility === true,
            );
            if (accessibilityItem?.data !== undefined) {
                return accessibilityItem.data;
            }
        }

        return defaultAccessibilityHref;
    };
}
