import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import { filter, tap } from "rxjs/operators";

import { AnalyticsService } from "@hermes/utils/analytics";
import { addToCartFailure, addToCartSuccess } from "@hermes/states/basket";

import { AddToCartErrorEvent } from "../events/add-to-cart-error.event";
import { AddToCartEvent } from "../events/add-to-cart.event";
import { QUICK_BUY } from "../types/add-to-cart.types";

/**
 * Effects quick buy analytics from grid page
 */
@Injectable()
export class AddToCartAnalyticsEffects {
    public addToCartSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(addToCartSuccess),

                filter(
                    (action) =>
                        action.sendAnalyticsEvent === true &&
                        action.context === QUICK_BUY,
                ),

                tap((action) => {
                    this.analyticsService.sendData(
                        new AddToCartEvent({
                            currencyCode: action.currencyCode,
                            product: action.product,
                            quantity: 1,
                            context: action.context,
                            sku: action.sku,
                        }),
                    );
                }),
            ),
        { dispatch: false },
    );

    public addToCartFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(addToCartFailure),
                filter(
                    (action) =>
                        action.context === QUICK_BUY &&
                        Boolean(action.error.internal_code),
                ),
                tap((payload) => {
                    this.analyticsService.sendData(
                        new AddToCartErrorEvent({
                            eventlabel: payload.error.internal_code,
                        }),
                    );
                }),
            ),
        { dispatch: false },
    );
    constructor(
        private actions$: Actions,
        private analyticsService: AnalyticsService,
    ) {}
}
