<div class="tray-container" [ngClass]="{ hidden: !isOpen && !showContainer }">
    @if (isOpen) {
    <div
        class="tray-wrapper tray-wrapper-{{ position }}"
        [@tray]="isOpen ? position : 'void'"
        (@tray.start)="onAnimationStart()"
        (@tray.done)="onAnimationEnd($event)"
        (scroll)="stopPropagation($event)">
        @for (tray of trays; track trackByUuid(idx, tray); let idx = $index) {
        <div [cdkTrapFocus]="true" [cdkTrapFocusAutoCapture]="true">
            @if (!tray.isDrupalTray) {
            <div
                class="tray-slide"
                [style.background-color]="tray.backgroundColor"
                [attr.role]="'dialog'"
                aria-modal="true"
                [attr.aria-labelledby]="'tray-title-' + tray.uuid"
                [@trayContent]="idx < trays.length - 1"
                [id]="tray.uuid">
                <h-tray-title
                    [tray]="tray"
                    ngClass="tray-title-fixed"
                    [style.background-color]="tray.titleBackgroundColor ?? tray.backgroundColor ?? defaultTrayColor"
                    (closeTray)="closeTray($event)">
                </h-tray-title>
                <div
                    class="tray-content"
                    [class.tray-content-no-padding-bottom]="noPaddingBottom"
                    [class.tray-content-no-padding-horizontal]="noPaddingHorizontal">
                    <h-tray-content [data]="tray"></h-tray-content>
                </div>
                @if (tray.hasCategoryInstitutional) {
                <h-category-institutional></h-category-institutional>
                }
            </div>

            } @else {

            <div class="drupal-tray-slide" [@trayContent]="idx < trays.length - 1" [id]="tray.uuid">
                <h-tray-content [data]="tray"></h-tray-content>
            </div>

            }
            <!-- Drupal tray handles it's own title/content and does not need all the style -->
        </div>

        }
    </div>
    }

    <h-tray-overlay [shown]="hasOverlay && isOpen" (overlayClick)="handleOverlayClick()"></h-tray-overlay>
    @if (hasMapOverlay) {
    <h-tray-overlay-map></h-tray-overlay-map>
    }
</div>
