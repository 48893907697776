import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { svgCross, SvgRegistryService } from "@hermes/aphrodite/svg";
import { HeaderModule } from "@hermes/header";
import { ShellStateModule } from "@hermes/states/shell";
import { TrayStateModule } from "@hermes/states/tray";
import { UserStateModule } from "@hermes/states/user";
import { CMSService } from "@hermes/utils/services/api-clients";

import { RouterServiceModule } from "@hermes/utils-generic/services/router";

import { ShellComponent } from "./components/shell/shell.component";
import { FooterModule } from "./footer/footer.module";

@NgModule({
    declarations: [ShellComponent],
    imports: [
        CommonModule,
        FooterModule,
        HeaderModule,
        RouterServiceModule,
        TrayStateModule,
        UserStateModule,
        ShellStateModule,
    ],
    exports: [FooterModule, ShellComponent],
    providers: [CMSService],
})
export class ShellModule {
    constructor(svgRegistryService: SvgRegistryService) {
        svgRegistryService.registerSvgs([svgCross]);
    }
}
