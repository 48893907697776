import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { LOCALE } from "@hermes/app-core";
import { Locale } from "@hermes/locale";
import { ExternalLibraryFacade } from "@hermes/states/external-library";
import {
    BAIDU_ENGINE_KEY,
    getMapEngine,
    GOOGLE_ENGINE_KEY,
    MapEngines,
} from "@hermes/utils/helpers";

import {
    ADD_LANGUAGE_TO_GOOGLE_MAPS_PLACES_API_URL,
    ADD_REGION_TO_GOOGLE_MAPS_PLACES_API_URL,
    BAIDU_CALLBACK_PARAMETER,
    BAIDU_MAPS_API_URL,
    BAIDU_MARKER,
    GOOGLE_CALLBACK_PARAMETER,
    GOOGLE_MAPS_API_URL,
    GOOGLE_MARKER,
} from "./geo-library.constant";

export const getGoogleMapUrl = (locale: Locale): string => {
    const { countryCode, langCode } = locale;
    const urlParameters = [];

    if (ADD_LANGUAGE_TO_GOOGLE_MAPS_PLACES_API_URL.has(countryCode)) {
        urlParameters.push(`language=${langCode}`);
    }

    if (ADD_REGION_TO_GOOGLE_MAPS_PLACES_API_URL.has(countryCode)) {
        urlParameters.push(`region=${countryCode.toUpperCase()}`);
    }

    urlParameters.push("loading=async");

    const params =
        urlParameters.length > 0 ? `&${urlParameters.join("&")}` : "";
    return `${GOOGLE_MAPS_API_URL}${params}`;
};

/**
 * LoadGeoLibrariesService is a wrapper to use ExternalLibraryState specifically with Google & Baidu Maps APIs.
 */
@Injectable({ providedIn: "root" })
export class LoadGeoLibrariesService {
    /**
     * Emits `true` when the Google library is actually loaded. Emits `false` at all other steps.
     */
    public isGoogleLoaded$: Observable<boolean> =
        this.externalLibraryFacade.isLoaded$(GOOGLE_ENGINE_KEY);

    /**
     * Emits `true` when the Baidu library is actually loaded. Emits `false` at all other steps.
     */
    public isBaiduLoaded$: Observable<boolean> =
        this.externalLibraryFacade.isLoaded$(BAIDU_ENGINE_KEY);

    /**
     * Emits `true` when the Google library is actually loading. Emits `false` at all other steps.
     */
    public isGoogleLoading$: Observable<boolean> =
        this.externalLibraryFacade.isLoading$(GOOGLE_ENGINE_KEY);

    /**
     * Emits `true` when the Baidu library is actually loading. Emits `false` at all other steps.
     */
    public isBaiduLoading$: Observable<boolean> =
        this.externalLibraryFacade.isLoading$(BAIDU_ENGINE_KEY);

    /**
     * Emits `true` when the Baidu or Google library is actually loaded. Emits `false` at all other steps.
     *
     * We listen to the library according to the current locale.
     */
    public isLoading$: Observable<boolean> = {
        BAIDU: this.isBaiduLoading$,
        GOOGLE: this.isGoogleLoading$,
    }[getMapEngine(this.locale.countryCode)];

    /**
     * Emits `true` when the Baidu or Google library is actually loaded. Emits `false` at all other steps.
     *
     * We listen to the library according to the current locale.
     */
    public isLoaded$: Observable<boolean> = {
        BAIDU: this.isBaiduLoaded$,
        GOOGLE: this.isGoogleLoaded$,
    }[getMapEngine(this.locale.countryCode)];

    /**
     * Emits `true` when the Google library has loaded with an error. Emits `false` at all other steps.
     */
    public googleHasError$: Observable<boolean> =
        this.externalLibraryFacade.isError$(GOOGLE_ENGINE_KEY);

    /**
     * Emits `true` when the Baidu library has loaded with an error. Emits `false` at all other steps.
     */
    public baiduHasError$: Observable<boolean> =
        this.externalLibraryFacade.isError$(BAIDU_ENGINE_KEY);

    /**
     * Emits `true` when the Baidu or Google library has loaded with an error. Emits `false` at all other steps.
     *
     * We listen to the library according to the current locale.
     */
    public hasError$: Observable<boolean> = {
        BAIDU: this.baiduHasError$,
        GOOGLE: this.googleHasError$,
    }[getMapEngine(this.locale.countryCode)];

    constructor(
        private externalLibraryFacade: ExternalLibraryFacade,
        @Inject(LOCALE) private locale: Locale,
    ) {}

    /**
     * Simple pass-through to the ExternalLibraryFacade to start loading the Google library.
     */
    public loadGoogle(): void {
        this.externalLibraryFacade.load({
            callbackParameterName: GOOGLE_CALLBACK_PARAMETER,
            key: GOOGLE_ENGINE_KEY,
            windowAttribute: GOOGLE_MARKER,
            url: getGoogleMapUrl(this.locale),
        });
    }

    /**
     * Same as loadGoogle() function but with in return the Observable that describes the loaded status.
     */
    public loadAndGetGoogleStatus(): Observable<boolean> {
        this.loadGoogle();
        return this.isGoogleLoaded$;
    }

    /**
     * Simple pass-through to the ExternalLibraryFacade to start loading the Baidu library.
     */
    public loadBaidu(): void {
        this.externalLibraryFacade.load({
            callbackParameterName: BAIDU_CALLBACK_PARAMETER,
            key: BAIDU_ENGINE_KEY,
            windowAttribute: BAIDU_MARKER,
            url: BAIDU_MAPS_API_URL,
        });
    }

    /**
     * Same as loadBaidu() function but with in return the Observable that describes the loaded status.
     */
    public loadAndGetBaiduStatus(): Observable<boolean> {
        this.loadBaidu();
        return this.isBaiduLoaded$;
    }

    /**
     * Simple pass-through to the ExternalLibraryFacade to start loading the Google or Baidu library (depending on the current locale).
     */
    public autoLoad(): void {
        const mapper: Record<MapEngines, () => void> = {
            BAIDU: () => this.loadBaidu(),
            GOOGLE: () => this.loadGoogle(),
        };

        const engine = getMapEngine(this.locale.countryCode);
        return mapper[engine]();
    }

    /**
     * Same as autoLoad() function but with in return the Observable that describes the loaded status (depending on the current locale).
     */
    public autoLoadAndGetStatus(): Observable<boolean> {
        const mapper: Record<MapEngines, () => Observable<boolean>> = {
            BAIDU: () => this.loadAndGetBaiduStatus(),
            GOOGLE: () => this.loadAndGetGoogleStatus(),
        };

        const engine = getMapEngine(this.locale.countryCode);
        return mapper[engine]();
    }
}
