import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from "@angular/core";

import { Edito } from "@hermes/api-model-editorial";
import { MenuEntryType } from "@hermes/api-model-shell";
import { getEdito } from "@hermes/fragments/menu-shared";
import { MenuEntryItem } from "@hermes/states/menu";

@Component({
    selector: "h-menu-sub-category",
    templateUrl: "./menu-sub-category.component.html",
    styleUrls: ["./menu-sub-category.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSubCategoryComponent implements OnInit {
    @Input()
    public menuEntry!: MenuEntryItem;

    @Output()
    public closeSubMenu: EventEmitter<void> = new EventEmitter<void>();

    public menuEntryType = MenuEntryType;

    public edito?: Edito;

    public ngOnInit(): void {
        this.edito = getEdito(this.menuEntry);
    }

    public onEscapeKey(event: Event): void {
        event.stopPropagation();
        this.closeSubMenu.emit();
    }
}
