import { Component, Input } from "@angular/core";

export const VERTICAL = "vertical";
export const HORIZONTAL = "horizontal";
export type Inclination = typeof VERTICAL | typeof HORIZONTAL;

@Component({
    selector: "h-separator",
    templateUrl: "./separator.component.html",
    styleUrls: ["./separator.component.scss"],
    standalone: true,
})
export class SeparatorComponent {
    @Input()
    public isFullLength: boolean = false;

    @Input()
    public lightColor: boolean = false;

    @Input()
    public inclination: Inclination = HORIZONTAL;
}
