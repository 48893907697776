import { NgModule, Pipe, PipeTransform } from "@angular/core";

import { ProductTemplateType } from "@hermes/api-model-product";

/**
 * this pipe is used to format the URL of scene 7 images in seo format
 */
@Pipe({
    name: "productImageSeoUrl",
    pure: false,
})
export class ProductImageSeoUrlPipe implements PipeTransform {
    public transform(url: string, urlOptions: SeoUrlOptions): string {
        if (!url) {
            throw new Error(
                "Param : url is mandatory for ProductImageSeoUrlPipe",
            );
        }
        switch (urlOptions.templateType) {
            case ProductTemplateType.Simple:
            case ProductTemplateType.Look:
            case ProductTemplateType.Bikini: {
                return this.urlTransformProduct(url, urlOptions);
            }
            case ProductTemplateType.Beltkit: {
                return this.urlTransformBeltkit(url, urlOptions);
            }
            case ProductTemplateType.AppleWatch: {
                return this.urlTransformAppleWatch(url, urlOptions);
            }
            case ProductTemplateType.Giftset: {
                return this.urlTransformGiftset(url, urlOptions);
            }
            case ProductTemplateType.DoubleFragrance: {
                return this.urlTransformDoubleFragrance(url, urlOptions);
            }
            // if perso product, returns url without transformation
            case ProductTemplateType.PersoBeltkit:
            case ProductTemplateType.PersoSMLG: {
                return url;
            }
            default: {
                throw new Error(
                    "Param : templateType is mandatory for ProductImageSeoUrlPipe",
                );
            }
        }
    }

    public getQualityParam(quality: number = 0, prefix: string = ""): string {
        // quality must be between 00 and 99
        quality = Math.min(Math.max(quality, 0), Math.min(quality, 99));

        return quality && quality > 0 ? `-${prefix}${quality}` : "";
    }

    public splitUrlByLastSlash(url: string): [string, string] {
        const splitUrlBySlash = url.split("/");
        // Get the last part of url
        const assetPath = splitUrlBySlash.pop();
        // Join the others parts
        const baseUrl = `${splitUrlBySlash.join("/")}/`;

        return [baseUrl, assetPath ?? ""];
    }

    private urlTransformProduct(
        url: string,
        urlOptions: SeoUrlOptions,
    ): string {
        const extend = this.getExtend(url);
        const align = 0;

        const baseUrl = url.split("?")[0].replace(/_/g, "-");
        const quality = this.getQualityParam(urlOptions.quality, "q");

        const [urlPart1, urlPart2] = this.splitUrlByLastSlash(baseUrl);

        return `${urlPart1}${urlOptions.slug}--${urlPart2}-${extend}-${align}-${urlOptions.imageSize}-${urlOptions.imageSize}${quality}_g.jpg`;
    }

    private urlTransformBeltkit(
        url: string,
        urlOptions: SeoUrlOptions,
    ): string {
        if (/_worn/g.test(url)) {
            return this.urlTransformProduct(url, urlOptions);
        }
        const formattedBeltkitUrl = url
            .replace("$leatherstrap=", "-")
            .replace("&$buckle=", "-")
            .replace("?", "");

        return this.urlTransformProductComplex(
            formattedBeltkitUrl,
            urlOptions,
            0,
        );
    }

    private urlTransformAppleWatch(
        url: string,
        urlOptions: SeoUrlOptions,
    ): string {
        if (/_worn/g.test(url)) {
            return this.urlTransformProduct(url, urlOptions);
        }
        const formattedAppleWatchUrl = url
            .replace("$cad=", "-")
            .replace("&$brac=", "-")
            .replace("templateaw", "applewatch")
            .replace("?", "");
        return this.urlTransformProductComplex(
            formattedAppleWatchUrl,
            urlOptions,
        );
    }

    private urlTransformGiftset(
        url: string,
        urlOptions: SeoUrlOptions,
    ): string {
        const baseUrl = url.split("?")[0].replace(/_/g, "-");

        const [urlPart1, urlPart2] = this.splitUrlByLastSlash(baseUrl);
        const quality = this.getQualityParam(urlOptions.quality);

        return `${urlPart1}${urlOptions.slug}--${urlPart2}-${urlOptions.imageSize}${quality}.jpg`;
    }

    private urlTransformDoubleFragrance(
        url: string,
        urlOptions: SeoUrlOptions,
    ): string {
        const formattedDoubleFragranceUrl = url
            .replace(/&?\$(perfume|refill|fragrance|leathercase|lock)=/g, "-")
            .replace("?", "");
        return this.urlTransformProductComplex(
            formattedDoubleFragranceUrl,
            urlOptions,
        );
    }

    private urlTransformProductComplex(
        formattedUrl: string,
        urlOptions: SeoUrlOptions,
        extend = 300,
    ) {
        const align = 0;

        const [urlPart1, urlPart2] = this.splitUrlByLastSlash(formattedUrl);
        const quality = this.getQualityParam(urlOptions.quality, "q");

        const seoUrl = (personalizedSize: number) =>
            `${urlPart1}${urlOptions.slug}--${urlPart2}-${extend}-${align}-${personalizedSize}-${personalizedSize}${quality}_g.jpg`;

        return seoUrl(urlOptions.imageSize ?? 1);
    }

    private getExtend(url: string): number {
        if (/_wom/g.test(url)) {
            return 300;
        }

        if (
            /_wm/g.test(url) ||
            /_worn/g.test(url) ||
            /_crop/g.test(url) ||
            /_detail/g.test(url) ||
            /_context/g.test(url) ||
            /_info/g.test(url) ||
            /_engraving/g.test(url) ||
            /-AVS/g.test(url)
        ) {
            return 0;
        }
        // default extends
        return 300;
    }
}

export interface SeoUrlOptions {
    slug: string;
    imageSize?: number;
    quality?: number;
    srcSet?: boolean;
    templateType?: string;
}

@NgModule({
    declarations: [ProductImageSeoUrlPipe],
    exports: [ProductImageSeoUrlPipe],
    providers: [ProductImageSeoUrlPipe],
})
export class ProductImageSeoUrlPipeModule {}
