import { Edito } from "@hermes/api-model-editorial";
import { MenuEntry, MenuEntryType } from "@hermes/api-model-shell";
import { MenuEntryItem } from "@hermes/states/menu";

/**
 * Safe access to editorial data in menu entry
 */
export const getEdito = (entry: MenuEntry): Edito | undefined => {
    if (
        (entry.type === MenuEntryType.gridMenuEntry ||
            entry.type === MenuEntryType.landitoMenuEntry ||
            entry.type === MenuEntryType.ecommerceStoryMenuEntry) &&
        entry.edito !== undefined &&
        Object.keys(entry.edito).length > 0
    ) {
        return entry.edito;
    }
    return undefined;
};

export const getEntryPimCode = (item: MenuEntryItem): string | undefined => {
    if (item.type === MenuEntryType.storyMenuEntry) {
        return "MISSING_PIM_CODE";
    }
    return item.pimCode;
};

export const isGridMenuEntry = (menuEntry: MenuEntry): boolean =>
    menuEntry.type === MenuEntryType.gridMenuEntry;

export const isStoryMenuEntry = (menuEntry: MenuEntry): boolean =>
    menuEntry.type === MenuEntryType.storyMenuEntry;
