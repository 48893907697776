import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { BindSPANavigationForCTADirective } from "./directives/bind-spa-navigation-for-cta.directive";
import { EditoAnalyticsService } from "./services/edito-analytics.service";

@NgModule({
    imports: [CommonModule],
    declarations: [BindSPANavigationForCTADirective],
    providers: [EditoAnalyticsService],
    exports: [BindSPANavigationForCTADirective],
})
export class BindSPANavigationForCTADirectiveModule {}
