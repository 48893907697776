import { CommonModule } from "@angular/common";
import {
    AfterViewInit,
    Component,
    ElementRef,
    forwardRef,
    Input,
    ViewChild,
} from "@angular/core";
import {
    ControlContainer,
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
} from "@angular/forms";

@Component({
    selector: "h-checkbox",
    templateUrl: "./checkbox.component.html",
    styleUrls: ["./checkbox.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxComponent),
            multi: true,
        },
    ],
    imports: [CommonModule],
    standalone: true,
})
export class CheckboxComponent implements ControlValueAccessor, AfterViewInit {
    @ViewChild("checkboxInput")
    public checkboxInput!: ElementRef;

    @Input()
    public id!: string;

    @Input()
    public insideLabel?: boolean;

    @Input()
    public labelledById?: string;

    @Input()
    public describedById?: string;

    @Input()
    public ariaExpanded: boolean = false;

    @Input()
    public dataTestId?: string;

    @Input()
    public controlName: string = "";

    @Input()
    public isCollapsible = false;

    @Input()
    public disabled: boolean = false;

    public activate: boolean = false;

    public touched!: () => void;
    public changed!: (isActivated: boolean) => void;

    constructor(protected controlContainer: ControlContainer) {}

    public get control(): FormControl | undefined {
        return this.controlContainer.control?.get(
            this.controlName,
        ) as FormControl;
    }

    public ngAfterViewInit(): void {
        if (this.ariaExpanded) {
            this.setAriaExpanded(this.activate);
        }
    }

    public toggle(): void {
        this.activate = !this.activate;

        if (this.ariaExpanded) {
            this.setAriaExpanded(this.activate);
        }

        this.changed(this.activate);
    }

    public writeValue(isActivated: boolean): void {
        this.activate = isActivated;
    }

    public registerOnChange(onChangeFunction: () => void): void {
        this.changed = onChangeFunction;
    }

    public registerOnTouched(onTouchedFunction: () => void): void {
        this.touched = onTouchedFunction;
    }

    /**
     * Add aria-expanded attribut to input
     */
    public setAriaExpanded(value: boolean) {
        this.checkboxInput.nativeElement.setAttribute(
            "aria-expanded",
            `${value}`,
        );
    }

    public controlHaveErrors(): boolean {
        return this.control
            ? this.control.invalid && this.control.touched
            : false;
    }
}
