<header
    #headerAndBannerContainer
    class="header-container"
    [attr.role]="'banner'"
    [ngStyle]="{
        '--header-height-mobile.px': HEADER_MAIN_MOBILE_HEIGHT,
        '--header-height-desktop.px': HEADER_MAIN_DESKTOP_HEIGHT,
        '--header-height-desktop-with-menu.px': HEADER_DESKTOP_HEIGHT_WITH_MENU
    }">
    <div #bannerContainer>
        <ngx-loading-bar [color]="'#444444'" [height]="'5px'" [includeSpinner]="false" [fixed]="true"></ngx-loading-bar>
        <h-banners-top-container></h-banners-top-container>
        @if (menuFacade.links$ | async; as seoLinks) { @if(!isMenuHeaderFFActivated() || (isUnderMenuBreakpoint$ |
        async) === true && !this.isInServerMode) {

        <h-seo-links [seoLinks]="seoLinks"></h-seo-links>
        }}

        <section
            #headerLinksContainer
            class="header-links-container"
            [class.transparent]="(isHeaderTransparent$ | async) === true">
            <h-header-links [isMenuHeaderActivated]="isMenuHeaderFFActivated()"></h-header-links>
        </section>
    </div>
    <!-- Background equal to scrolling part (fixed) -- needed to have a smooth scrolling when header goes from relative to fixed-->
    <div
        #headerBackgroundFixed
        class="header-background-fixed"
        [class.menu-header-activated]="isMenuHeaderFFActivated()"
        [hidden]="headerState().headerPosition !== 'fixed'"></div>
    <!-- Header : scrolling part -->
    <div
        #headerMainContainer
        class="header-main-container"
        [class.fixed]="headerState().headerPosition === 'fixed'"
        [class.transparent]="(isHeaderTransparent$ | async) === true && !isSubMenuActive()"
        [class.menu-header-activated]="isMenuHeaderFFActivated()">
        <div
            #headerMain
            class="header-main"
            [class.menu-header-activated]="isMenuHeaderFFActivated()"
            [class.hide-header-bar]="isMenuHeaderFFActivated() && headerState().isOnlyMenuBarDisplayed">
            <h-header-bar class="header-bar" [isMenuHeaderActivated]="isMenuHeaderFFActivated()"></h-header-bar>
        </div>
        <div #menuBar class="header-menu-bar">
            @if (isMenuHeaderFFActivated() && (this.isInServerMode || (this.isUnderMenuBreakpoint$ | async) === false))
            {
            <h-menu-bar></h-menu-bar>
            }
        </div>
    </div>
    @if (isAccountCreated) {
    <h-account-creation-banner
        [isAccountCreatedByWeChat]="isAccountCreatedByWechat"
        (closeBanner)="closeAccountCreationBanner()">
    </h-account-creation-banner>
    }
</header>
