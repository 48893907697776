@if ((isNewsletterRemove$ | async) === true && (isMobile$ | async) === false) {

<h-svg-icon class="icon-hermes-logo" [name]="'hermes-logo'"></h-svg-icon>

} @else {

<span
    role="heading"
    i18n-role
    aria-level="2"
    i18n="@@hermes.common.newsletter"
    class="heading heading-5 regular-uppercase"
    [attr.data-testid]="'footer-newsletter'">
    Newsletter
</span>

<p class="paragraph-small" i18n="@@hermes_footer.newsletter.subscribe.copy">
    Receive our newsletter and discover our stories, collections, and surprises.
</p>

<h-call-to-action
    (click)="handleClickOnSubscribeButton($event)"
    [trayTargetId]="'tray-subscribe'"
    [appearance]="'secondary'"
    [size]="'medium'"
    [hTrayOpener]="'tray-subscribe'"
    [trayOverlay]="true"
    [isDrupalTray]="true"
    [dataTestId]="'Subscribe'">
    <span i18n="@@hermes_footer.newsletter.subscribe.button.label">Subscribe</span>
    <span hSrOnlyNoSnippet>
        &nbsp;
        <span i18n="@@hermes_footer.newsletter.subscribe.button.label.sr">to Hermès Newsletter</span>
    </span>
</h-call-to-action>

}
