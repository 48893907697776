import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import { switchMap, withLatestFrom } from "rxjs/operators";

import { UserStateService } from "@hermes/states/user";

import {
    editProgressionStateSaved,
    updateProgressionState,
} from "../actions/conversion-funnel.action";
import { State } from "../reducers/conversion-funnel.reducer";
import { selectProgressionState } from "../selectors/conversion-funnel.selectors";

@Injectable()
export class ConversionFunnelEffects {
    public editProgression$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateProgressionState),
            withLatestFrom(this.store.select(selectProgressionState)),
            switchMap(([, state]) => {
                this.userService.setCheckoutStep(state);
                return of(editProgressionStateSaved());
            }),
        ),
    );

    constructor(
        private actions$: Actions,
        private store: Store<State>,
        private userService: UserStateService,
    ) {}
}
