import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, of } from "rxjs";

import { Product } from "@hermes/api-model-product";
import { LOCALE, Settings } from "@hermes/app-core";
import { Locale } from "@hermes/locale";
import { Logger } from "@hermes/logger";
import { LOGGER } from "@hermes/web-logger";

import { ProductPersonalizationFacade } from "../../facades/product-personalization.facade";
import {
    formatSku,
    getProductTemplateType,
} from "../../mappers/product-personalization.mapper";
import {
    PersoParsedUrl,
    ProductCategory,
} from "../../types/product-personalization.type";

@Injectable()
export class FetchPersonalizeProductService {
    constructor(
        private http: HttpClient,
        private settings: Settings,
        private router: Router,
        @Inject(LOGGER) private logger: Logger,
        @Inject(LOCALE) private locale: Locale,
        private productPersonalizationFacade: ProductPersonalizationFacade,
    ) {}

    /**
     * Allow to get productInfo with passed SKUs
     *
     * @returns Observable personalize-page api response
     */
    public fetchPersonalizeProductWithSkus(
        productSkus: string[],
    ): Observable<Product[] | { error: string }> {
        if (productSkus.length === 0) {
            this.logger.error(
                "[FetchPersonalizeProduct] Called without sku(s)",
            );
            return of([]);
        }

        let parameters = new HttpParams();

        productSkus.forEach((sku: string) => {
            parameters = parameters.append(
                "productskus",
                encodeURIComponent(sku),
            );
        });

        return this.http.get<Product[] | { error: string }>(
            `${this.settings.apiUrl}/personalize-page`,
            {
                params: parameters,
            },
        );
    }

    /**
     * Map dac response to their corresponding product
     */
    public updatePersoProducts(
        responseMapped: Product[],
        productCategory: ProductCategory,
        urlParsedParameters: PersoParsedUrl,
    ): void {
        for (const product of responseMapped) {
            const sku: string = formatSku(product.sku);
            const templateType = getProductTemplateType(productCategory);
            if (templateType) {
                product.templateType = templateType;
            }
            if (sku === urlParsedParameters.defaultSku) {
                this.productPersonalizationFacade.updateParentProduct(product);
            }
            if (sku === urlParsedParameters.firstSku) {
                this.productPersonalizationFacade.updateFirstProduct(product);
            }
            if (sku === urlParsedParameters.secondSku) {
                this.productPersonalizationFacade.updateSecondProduct(product);
            }
        }
    }

    /**
     * Allow to navigate to given path
     */
    public navigateTo(path: string, originalUrl: string): void {
        this.router.navigate([path], {
            skipLocationChange: true,
            replaceUrl: false,
            queryParams: {
                originalUrl,
            },
        });
    }

    public reorderBeltkitsProducts(
        products: Product[],
        productSkusReferential: string[],
    ): void {
        products.sort(
            (productA, productB) =>
                productSkusReferential.indexOf(productB.sku) -
                productSkusReferential.indexOf(productA.sku),
        );
    }
}
