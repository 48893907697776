import { CommonModule } from "@angular/common";

import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { LoadingBarModule } from "@ngx-loading-bar/core";

import { TranslateModule } from "@ngx-translate/core";

import { LogoComponent } from "@hermes/aphrodite/logo";
import {
    svgBackArrow,
    svgBars,
    svgCross,
    svgFilledCart,
    SvgIconComponent,
    svgPerson,
    svgPersonWithDot,
    SvgRegistryService,
    svgSearch,
} from "@hermes/aphrodite/svg";
import { BannersModule } from "@hermes/banners";
import { BackToHomeButtonModule } from "@hermes/fragments/back-to-home-button";
import {
    AsMenuEntryItemWithEditoPipe,
    MenuAnalyticsService,
    MenuUtilsService,
} from "@hermes/fragments/menu-shared";
import { SeoLinksModule } from "@hermes/fragments/seo-links";
import { MediaCtaModule } from "@hermes/media-cta";
import { MenuStateModule } from "@hermes/states/menu";
import { TrayStateModule } from "@hermes/states/tray";
import { UserStateModule } from "@hermes/states/user";

import { FocusDirectiveModule } from "@hermes/utils-generic/directives/focus";
import { SrOnlyNoSnippetDirective } from "@hermes/utils-generic/directives/sr-only-no-snippet";
import { RouterServiceModule } from "@hermes/utils-generic/services/router";

import { BackButtonCheckoutComponent } from "./components/back-button-checkout/back-button-checkout.component";
import { HeaderComponent } from "./components/header/header.component";
import { HeaderBarComponent } from "./components/header-bar/header-bar.component";
import { HeaderLightComponent } from "./components/header-light/header-light.component";
import { HeaderLinksComponent } from "./components/header-links/header-links.component";
import { HeaderSearchBarComponent } from "./components/header-search-bar/header-search-bar.component";
import { HeaderSearchSuggestionsComponent } from "./components/header-search-suggestions/header-search-suggestions.component";
import { MenuBarEditoComponent } from "./components/menu-bar/menu-bar-edito/menu-bar-edito.component";
import { MenuBarLinkComponent } from "./components/menu-bar/menu-bar-link/menu-bar-link.component";
import { MenuBarComponent } from "./components/menu-bar/menu-bar.component";
import { MenuParentCategoryComponent } from "./components/menu-bar/parent-category/menu-parent-category.component";
import { MenuSubCategoryComponent } from "./components/menu-bar/sub-category/menu-sub-category.component";
import { RedrawDirective } from "./directives/redraw/redraw.directive";
import { CartLabelPipe } from "./pipes/cart-label.pipe";
import { StrongPipe } from "./pipes/strong.pipe";
import { SuggestionItemPipe } from "./pipes/suggestion-item.pipe";
import { HeaderSearchService } from "./services/header-search.service";

@NgModule({
    declarations: [
        BackButtonCheckoutComponent,
        CartLabelPipe,
        HeaderBarComponent,
        HeaderComponent,
        HeaderLightComponent,
        HeaderLinksComponent,
        HeaderSearchBarComponent,
        HeaderSearchSuggestionsComponent,
        SuggestionItemPipe,
        StrongPipe,
        RedrawDirective,
        MenuBarComponent,
        MenuParentCategoryComponent,
        MenuSubCategoryComponent,
        MenuBarEditoComponent,
        MenuBarLinkComponent,
    ],
    imports: [
        BannersModule,
        CommonModule,
        FocusDirectiveModule,
        SrOnlyNoSnippetDirective,
        FormsModule,
        LoadingBarModule,
        SeoLinksModule,
        LogoComponent,
        MenuStateModule,
        ReactiveFormsModule,
        RouterServiceModule,
        SvgIconComponent,
        TranslateModule,
        TrayStateModule,
        UserStateModule,
        BackToHomeButtonModule,
        AsMenuEntryItemWithEditoPipe,
        MediaCtaModule,
    ],
    exports: [HeaderComponent, HeaderLightComponent],
    providers: [HeaderSearchService, MenuAnalyticsService, MenuUtilsService],
})
export class HeaderModule {
    constructor(svgRegistryService: SvgRegistryService) {
        svgRegistryService.registerSvgs([
            svgBars,
            svgCross,
            svgSearch,
            svgBackArrow,
            svgPerson,
            svgPersonWithDot,
            svgFilledCart,
        ]);
    }
}
