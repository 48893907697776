import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { GridMenuEntry, MenuEntry } from "@hermes/api-model-shell";
import { Settings } from "@hermes/app-core";

/**
 * CMS service call the Drupal REST API
 */
@Injectable()
export class CMSService implements OnDestroy {
    private subscription = new Subscription();

    constructor(private http: HttpClient, private settings: Settings) {}

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    /**
     * Retrieves categories from CMS.
     */
    public fetchCategories(): Observable<MenuEntry> {
        return this.http
            .get<MenuEntry>(`${this.settings.apiUrl}/categories`, {
                observe: "response",
            })
            .pipe(
                map((response: HttpResponse<any>) => {
                    if (response.status === 200) {
                        return response.body;
                    } else if (response.status === 204) {
                        return new GridMenuEntry();
                    }
                    throw new Error(`Unexpected response": ${response}`);
                }),
                catchError((error) => {
                    throw new Error(error.message);
                }),
            );
    }
}
