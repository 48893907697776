<!-- eslint-disable @angular-eslint/template/prefer-control-flow -->

<!-- TODO: control flow schematics: projected content '<ng-content>' is not working post migration (https://github.com/angular/angular/issues/56037) -->

<div
    class="main-wrapper"
    *ngIf="(noShell$ | async) === false; else bypassShell"
    [attr.aria-hidden]="trayOpened$ | async">
    <h-header class="header" *ngIf="(isFull$ | async) === true"></h-header>
    <h-header-light
        class="header"
        *ngIf="(isLight$ | async) === true"
        [displayBackToHome]="isBackButtonHome"></h-header-light>
    <main id="main" class="main-element" [attr.role]="'main'">
        <ng-container [ngTemplateOutlet]="transcludedContent"></ng-container>
    </main>
    <h-footer *ngIf="(isFull$ | async) === true"></h-footer>
    <h-footer-light *ngIf="(isLight$ | async) === true"></h-footer-light>
</div>

<ng-template #bypassShell>
    <ng-container [ngTemplateOutlet]="transcludedContent"></ng-container>
</ng-template>

<ng-template #transcludedContent>
    <div id="content" class="content-wrapper">
        <ng-content></ng-content>
    </div>
</ng-template>
