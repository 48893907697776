@if (isGeoFilteringBannerVisible$ | async) {
<div class="banner geo-filtering-banner">
    <section>
        <p class="banner-message paragraph-small">
            <span i18n="@@hermes.header_notification.geo_filtering_banner.message"
                >Welcome to Hermes.com. We do not deliver in your country. What would you like to do?</span
            >
            <span>
                <button data-testid="continue-button" class="button-link" (click)="continueOnHermes()">
                    <span i18n="@@hermes.banner.continue_on_hermes">Continue on Hermès </span>
                    {{ "hermes_country_selector" | translate }}
                </button>
                <span i18n="@@hermes.header_notification.geo_filtering_banner.or">or</span>
                <button
                    (click)="selectAnotherCountry()"
                    class="button-link"
                    data-testid="select-another-country-button"
                    i18n="@@hermes.accessibility.header-country-label.select-country"
                    aria-haspopup="dialog"
                    i18n-aria-haspopup
                    aria-controls="tray-locale"
                    i18n-aria-controls
                    [hTrayOpener]="'country-selector-tray'"
                    [trayPosition]="'right'"
                    [trayOverlay]="true"
                    [trayTitle]="'hermes-country-selector-tray.country'">
                    Select another country
                </button>
            </span>
        </p>
    </section>
    <button class="close-icon" (click)="close()">
        <span hSrOnlyNoSnippet i18n="@@hermes.header.notification-close">Close the notification</span>
        <h-svg-icon [name]="'cross'"></h-svg-icon>
    </button>
</div>
}
