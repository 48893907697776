@if (column) {
<span
    role="heading"
    i18n-role
    aria-level="2"
    [attr.data-testid]="column.label | sanitize | prefixWith : 'column-label-'"
    [attr.id]="column.label | sanitize | prefixWith : 'column-label-'">
    <button
        class="column-toggle heading-5 regular-uppercase"
        [attr.aria-expanded]="column.opened || false"
        [attr.aria-controls]="column.label | sanitize | prefixWith : 'column-list-'"
        (click)="handleClickOnColumnLabel()">
        <span>{{ column.label }}</span>

        @if (!column.opened) {
        <h-svg-icon [name]="'plus'"> </h-svg-icon>
        } @if (column.opened) {
        <h-svg-icon [name]="'minus'"> </h-svg-icon>
        }
    </button>
</span>
} @if (column) {
<ul
    [attr.id]="column.label | sanitize | prefixWith : 'column-list-'"
    [attr.aria-labelledby]="column.label | sanitize | prefixWith : 'column-label-'"
    [attr.aria-hidden]="!column.opened"
    [class.opened]="column.opened">
    @for (item of column.items; track item) {
    <li [class.keyboard-navigation]="layoutFacade.keyboardNavigation$ | async">
        <!-- Basic links -->
        @if (item.type === "internalLink" || item.type === "externalLink") {
        <a
            [tabIndex]="column.opened ? 0 : -1"
            [attr.target]="item.type === 'externalLink' ? '_blank' : undefined"
            [attr.rel]="item.type === 'externalLink' ? 'noopener noreferrer' : undefined"
            [href]="item.data"
            (click)="handleClickOnColumnItem($event, item)">
            {{ item.label }}
            @if (item.type === 'externalLink') {
            <span hSrOnlyNoSnippet i18n="@@hermes.footer.social.new-tab"> New tab </span>
            }
        </a>
        } @if (item.data === 'tray-help') {
        <a [tabIndex]="column.opened ? 0 : -1" (click)="goToContactUsPage($event)" href="{{ baseHref }}/contact-us/">
            {{ item.label }}
        </a>
        } @else { @if (item.type === 'button') {
        <button
            [hTrayOpener]="item.data"
            [trayOverlay]="true"
            [isDrupalTray]="true"
            [tabIndex]="column.opened ? 0 : -1"
            [attr.aria-haspopup]="'dialog'"
            [attr.aria-expanded]="'false'"
            [attr.aria-controls]="item.data"
            (click)="handleClickOnColumnItem($event, item)">
            {{ item.label }}
        </button>
        } }

        <!-- Encapsulated trays -->
    </li>
    }
</ul>
}
