<footer id="page-footer" class="page-footer" [attr.role]="'contentinfo'">
    @if (footerItems$ | async; as footerItems) {

    <div class="main-footer">
        <div class="links-block">
            @if (footerItems.columns) {
            <h-footer-columns [columns]="footerItems.columns"></h-footer-columns>
            }
        </div>

        <div class="here-to-help-block">
            <h-footer-here-to-help></h-footer-here-to-help>
        </div>

        <div class="newsletter-subscribe-block">
            <h-footer-newsletter></h-footer-newsletter>
        </div>

        <div class="social-block">
            @if (footerItems.socialNetworks) {
            <h-footer-social-networks [socialNetworks]="footerItems.socialNetworks"> </h-footer-social-networks>
            }
        </div>
    </div>

    <div class="sub-footer">
        <div class="country-selector-block">
            <h-footer-country-selector-button></h-footer-country-selector-button>
        </div>

        <h-svg-icon class="logo-block" [name]="'hermes-logo'"></h-svg-icon>

        @if (footerItems.legalLinks) {
        <div class="legal-links-block">
            <h-footer-legal-links [legalLinks]="footerItems.legalLinks"> </h-footer-legal-links>
        </div>
        }
    </div>

    <div class="copyright-block">
        <h-footer-copyright [hasLightCopyright]="false"></h-footer-copyright>
    </div>

    }
</footer>
