import { AnimationEvent } from "@angular/animations";
import { DOCUMENT } from "@angular/common";
import {
    ChangeDetectorRef,
    Component,
    HostListener,
    Inject,
    OnDestroy,
    OnInit,
    Renderer2,
} from "@angular/core";
import { Subscription } from "rxjs";

import { windowScrollTo } from "seamless-scroll-polyfill";

import exportedSass from "@hermes/aphrodite/design-system-tools";
import { Context } from "@hermes/app-core";
import { stopEventPropagation } from "@hermes/utils-generic/helpers";

import { BreakpointService } from "@hermes/utils-generic/services/user-interface";

import { trayContainerAnimations } from "../../components/tray-container/tray-container.animations";
import { TrayData, TrayPosition } from "../../models/tray-data.model";
import { TrayStackService } from "../../services/tray-stack.service";

/**
 * This components manages the tray and its animations.
 * It displays opened trays and hides when the stack is empty..
 */
@Component({
    animations: trayContainerAnimations,
    selector: "h-tray-container",
    templateUrl: "./tray-container.component.html",
    styleUrls: ["./tray-container.component.scss"],
})
export class TrayContainerComponent implements OnInit, OnDestroy {
    /**
     * List of trays (title and content) to display.
     */
    public trays: TrayData[] = [];

    /**
     * true if the tray container is open
     */
    public isOpen: boolean = false;

    /**
     * true if the overlay needs to be shown
     */
    public hasOverlay: boolean = false;

    /**
     * true if the map overlay needs to be shown
     */
    public hasMapOverlay: boolean = false;

    /**
     * true if the footer "Category Institutional" needs to be shown
     */
    public hasCategoryInstitutional: boolean = false;

    /**
     * "left" or "right"
     */
    public position: TrayPosition = "right";

    /**
     * Indicate if we show the container (linked to isOpen, but delayed after the animations)
     */
    public showContainer: boolean = false;

    public noPaddingBottom: boolean = false;

    public noPaddingHorizontal: boolean = false;

    /**
     * subscription to the trayStack observable
     */
    private subscriptions: Subscription = new Subscription();

    private scrollTop: number = 0;

    public defaultTrayColor = exportedSass.miscellanousColors["color-white"];

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private trayStack: TrayStackService,
        private breakpointService: BreakpointService,
        private renderer: Renderer2,
        private context: Context,
        private changeDetector: ChangeDetectorRef,
    ) {}

    /**
     * Close the tray.
     */
    @HostListener("document:keydown.escape", ["$event"])
    public closeTray(event: Event): void {
        this.stopPropagation(event);
        this.trayStack.popTray();
    }

    public ngOnInit(): void {
        this.subscriptions.add(
            this.trayStack.trays$.subscribe((value: TrayData[]) => {
                this.trays = value;
                this.deriveContainerData(this.trays);
                this.changeDetector.detectChanges();
                this.handleBodyScroll();
            }),
        );
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    /**
     * This is needed for performance of the *ngFor and to keep old trays in the dom.
     * Without it, angular compares array content by reference and recreates all the trays.
     */
    public trackByUuid(_index: number, item: TrayData): string {
        return item.uuid;
    }

    /**
     * Prevents the scroll to go to the page.
     */
    public stopPropagation(event: Event): void {
        stopEventPropagation(event);
        event.stopImmediatePropagation();
    }

    /**
     * Sets parameters of the tray from the last in the stack.
     */
    public deriveContainerData(data: TrayData[]): void {
        if (!data || data.length === 0) {
            this.isOpen = false;
            this.hasOverlay = false;
            this.hasMapOverlay = false;
            this.position = "right";
            return;
        }

        const lastTray: TrayData = data[data.length - 1];

        this.hasOverlay = Boolean(lastTray.hasOverlay);
        this.hasMapOverlay = Boolean(lastTray.hasMapOverlay);
        this.isOpen = Boolean(lastTray.isOpen);
        this.position = lastTray.position ?? "right";
        this.noPaddingBottom = Boolean(lastTray.noPaddingBottom);
        this.noPaddingHorizontal = Boolean(lastTray.noPaddingHorizontal);
    }

    public handleOverlayClick(): void {
        this.trayStack.closeAllTrays();
    }

    public onAnimationStart(): void {
        this.showContainer = true;
    }

    public onAnimationEnd(event: AnimationEvent): void {
        if (event.toState === "void") {
            this.showContainer = false;
        }
    }

    /**
     * Handles body scroll locking.
     * On iOS, setting 'overflow: hidden' alone does not fully lock the body scroll,
     * so this function uses a fixed position with an offset on the body element.
     */
    private handleBodyScroll(): void {
        if (
            this.breakpointService.mediumBreakpointMatcher() ||
            this.context.isInServerMode()
        ) {
            return;
        }

        if (this.trays.length === 1) {
            this.scrollTop = window.scrollY;

            // apply the fixed position with offset to prevent the page from jumping to the top
            this.renderer.setProperty(
                document.body,
                "style",
                `--scroll-y: -${this.scrollTop}px`,
            );
            this.renderer.addClass(this.document.body, "fixed-with-offset");
        } else if (this.trays.length === 0) {
            this.renderer.removeClass(document.body, "fixed-with-offset");
            // restore the previous scroll position
            windowScrollTo(window, { top: this.scrollTop });
        }
    }
}
