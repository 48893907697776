import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { map, mergeMap, Observable, take } from "rxjs";

import { LOCALE } from "@hermes/app-core";
import { Locale } from "@hermes/locale";
import { Logger } from "@hermes/logger";
import {
    ENABLE_QUERY_LOCALE_INTERCEPTOR,
    FeatureFlagFacade,
} from "@hermes/states/flipper";
import { LOCALE_TOKEN_NAME } from "@hermes/utils/constants";
import { LOGGER } from "@hermes/web-logger";

@Injectable()
export class LocaleInterceptor implements HttpInterceptor {
    private excludedUrls: string[] = ["/address/districts"];

    constructor(
        @Inject(LOCALE) private locale: Locale,
        @Inject(LOGGER) private logger: Logger,
        private featureFlagFacade: FeatureFlagFacade,
    ) {}

    public intercept<T, U>(
        request: HttpRequest<T>,
        next: HttpHandler,
    ): Observable<HttpEvent<U>> {
        return this.featureFlagFacade
            .isActivated(ENABLE_QUERY_LOCALE_INTERCEPTOR)
            .pipe(
                take(1),
                map((isActivated) => {
                    let params = request.params;
                    let headers = request.headers.set(
                        LOCALE_TOKEN_NAME,
                        this.locale.code,
                    );

                    if (isActivated && !this.isExcludedUrl(request.url)) {
                        const existingLocale = params.get("locale");

                        if (existingLocale) {
                            this.logger.warn(
                                `Existing locale detected: ${existingLocale} on ${request.url}. It will be replaced with: ${this.locale.code}`,
                            );
                            params = params.delete("locale");
                            headers = headers.delete("locale");
                        }

                        // add exception for some endpoints using POST method
                        if (request.method === "POST") {
                            headers = headers.set("locale", this.locale.code);
                        } else {
                            params = params.set("locale", this.locale.code);
                        }
                    }

                    return request.clone({ headers, params });
                }),
                mergeMap((updatedRequest) => next.handle(updatedRequest)),
            );
    }

    private isExcludedUrl(url: string): boolean {
        return this.excludedUrls.some((excludedUrl) =>
            url.includes(excludedUrl),
        );
    }
}
