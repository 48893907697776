import {
    Product,
    ProductItemBikini,
    ProductItemLook,
} from "@hermes/api-model-product";

import {
    areAllSizeVariantsOutOfStock,
    hasSizeVariant,
    isOutOfStock,
} from "@hermes/fragments/product-utils";
import { EcomErrorCodes } from "@hermes/utils-generic/constants";

import { AddToCartCallToAction } from "../add-to-cart.state";

/**
 * If the product is out of stock, returns the proper error between generic OOS or size OOS
 * Otherwise, returns a "ok" CTA state
 */
export const getProductAddToCartCallToActions = (
    product: Product | ProductItemLook | ProductItemBikini,
    ignoreSizeForError: boolean = false,
): AddToCartCallToAction => {
    if (
        (product.stock && isOutOfStock(product.stock)) ||
        (hasSizeVariant(product) && areAllSizeVariantsOutOfStock(product))
    ) {
        return {
            ecomError: {
                errorCode:
                    !ignoreSizeForError && hasSizeVariant(product)
                        ? EcomErrorCodes.SIZE_OUT_OF_STOCK
                        : EcomErrorCodes.OUT_OF_STOCK,
                enabled: true,
            },
            button: {
                enabled: false,
            },
        };
    }

    return {
        ecomError: {
            errorCode: undefined as unknown as string,
            enabled: false,
        },
        button: {
            enabled: true,
        },
    };
};
