import { Product, ProductTemplateType } from "@hermes/api-model-product";
import {
    BELTKIT_ANALYTICS_CATEGORY,
    SMLG_ANALYTICS_CATEGORY,
} from "@hermes/fragments/product-utils";
import { QUICK_BUY } from "@hermes/states/add-to-cart";

import { isDoubleObject } from "@hermes/utils-generic/helpers";

export interface EventCategoryData {
    templateType?: string;
    sku?: string;
    context?: string;
}

export const getEventCategory = (
    eventCategoryData: EventCategoryData,
): string => {
    if (eventCategoryData.context === QUICK_BUY) {
        return "Product_Grid";
    }

    switch (eventCategoryData.templateType) {
        case ProductTemplateType.Beltkit: {
            return "BeltKitPage";
        }
        case ProductTemplateType.AppleWatch: {
            return "AppleWatchPage";
        }
        case ProductTemplateType.Giftset: {
            return "SetsProductPage";
        }
        case ProductTemplateType.DoubleFragrance: {
            return "FragranceProductPage";
        }
        case ProductTemplateType.Look: {
            return "LooksProductPage";
        }
        case ProductTemplateType.Bikini: {
            return eventCategoryData.sku &&
                isDoubleObject(eventCategoryData.sku)
                ? "IdokitProductPage"
                : "BikiniProductPage";
        }
        case ProductTemplateType.PersoSMLG: {
            return SMLG_ANALYTICS_CATEGORY;
        }
        case ProductTemplateType.PersoBeltkit: {
            return BELTKIT_ANALYTICS_CATEGORY;
        }
        default: {
            return "SingleProductPage";
        }
    }
};

export const getPageType = (eventCategoryData: EventCategoryData): string => {
    switch (eventCategoryData.templateType) {
        case ProductTemplateType.Beltkit: {
            return "double product page belt kits";
        }
        case ProductTemplateType.AppleWatch: {
            return "double product page apple watch";
        }
        case ProductTemplateType.Giftset: {
            return "set product page";
        }
        case ProductTemplateType.PersoSMLG: {
            return "pm product page";
        }
        case ProductTemplateType.DoubleFragrance: {
            return "double product page fragrance";
        }
        case ProductTemplateType.PersoBeltkit: {
            return "belt perso product page";
        }
        case ProductTemplateType.Look: {
            return "look product page";
        }
        case ProductTemplateType.Bikini: {
            return eventCategoryData.sku &&
                isDoubleObject(eventCategoryData.sku)
                ? "idokit product page"
                : "bikini product page";
        }
        default: {
            return "single product page";
        }
    }
};

export function getFormatedSku(product: Product): string {
    switch (product.templateType) {
        case ProductTemplateType.AppleWatch: {
            return `${product.appleWatchAttributes?.skuGuizmo}|${product.appleWatchAttributes?.skuStrap}`;
        }
        case ProductTemplateType.Beltkit: {
            return `${product.beltkitAttributes?.skuBuckle}|${product.beltkitAttributes?.skuLeather}`;
        }
        case ProductTemplateType.DoubleFragrance: {
            return `${product.doubleFragranceAttributes?.skuPerfume}|${product.doubleFragranceAttributes?.skuAccessory}`;
        }
        case ProductTemplateType.Bikini: {
            if (
                isDoubleObject(product.sku) &&
                product.bikiniAttributes?.items?.length === 2
            ) {
                return `${product.bikiniAttributes?.items[0].sku}|${product.bikiniAttributes?.items[1].sku}`;
            }
            return product.sku;
        }
        default: {
            return product.sku;
        }
    }
}
