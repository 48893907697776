<h-modal
    (closeModal)="close()"
    i18n-ariaLabelledby
    ariaLabelledby="apple-pay-modal"
    class="align-center no-padding"
    id="modal-apple-pay"
    role="dialog">
    @if (state === 'error') {
    <div h-modal-header>
        <button
            type="button"
            cdkFocusInitial
            (click)="close()"
            class="notification-close"
            aria-label="Close"
            i18n-aria-label="@@hermes.accessibility.close_tray">
            <h-svg-icon [name]="'cross-rounded'"></h-svg-icon
        ></button>
    </div>
    }
    <div h-modal-body>
        <div>
            <h-redirect-message [state]="state" [errorMessage]="errorMessage"></h-redirect-message>
        </div>
    </div>
</h-modal>
<div class="modal-background"></div>
