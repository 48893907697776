import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { Product } from "@hermes/api-model-product";
import { LOCALE, Settings } from "@hermes/app-core";
import { Locale } from "@hermes/locale";

import { CrossSell } from "../types/product-page.types";

/**
 * Manages cross-selling products.
 */
@Injectable()
export class CrossSellingService {
    constructor(
        @Inject(LOCALE) private locale: Locale,
        private http: HttpClient,
        private settings: Settings,
    ) {}

    /**
     * Fetch cross-selling products based on product's sku and categories. Set crossSellingProducts$ observable.
     *
     * @param product product on current page
     */
    public fetchCrossSellingProducts(product: Product): Observable<CrossSell> {
        const params = this.getParams(product);
        return this.http.get<CrossSell>(
            `${this.settings.apiUrl}/cross-sell/${encodeURIComponent(
                product.sku,
            )}`,
            {
                params,
            },
        );
    }

    private getParams(product: Product): HttpParams {
        let params = new HttpParams().set(
            "secondid",
            encodeURIComponent(product.secondId),
        );

        if (product.categories) {
            product.categories.forEach((category) => {
                params = params.append("categories[]", category);
            });
        }

        return params;
    }
}
