import { BreakpointState } from "@angular/cdk/layout";
import { CommonModule } from "@angular/common";
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { BehaviorSubject, filter, Subscription, take } from "rxjs";
import type Player from "video.js/dist/types/player";

import {
    IconButtonComponent,
    IconButtonSize,
    MEDIUM,
    SMALL,
} from "@hermes/aphrodite/icon-button";
import { LayoutFacade } from "@hermes/aphrodite/layout";
import {
    registerSvgs,
    SvgIconComponent,
    svgMutedMedia,
    svgPauseMedia,
    svgPlayMedia,
    svgUnmutedMedia,
} from "@hermes/aphrodite/svg";
import { Context, LANG } from "@hermes/app-core";
import { Lang } from "@hermes/locale";
import { Logger } from "@hermes/logger";
import { AnalyticsService } from "@hermes/utils/analytics";

import { DetectViewportDirectiveModule } from "@hermes/utils-generic/directives/detect-viewport";
import { SrOnlyNoSnippetDirective } from "@hermes/utils-generic/directives/sr-only-no-snippet";
import { stopEventPropagation } from "@hermes/utils-generic/helpers";
import { BreakpointService } from "@hermes/utils-generic/services/user-interface";
import { LOGGER } from "@hermes/web-logger";

import {
    EVENT_ACTION_VIDEO_AUTOPLAY,
    EVENT_ACTION_VIDEO_FIRST_PLAY,
    EVENT_ACTION_VIDEO_FULLSCREEN,
    EVENT_ACTION_VIDEO_MUTE,
    EVENT_ACTION_VIDEO_NEW_PLAY,
    EVENT_ACTION_VIDEO_PAUSE,
    EVENT_ACTION_VIDEO_STOP,
    NON_INTUA_EVENT,
    UAEVENT,
    VideoEvent,
} from "./video.event";

export const EXTERNAL_POSITION = "external";
export const LOW_POSITION = "low";
export const MODERATE_POSITION = "moderate";
export const MEDIUM_POSITION = "medium";
export const HIGH_POSITION = "high";
export type ControlPosition =
    | typeof LOW_POSITION
    | typeof MODERATE_POSITION
    | typeof MEDIUM_POSITION
    | typeof HIGH_POSITION
    | typeof EXTERNAL_POSITION;

export const EDITORIAL_MEDIA_QUERY_CONSTANTS = {
    xsmall: "(min-width: 320px) and (max-width: 414px)",
    small: "(min-width: 415px) and (max-width: 768px)",
    medium: "(min-width: 769px) and (max-width: 1024px)",
    large: "(min-width: 1025px) and (max-width: 1280px)",
    xlarge: "(min-width: 1281px) and (max-width: 1920px)",
    xxlarge: "(min-width: 1921px)",
};

export const CONTENTS_SIZES_CONSTANTS = {
    SNACKABLE_VIDEO: {
        xsmall: 358,
        small: 768,
        medium: 923,
        large: 923,
        xlarge: 1280,
        xxlarge: 1920,
    },
    PUSH_SNACKABLE_VIDEO_STANDARD: {
        xsmall: 358,
        small: 768,
        medium: 923,
        large: 923,
        xlarge: 1280,
        xxlarge: 1920,
    },
    DEFAULT: {
        xsmall: 414,
        small: 768,
        medium: 1024,
        large: 1280,
        xlarge: 1920,
        xxlarge: 3840,
    },
};

@Component({
    selector: "h-video",
    standalone: true,
    templateUrl: "./video.component.html",
    styleUrls: ["./video.component.scss"],
    // False positive, we need to override videojs CSS.
    // eslint-disable-next-line @angular-eslint/use-component-view-encapsulation
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        DetectViewportDirectiveModule,
        IconButtonComponent,
        SvgIconComponent,
        SrOnlyNoSnippetDirective,
    ],
})
export class VideoComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input()
    public posterUrl?: string;
    @Input()
    public alwaysShowControls: boolean = false;
    @Input()
    public showMuteButtons: boolean = false;
    @Input()
    public mediaType: string = "snackable_video";
    @Input()
    public mobileVideoUrl?: string;
    @Input()
    public videoName!: string;
    /** Example 16-9 for ratio 16:9 */
    @Input()
    public videoRatio?: `${number}-${number}`;

    @Input()
    public preventFirstAutoplayEvent: boolean = false;

    @Input()
    public isLightMode: boolean = false;

    /** Set play/pause button positon on LightMode appearance */
    @Input()
    public controlPosition: ControlPosition = LOW_POSITION;

    /** Set play/pause button size on LightMode appearance */
    @Input()
    public controlSize: IconButtonSize = MEDIUM;

    // Set a11y visibility
    @Input()
    public isAriaHidden: boolean = false;

    @Input()
    public displayGradient: boolean = false;

    // Set accessibility for button play/pause on lightMode
    @Input()
    public disableControlTabIndex: boolean = false;

    /**
     * Can receive external container to receive the control button container.
     */
    @Input() public videoButtonContainer?: HTMLDivElement;

    @Input() public detectViewportOnce = true;
    @Input() public detectViewportTreeshold = 0.5;

    /** Event sent when clicking on play/pause  buttons */
    @Output()
    public clickOnPlayPauseVideoControls: EventEmitter<boolean> =
        new EventEmitter<boolean>();

    // to get videoelement HTML
    @ViewChild("video", { static: true })
    public videoElement!: ElementRef;

    /**
     * Reference to the control button container.
     */
    @ViewChild("buttonContainer", { static: true })
    public buttonContainer!: ElementRef<HTMLElement>;

    /** True if video is ready to play */
    public videoReady: boolean = false;
    /** True if video is loaded */
    public videoLoaded$ = new BehaviorSubject(false);

    public hidePlayPauseButton = false;

    // videoJs player
    public player!: Player;
    public editorialMediaQueries = EDITORIAL_MEDIA_QUERY_CONSTANTS;

    // for analytics
    public lastAnalyticsPercentSent: number = 0;
    public isAutoPause: boolean = false;
    public isAutoPlay: boolean = false;
    public isAutoMute: boolean = false;
    public isFirstAutoPlay: boolean = true;
    public isFirstPlay: boolean = true;
    public svgIconClass: "pause-media" | "play-media" = "pause-media";
    public svgMuteClass: "muted-media" | "unmuted-media" = "muted-media";

    public videoUrlInput = "";
    public videoLoopMuteAutoplayInput = true;
    public isPlayingInput = true;

    private analyticsPositionSteps: number[] = [95, 75, 50, 25];
    private isPosterLoaded = false;
    private subscription: Subscription = new Subscription();

    constructor(
        public breakpointService: BreakpointService,
        private readonly context: Context,
        private zone: NgZone,
        public changeDetector: ChangeDetectorRef,
        private analyticsService: AnalyticsService,
        public layoutFacade: LayoutFacade,
        private renderer: Renderer2,
        @Inject(LANG) private lang: Lang,
        @Inject(LOGGER) private logger: Logger,
    ) {
        registerSvgs([
            svgPlayMedia,
            svgPauseMedia,
            svgUnmutedMedia,
            svgMutedMedia,
        ]);
    }

    public get iconSize(): number {
        if (this.controlSize === SMALL) {
            return 8;
        }
        return 9;
    }

    @Input() public set videoLoopMuteAutoplay(value: boolean) {
        this.videoLoopMuteAutoplayInput = value;
        this.changeVideoAutoplayProperty(value);
    }

    @Input() public set isPlaying(value: boolean) {
        this.isPlayingInput = value;
        if (this.player) {
            if (value) {
                this.player.play();
                this.updateLightModePlayPauseButton(false);
            } else {
                this.player.pause();
                this.updateLightModePlayPauseButton(true);
            }
            this.changeDetector.markForCheck();
        }
    }

    // We need to update player video and poster at each url change.
    @Input() public set videoUrl(url: string) {
        if (!this.videoUrlInput) {
            // First change, nothing to do.
            this.videoUrlInput = url;
            return;
        }
        if (this.videoUrlInput === url) {
            // Same video, nothing to do.
            return;
        }
        this.videoUrlInput = url;
        if (url && this.player && this.videoLoaded$.getValue()) {
            // We reset video state to be paused.
            this.isPlayingInput = false;

            // We update video player video source and poster.
            this.zone.runOutsideAngular(() => {
                this.updatePlayerVideoSource();
                this.updatePlayerPosterUrl();
            });
        }
    }

    public ngOnInit(): void {
        // set the appropriate video format(url) depending on viewport
        if (
            this.context.isInBrowserMode() &&
            !this.breakpointService.mediumBreakpointMatcher() &&
            this.mobileVideoUrl
        ) {
            this.videoUrl = this.mobileVideoUrl;
        }

        // If a container is provided, move the button container to it.
        if (this.videoButtonContainer) {
            this.renderer.appendChild(
                this.videoButtonContainer,
                this.buttonContainer.nativeElement,
            );
        }
    }

    /**
     * Change manually video autoplay property
     *
     * @param isLoopMuteAutoplay
     */
    public changeVideoAutoplayProperty(isLoopMuteAutoplay: boolean) {
        if (this.player) {
            if (isLoopMuteAutoplay) {
                this.isAutoPlay = true;
                this.player.play();
                this.player.muted(true);
                this.player.loop(true);
                this.player.controls(this.alwaysShowControls);
            } else {
                this.autoPauseVideo();
                this.player.muted(false);

                this.updateLightModeMuteUnmuteButton(false);
                this.player.loop(false);
                this.player.controls(this.alwaysShowControls);
            }
        }
    }

    /**
     * Auto-pause video
     */
    public autoPauseVideo(): void {
        this.isAutoPause = true;
        this.player.pause();
    }

    /**
     * send analytics event on play event from video js :
     * - 'firstPlay' if autoload is disable and user click the first time. In this case, autoplay is never sent
     * - 'autoPlay' if autoload is enable and video is played automatically. In this case, firstplay is never sent
     * - 'newPlay' in case the video is paused and user click on play
     */
    public onPlayEventHandler(): void {
        if (this.isAutoPlay) {
            if (this.isFirstAutoPlay && !this.preventFirstAutoplayEvent) {
                this.pushAnalyticsEvent(
                    EVENT_ACTION_VIDEO_AUTOPLAY,
                    NON_INTUA_EVENT,
                );
            }
            if (this.isFirstAutoPlay) {
                this.preventFirstAutoplayEvent = false;
                this.isFirstPlay = false;
                this.isFirstAutoPlay = false;
            }
            this.isAutoPlay = false;
        } else {
            if (this.isFirstPlay) {
                this.pushAnalyticsEvent(EVENT_ACTION_VIDEO_FIRST_PLAY);
                this.isFirstPlay = false;
                this.isFirstAutoPlay = false;
            } else {
                this.pushAnalyticsEvent(EVENT_ACTION_VIDEO_NEW_PLAY);
            }
        }
    }

    /**
     * Allow to create && to init videoJs player
     */
    public async ngAfterViewInit(): Promise<unknown> {
        if (!this.context.isInBrowserMode()) {
            return undefined;
        }

        this.updateLightModePlayPauseButton(
            !this.videoLoopMuteAutoplayInput || !this.isPlayingInput,
        );

        this.updateLightModeMuteUnmuteButton(this.videoLoopMuteAutoplayInput);

        // VideoJS has pt-BR and pt-PT file for Portugese
        const langCodeVideoJs =
            this.lang.langCode === "pt" ? "pt-BR" : this.lang.langCode;

        const translationFile = await import(
            `video.js/dist/lang/${langCodeVideoJs}.json`
        ).catch((error) => {
            this.logger.error(
                "Video.js translation file not found for the current locale",
                error,
            );
        });

        const options = {
            preload:
                this.isLightMode && !this.videoLoopMuteAutoplayInput
                    ? "auto"
                    : "none",
            muted: this.videoLoopMuteAutoplayInput,
            controls: !this.isLightMode,
            inactivityTimeout: this.alwaysShowControls ? 0 : 2000,
            loop: this.videoLoopMuteAutoplayInput,
            playsinline: true,
            language: langCodeVideoJs,
            languages: {
                [langCodeVideoJs]: translationFile,
            },
        };

        // IMPORTANT:
        // Lazy-load video.js using dynamic import to prevent loading the entire library upfront.
        // This reduces the initial bundle size and improves performance by ensuring video.js is only loaded when needed.
        this.player = await import("video.js").then((videoJSBundle) =>
            this.zone.runOutsideAngular(() =>
                videoJSBundle.default(this.videoElement.nativeElement, options),
            ),
        );

        return this.zone.runOutsideAngular(() => {
            this.updatePlayerVideoSource();

            this.isAutoMute = this.videoLoopMuteAutoplayInput;

            // Init analytics listener and update videoLoaded$ to remove black background from video
            this.player.ready(() =>
                this.zone.run(() => {
                    this.videoLoaded$.next(true);
                    this.initAnalyticsOnVideo();
                    this.player.el().removeAttribute("role");
                    if (this.isLightMode) {
                        const loadingSpinner =
                            this.player.getChild("LoadingSpinner");
                        if (loadingSpinner) {
                            loadingSpinner.hide();
                        }
                    }
                }),
            );
        });
    }

    /**
     * Allow to construct playlist url
     * -replace /image/ by /content/
     * -remove url params
     * -adding .m3u8 to url
     */
    public constructPlayListUrl(videoUrl: string): string {
        if (!videoUrl) {
            return "";
        }

        videoUrl = videoUrl.replace("/image/", "/content/");

        return videoUrl.includes(".m3u8")
            ? videoUrl
            : `${videoUrl.split("?")[0]}.m3u8`;
    }

    public handleViewportVisibilityChange(isVisibleInViewPort: boolean): void {
        // For product page we hide button play/pause when video is not in viewport.
        if (this.controlPosition === EXTERNAL_POSITION) {
            this.setHidePlayPauseButton(!isVisibleInViewPort);
        }

        try {
            this.handlePosterViewportVisibility(isVisibleInViewPort);

            if (this.videoLoopMuteAutoplayInput) {
                return this.handleAutoplayOnViewportVisibilityChange(
                    isVisibleInViewPort,
                );
            }
        } catch {
            // do nothing to prevent useless log in console browser
        }
    }

    /**
     * Handle the poster url depending on breakpoints
     *
     * @param breakpointState Different breakpoints
     */
    public handlePosterUrl(breakpointState: BreakpointState): void {
        // Get the matched mediaQuery
        const mediaQueries = Object.entries(this.editorialMediaQueries)
            // On every editorial media queries, get the one matched by the observer (small, medium, large ...)
            .find(([, mediaqueries]) => {
                // Get the breakpoint matched. E.g.: {"(min-width: 1921px)": true}
                const breakpointMatch = Object.entries(
                    breakpointState.breakpoints,
                ).find(([, matchedMediaQueries]) => matchedMediaQueries);

                // if a breakpoint have matched, return a boolean
                if (breakpointMatch) {
                    return mediaqueries === breakpointMatch[0];
                }

                // If no one match, return false
                return false;
            });

        if (!mediaQueries) {
            return;
        }

        const [mediaQuery] = mediaQueries;

        // Get different sizes on the media type
        const contentSizes =
            (
                CONTENTS_SIZES_CONSTANTS as unknown as Record<
                    string,
                    Record<string, number>
                >
            )[this.mediaType.toUpperCase()] || CONTENTS_SIZES_CONSTANTS.DEFAULT;

        // Get the poster size on the current media query type
        const contentSize = contentSizes[mediaQuery];

        // Set the poster URL with his attibutes
        if (this.posterUrl) {
            const poster = this.posterUrl.includes("?")
                ? this.posterUrl
                : `${this.posterUrl}?`;
            this.subscription.add(
                this.videoLoaded$
                    .pipe(
                        filter((loaded) => loaded),
                        take(1),
                    )
                    .subscribe(() =>
                        this.player.poster(
                            `${poster}&fit=wrap%2C0&wid=${contentSize}`,
                        ),
                    ),
            );
        }
    }

    /**
     * Remove video component from html
     */
    public ngOnDestroy(): void {
        if (this.player) {
            this.player.dispose();
        }
        if (
            this.videoButtonContainer &&
            this.buttonContainer.nativeElement.parentNode ===
                this.videoButtonContainer
        ) {
            this.renderer.removeChild(
                this.videoButtonContainer,
                this.buttonContainer.nativeElement,
            );
        }
        this.subscription.unsubscribe();
    }

    /**
     * send analytics event on pause event from video js :
     * - videojs send a pause event at end of the video. if the video is ended, sent 'stop'
     * - if user click on pause, send 'pause'
     */
    public onPauseEventHandler(): void {
        if (this.isAutoPause) {
            this.isAutoPause = false;
        } else {
            if (this.player.ended()) {
                this.pushAnalyticsEvent(EVENT_ACTION_VIDEO_STOP);
            } else {
                this.pushAnalyticsEvent(EVENT_ACTION_VIDEO_PAUSE);
            }
        }
    }

    /**
     * send analytics progress event on timeupdate from video js
     */
    public onTimeUpdateEventHandler(): void {
        // calculate percent of current position
        // Get current time and duration by function but could be undefined, in that case retrieve cache data
        const currentTime =
            this.player.currentTime() || this.player.cache_.currentTime;
        const duration = this.player.duration() || this.player.cache_.duration;
        const currentPercent: number = Number.parseInt(
            ((currentTime / duration) * 100).toFixed(0),
            10,
        );

        const analyticsPercent = this.analyticsPositionSteps.find(
            (positionStep: number) => currentPercent >= positionStep,
        );

        if (
            !this.videoLoopMuteAutoplayInput &&
            analyticsPercent &&
            this.lastAnalyticsPercentSent !== analyticsPercent
        ) {
            this.pushAnalyticsEvent(`${analyticsPercent}%`);
            this.lastAnalyticsPercentSent = analyticsPercent;
        }
    }

    /**
     * send analytics fullscreen event on fullscreen from video js
     */
    public onFullscreenEventHandler(): void {
        if (this.player.isFullscreen()) {
            this.pushAnalyticsEvent(EVENT_ACTION_VIDEO_FULLSCREEN);
        }
    }

    /**
     * send analytics muted event on volumechange from video js
     */
    public onVolumechangeEventHandler(): void {
        if (this.player.muted()) {
            if (this.isAutoMute) {
                this.isAutoMute = false;
            } else {
                this.pushAnalyticsEvent(EVENT_ACTION_VIDEO_MUTE);
            }
        }
    }

    /**
     * Send UaEvent for Analytics
     *
     * @param action name of action (FirstPlay, Stop, ...)
     */
    public pushAnalyticsEvent(action: string, name?: string): void {
        this.analyticsService.sendData(
            new VideoEvent({
                eventlabel: this.videoName,
                eventaction: action,
                name: name ?? UAEVENT,
            }),
        );
    }

    public muteUnmuteVideo(event: Event): void {
        if (!this.player || !this.videoReady || this.player.controls()) {
            return;
        }
        if (this.player.muted()) {
            this.player.muted(false);
            this.updateLightModeMuteUnmuteButton(false);
        } else {
            this.player.muted(true);
            this.updateLightModeMuteUnmuteButton(true);
        }
        stopEventPropagation(event);
    }

    /**
     * If the video is paused we start it, if not we pause it
     */
    public playPauseVideo(event?: Event): void {
        if (
            !this.player ||
            !(this.videoReady || this.alwaysShowControls) ||
            this.player.controls()
        ) {
            return;
        }
        if (this.player.paused()) {
            this.player.play();
            this.clickOnPlayPauseVideoControls.emit(true);
            this.updateLightModePlayPauseButton(false);
            this.isPlayingInput = true;
        } else {
            this.player.pause();
            this.clickOnPlayPauseVideoControls.emit(false);
            this.updateLightModePlayPauseButton(true);
            this.isPlayingInput = false;
        }
        if (event) {
            stopEventPropagation(event);
        }
    }

    public updateLightModePlayPauseButton(isPlayIcon: boolean): void {
        if (this.isLightMode) {
            this.svgIconClass = isPlayIcon ? "play-media" : "pause-media";
            this.setHidePlayPauseButton(
                !this.alwaysShowControls && !isPlayIcon,
            );
        }
    }

    public updateLightModeMuteUnmuteButton(isMutedIcon: boolean): void {
        if (this.isLightMode) {
            this.svgMuteClass = isMutedIcon ? "muted-media" : "unmuted-media";
            this.changeDetector.detectChanges();
        }
    }

    private handlePosterViewportVisibility(isVisibleInViewPort: boolean): void {
        if (isVisibleInViewPort && !this.isPosterLoaded) {
            this.isPosterLoaded = true;
            this.subscription.add(
                this.breakpointService
                    // Observe on all different breakpoints
                    .severalBreakpointObserver(
                        Object.values(this.editorialMediaQueries),
                    )
                    .pipe(take(1)) // unsubscribe as soon as we have it
                    .subscribe((breakpointState) =>
                        this.handlePosterUrl(breakpointState),
                    ),
            );
        }
    }

    private handleAutoplayOnViewportVisibilityChange(
        isVisibleInViewPort: boolean,
    ): void {
        if (isVisibleInViewPort && this.isPlayingInput) {
            this.isAutoPlay = true;
            this.player.play();
            this.isAutoPause = false;
            return;
        }

        this.autoPauseVideo();
    }

    /**
     * init listeners on videojs event to send analytics
     */
    private initAnalyticsOnVideo(): void {
        // newplay, firstplay, autoplay
        this.player.on("play", () => this.onPlayEventHandler());

        // pause, stop
        this.player.on("pause", () => this.onPauseEventHandler());

        this.player.on("touchstart", () => {
            this.playPauseVideo();
        });

        // update video position
        this.player.on("timeupdate", () => this.onTimeUpdateEventHandler());

        // fullscreen
        this.player.on("fullscreenchange", () =>
            this.onFullscreenEventHandler(),
        );

        // When video is ready to be played
        this.player.on("canplaythrough", () => {
            this.videoReady = true;
            this.changeDetector.detectChanges();
        });

        // When video is ended, change light mode button state
        this.player.on("ended", () => {
            this.updateLightModePlayPauseButton(true);
        });

        // volume change for mute
        this.player.on("volumechange", () => this.onVolumechangeEventHandler());
    }

    private setHidePlayPauseButton(hide: boolean) {
        this.hidePlayPauseButton = hide;
        this.changeDetector.detectChanges();
    }

    private updatePlayerVideoSource() {
        this.player.src({
            src: this.constructPlayListUrl(this.videoUrlInput),
            type: "application/x-mpegURL",
        });
    }

    private updatePlayerPosterUrl() {
        const currentUrl = this.player.poster();
        if (currentUrl && this.posterUrl) {
            const [_basePart, queryParameters] = currentUrl.split("?");
            const posterUrl = `${this.posterUrl}${
                queryParameters ? `?${queryParameters}` : ""
            }`;
            this.player.poster(posterUrl.toString());
        }
    }
}
