import { Component, HostListener, Input, OnInit } from "@angular/core";
import { combineLatest, map } from "rxjs";

import { CtaSize, LARGE } from "@hermes/aphrodite/call-to-action";
import { Context } from "@hermes/app-core";
import { ApplePayFacade, ApplepayStatus } from "@hermes/states/apple-pay";
import { ConversionFunnelFacade } from "@hermes/states/conversion-funnel";

@Component({
    selector: "h-apple-pay-button",
    templateUrl: "./apple-pay-button.component.html",
    styleUrls: ["./apple-pay-button.component.scss"],
})
export class ApplePayButtonComponent implements OnInit {
    @Input()
    public disabled = false;

    @Input()
    public preventSubmit = true;

    @Input()
    public size: CtaSize = LARGE;

    public isLoading$ = combineLatest([
        this.applePayFacade.status$.pipe(
            map((status) => status === ApplepayStatus.AddingProductToCart),
        ),
        this.conversionFunnelFacade.isLoading$,
    ]).pipe(
        map(
            ([isAddingToCartLoading, isFunnelLoading]) =>
                isAddingToCartLoading || isFunnelLoading,
        ),
    );

    public status = ApplepayStatus;

    constructor(
        private context: Context,
        public applePayFacade: ApplePayFacade,
        public conversionFunnelFacade: ConversionFunnelFacade,
    ) {}

    public ngOnInit(): void {
        if (this.context.isInServerMode()) {
            return;
        }
    }

    /**
     * allows you to listen to the closing event of the apple pay modal on all browsers
     */
    @HostListener("window:apple-pay-close")
    public onApplePayClose(): void {
        this.conversionFunnelFacade.hideLoader();
    }

    public onBuyWithApplePayClick($event: Event): void {
        if (this.preventSubmit) {
            $event.stopImmediatePropagation();
            this.applePayFacade.clickOnApplepayButton();
        }
    }
}
