import {
    NgFor,
    NgIf,
    NgSwitch,
    NgSwitchCase,
    NgTemplateOutlet,
} from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

import { Context } from "@hermes/app-core";

import { HeadService } from "@hermes/utils-generic/services/head";

import {
    LoadingMode,
    DEFAULT_MEDIA_QUERIES_OPTIONS,
    MediaPicture,
    MediaQueryOption,
} from "../../model/media.types";

import { PosterUrlPipe } from "../../pipes/poster-url/poster-url.pipe";

import {
    computeLoadingMode,
    generateLinkDefinitions,
} from "./media-picture.helpers";

@Component({
    selector: "h-media-picture",
    templateUrl: "./media-picture.component.html",
    styleUrls: ["./media-picture.component.scss"],
    imports: [
        NgIf,
        NgFor,
        NgSwitchCase,
        NgSwitch,
        NgTemplateOutlet,
        PosterUrlPipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class MediaPictureComponent {
    @Input()
    public mediaQueriesOptions?: MediaQueryOption[];

    @Input()
    public title?: string;

    public defaultMediaQueriesOptions = DEFAULT_MEDIA_QUERIES_OPTIONS;

    public mediaUrl: string = "";
    public imageAlt: string = "";

    public loadingMode: LoadingMode;

    private isInServerMode: boolean;
    private prPreloadPoster!: boolean;
    private prMediaPicture?: MediaPicture;

    constructor(private context: Context, private headService: HeadService) {
        this.isInServerMode = this.context.isInServerMode();
    }

    public get mediaPicture(): MediaPicture | undefined {
        return this.prMediaPicture;
    }
    @Input({ required: true })
    public set mediaPicture(mediaPicture: MediaPicture | undefined) {
        this.prMediaPicture = mediaPicture;
        if (this.mediaPicture) {
            this.imageAlt = this.mediaPicture.imageAlt ?? "";
            this.mediaUrl =
                this.mediaPicture.desktopImage || this.mediaPicture.mobileImage;
            this.loadingMode = computeLoadingMode({
                preloadPoster: this.prPreloadPoster,
                isInServerMode: this.isInServerMode,
                mediaUrl: this.mediaUrl,
            });
            this.setPreloadLink();
        }
    }

    @Input({ required: true })
    public set preloadPoster(preloadPoster: boolean) {
        this.prPreloadPoster = preloadPoster;
        this.loadingMode = computeLoadingMode({
            preloadPoster: this.prPreloadPoster,
            isInServerMode: this.isInServerMode,
            mediaUrl: this.mediaUrl,
        });
        this.setPreloadLink();
    }

    public trackBySize(_index: number, mediaQueryOptions: MediaQueryOption) {
        return mediaQueryOptions.size;
    }

    public setPreloadLink(): void {
        const linksToAddInTheHead = generateLinkDefinitions(
            this.prPreloadPoster,
            this.prMediaPicture,
            this.mediaQueriesOptions ?? DEFAULT_MEDIA_QUERIES_OPTIONS,
        );
        linksToAddInTheHead.forEach((linkToAdd) =>
            this.headService.addTag(linkToAdd),
        );
    }
}
