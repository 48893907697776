<div class="not-found-page">
    <div class="container">
        <h1 i18n="@@hermes-global-translations.darn-internet"> Darn internet! </h1>

        <p class="paragraph-medium" i18n="@@hermes-global-translations.were-sorry.line1">
            We’re sorry. The page you were looking for no longer exists.
        </p>
        <p class="paragraph-medium" i18n="@@hermes-global-translations.were-sorry.line2">
            Fear not, we’re bringing you back home.
        </p>
        <div class="link">
            <a
                class="paragraph-medium"
                i18n="@@hermes-global-translations.home"
                (click)="sendGoToHomeAnalytics()"
                href="{{ baseHref }}/">
                Go back to home or search for something else
            </a>
            <ng-container i18n="@@hermes-global-translations.or"> or </ng-container>
            <button
                i18n="@@hermes-global-translations.search"
                (click)="goToSearchInput($event)"
                (keyup.enter)="goToSearchInput($event)"
                class="gotToSearchButton paragraph-medium">
                search for something else
            </button>
        </div>
    </div>
</div>
