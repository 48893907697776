<span
    role="heading"
    i18n-role
    aria-level="2"
    i18n="@@hermes--footer-wechat-social.follow-us"
    [attr.data-testid]="'footer-social-network'"
    class="heading heading-5 regular-uppercase"
    >Follow us</span
>

<ul>
    @for (socialNetwork of socialNetworks; track trackBySocialNetwork($index, socialNetwork)) {
    @if(socialNetwork.iconName === 'wechat') {
    <li class="wechat">
        <!-- Custom template for Wechat -->
        <button (click)="handleClickOnWechat($event, 'wechat')">
            <span hSrOnlyNoSnippet>
                {{ socialNetwork.iconName }}
                <ng-container i18n="@@hermes.footer.social.new-window">(new window)</ng-container>
            </span>

            <h-svg-icon [name]="'wechat-alternate'" class="wechat-icon"></h-svg-icon>
        </button>

        @if (showWeChatTootip) {
        <div id="tooltip-wechat" class="arrow-box">
            <div class="tooltip-wechat-wrapper">
                <button id="close-tooltip" class="button-icon icon-close-wechat" (click)="hideWeChatTooltip()">
                    <h-svg-icon [name]="'cross'" class="icon-close-tooltip"></h-svg-icon>
                </button>

                <div class="wechat-tooltip-text-wrapper">
                    <p class="follow-us-title" i18n="@@hermes--footer-wechat-social.follow-us">Follow us</p>
                    <img
                        [src]="weChatImageUrl"
                        alt="Hermes Official Wechat account"
                        i18n-alt="@@block--hermes-general-info--help-tray.wechat-QR-code-alt"
                        class="qr-code"
                        loading="lazy" />
                    <p class="follow-us-description" i18n="@@hermes--footer.wechat-social.scan-in-wechat">
                        Scan in Wechat
                    </p>
                    <p
                        class="follow-us-description-mobile"
                        i18n="@@block--hermes-general-info--help-tray.wechat-description-mobile">
                        Wechat ID : Hermes_Official
                    </p>
                </div>
            </div>
        </div>
        }
    </li>
    } @else {
    <li class="{{ socialNetwork.iconName }}">
        <!-- Common template for social networks -->
        <a
            href="{{ socialNetwork.data }}"
            target="_blank"
            rel="noopener noreferrer"
            (click)="handleClickOnSocialNetwork($event, socialNetwork.iconName)">
            <span hSrOnlyNoSnippet>
                {{ socialNetwork.label }}
                <ng-container i18n="@@hermes.footer.social.new-window">(new window)</ng-container>
            </span>

            <h-svg-icon
                [name]="socialNetwork.iconName"
                [class]="socialNetwork.iconName"
                class="social-network-icon"></h-svg-icon>
        </a>
    </li>
    } }
</ul>
