import { createFeatureSelector, createSelector } from "@ngrx/store";

import {
    conversionFunnelFeatureKey,
    State,
} from "../reducers/conversion-funnel.reducer";

export const selectConversionFunnelState = createFeatureSelector<State>(
    conversionFunnelFeatureKey,
);

export const selectProgressionState = createSelector(
    selectConversionFunnelState,
    (conversionFunnelState: State) =>
        conversionFunnelState.checkout.progressionState,
);

export const selectIsPaymentActive = createSelector(
    selectConversionFunnelState,
    (conversionFunnelState: State) =>
        conversionFunnelState.checkout.progressionState.isPaymentActive,
);

export const selectIsPaymentDone = createSelector(
    selectConversionFunnelState,
    (conversionFunnelState: State) =>
        conversionFunnelState.checkout.progressionState.isPaymentDone,
);

export const selectIsAccountActive = createSelector(
    selectConversionFunnelState,
    (conversionFunnelState: State) =>
        conversionFunnelState.checkout.progressionState.isAccountActive,
);

export const selectIsDeliveryActive = createSelector(
    selectConversionFunnelState,
    (conversionFunnelState: State) =>
        conversionFunnelState.checkout.progressionState.isDeliveryActive,
);

export const selectAccordionSummary = createSelector(
    selectConversionFunnelState,
    (conversionFunnelState: State) => conversionFunnelState.accordionSummary,
);

export const selectIsLoading = createSelector(
    selectConversionFunnelState,
    (conversionFunnelState: State) => conversionFunnelState.isLoading,
);

export const selectIsCartSubmited = createSelector(
    selectConversionFunnelState,
    (conversionFunnelState: State) =>
        conversionFunnelState.cart.isCartSubmitted,
);

export const selectHideErrorPayment = createSelector(
    selectConversionFunnelState,
    (conversionFunnelState: State) =>
        conversionFunnelState.checkout.hideErrorPayment,
);

export const selectRequiredErrorOnAddressStreetFields = createSelector(
    selectConversionFunnelState,
    (conversionFunnelState: State) =>
        conversionFunnelState.checkout.defaultAddress,
);

export const selectFocusState = createSelector(
    selectConversionFunnelState,
    (accessibilityState: State) => accessibilityState.checkout.focusState,
);

export const selectFocusOnEditShippingAddress = createSelector(
    selectConversionFunnelState,
    (accessibilityState: State) =>
        accessibilityState.checkout.focusState.focusOnEditShippingAddress,
);

export const selectIsAddressErrorFocusable = createSelector(
    selectConversionFunnelState,
    (accessibilityState: State) =>
        accessibilityState.checkout.isAddressErrorFocusable,
);

export const selectFocusOnNewCustomerEmail = createSelector(
    selectConversionFunnelState,
    (accessibilityState: State) =>
        accessibilityState.checkout.focusState.focusOnNewCustomerEmail,
);

export const selectIsShippingAddressBlocked = createSelector(
    selectConversionFunnelState,
    (accessibilityState: State) =>
        accessibilityState.checkout.isShippingAddressBlocked,
);

export const selectShippingAddressResponseError = createSelector(
    selectConversionFunnelState,
    (accessibilityState: State) =>
        accessibilityState.checkout.shippingAddressResponseError,
);

export const selectGoNextStep = createSelector(
    selectConversionFunnelState,
    (conversionFunnelState: State) => conversionFunnelState.checkout.goNextStep,
);
