import { GTMEventData } from "../types/gtm.type";
import { AnalyticsEvent } from "./analytics.event";

/**
 * Abstract class for call-to-action event
 */
export abstract class CallToActionAnalyticsEvent<DataType = any>
    implements AnalyticsEvent<GTMEventData, DataType>
{
    /**
     * Event name expected for every uaevent event
     */
    public name: string = "uaevent";

    /**
     * Event's data from component to format correctly event
     */
    public eventData: DataType;

    constructor(data: DataType) {
        this.eventData = data;
    }

    /**
     * Overloaded by each subclass with their own formatter
     * Return event formatted to send to GTM
     */
    public formatForGTM(): GTMEventData {
        return {
            event: this.name,
            eventcategory: "SingleProductPage",
        };
    }
}
