import { createReducer, on } from "@ngrx/store";

import * as ErrorsActions from "../actions/errors.actions";

export const errorsFeatureKey = "errors";

export interface State {
    hasCheckoutError: boolean;
}

export const initialState: State = {
    hasCheckoutError: false,
};

export const reducer = createReducer(
    initialState,

    on(ErrorsActions.setHasCheckoutError, (state, action) => ({
        ...state,
        hasCheckoutError: action.value,
    })),
);
