import { createAction, props } from "@ngrx/store";

import { Address } from "@hermes/api-model-address";
import { ErrorItem } from "@hermes/api-model-basket";

import {
    FocusState,
    ProgressionState,
} from "../reducers/conversion-funnel-state.types";

export const updateProgressionState = createAction(
    "[Checkout page] Switch some values from accordion state",
    props<{ newKeys: ProgressionState }>(),
);

export const editProgressionStateSaved = createAction(
    "[Checkout page] Save progressionstate to localstorage",
);

export const setHideErrorPayment = createAction(
    "[Checkout page] Set Error payment display status",
    props<{ value: boolean }>(),
);

export const setRequiredErrorOnAddressStreetFields = createAction(
    "[Checkout page] Set required error on address street fields",
    props<{ defaultAddress: Address }>(),
);

export const editFocusState = createAction(
    "[Accessiblity] edit accessibility focus state",
    props<{ keys: FocusState }>(),
);

export const editAddressErrorFocusable = createAction(
    "[Accessiblity] Edit value of addressError",
    props<{ isAddressErrorFocusable: boolean }>(),
);

export const accordionSummary = createAction(
    "[Checkout page] Accordion cart summary",
    props<{ value: boolean }>(),
);

export const editIsShippingAddressBlocked = createAction(
    "[Checkout page] Edit value of isShippingAddressBlocked",
    props<{ isShippingAddressBlocked: boolean }>(),
);

export const editShippingAddressResponseError = createAction(
    "[Checkout page] Edit value of ShippingAddressResponseError",
    props<{ shippingAddressResponseError: ErrorItem }>(),
);

export const goNextStep = createAction(
    "[Checkout page] go next step",
    props<{ nextStep: "delivery" | undefined }>(),
);

export const showLoader = createAction("[Checkout page] Show loader");

export const hideLoader = createAction("[Checkout page] Hide loader");

export const submitCart = createAction("[Checkout page] Cart Submited");
