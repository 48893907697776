@if (isGeoFilteringBannerDisplayed$ | async) {
<h-geo-filtering-banner (closeBanner)="closeGeoFilteringBanner()"></h-geo-filtering-banner>
}
<!-- bottom cookie banner -->
@if (cookieBanner$ | async; as cookieBanner) {
<h-cookie-banner [cookieBanner]="cookieBanner" (closeBanner)="closeCookieBanner()"></h-cookie-banner>
} @if (notificationsBanners$ | async; as notificationsBanners) {
<h-notification-banner
    class="header-notifications"
    [notificationsBanners]="notificationsBanners"
    (closeBanner)="closeNotificationsBanner()">
</h-notification-banner>
}
