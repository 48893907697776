<div
    class="parent-category"
    [class.active]="isActive()"
    (mouseenter)="onMouseEnterParentCategory()"
    (mouseleave)="onMouseLeaveParentCategory()">
    @if (isMenuCategory) {
    <button
        #menuEntryButton
        class="category-link label-large-uppercase bold"
        [class.active]="isActive()"
        role="menuitem"
        aria-haspopup="true"
        [attr.aria-controls]="'sub-menu-' + menuEntryNameHtmlClean"
        [attr.aria-expanded]="isActive()"
        (keydown.space)="toggleEntry($event)"
        (keydown.enter)="toggleEntry($event)"
        (keydown.escape)="closeMenu()"
        ><span [innerHTML]="menuEntry.name"></span
    ></button>
    } @else {

    <h-menu-bar-link
        #menuEntryLink
        [menuEntry]="menuEntry"
        class="menu-link label-large-uppercase bold"
        [class.active]="isActive()"
        role="menuitem"
        (menuLinkClicked)="closeMenu()"></h-menu-bar-link>

    } @if (isMenuCategory) {
    <div #subMenu [id]="'sub-menu-' + menuEntryNameHtmlClean" class="sub-menu" [class.show]="isActive()" aria-haspopup>
        <h-menu-sub-category [menuEntry]="menuEntry" (closeSubMenu)="closeMenu()"> </h-menu-sub-category>
    </div>
    }
</div>
