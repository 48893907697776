import { Injectable } from "@angular/core";

import { MenuEntryType } from "@hermes/api-model-shell";
import { MenuEntryItem } from "@hermes/states/menu";
import { AnalyticsService, EVENT_UAEVENT } from "@hermes/utils/analytics";

import { getEntryPimCode } from "../helpers/menu.helper";
import { MenuAnalytics, SHOP_NAV_TRAY_EVENT } from "../models/analytics.model";
import { MenuEntryItemWithEdito } from "../models/menu.model";

@Injectable()
export class MenuAnalyticsService {
    constructor(private analyticsService: AnalyticsService) {}

    public sendAnalyticsOnMenuEditoClick(
        menuEntry: MenuEntryItemWithEdito,
    ): void {
        this.analyticsService.sendRawData<MenuAnalytics>({
            event: EVENT_UAEVENT,
            eventcategory: SHOP_NAV_TRAY_EVENT,
            eventaction: `Lv1_${menuEntry.pimCode}`,
            eventlabel: `Lv2_${menuEntry.pimCode}_EDITO_IMG`,
        });
    }

    public sendAnalyticsOnMenuLinkClick(
        primaryEntry: MenuEntryItem | undefined,
        secondaryEntry: MenuEntryItem | undefined,
        menuEntry: MenuEntryItem,
    ): void {
        const analyticsData: MenuAnalytics = {
            event: EVENT_UAEVENT,
            eventcategory: SHOP_NAV_TRAY_EVENT,
            eventaction: "",
        };

        if (menuEntry.type === MenuEntryType.storyMenuEntry) {
            analyticsData.eventaction = menuEntry.eventType;
            analyticsData.eventlabel = menuEntry.name;
        } else {
            analyticsData.eventaction = primaryEntry
                ? `Lv1_${getEntryPimCode(primaryEntry)}`
                : `Lv1_${getEntryPimCode(menuEntry)}`;

            analyticsData.eventlabel = secondaryEntry
                ? `Lv2_${getEntryPimCode(secondaryEntry)}`
                : `Lv2_${getEntryPimCode(menuEntry)}`;

            if (primaryEntry && secondaryEntry) {
                analyticsData.event_lv3 = `Lv3_${getEntryPimCode(menuEntry)}`;
            }
        }

        this.analyticsService.sendRawData<MenuAnalytics>(analyticsData);
    }
}
