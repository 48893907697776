import { CommonModule } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";
import { Subscription } from "rxjs";

import { LayoutFacade } from "@hermes/aphrodite/layout";
import { Settings } from "@hermes/app-core";

@Component({
    selector: "h-loader",
    templateUrl: "./loader.component.html",
    styleUrls: ["./loader.component.scss"],
    imports: [CommonModule],
    standalone: true,
})
export class LoaderComponent implements OnInit, OnDestroy {
    public loaderImageUrl: SafeStyle | undefined;

    public loaderShown: boolean = false;

    private subscription: Subscription = new Subscription();

    constructor(
        private domSanitizer: DomSanitizer,
        private settings: Settings,
        private layoutFacade: LayoutFacade,
    ) {}

    public ngOnInit(): void {
        this.subscription.add(
            this.layoutFacade.selectLoaderState$.subscribe(
                (showloader: boolean) => {
                    if (showloader) {
                        this.setRandomLoader();
                    }
                    this.loaderShown = showloader;
                },
            ),
        );
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    /**
     * Function to set the loader url randomly
     */
    public setRandomLoader(): void {
        const loaderVariant = Math.floor(Math.random() * 6);
        this.loaderImageUrl = this.domSanitizer.bypassSecurityTrustStyle(
            `url('${this.settings.assetsUrlEditoContent}/Loader/loader-${loaderVariant}.gif')`,
        );
    }
}
