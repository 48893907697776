import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    effect,
    inject,
    signal,
} from "@angular/core";

import { Observable, Subscription } from "rxjs";

import { MenuEntryItem, cleanMenuEntryName } from "@hermes/states/menu";

import { stopEventPropagation } from "@hermes/utils-generic/helpers";
import { RouterService } from "@hermes/utils-generic/services/router";

import { isMenuCategory } from "../../../helpers/menu-bar.helper";
import { MenuBarLinkComponent } from "../menu-bar-link/menu-bar-link.component";

@Component({
    selector: "h-menu-parent-category",
    templateUrl: "./menu-parent-category.component.html",
    styleUrls: ["./menu-parent-category.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuParentCategoryComponent implements OnInit, OnDestroy {
    public static activeMenu = signal<MenuParentCategoryComponent | undefined>(
        undefined,
    );

    @ViewChild("menuEntryButton", { read: ElementRef })
    public menuEntryButton?: ElementRef<HTMLButtonElement>;
    @ViewChild("menuEntryLink", { read: MenuBarLinkComponent })
    public menuLinkComponent?: MenuBarLinkComponent;

    @Input({ required: true })
    public menuEntry!: MenuEntryItem;

    @Input({ required: true })
    public onCursorLeaveMenuBar$!: Observable<void>;

    public hoverEnterTimeout!: NodeJS.Timeout;
    public hoverLeaveTimeout!: NodeJS.Timeout;

    public activationOrigin = signal<"keyboard" | "mouse">("mouse");

    public isActive = signal(false);

    public isFocused = signal(false);

    public isMenuCategory!: boolean;

    public subscriptions: Subscription = new Subscription();

    public routerService = inject(RouterService);

    constructor() {
        effect(() => {
            if (this.isFocused()) {
                if (this.isMenuCategory && this.menuEntryButton) {
                    this.menuEntryButton.nativeElement.focus();
                } else if (!this.isMenuCategory && this.menuLinkComponent) {
                    this.menuLinkComponent.getFocusableElement().focus();
                }
            }
        });

        effect(
            () => {
                const activeMenu = MenuParentCategoryComponent.activeMenu();
                if (
                    this.activationOrigin() === "keyboard" &&
                    activeMenu !== this &&
                    this.isActive()
                ) {
                    this.isActive.set(false);
                }
            },
            { allowSignalWrites: true },
        );
    }

    public get menuEntryNameHtmlClean(): string {
        return cleanMenuEntryName(this.menuEntry.name);
    }

    @Input({ required: true })
    public set focused(focused: boolean) {
        this.isFocused.set(focused);
    }

    public ngOnInit(): void {
        this.isMenuCategory = isMenuCategory(this.menuEntry);
        this.subscriptions.add(
            this.onCursorLeaveMenuBar$.subscribe(() => {
                this.isActive.set(false);
            }),
        );
    }

    public onMouseEnterParentCategory() {
        clearTimeout(this.hoverLeaveTimeout);
        this.hoverEnterTimeout = setTimeout(() => {
            this.activationOrigin.set("mouse");
            this.isActive.set(true);
        }, 175);
    }

    public onMouseLeaveParentCategory() {
        clearTimeout(this.hoverEnterTimeout);
        this.hoverLeaveTimeout = setTimeout(() => {
            this.isActive.set(false);
        }, 300); // Timeout is greater on leave to not have the sub menu flickering from each category
    }

    public toggleEntry(event: Event): void {
        stopEventPropagation(event);
        this.activationOrigin.set("keyboard");

        if (this.isActive()) {
            this.isActive.set(false);
        } else {
            this.isActive.set(true);
            MenuParentCategoryComponent.activeMenu.set(this);
        }
    }

    public closeMenu(): void {
        this.isActive.set(false);
        MenuParentCategoryComponent.activeMenu.set(this);
        if (this.isMenuCategory && this.menuEntryButton) {
            this.menuEntryButton.nativeElement.focus();
        } else if (!this.isMenuCategory && this.menuLinkComponent) {
            this.menuLinkComponent.getFocusableElement().focus();
        }
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
